.createConv_body.venue_create .addedMemberList {
    max-height: 350px;
    overflow: auto;
}
.headerMark {
    display: flex;
    align-items: center;
    grid-gap: 20px;
    gap: 20px;
}
.headerMark label.inputLabel {
    width: 55%;
}
.markAllChecked {
    display: inline-block;
    /* grid-gap: 12px;
    gap: 12px; */
    width: 45%;
    
}
.createGroupConv.venueIndivisual .inputGroup.inCreateReport {
    padding-right: 0;
}
/* Checkboxes*/
.checkbox-wrapper .checkbox {
    display: table-cell;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
  }
  
  .checkbox-wrapper label {
    display: inline-block;
    color: #032e84;
    cursor: pointer;
    position: relative;
    font-size: 14px;
  }
  
  .checkbox-wrapper label span {
    display: inline-block;
    position: relative;
    background-color: transparent;
    width: 22px;
    height: 22px;
    transform-origin: center;
    border: 2px solid #0b1f47;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 6px;
    transition: background-color 150ms 200ms, transform 350ms cubic-bezier(0.78, -1.22, 0.17, 1.89);
    top: 1px;
  }
  
  .checkbox-wrapper label span:before {
    content: "";
    width: 0px;
    height: 2px;
    border-radius: 2px;
    background: #0b1f47;
    position: absolute;
    transform: rotate(45deg);
    top: 8px;
    left: 6px;
    transition: width 50ms ease 50ms;
    transform-origin: 0% 0%;
  }
  
  .checkbox-wrapper label span:after {
    content: "";
    width: 0;
    height: 2px;
    border-radius: 2px;
    background: #0b1f47;
    position: absolute;
    transform: rotate(305deg);
    top: 12px;
    left: 8px;
    transition: width 50ms ease;
    transform-origin: 0% 0%;
  }
  
  .checkbox-wrapper label:hover span:before {
    width: 5px;
    transition: width 100ms ease;
  }
  
  .checkbox-wrapper label:hover span:after {
    width: 10px;
    transition: width 150ms ease 100ms;
  }
  
  .checkbox-wrapper input[type=checkbox] {
    display: none;
  }
  
  .checkbox-wrapper input[type=checkbox]:checked + label span {
    background-color: #0b1f47;
    transform: scale(1.1);
  }
  
  .checkbox-wrapper input[type=checkbox]:checked + label span:after {
    width: 10px;
    background: #fff;
    transition: width 150ms ease 100ms;
  }
  
  .checkbox-wrapper input[type=checkbox]:checked + label span:before {
    width: 5px;
    background: #fff;
    transition: width 150ms ease 100ms;
  }
  
  .checkbox-wrapper input[type=checkbox]:checked + label:hover span {
    background-color: #0b1f47;
    transform: scale(1.1);
  }
  
  .checkbox-wrapper input[type=checkbox]:checked + label:hover span:after {
    width: 10px;
    background: #fff;
    transition: width 150ms ease 100ms;
  }
  
  .checkbox-wrapper input[type=checkbox]:checked + label:hover span:before {
    width: 5px;
    background: #fff;
    transition: width 150ms ease 100ms;
  }
  .markAllChecked.recip .checkbox-wrapper {
    flex: 1 1 33%;
}
.maintainterUserArea {
  gap: 20px;
  margin-top: 6px;
}
.venueSettingArea .markCheckedFields .checkbox-wrapper {
  flex: 1 1 33%;
}
.forMaintanenceTap .checkbox-wrapper:nth-of-type(4) {
  flex-basis: 55% !important;
}
.addNewLabel:hover span{
  color: #318fff;
}
.inputLabel, .inputLabelPay{
  width: 100%;
  display: flow-root;
  color: #002e98;
  display: flex;
  align-items: center;
}
button.btnAction.disabled{
  pointer-events: none;
  opacity: 0.5;
}
.cu_doubleInputGroup .inputGroup {
  margin-bottom: 10px;
}
.tooltip1 .tooltiptext1.tooltipVenue {
  /* visibility: hidden; */
  width: 550px;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -215px;
  top: 24px;
  font-size: 14px;
  font-weight: normal;
  border: 2px solid #94b2ff;
}
.venueSettingArea .participantsModalBody {
  padding-left: 32px;
}


  