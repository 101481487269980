/* start Message area like whatsup*/
.user_msg.myArea {
  float: right;
  /* width: 100%; */
  clear: both;
  padding-left: 20px;
  padding-right: 40px;
  /* margin-right: 12%; */
}

.sender_info_myArea {
  width: 100%;
  display: flex;
  justify-content: right;
  padding-right: 8px;
}
.sender_info_area_userArea {
  width: 100%;
  display: flex;
  justify-content: left;
}

.sender_info_chat {
  display: flex;
  align-items: flex-start !important;
  justify-content: flex-start;
  flex-direction: column;
}

.msg_time {
  margin-left: 0px;
}

.user_msg.myArea .mag_inner {
  display: flex;
  flex-direction: row-reverse;
  gap: 8px;
}

.user_msg.othersArea .mag_inner {
  display: flex;
  gap: 8px;
}

.user_msg.myArea .msg_body {
  text-align: right;
  /* margin-bottom: 13px; */
}

.user_msg .msg_body {
  margin-bottom: 4px;
}

.user_msg.myArea .mediaContainer.chatScreenArea {
  justify-content: flex-end;
}

.user_msg.myArea .msgReactionContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 6px;
}

.msgReactionContainer:empty {
  display: none !important;
}

.user_msg.myArea .msgFlagReplyOption {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 2px;
  flex-direction: row-reverse;
}

.user_msg.myArea .msg_info {
  width: calc(100% - 10px);
}

.user_msg.myArea .msg_hover_options {
  right: unset;
  left: 0;
}

.user_msg.othersArea .msg_hover_options {
  right: 60px;
  left: unset;
}

.user_msg.othersArea {
  width: 60%;
  clear: both;
  padding-left: 0;
  margin-left: 12%;
}

.user_msg.othersArea .msg_info {
  width: calc(100% - 10px);
}

/* .user_msg:not(.checklistEditContainer .user_msg):hover {
  background-color: #F3F3F3;
} */

/* .user_msg:not(.checklistEditContainer .user_msg):hover {
    background-color: #F3F3F3 !important;
  } */
.mediaContainer.chatScreenArea {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  /* padding: 10px 0px 9px 13px; */
  /* background-color: #EBEEF5; */
  justify-content: flex-start;
}

.user_msg.othersArea .msg_body .msg_txt {
  background-color: #F3F4F6;
  color: #0b1f47;
  padding: 6px 13px 7px;
  font-size: 12px;
  width: max-content;
  text-align: left;
  float: left;
  border-radius: 5px;
  width: fit-content;
}

.user_msg.myArea .msgReaction {
  margin-right: 0 !important;
  margin-left: 10px !important;
}

.user_msg.myArea .divReplySection {
  justify-content: flex-end;
}

#root.dark .user_msg.myArea .msg_body .msg_txt {
  background-color: #f3f4f624;
}

#root.dark .user_msg.othersArea .msg_body .msg_txt {
  background-color: #f3f4f624;
}

/* #root.dark .mediaContainer.chatScreenArea {
    background-color: #ebeef524;
  } */

/* .user_msg.myArea .single_file .img_holder {
  float: right;
} */

/* .user_msg.myArea p.file_size {
  float: right;
}

.user_msg.myArea .single_file .file_name {
  float: right;
}

.user_msg.myArea .single_file .file_name {
  padding-right: 0;
} */

.gt3.single_file {
  max-width: unset !important;
  min-width: calc(50% - 20px) !important;
  flex: 1;
}

.attachFile.single_file {

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  min-height: 30px !important;
  font-size: 14px !important;
  font-weight: normal;
  max-width: calc(50% - 10px) !important;
  border-radius: 7px;
  position: relative;
  left: 25%;
}


.userArea {
  width: 100%;
  padding-left: 25px;
  /* padding-left: 50px; */
}


.user_msg.userArea .mag_inner {
  display: flex;
  grid-gap: 19px;
  gap: 19px;
  justify-content: flex-start;
}

.user_msg.userArea .msg_body {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 65px;
}

.user_msg.userArea .msg_body .msg_txt {
  background-color: #F3F4F6;
  /* color: #0b1f47; */
  color: #05112a;
  /* padding: 6px 13px 7px; */
  padding: 10px 15px;
  font-size: 12px;
  width: max-content;
  float: right;
  border-radius: 5px;
  width: fit-content;
  border-radius: 10px;
}

.user_msg.userArea .msg_body .msg_txt p {
  /* background-color: #F3F4F6; */
  color: #0b1f47;
  font-size: 12px;
  width: max-content;
  float: right;
  border-radius: 5px;
  width: fit-content;
  line-height: 1.5;
  flex-grow: 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b1f47;
}

.aiwidth{
  width: 100% !important;
}

/* .user_msg.userArea{
    width: 70%;
  }
   */

.user_msg.myArea .mag_inner {
  display: flex;
  flex-direction: row-reverse;
  grid-gap: 19px;
  gap: 19px;
}

.user_msg.myArea .msg_body {
  display: flex;
  gap: 8px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.user_msg.myArea .msg_body .msg_txt {
  background-color: #F3F4F6;
  color: #0b1f47;
  padding: 6px 13px 7px;
  font-size: 12px;
  width: max-content;
  text-align: left;
  float: right;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
}

.user_msg.myArea .msg_body .msg_txt p {
  /* background-color: #F3F4F6; */
  float: right;
  border-radius: 5px;
  width: -moz-fit-content;
  width: fit-content;
  flex-grow: 0;
  font-family: Poppins;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b1f47;
}

/* .user_msg.myArea{
    width: 70%;
  } */

.attachFileMorePlus {
  width: 265px;
  height: 30px;
  flex-grow: 0;
  display: flex;
  justify-content: center;
  padding: 4px;
  text-align: center;
  border-radius: 7px;
  background-color: #fff;
}

.Ellipse-31 {
  width: 12px;
  height: 12px;
  flex-grow: 0;
  margin: 3px 6px 3px 0;
  border-radius: 40px;
  border: solid 1.5px #8b57db;
  background-color: rgba(139, 87, 219, 0.2);
}

.Ellipse-44 {
  width: 12px;
  height: 12px;
  flex-grow: 0;
  margin: 3px 6px 3px 0;
  border-radius: 40px;
  border: solid 1.5px #f98;
  background-color: rgba(255, 153, 136, 0.23);
}

.fileTagHolderNew {
  width: 100%;
  background-color: transparent;
  position: relative;
  bottom: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  top: 6px;
}

.fileTagHolderNew:empty {
  display: none;
}

.file_hover_option_gql {
  position: absolute;
  border-radius: 4px;
  background-color: #f3f3f3;
  top: 1px;
  height: 40px;
  right: 1px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  display: none;
}

.gqlChatFileContainer {
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: 6px;
  position: relative;
  padding: 12px;
}

.gqlChatFileContainer:hover .file_hover_option_gql {
  display: flex !important
}

.user_msg.myArea .msg_body .msg_txt {
  background-color: #F3F4F6;
  color: #0b1f47;
  padding: 6px 13px 7px;
  font-size: 12px;
  width: max-content;
  text-align: left;
  float: right;
  border-radius: 5px;
  width: fit-content;

}

.myArea p.titleBar {
  flex-direction: row-reverse;
}

.linkIconForTitle {
  background-image: url("../media/images/link-svgrepo-com.svg");
}

.msg_title_link p {
  color: #318fff !important;
}

.msg_link_url {
  color: #7f8899 !important;
  text-decoration: underline;
}

/* End Message area like whatsup*/