
/* hebrew */
@font-face {
  font-family: 'hvAssistant';
  font-style: normal;
  font-weight: 400;
  src: local('Assistant'), local('Assistant-Regular'), url('2sDcZGJYnIjSi6H75xkzamW5O7w.woff2') format('woff2');
  unicode-range: U+0590-05FF, U+20AA, U+25CC, U+FB1D-FB4F;
  font-display: swap;
}

/* latin */
@font-face {

  font-family: 'hvAssistant';
  font-style: normal;
  font-weight: 400;
  src: local('Assistant'), local('Assistant-Regular'), url('2sDcZGJYnIjSi6H75xkzaGW5.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;

}

/* @font-face {
  font-family: "FontAwesome";
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.6.1") format("embedded-opentype"), 
      url("../fonts/fontawesome-webfont.woff2?v=4.6.1") format("woff2"), 
      url("../fonts/fontawesome-webfont.woff?v=4.6.1") format("woff"), 
      url("../fonts/fontawesome-webfont.ttf?v=4.6.1") format("truetype"), 
      url("../fonts/fontawesome-webfont.svg?v=4.6.1#fontawesomeregular") format("svg");
  font-style: normal;
  font-weight: normal;
} */


/* cyrillic-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('mem8YaGs126MiZpBA-UFWJ0bbck.woff2') format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  font-display: swap;

}
/* cyrillic */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('mem8YaGs126MiZpBA-UFUZ0bbck.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  font-display: swap;

}
/* greek-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('mem8YaGs126MiZpBA-UFWZ0bbck.woff2') format('woff2');
  unicode-range: U+1F00-1FFF;
  font-display: swap;

}
/* greek */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('mem8YaGs126MiZpBA-UFVp0bbck.woff2') format('woff2');
  unicode-range: U+0370-03FF;
  font-display: swap;

}
/* vietnamese */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('mem8YaGs126MiZpBA-UFWp0bbck.woff2') format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  font-display: swap;

}
/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('mem8YaGs126MiZpBA-UFW50bbck.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  font-display: swap;

}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('mem8YaGs126MiZpBA-UFVZ0b.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;

}


/* @font-face {
  font-family: "FontAwesome";
  src: url("../fonts/fontawesome-webfont.eot?#iefix&v=4.6.1") format("embedded-opentype"), 
      url("../fonts/fontawesome-webfont.woff2?v=4.6.1") format("woff2"), 
      url("../fonts/fontawesome-webfont.woff?v=4.6.1") format("woff"), 
      url("../fonts/fontawesome-webfont.ttf?v=4.6.1") format("truetype"), 
      url("../fonts/fontawesome-webfont.svg?v=4.6.1#fontawesomeregular") format("svg");
  font-style: normal;
  font-weight: normal;
} */

@font-face {
  font-family: 'SourceSansPro';
  src: url('SourceSansPro-Regular.eot');
  src: url('SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
       url('SourceSansPro-Regular.svg#SourceSansPro-Regular') format('svg'),
       url('SourceSansPro-Regular.ttf') format('truetype'),
       url('SourceSansPro-Regular.woff') format('woff'),
       url('SourceSansPro-Regular.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;

}
@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Regular.eot');
    src: url('Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Regular.woff2') format('woff2'),
        url('Poppins-Regular.woff') format('woff'),
        url('Poppins-Regular.ttf') format('truetype'),
        url('Poppins-Regular.svg#Poppins-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
} 
@font-face {
    font-family: 'Poppins';
    src: url('Poppins-ExtraLight.eot');
    src: url('Poppins-ExtraLight.eot?#iefix') format('embedded-opentype'),
        url('Poppins-ExtraLight.woff2') format('woff2'),
        url('Poppins-ExtraLight.woff') format('woff'),
        url('Poppins-ExtraLight.ttf') format('truetype'),
        url('Poppins-ExtraLight.svg#Poppins-ExtraLight') format('svg');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('Poppins-Light.eot');
    src: url('Poppins-Light.eot?#iefix') format('embedded-opentype'),
        url('Poppins-Light.woff2') format('woff2'),
        url('Poppins-Light.woff') format('woff'),
        url('Poppins-Light.ttf') format('truetype'),
        url('Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

/* Start rubik font */
@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Black.eot');
  src: url('Rubik-Black.eot?#iefix') format('embedded-opentype'),
      url('Rubik-Black.woff2') format('woff2'),
      url('Rubik-Black.woff') format('woff'),
      url('Rubik-Black.ttf') format('truetype'),
      url('Rubik-Black.svg#Rubik-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-BlackItalic.eot');
  src: url('Rubik-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('Rubik-BlackItalic.woff2') format('woff2'),
      url('Rubik-BlackItalic.woff') format('woff'),
      url('Rubik-BlackItalic.ttf') format('truetype'),
      url('Rubik-BlackItalic.svg#Rubik-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Bold.eot');
  src: url('Rubik-Bold.eot?#iefix') format('embedded-opentype'),
      url('Rubik-Bold.woff2') format('woff2'),
      url('Rubik-Bold.woff') format('woff'),
      url('Rubik-Bold.ttf') format('truetype'),
      url('Rubik-Bold.svg#Rubik-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-BoldItalic.eot');
  src: url('Rubik-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('Rubik-BoldItalic.woff2') format('woff2'),
      url('Rubik-BoldItalic.woff') format('woff'),
      url('Rubik-BoldItalic.ttf') format('truetype'),
      url('Rubik-BoldItalic.svg#Rubik-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Light.eot');
  src: url('Rubik-Light.eot?#iefix') format('embedded-opentype'),
      url('Rubik-Light.woff2') format('woff2'),
      url('Rubik-Light.woff') format('woff'),
      url('Rubik-Light.ttf') format('truetype'),
      url('Rubik-Light.svg#Rubik-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Italic.eot');
  src: url('Rubik-Italic.eot?#iefix') format('embedded-opentype'),
      url('Rubik-Italic.woff2') format('woff2'),
      url('Rubik-Italic.woff') format('woff'),
      url('Rubik-Italic.ttf') format('truetype'),
      url('Rubik-Italic.svg#Rubik-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-LightItalic.eot');
  src: url('Rubik-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('Rubik-LightItalic.woff2') format('woff2'),
      url('Rubik-LightItalic.woff') format('woff'),
      url('Rubik-LightItalic.ttf') format('truetype'),
      url('Rubik-LightItalic.svg#Rubik-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Medium.eot');
  src: url('Rubik-Medium.eot?#iefix') format('embedded-opentype'),
      url('Rubik-Medium.woff2') format('woff2'),
      url('Rubik-Medium.woff') format('woff'),
      url('Rubik-Medium.ttf') format('truetype'),
      url('Rubik-Medium.svg#Rubik-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-MediumItalic.eot');
  src: url('Rubik-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('Rubik-MediumItalic.woff2') format('woff2'),
      url('Rubik-MediumItalic.woff') format('woff'),
      url('Rubik-MediumItalic.ttf') format('truetype'),
      url('Rubik-MediumItalic.svg#Rubik-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Rubik';
  src: url('Rubik-Regular.eot');
  src: url('Rubik-Regular.eot?#iefix') format('embedded-opentype'),
      url('Rubik-Regular.woff2') format('woff2'),
      url('Rubik-Regular.woff') format('woff'),
      url('Rubik-Regular.ttf') format('truetype'),
      url('Rubik-Regular.svg#Rubik-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* End rubik font */


