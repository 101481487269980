.barChartCon {
    margin-top: 20px;
}

.barChartMainCon {
    width: 100%;
    display: flex;
    gap: 20px;
}

.barChartDayNameCon {
    width: 8%;
}

.barChartDayName {
    color: #0b1f47;
    font-size: 14px;
}

.barChartMainBg {
    width: 70%;
    height: 22px;
    border-radius: 10px;
    display: flex;
    margin-bottom: 15px;
}

.barChartCost {
    height: 22px;
    background-color: #032e84;
    border-radius: 10px;
    z-index: 10;
}

.barChartCostText {
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin-left: 5px;
    text-align: right;
    margin-top: 2px;
    margin-right: 10px;
}

.barChartSalesText {
    color: #0b1f47;
    font-size: 12px;
    font-weight: 500;
    margin-left: 16px;
    text-align: right;
    margin-top: 2px;
    margin-right: 10px;
}

.barChartSales {
    height: 22px;
    border-radius:10px;
    margin-left: -10px;
    background-color: #7ed6d0;
    z-index: 5;
}

.barChartHeader{
    display: flex;
    gap: 20px;
    margin-top: 25px;
}
.barChartSalesHead {
    height: 22px;
    border-radius: 20px;
    background-color: #032e84;
}
.barChartSalesHead p {
    font-size: 14px;
    color: White;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0px;
}
.barChartCostHead {
    height: 22px;
    border-radius: 20px;
    background-color: #7ed6d0;
}

.barChartCostHead p {
    font-size: 14px;
    color: #0b1f47;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 0px;
}
.doughnut_chart_eod{
    display: flex;
    gap: 100px;
    align-items: center;
}
.roundCircleColor{
    width: 10px;
    height: 10px;
    border-radius: 100%;
}
.chartRightSide{
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 24px;
}
.chartRightSideText p {
    font-size: 14px;
    color: #0b1f47;
}
.totalSaleHead div{
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    /* text-align: left; */
    color: #0b1f47;
}