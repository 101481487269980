html,
body,
#root {
    height: 100% !important;
    overflow: hidden;
}

:root {
    --B1: 1px solid #d8d8d8;
    /* --F1: 'Poppins'; */
    --F1: 'Poppins';
    /* --F2: "Open SansBlack"; */
    --FA: "FontAwesome";
    --BR4: 4px;
    --BR14: 14px;
    --MRT: 8px;
    --C0: #000000;
    --CD8: #d8d8d8;
    --C16: #0084ff;
    --FFF: #FFFFFF;
    --AIC: #d9e2e8;
    --NewRed: #E91E63;
    --NewblueC: #2196F3;
    --PrimaryC: #023d67;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    /*font-family: 'hvAssistant';*/
    font-family: 'Poppins';
}

.raise-hand-cls {
    position: absolute;
    margin: 15px;
    cursor: pointer;
    background: #181818;
    border-radius: 25%;
}

.raise-hand-img {
    width: 60px;

}

.raise-hand-count {
    color: white;
    text-align: center;
    background: #165ecc;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: inline-block;
    font-size: 14px;
    font-weight: bold;
    right: -10px;
    position: absolute;
    bottom: -5px;
    display: none;

}



.myCallModal .modal-content {
    /*background-color:#2E302D;*/
    top: 134px;
    height: 436px;
    width: 384px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.12), 0 6px 12px 0 rgba(0, 0, 0, 0.24);
    position: relative;
    transform: translateX(-50%);
    left: 50%;
    max-height: unset !important;
    max-width: unset !important;
}

/*.myCallModal .modal-footer{
    border:none !important;
    display: block !important;
}
*/
.myCallModal .modal-body {
    padding-top: 47px;
}

/* --- */
.call_ringtone_audio {
    display: none;
}

.calling_userspace {
    position: absolute;
    left: 45%;
    right: unset;
    top: 30%;
    bottom: unset;
    height: 14px;
    width: 24px;
    border-radius: 8px;
}

.myCallModal {
    /*display: none;*/
    margin-bottom: 10px;
    /* left: unset; */
    /* right: 1%; */
    /* top:50px; */
}

.myCallModal .modal-content {
    top: 0px;
    width: 230px;
    height: 230px;
    animation: glow 1s infinite alternate;
}

.myCallModal .calling_userimg {
    width: 60px;
    height: 60px
}

.myCallModal .modalCallMsg {
    font-size: 16px
}

.myCallModal .modal-body {
    padding-top: 10px;
}

.mergeBtnGroup {
    display: flex;
    justify-content: space-around;
    height: 40px;
    width: 85%;
    margin: 10px auto 15px auto;

}

.modalCallReject {
    /* position: absolute; */
    /* left: 15%; */
    right: unset;
    /* top: 90%; */
    bottom: unset;
    width: 36px;
    height: 36px;
    cursor: pointer;
    opacity: 0.87;
    /* transform: translate(-15%,-90%); */
}

.modalCallAccept {
    /* position: absolute; */
    /* left: 50%; */
    right: unset;
    /* top: 91%; */
    bottom: unset;
    height: 36px;
    width: 36px;
    cursor: pointer;
    /* transform: translate(-50%,-91%); */
}

.modalCallTextMsg {
    /* position: absolute; */
    /* left: 85%; */
    right: unset;
    /* top: 90%; */
    bottom: unset;
    cursor: pointer;
    width: 36px;
    height: 36px;
    opacity: 0.87;
    display: none;
    /* transform: translate(-85%,-90%); */
}

/* --- */

.modal-backdrop {
    background: none !important;

}

#call_userimg {
    margin-left: auto;
    margin-right: auto;
    display: block;
    border-radius: 50px;
    max-width: 192px;
    max-height: 192px;
    position: absolute;
    text-align: center;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40%;
}

.calling_userimg {
    display: block;
    margin: 0 auto;
    border-radius: 50px;
    height: 184px;
    width: 184px;
}

.modalCallMsg {
    height: 36px;
    color: #FFFFFF;
    font-size: 32px;
    font-weight: normal;
    letter-spacing: -0.4px;
    line-height: 36px;
    text-align: center;
    margin: 16px 0 0 0;
}

.modal_incomingcall {
    height: 16px;
    color: rgba(255, 255, 255, 0.64);
    font-size: 12px;
    letter-spacing: 0.17px;
    line-height: 16px;
    text-align: center;
    margin: 8px 0 0 0;
    text-transform: uppercase;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.modal_callstatus {
    height: 16px;
    color: rgba(255, 255, 255, 0.64);
    font-size: 12px;
    letter-spacing: 0.17px;
    line-height: 16px;
    text-align: center;
    margin: 8px 0 0 0;
}



.backwrapCall {
    width: auto;
    height: 100%;
    display: block;
    overflow: auto;
    position: fixed;
    top: 15px;
    right: 50px;
    /* background: rgba(0, 0, 0, 0.4); */
    z-index: 100000000000;
}








.call_body {
    height: 100%;
    width: 100%;
    background: black;
    position: absolute;
    overflow: hidden;
}

.calling_head {
    width: 100%;
    height: 8%;
    top: 0;
    position: absolute;
    background: black;
    display: flex;
    box-sizing: border-box;

}

.calling_head span {
    color: white;

    vertical-align: middle;
    text-align: center;
    flex: 1;
    margin: auto auto !important;
}

.calling_body {

    height: 100%;



}

.call_setting {
    width: 70%;
    max-height: 350px;
    height: 70%;
    max-width: 800px;
    z-index: 111;
    position: absolute;
    margin: auto auto;
    background: white;
    right: 0 !important;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
    border-radius: 5px;
    border: 1px solid gray;

}

.row1 {


    padding-left: 10px;
    margin-bottom: 0px;
    padding: 10px;

}

.text_row1 {
    font-size: 19px;
}




/*.row1 > div{
    margin:auto auto;
    height: 50%;
}
*/

.row2 {
    height: 270px;
    width: 100%;
    background: white;

}

.row2 .left {

    height: 100%;
    width: 48%;
    float: left;

    display: flex;

}

.row2 .right {

    height: 100%;
    width: 48%;
    float: right;

}

.left .video_sample {
    margin: auto auto;
    height: 80%;
    width: 85%;
    border-radius: 5px;
    background: black;

}

.video_setting {
    padding: 10px;


}

.video_setting div {
    margin-top: 10px;
    font-size: 17px;
    color: black;
}

.select {
    width: 90%;
    border: 1px solid grey;
    font-size: 16px;
    padding: 7px;
    margin-top: 5px;
    border-radius: 5px;
}

.calling_foot {
    font-size: 10px;
    width: 100%;
    height: 15%;
    bottom: 10px;
    position: absolute;
    background: black;
    display: flex;
    box-sizing: border-box;
}

.row3 {
    height: auto;
}

.continue {

    /*    width: 120px;
    height: 30px;*/
    background: var(--PrimaryC);
    color: white;
    font-size: 16px;
    padding: 7px 10px;
    float: right;
    cursor: pointer;
    border-radius: 5px;
    margin: -10px 15px 2px 0px;


}

.left_icon {

    color: white;
    border-radius: 20px;
    padding: 10px 0px;
    margin: auto 20px !important;
    text-align: center;
    width: 5.5%;
    cursor: pointer;


}

.option_list span:hover {
    background: #E91E63;
    color: white;
    border-radius: 5px;
}

.option_list span {
    padding: 5px 15px;
    display: table;
    color: black;
    font-size: 13px;
    cursor: pointer;
    width: auto;

    margin-bottom: 5px;
}

.option_list {
    background: white;
    border-radius: 5px;
    padding: 10px;
    position: absolute;
    width: 350px;
    height: auto;
    bottom: 100px;
    left: 15px;
    z-index: 9999;
}

.close_btn {
    background: url(../public/media/images/close_button.svg) no-repeat;
    background-size: 90%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    float: right;
    cursor: pointer;
}

.merge_call_btn {
    background: url(../public/media/images/call/call_merge.png) no-repeat;
    background-size: 90%;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 45px;
    top: 15px;
    float: right;
    cursor: pointer;
}

/*.left_icon:active .option_list{
    display: block;
}*/

.center_icon {

    color: white;
    border-radius: 20px;
    padding: 10px;
    margin: auto auto !important;
}


.center_icon div {
    display: inline-grid;
    margin-right: 25px;
    text-align: center;
    flex-wrap: wrap;
    cursor: pointer;
    width: 5.5%;
}

.calling_foot .setting_icon {
    color: white;
    width: 45px;
    height: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/switch_video_48px.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}


.calling_foot .mute_icon {
    color: white;

    width: 45px;
    height: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/mute-off_48px_red.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}

.calling_foot .chat_icon {
    color: white;
    height: 45px;
    width: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/message_56px.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}


.calling_foot .screen_icon {
    color: white;
    height: 45px;
    width: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/screen-share-on_48px.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}

.calling_foot .hand_icon {
    color: white;
    height: 45px;
    width: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/raise-hand.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}

.calling_foot .camera_icon {
    color: white;
    height: 45px;
    width: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/video-off_48px_red.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}

.calling_foot .leave_icon {
    color: white;
    height: 45px;
    width: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/hang-up_56px.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}




.calling_foot .invite_icon {
    color: white;
    height: 45px;
    width: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/add-contact_48px.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}

.calling_foot .option_icon {
    color: white;
    height: 45px;
    width: 45px;
    margin: auto auto;
    text-align: center;
    justify-content: center;
    background: url(../public/media/images/call/MoreMenu.svg) no-repeat;
    background-size: 70%;
    background-position: 50%;
}


.member_text {
    display: inline-block;
    font-size: 25px;
    font-weight: bolder;
    color: var(--PrimaryC);
    margin-top: -10px;
}


.conf_text {
    display: inline-block;
    font-size: 18px;

    color: var(--PrimaryC);
    margin-top: -10px;
}


.grey_text {
    font-size: 14px;
    color: grey;
    margin-left: 5px;
}

.member_search {
    display: block;
    font-size: 14px;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #D1CCD4;
    width: 100%;
    margin: auto auto;
    margin-top: 5px;
    font-family: var(--F1);

}

.member_search::placeholder {
    font-weight: 600;
    font-size: 14px;
}

/*.left_content{
    position: relative;
}*/
.right_content {
    position: fixed;
    right: 0;
    /* float: right; */
    width: 315px;
    height: 100%;
    background: white;
    border-radius: 5px;
    overflow: hidden;
    /*  display: inline-grid;*/
}


.right_div_top {
    height: auto;
    width: 92%;
    margin: 18px auto;
}

.friend_section {

    height: 82%;
    width: 92%;
    margin: auto auto;
    overflow-y: auto;

}

.list_friend {
    width: 100%;
    display: grid;
    padding: 10px;
    margin-bottom: 10px;
    grid-template-columns: 50px auto;
    padding: 10px 10px;
    border: 1px solid #D1CCD4;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
}

.list_friend:hover {

    border: 1px solid #5CD4EF;
    background: #EFEFEF;

}


.image {
    position: relative;
}

.image img {
    width: 40px;
    height: 40px;
    border-radius: 50%;

}

.list_friend.online .image::after {
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    right: 8px;
    bottom: 8px;
    background-image: url(../public/media/images/OnlineStatus.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 24px;
}

.list_friend.offline .image::after {
    content: "";
    width: 13px;
    height: 13px;
    position: absolute;
    right: 8px;
    bottom: 8px;
    background-image: url(../public/media/images/OfflineStatus.svg);
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 24px;
}

.friend_name {
    display: inline;
    margin: auto 0;
    font-weight: 600;
    color: var(--PrimaryC);
    font-size: 14px;

}

.friend_status {
    display: inline;
    margin-left: 5px;
    font-size: 12px;
    color: #002e98;

}

/*.friend_status::before {
  content: "("
}

.friend_status::after {
  content: ")"
}*/

.chat_img {
    vertical-align: middle;
    margin-right: 5px;
}

.voip_loader {
    background-color: #474747;
    background-image: url(../public/media/images/loginBefore/WorfreeliCircularDots.svg);
    background-repeat: no-repeat;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 150px;
    /* border: 1px solid #0c1e47; */
    width: 100vw;
    height: 100vh;
}

.voip_loader_status {
    background-image: url(../public/media/images/loginBefore/WorfreeliCallConnect.svg);
    background-repeat: no-repeat;
    /*background-position-x: 50%;*/
    background-position-y: 50%;
    background-size: 50px;
    /* border: 1px solid #0c1e47; */

    height: 10px;
    /*margin-top: 5px;*/
    width: 50px;
    display: inline-block;
}

.voip_loader_dot {
    background-image: url(../public/media/images/loginBefore/WorfreeliCallConnect.svg);
    background-repeat: no-repeat;
    /*background-position-x: 50%;*/
    background-position-y: 50%;
    background-size: 50px;
    /* border: 1px solid #0c1e47; */

    height: 10px;
    /*margin-top: 5px;*/
    width: 50px;
    display: inline-block;
    top: 82%;
    position: absolute;
    left: calc(50% - 25px);
    color: white;
    font-size: 18px;
    /*transform: translate(-50%,-77% );*/
}

.rotate180 {
    transform: rotate(180deg);
}

.connect_status {
    top: 80%;
    position: absolute;
    left: 50%;
    color: white;
    font-size: 18px;
    transform: translate(-50%, -75%);
    -webkit-animation: fade-in-out 3s infinite;
    -moz-animation: fade-in-out 3s infinite;
    -o-animation: fade-in-out 3s infinite;
    animation: fade-in-out 3s infinite;
}

.callMutePopup {
    z-index: 1000000000000000000000000;
}

.hide_loader {
    display: none;
}

body#connectBody #jitsi-container iframe {
    display: inline !important;
}

/*FADE IN-OUT*/
@-webkit-keyframes fade-in-out {

    0%,
    100% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

}

@-moz-keyframes fade-in-out {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        oapcity: 1;
    }
}

@-o-keyframes fade-in-out {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        oapcity: 1;
    }
}

@keyframes fade-in-out {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        oapcity: 1;
    }
}

.dot-flashing::before,
.dot-flashing::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
}

.dot-flashing {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #57d186;
    color: #57d186;
    animation: dotFlashing 1s infinite linear alternate;
    animation-delay: 1s;
    top: 77%;
    position: absolute;
    left: 50%;
    font-size: 18px;
    transform: translate(-50%, -77%);
}



.dot-flashing::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #57d186;
    color: #57d186;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 0s;
}

.dot-flashing::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #57d186;
    color: #57d186;
    animation: dotFlashing 1s infinite alternate;
    animation-delay: 2s;
}

@keyframes dotFlashing {
    0% {
        background-color: #9880ff;
    }

    50%,
    100% {
        background-color: #0C1E47;
    }
}
.expensesIcon {
    width: 24px;
    height: 20px;
    background-image: url("./leftIcons/newleftIconlt.png");
    background-size: 23px;
    background-position: 50%;
    background-repeat: no-repeat;
  }