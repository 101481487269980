@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./media/fonts/font.css");
@import url("./media/fonts/all.css");

[disabled] {
  pointer-events: none !important;
  opacity: 0.5 !important;

}

.App {
  text-align: center;
}

.rdw-suggestion-option {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently */
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: var(--PrimaryC)
}

::selection {
  color: white;
  background: var(--PrimaryC)
}

/***************/
/* Connect CSS */
/***************/

:root {
  --B1: 1px solid #d8d8d8;
  /* --F1: "SourceSansPro"; */
  /* --F2: "Open SansBlack"; */
  --F1: "Poppins";
  --FA: "FontAwesome";
  --BR4: 4px;
  --BR14: 14px;
  --MRT: 8px;
  --C0: #000000;
  --CD8: #d8d8d8;
  --C16: #0084ff;
  --FFF: #ffffff;
  --AIC: #d9e2e8;
  --NewRed: #e91e63;
  --NewblueC: #2196f3;
  --PrimaryC: #0c1e47;

  --Smalt: #002e98;
  --Anakiwa: #94b2ff;
  --Mercury: #e8e8e8;
  --DeepSapphire: #0b1f47;
  --Cerise: #e82a87;
  --AzureRadiance: #028bff;
  --Emerald: #57d186;
  --Alto: #d8d8d8;
  --Downriver: #0d2150;
  --Supernova: #ffcd00;
  --Tundora: #464646;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  /*font-family: 'hvAssistant';*/
  /* font-family: "SourceSansPro"; */
  font-family: "Poppins";

}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

.app_container {
  width: 100%;
  height: 100vh;
}

.main_container {
  /* background-color: #ff55551f; */
  background-color: #fff;
  height: 100vh;
  width: 100%;
}

.main_header {
  height: 64px;
  width: calc(100% - 346px);
  background-color: #fff;
  float: right;
  display: none;
}

.main_header .part_head {
  width: 33%;
  height: 100%;
  float: left;
}

.main_menu {
  margin: 0px 20px;
  padding: 0px;
  height: 100%;
  width: 100%;
}

.main_menu li {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  color: #0c1e47;
  height: 100%;
  cursor: pointer;
  float: left;
  font-size: 22px;
  font-weight: normal;
  border-top: 5px solid #0c1e47;
}

.workFreeli {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0c1e47;
  /*font-family: hvAssistant;*/
  font-size: 30px;
  font-weight: 600;
  cursor: pointer;
  letter-spacing: 2px;
  height: 100%;
}

.right_head {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.user_icon {
  width: 36px;
  height: 36px;
  border-radius: 10px;
  margin-right: 10px;
  cursor: pointer;
}

.user_icon+.user_icon {
  margin-right: 30px;
  position: relative;
  border: 2px solid #00ff00;
}

.user_icon+.user_icon::after {
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50px;
  position: absolute;
  background-image: url("./media/images/Down_Chevron.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%;
  top: 6px;
  right: -30px;
  transition: all 300ms ease-out;
}

.user_icon+.user_icon.active::after {
  transform: rotate(180deg);
}

.user_icon img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.left_container {
  width: 346px;
  height: 100%;
  float: left;
  background-color: #0b1f47;
  transition: width 400ms;
  position: relative;
}

.left_top_bar {
  height: auto;
  width: 100%;
  position: relative;
  /* padding: 0px 42px 2px; */
  /* padding: 0px 17px 2px; */
  /* background-color: #0c1e47; */
  background-color: transparent;

}

.left_top_bar.maiLeftBar {
  /* new css*/
  /* margin-bottom: 20px; */
  /* border-bottom: 1px solid;
    border-color: rgba(227, 242, 255, 0.26); */
  border-bottom: 0.067px solid #475674;
}

.archiveBorderTop {
  border-top: 1px solid #475674;
}

.left_top_bar .logoSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 31px;
  padding-right: 31px;
  width: 100%;
  height: 75px;
}

.left_top_bar .freeli_logo {
  background-image: url("./media/images/WFLogo.webp");
  background-position: center;
  width: 132px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100%;
}

.switch_section {
  display: flex;
  align-items: center;
  justify-content: center;
}

#root.dark .left_top_bar .freeli_logo {
  background-image: url("./media/images/WFLogo.webp");
}

#root.dark input[type="email"]:disabled,
input[type="text"]:disabled {
  color: #9b9b9b !important;
}

#root.dark .checkListPlainText.title:empty:before {
  color: #9b9b9b !important;
}

.tooltipClose {
  /* background-image: url("./media/images/close_button_w.svg"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_exit outline.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  position: absolute;
  top: 4px;
  width: 20px;
  height: 20px;
  right: 3px;
  /* border: 1px solid #ffffff;
  border-radius: 50px; */
}

.left_top_bar .freeli_tab {
  width: auto;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 1px;
  line-height: 25px;
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
  top: 1px;
  left: -11px;
}

.tabArrow {
  width: 14px;
  height: 14px;
  background-image: url('./media/images/down_angle.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  margin-left: 6px;
  position: relative;
  bottom: -2px;
  cursor: pointer;
}

.left_top_bar .filtrSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /*after change design*/
  border-radius: 8px;
  background-color: #1c3056;
  height: 72px;
  padding: 0 14px;
  margin: 0 17px;
}

.left_top_bar .userLogo {
  box-sizing: border-box;
  height: 50px;
  width: 50px;
  /* border: 2px solid #e82a87; */
  border-radius: 50%;
  cursor: pointer;
  /* display: flex; */
  display: inline-block;
  align-items: center;
  justify-content: center;
  position: relative;
  user-select: none;
  /* padding-left: 2px;
  padding-top: 2px; */
  padding: 2px;

}

.left_top_bar .userLogo img {
  width: 45.9px;
  height: 45.9px;
  border-radius: 50%;
  user-select: none;
  object-fit: cover;
}

.userLogo.active {
  pointer-events: none;
}

.filtrSection .filtrs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.filtrs .filtrMute {
  width: 24px;
  height: 24px;
  margin-right: 26px;
  cursor: pointer;
  background-image: url("./media/images/mute-icon.png");
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
}

.filtrs .filtrMute.active {
  pointer-events: none;
}

span.filtrMute .unreadCounter {
  position: absolute;
  top: -15px;
  right: -9px;
  margin: 0;
}

.userLogo .unreadCounter {
  position: absolute;
  top: -10px;
  right: -5px;
  margin: 0;
}

.userLogo .unreadCounter:empty {
  display: none;
}

/*after new leftbar design */
.flipNavIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: transparent;
  top: 0px;
  right: 0px;
  cursor: pointer;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.flipNavIcon::after {
  content: "";
  background-image: url("./media/images/gear_w.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 17px;
  width: 17px;
  height: 17px;
  display: flex;
  /* position: absolute;
  right: -24px; */
  transition: all 0.3s ease;
}


.userLogo.active .flipNavIcon::after {
  transform: rotate(180deg);
}

.filtrs .filtrFilter {
  width: 24px;
  height: 24px;
  margin-right: 26px;
  cursor: pointer;
  background-image: url("./media/images/filter-left.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
}

.filtrs .filtrSearch {
  width: 20px;
  height: 20px;
  cursor: pointer;
  /* background-image: url("./media/images/png/search-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_search.svg");
  background-position: 50%;
  background-repeat: no-repeat;
}

.leftFilterInfo {
  width: 17px;
  height: 17px;
  background-color: #ffffff;
  border-radius: 50%;
  background-image: url("./media/images/conv-i-new-bb.png");
  background-size: 18px;
  background-position: 50%;
  background-repeat: no-repeat;
  margin-left: 8px;
  cursor: pointer;
}

.leftFilterSection {
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.leftFilterSection span {
  width: 28px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}

.leftSearchIcon {
  background-image: url("./media/images/search_icon.svg");
  background-size: 16px;
}

.leftSearchIcon:hover {
  background-image: url(./media/images/search_icon_red.svg);
}

.leftFilterIcon {
  background-image: url("./media/images/filter.svg");
  background-size: 17px;
}

.leftFlagIcon {
  background-image: url(./media/images/NotFlagged.svg);
  background-size: 22px;
}

.leftUnreadIcon {
  background-image: url(./media/images/newMessageHovAc.svg);
  background-size: 18px;
}

.leftUnreadIcon:hover {
  background-image: url(./media/images/newMessageHovRed.svg);
}

.leftSearchSection {
  display: flex;
  align-items: center;
  width: 83%;
}

.leftSearchSection input {
  background-image: url(./media/images/search_icon.svg);
  background-size: 16px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  padding-left: 20px;
  color: #fff;
  margin-right: 10px;
  width: 85%;
  font-size: 14px;
}

.leftSearchSection input::placeholder {
  color: #ffffff;
  font-size: 14px;
}

.closeLeftSearch {
  width: 18px;
  height: 18px;
  background-image: url("./media/images/cancel_round_red.svg");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.group_container_leftSideAdmin {
  width: 100%;

}

.group_container {
  width: 100%;
  height: calc(100% - 456px);
  overflow-y: auto;
  background-color: transparent;
}

.group_container.withreply {
  width: 100%;
  height: calc(100% - 495px);
  overflow-y: auto;
  /* background-color: #0c1e47; */
  background-color: transparent;
}

.threadMsgBar {
  width: 100%;
  height: 35px;
  background-color: var(--NewRed);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.17px;
  cursor: pointer;
}

.list_container_title {
  /* color: #94b2ff; */
  color: #e3f2ff;
  font-size: 14px;
  font-weight: 300;
  padding: 0 20px 0 42px;
  line-height: 36px;
  padding-left: 54px;
  margin: 0px 0 10px;
  margin-bottom: 5px;
}

h3.list_container_title.admin_left {
  padding-left: 32px;
  font-size: 20px;
  margin-top: 0px;
}

.list_container_title.create_conv {
  position: relative;
  cursor: pointer;
  margin-top: 10px;
}

.list_container_title.create_conv span.createIcon {
  position: absolute;
  right: 20px;
  top: 6px;
  width: 24px;
  height: 24px;
  background-image: url('./media/images/plus.webp');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  /* background-color: #94b2ff; */
  border-radius: 50%;
  z-index: 1;
}

.conv_list {
  list-style: none;
  width: 100%;
  color: #e8e8e8;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 36px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  height: 36px;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 14px 50%;
  padding: 0 0px 0 42px;
  padding-left: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 300;
}

.conv_list:hover {
  background-color: #0C2854;
}

.conv_list.selected {
  background-color: #243A66;
  font-weight: 300;
}

.conv_list.selected.hide_select {
  background-color: unset;
  font-weight: normal;
}

span.conv_list_Opt {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: absolute; */
  right: 20px;
  z-index: 3;
  margin-right: 19px;
}

span.conv_list_Opt:empty {
  display: none;
}

span.conv_list_mute {
  width: 20px;
  height: 20px;
  background-image: url(./media/images/muteIcon.webp);
  background-repeat: no-repeat;
  background-position: 50%;
}

span.temp_user_remove::before {
  width: 24px;
  height: 24px;
  content: "\f057";
  font-family: var(--FA);
  font-weight: 600;
  color: var(--NewRed);
  font-size: 20px;
}


span.unreadCounter {
  height: 24px;
  width: 24px;
  background-color: #7b40d5;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

span.unreadCounter:empty {
  display: none;
}

.conv_list.strict {
  color: #f5f9fc;
  font-size: 14px;
  font-weight: 300;
  padding-left: 56px;
}

.conv_list.notify {
  background-image: url(./media/images/bell.svg);
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.conv_list.health {
  background-image: url(./media/images/health.svg);
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.conv_list.filepanel {
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_files.svg");
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.conv_list.taskpanel {
  background-image: url(./media/images/png/taskPanel.png);
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.conv_list.taskmanagementpanel {
  background-image: url('./media/images/png/taskPanel.png');
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

li.conv_list.strict.TaskManagementPanel {
  background-image: url('./media/images/png/taskPanel.png');
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.conv_list.flagPanel {
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_flag.svg');
  background-position: 24px 64%;
  background-repeat: no-repeat;
  background-size: 18px;
  margin-bottom: 4px;
}

/* Alamgir for all private msg icon */
.conv_list.PrivatePanel {
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_private room.svg');
  background-position: 24px 46%;
  background-repeat: no-repeat;
  background-size: 13px;
}

.conv_list.cal_panel {

  background-image: url("./media/images/icons/other/LightBlue/SVG/calendar_lightblue.svg");
  background-position: 24px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.conv_list.LinkPanel {
  background-image: url("./media/images/icons/other/LightBlue/SVG/link_icon_blue.svg");
  background-position: 24px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.conv_list.Archivepanel {
  background-image: url("./media/images/box-archive-solid_blue.svg");
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.pinBar {
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_pinned.svg');
  background-position: 20px 60%;
  background-repeat: no-repeat;
  background-size: 22px;
  /*add manzu*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.create_conv {
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_conversations.svg');
  background-position: 24px 58%;
  background-repeat: no-repeat;
  background-size: 16px;
}

.conv_list.offline::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  left: 24px;
  top: 12px;
}

.conv_list.online::before {
  content: "";
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /* background-color: #57d186; */
  background-color: #d0f29c;
  position: absolute;
  left: 24px;
  top: 12px;
}

.conv_list.join_call::before {
  content: "";
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  left: 24px !important;
  top: 12px;
  background-image: url('./media/images/call/green_voice_call.svg') !important;
  background-size: 15px !important;
  background-position: 50%;
}

.conv_list.private:before {
  content: "";
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_private room.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 24px;
  top: 12px;
}

.conv_list.mute:before {
  content: "";
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_notifications_off.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 24px;
  top: 12px;
}

.conv_list.everyOne.system_converstion:before {
  content: "";
  background-image: url("./media/images/icons/blackTheme/SVG/hash.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 24px;
  top: 12px;
}

.conv_list.channel.system_converstion:before {
  content: "";
  background-image: url("./media/images/icons/blackTheme/SVG/channel.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 24px;
  top: 12px;
}

.conv_list.private.selected {
  background-position: 4px 50%;
}

.conv_list.public {
  background-image: url("./media/images/PublicHash.svg");
  background-size: 25px !important;
  background-position: 10px 50%;
}

.conv_list.public.selected {
  background-position: 4px 50%;
}

.right_container {
  width: calc(100% - 346px);
  height: 100vh;
  float: left;
  background-color: #fff;
  position: relative;
  transition: width .4s;
}

.subRightContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  right: 0;
  z-index: 10;
}

.popUpRightContainer {
  width: calc(100% - 346px) !important;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fff;
  top: 0;
  right: 0;
  z-index: 100;
}

.right_container_fileHub {
  width: calc(100% - 346px);
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  transition: width 400ms;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 4000;
}

.top_bar {
  width: 100%;
  height: 76px;
  background-color: #ffffff;
  border-bottom: 1px solid #e3f2fe;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  position: relative;
}

.top_bar.convSearchActive {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.part_top_bar {
  width: auto;
  height: 100%;
  float: left;
  display: flex;
  align-items: center;
  padding: 0 6px;
  position: relative;
}

.part_top_bar.single,
.part_top_bar.group {
  width: 50%;
}

.part_top_bar::after {
  content: "";
}

.part_top_bar:last-child::after {
  display: none;
}

.part_top_bar .part_top_bar:first-child {
  padding-left: 0px;
}

.conv_img {
  position: relative;
  float: left;
  width: 46px;
  height: 46px;
  position: relative;
  border-radius: 50%;
}

.conv_img.online::after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("./media/images/OnlineStatus.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 24px;
}

.conv_img.offline::after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("./media/images/OfflineStatus.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 24px;
}

.part_top_bar.group .conv_img::after {
  display: none;
}

.conv_img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  cursor: pointer;
}

.conv_desc {
  float: left;
  width: calc(100% - 56px);
}

.conv_name {
  color: #002e98;
  width: auto;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  /* manzu modify font-size: 16px; */
  font-size: 16px;
  line-height: 22px;
  text-indent: 16px;
  font-weight: bold;
}

.conv_info {
  text-indent: 36px;
  /* background-image: url("./media/images/Users.svg"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_2 users.svg");
  background-repeat: no-repeat;
  background-position: 14px 50%;
  background-size: 16px;
  line-height: 22px;
  display: none;
  color: #0c1e47;
  font-size: 12px;
}

.conv_info_gray {
  text-indent: 36px;
  /* background-image: url("./media/images/Users.svg"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_2 users gary.svg");
  background-repeat: no-repeat;
  background-position: 15px 4px;
  background-size: 15px;
  line-height: 22px;
  display: none;
  color: #5b6477;
  font-size: 12px;
}

.conv_info span {
  padding: 0 4px;
}

.conv_info_gray span {
  padding: 0 4px;
}

.part_top_bar.group .conv_info {
  display: block;
  white-space: nowrap;
  cursor: pointer;
}

.part_top_bar.group .conv_info_gray {
  display: block;
  white-space: nowrap;
  cursor: pointer;
}

.opt_icons {
  width: 30px;
  height: 26px;
  border: 2px solid transparent;
  margin: 0 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
}

.conv_voice {
  /* background-image: url("./media/images/png/call-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_call.svg");
  background-size: 24px;
}

.conv_voice.active {
  pointer-events: none;
  /* background-image: url("./media/images/png/call-icon_active.png"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_call.svg");
}

.join-call {
  /* background-image: url("./media/images/call/custom_call_join.svg"); */
  background-image: url("./media/images/icons/other/Green/SVG/WorkFreeli_Icons_Green_call.svg");
  background-size: 22px;
  /*background-color: red !important;*/
}

@keyframes flickerAnimationCall {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimationCall {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimationCall {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimationCall {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}

.animate-flicker-call {
  -webkit-animation: flickerAnimationCall 5s infinite;
  -moz-animation: flickerAnimationCall 5s infinite;
  -o-animation: flickerAnimationCall 5s infinite;
  animation: flickerAnimationCall 5s infinite;
}

.conv_video {
  background-image: url("./media/images/custom_video_call.svg");
  background-size: 26px;
}

.conv_unpin {
  /* background-image: url("./media/images/png/pin-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_pinned.svg");
  background-size: 26px;
  transition: all 0.3s ease;
  transform: rotate(45deg);
}

.conv_unpin.active {
  background-size: 24px;
  transform: rotate(-45deg);
  /* background-image: url("./media/images/png/pin-icon_active.png"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_pinned.svg");
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}

/* .conv_pin {
  background-image: url("./media/images/custom_pinned.svg");
  background-size: 15px;
} */
.conv_unflaggd {
  background-image: url("./media/images/NotFlagged.svg");
  background-size: 16px;
}

.conv_flagged {
  background-image: url("./media/images/Flagged.svg");
  background-size: 16px;
}

.conv_search {
  /* background-image: url("./media/images/png/search-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_search.svg");
  background-size: 26px;
}

.conv_mute {
  /* background-image: url("./media/images/bellNot.svg"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_notifications off.svg");

  background-size: 28px;
}

.conv_mute.active {
  /* background-image: url("./media/images/bellNot_blue.svg"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_notifications_off.svg");
}

.conv_mute_all {
  /* background-image: url("./media/images/bellNot_new.svg"); */

  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_notifications_off.svg");
  background-size: 20px;
  margin: 0 10px 0 0;
}

.conv_mute_all.active {
  background-image: url("./media/images/bellNot_red.svg");
  margin: 0 10px 0 0;
}

.conv_filter {
  /* background-image: url("./media/images/png/filter_icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_filter2.svg");
  background-size: 26px;
}

.conv_filter.active {
  /* background-image: url("./media/images/png/filter_icon_active.png"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_filter2.svg");
  background-size: 26px;
  /* pointer-events: none; */
}

.conv_filter:hover {
  /* background-image: url("./media/images/png/filter_icon_active.png"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_filter2.svg");
  background-size: 26px;
  /* pointer-events: none; */
}

.conv_privatefilter {
  /* background-image: url("./media/images/png/lock-icon_new.png"); */
  /* background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_private room.svg"); */
  /* background-size: 24px; */
  color: #ffffff;
  background-color: #0b1f47;
  border-radius: 19px;
  padding: 3px 19px;
  font-size: 16px;
  font-family: var(--F1);
  cursor: pointer;
  font-weight: normal;
  box-sizing: border-box;
  line-height: 25px;
  width: 122px;
  height: 34px;
}

.moreMenuPopup li._pingConv:before {
  content: "";
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_pinned.svg");
  width: 16px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
}

.moreMenuPopup li._pingConv:hover:before {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_pinned.svg");
}

.conv_tasks {
  background-image: url("./media/images/png/task-list-icon.png");
  background-size: 24px;
}

.conv_tasks.active {
  background-image: url("./media/images/png/task-list-icon-active.png");
}

.conv_flags {
  /* background-image: url("./media/images/png/flagPanel-blue.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_flag.svg");
  background-size: 26px;
}

.conv_flags.active {
  /* background-image: url("./media/images/png/flagPanel-active.png"); */
  /* background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_flag.svg"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_flag.svg");
}

.conv_more {
  background-image: url("./media/images/png/menu-icon.png");
  background-size: 26px;
}

.conv_more.active {
  background-image: url("./media/images/png/menu-icon_active.png");
  background-size: 26px;
  /* pointer-events: none; */
}

.file_opt {
  color: #ffffff;
  background-color: #0b1f47;
  border-radius: 19px;
  padding: 0 18px;
  font-size: 14px;
  font-family: var(--F1);
  cursor: pointer;
  font-weight: normal;
  box-sizing: border-box;
  line-height: 34px;
  padding-bottom: 2px;
}

.file_opt:hover {
  background-color: #0d2150;
}

.msg_Container {
  width: 100%;
  height: calc(100% - 182px);
  background-color: #fff;
  overflow-y: auto;
}

.msg_Container::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
}

.msg_Container scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
}

.msg_Container::-moz-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
}

.msg_Container::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

.msg_Container::-moz-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

.msg_Container scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
}

scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
}

::-moz-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #fafafa;
}

::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

::-moz-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

.Scrollbars>div {
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}

.user_msg {
  /* width: 85%; */
  display: flex;
  height: auto;
  /* padding: 8px 50px; */
  padding: 3px 35px;
  float: left;
  position: relative;
  /* margin-bottom: 8px; */
  transition: all 0.4s ease;
}

.notification_msg {
  display: block !important;
  width: 100%;
  height: auto;
  /* padding: 8px 50px; */
  padding: 3px 50px;
  float: left;
  position: relative;
  /* margin-bottom: 8px; */
  /* transition: all 0.4s ease; */
}

.msg_hover_options_con {
  width: 100%;
  position: relative;
}

.msg_hover_options {
  min-width: auto;
  border-radius: 4px;
  border: 1px solid #979797;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  float: right;
  position: absolute;
  bottom: 8px;
  right: 0;
  background-color: #ffffff;
  z-index: 1;
  /* z-index: 9; */
}

/* .user_msg:not(.user_msg.edit_active):hover .msg_hover_options {
  display: flex;
} */

/* .user_msg:hover .msg_hover_options {
  display: flex;
}

.user_msg.edit_active .msg_hover_options {
  display: none;
} */

.single_user_msg:hover .msg_hover_options {
  display: flex;
}

.single_user_msg.edit_active .msg_hover_options {
  display: none;
}

.single_user_msg {
  display: flow-root;
}

.opts {
  width: 40px;
  height: 100%;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 20px;
  cursor: pointer;
}

.opts.emoji_opts {
  background-image: url("./media/images/png/reaction-icon.png");
  background-size: 24px;
}

.opts.task_opts {
  /* background-image: url("./media/images/png/task-list-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_tasks1.webp");
  background-size: 20px;
  display: none;
}

.opts.copy_opts {
  background-image: url("./media/images/file_blue.svg");
  background-size: 20px;
}

.opts.delete_opts {
  background-image: url("./media/images/trash-red.svg");
  background-size: 15px;
}

.opts.forward_opts {
  /* background-image: url("./media/images/png/forward-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_forward.svg");
  background-size: 20px;
}

.opts.upload_opts {
  background-image: url("./media/images/png/attachment-icon.png");
  background-size: 24px;
}

.opts.reply_opts {
  /* background-image: url("./media/images/png/thread_icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue-01-webp.webp");
  background-size: 24px;
}

.opts.share_opts {
  /* background-image: url("./media/images/png/thread_icon.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/shareicon.svg");
  background-size: 16px;
}

.opts.attach_opts {
  background-image: url("./media/images/png/attachment-icon.png");
  background-size: 24px;
}

.opts.tag_opts {
  background-image: url("./media/images/png/tag-icon.png");
  background-size: 24px;

}

#root.dark .opts.tag_opts {
  background-image: url("./media/images/png/tag-icon_w.png");
}

.opts.download {
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_download.svg");
  background-size: 18px;
}

#root.dark .opts.download {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_download.svg");
}

.opts.edit_opts {
  /* background-image: url("./media/images/png/edit-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_edit.svg");
  background-size: 20px;
}

.opts.delete_opts {
  /* background-image: url("./media/images/trash-red.svg"); */
  background-image: url("./media/images/icons/other/Red/SVG/WorkFreeli_Icons_Red_trash.svg");
  background-size: 16px;
}

.opts.more_opts {
  /* background-image: url("./media/images/png/meatball-menu-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_more options.svg");
  background-size: 20px;
}

.opts.convert_task {
  /* background-image: url("./media/images/png/meatball-menu-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/task-list-add-svgrepo-com.svg");
  background-size: 20px;
}

.sender_img {
  width: 49px;
  /* height: 50px; */
  height: 45px;
  overflow: hidden;
  display: flex;
  float: left;
  align-items: center;
  justify-content: center;
}

.sender_img.nameL {
  display: flex;
}

.nameLetters {
  width: 41px;
  height: 41px;
  /* background-color: #028bff; */
  /* background-color: #9b9b9b; */
  background-color: #0c1e47;

  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

#root.dark .nameLetters {
  width: 100%;
  height: 100%;
  background-color: #ffffff !important;
  color: #0c1e47 !important;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

._userImgage .nameLetters,
.userLogo .nameLetters,
.conv_img .nameLetters {
  width: 100%;
  height: 100%;
}

.conv_img .nameLetters {
  font-size: 18px;
}

.sender_img img {
  width: 49px;
  border-radius: 50%;
  height: 45px;
  cursor: pointer;
  object-fit: cover;
}

.msg_info {
  float: left;
  width: calc(100% - 0px);
  padding-left: 10px;
}

.sender_info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sender_name {
  font-weight: 500;
  cursor: pointer;
  color: #0d2150;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 23px;
}

.sender_name:hover {
  color: var(--NewRed);
}

.msg_time {
  margin-left: 10px;
  color: #5b6477;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 23px;
}

.is_delivered {
  color: #5b6477;
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  line-height: 23px;
}

.msg_body {
  margin-top: 4px;
  color: #000000;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 18px;
  display: flow-root;
  float: left;
  width: 100%;
}

.msg_body .msg_txt {
  word-break: break-word;
}

.msg_body .msg_txt a {
  color: blue;
}

.bottom_bar {
  box-shadow: 10px 3px 8px rgb(0 0 0 / 30%);
  width: 100%;
  max-height: 450px;
  background-color: #fff;
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 90px;
  padding-right: 40px;
  /* padding: 24px 50px; */

  position: absolute;
  display: flow-root;
  bottom: 0;
  z-index: 2;
  /* transition: all 0.5s ease; */
}

.task_message_con {
  width: max-content;
  height: auto;
  /* min-height: 40px; */
  border: 1px solid #d9e2e8;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* cursor: pointer; */
  font-weight: 500;
  margin-left: 55px;
  border-radius: 4px;
}

.task_message_icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 50px;
  font-size: 20px;
  color: #028bff;
  margin-right: 5px;
}

.task_message_label {
  color: #0b1f47;
  /* width: calc(100% - 70px); */
  width: 100%;
  text-align: left;
  padding: 7px 0;
  letter-spacing: 0.4px;
}

.create_box {
  border: 1px solid #595959;
  width: 100%;
  float: right;
  min-height: 54px;
  max-height: 350px;
  border-radius: 6px;
  font-size: 16px;
  padding: 11px 194px 8px 88px;
  display: flex;
  justify-content: flex-start;
  overflow-y: auto;
  word-break: break-word;
  line-height: 28px;
  resize: none;
}

.create_box_call {
  border: 1px solid #595959;
  width: 100%;
  float: right;
  /*min-height: 54px;*/
  max-height: 150px;
  border-radius: 6px;
  font-size: 18px;
  padding: 10px 45px 10px 10px;
  display: flex;
  /* align-items: center; */
  justify-content: flex-start;
  overflow-y: auto;
  word-break: break-word;
  /*line-height: 36px;*/
  resize: none;
  /*margin-right: 10px;*/
}

.flex_img_center {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.bottom_bar.private_conv .create_box {
  padding-left: 10px;
}

.create_box[placeholder] {
  content: attr(placeholder);
  /*color: rgba(0, 0, 0, 0.24);*/
  font-family: var(--F1);
  cursor: text;
  overflow-y: auto;
}

.create_box[contenteditable="false"] {
  padding: 8px 12px 8px 12px;
}

.create_box[contenteditable="false"]+.send_msg_opt {
  display: none;
}

.send_msg_info {
  float: left;
  width: 20px;
  height: 20px;
  display: flex;
  border: 1px solid #0c1e47;
  border-radius: 50%;
  margin-right: 10px;
  position: absolute !important;
  bottom: 34px;
  cursor: pointer;
  background-image: url("./media/images/conv-i-new-bb.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 18px;
  display: none;
}

.send_msg_opt {
  height: 53px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 58px;
  bottom: 24px;
}

.send_msg_opt a,
.send_msg_opt label,
.send_msg_opt div {
  width: 20px;
  height: 20px;
  /*background-color: #0C1E47;*/
  /* border-radius: 50%; */
  margin: 0 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
  /* background-size: 100%; */
}

.msg_bottom_bar.offline {
  pointer-events: none;
  opacity: 0.3;
  transition: all .5s ease-in-out;
}

.msg_bottom_bar.online {
  /* pointer-events: none; */
  opacity: 1;
  transition: all .5s ease-in-out;
}

.task_selector {
  background-image: url("./media/images/png/task-list-icon.png");
  background-size: 24px;
}

.checklist_selector {
  /* background-image: url('./media/images/hayven_checked.svg'); */
  background-image: url("./media/images/png/task_list-icon.png");
  background-size: 24px;
}

.attachment_selector {
  /* background-image: url("./media/images/Attach.svg"); */
  /* background-image: url("./media/images/png/attachment-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_attach_file.svg");
  background-size: 20px;
  margin-right: 8px !important;
}

.discussionArea .attachment_selector {
  margin-right: 9px !important;
}

.audioContainer canvas {
  width: 100% !important;
}

.audioContainer audio {
  width: 100% !important;
}

.voice_msg_off {
  /* width: 24px; */
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_mic_on.svg");
  background-size: 40px;
}

.voice_msg_on {
  /* width: 24px; */
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_mic_on.svg");
  background-size: 40px;
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;

}

.voice_upload_label {
  height: 81px;
  box-sizing: border-box;
  /* width: 100%; */
  border-radius: 6px;
  /* background-color: #0b1f47; */
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* margin-bottom: 15px; */
}

.voice_upload_text {
  text-align: center;
  color: #002e98;
  font-size: 16px;
  letter-spacing: 1px;
  font-weight: 500;
}

.voice-graph {
  /* height: 100%; */
  display: flex;
  align-items: center;
  flex-direction: column;
}

.voice-graph.hidegraph canvas {
  /* height: 100%; */
  display: none;

}

.voice_control {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.audiograph canvas {
  width: 430px !important;
  height: 81px !important;
}

.audiograph audio {
  width: 430px !important;
  height: 64px !important;
}

.textCloseCancel {
  background-size: 20px;
  margin-right: 15px !important;
}

.microphone_audio_on {
  /* background-image: url("./media/images/Attach.svg"); */
  /* background-image: url("./media/images/png/attachment-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_mic_on.svg");
  background-size: 20px;
  margin-right: 15px !important;
  /* display: none; */
}

.microphone_audio_on_file {
  /* background-image: url("./media/images/Attach.svg"); */
  /* background-image: url("./media/images/png/attachment-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_mic_on.svg");
  background-size: 20px;
  margin-right: 5px !important;
  /* display: none; */
}

#root.dark .microphone_audio_on_file {
  /* background-image: url("./media/images/Attach.svg"); */
  /* background-image: url("./media/images/png/attachment-icon.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_mic_on.svg");
  background-size: 20px;
  margin-right: 5px !important;
  /* display: none; */
}

.microphone_audio_off {
  /* background-image: url("./media/images/Attach.svg"); */
  /* background-image: url("./media/images/png/attachment-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_mic_on.svg");
  background-size: 20px;
  margin-right: 15px !important;
  -webkit-animation: flickerAnimation 2s infinite;
  -moz-animation: flickerAnimation 2s infinite;
  -o-animation: flickerAnimation 2s infinite;
  animation: flickerAnimation 2s infinite;
  display: none !important;
}

@keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@-o-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes flickerAnimation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.3;
  }

  100% {
    opacity: 1;
  }
}

.emoji_selector {
  /* background-image: url('./media/images/AddEmoji_White.svg'); */
  background-image: url("./media/images/png/reaction-icon.png");
  background-size: 24px;
  visibility: hidden;
}

.msgSend_btn {
  /* background-image: url('./media/images/circle_up_arrow.svg'); */
  /* background-image: url("./media/images/png/send-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_send.svg");
  background-size: 20px;
}

.msgSend_btn_call {
  width: 24px;
  height: 24px;
  /* background-color: #0C1E47; */
  /* border-radius: 50%; */
  margin: 0 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
  /*background-image: url(./media/images/circle_up_arrow.svg);*/
  background-image: url(./media/images/png/send-icon.png);
  background-size: 24px;
  position: absolute;
  right: 40px;
}

/*******************/
/* Connect CSS End */
/*******************/

/*******************/
/*    Login CSS    */
/*******************/

.register_container,
.login_container {
  background-color: #f5f5f5;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.fade-in {
  opacity: 0;
  animation: fadeIn ease-in 0.3s;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.work_account {
  background: #fafafa;
  border-radius: 4px;
  height: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 600px;
  padding: 30px 60px;
}

.social_account {
  background: #fafafa;
  border-radius: 4px;
  height: auto;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  width: 600px;
  padding: 30px 60px;
}

.form_container {
  background-color: #eef3f9;
  width: 50%;
  height: 100%;
  padding: 40px 8%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
  position: relative;
}

.form_container form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.loginBody {
  max-height: calc(100vh - 305px);
  overflow-y: auto;
  /* margin-bottom: 25px; */
}

.sign-in {
  width: 100%;
  padding: 0 8px;
}

.logo_icon {
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo_icon img {
  width: 260px;
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

.hello-welcome-back {
  position: relative;
  height: 42px;
  color: rgba(0, 0, 0, 0.86);
  font-size: 28px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
}

/* #email:focus {
  border: 1px solid #d6d6d6;
} */
#password::placeholder,
#password2::placeholder {
  font-size: 16px;
  font-family: var(--F1);
}

#newPass,
#confirmPass,
#oldPass,
#confPass,
#password,
#password2 {
  border: 1px solid #d6d6d6;
  background: white;
  padding: 8px 50px;
  line-height: 40px;
  font-size: 54px;
  -moz-font-size: 2.3em;
  font-family: auto;
  letter-spacing: 8px;
  margin-top: 0;
}

#confPass[type="text"],
#oldPass[type="text"],
#newPass[type="text"],
#confirmPass[type="text"],
#password[type="text"],
#password2[type="text"] {
  border: 1px solid #d6d6d6;
  background: white;
  padding: 8px 50px;
  line-height: 40px;
  font-size: 18px;
  font-family: var(--F1);
  letter-spacing: 8px;
}

#password:placeholder-shown,
#password2:placeholder-shown {
  font-size: 16px !important;
  /* Red border only if the input is empty */
  font-family: var(--F1);
}

.passwordPlaceholder {
  display: none;
}

.passwordPlaceholderNew {
  position: absolute;
  left: 48px;
  top: 14px;
  line-height: 40px;
  font-size: 113px;
  font-family: auto;
  letter-spacing: -6px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adb5bdd1;
  pointer-events: none;
}

input#password:not(:valid)+.errorLabel+.passwordPlaceholder {
  position: absolute;
  left: 48px;
  top: 14px;
  line-height: 40px;
  font-size: 113px;
  font-family: auto;
  letter-spacing: -6px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adb5bdd1;
  pointer-events: none;
}

input#password:not(:valid)+.passwordInfoLabel+.passwordPlaceholder,
input#password2:not(:valid)+.passwordInfoLabel+.passwordPlaceholder {
  position: absolute;
  left: 48px;
  top: 14px;
  line-height: 40px;
  font-size: 113px;
  font-family: auto;
  letter-spacing: -6px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adb5bdd1;
  pointer-events: none;
}

.rgInputf:not(:valid)+.passwordPlaceholder.firstPass,
.rgInputf:not(:valid)+.passwordPlaceholder.firstPass {
  top: 36px;
}

input#oldPass:not(:valid)+.passwordPlaceholder,
input#newPass:not(:valid)+.errorLabel+.rgInputMsg+.passwordPlaceholder,
input#confPass:not(:valid)+.rgInputMsg+.passwordPlaceholder,
input#password2:not(:valid)+.rgInputMsg+.passwordPlaceholder {
  position: absolute;
  left: 48px;
  top: 20px;
  line-height: 40px;
  font-size: 113px;
  font-family: auto;
  letter-spacing: -6px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adb5bdd1;
  pointer-events: none;
}

#password:focus {
  border: 1px solid #d6d6d6;
  background: white;
}

.sign-into-your-account {
  position: relative;
  height: 21px;
  margin: 4px auto 0px;
  color: #9b9b9b;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  margin-bottom: 40px;
}

.form-field {
  width: 100%;
  position: relative;
}

.error_field {
  padding: 20px 0;
}

.error-msg {
  list-style: none;
  color: #900;
  padding-left: 0px;
  text-align: center;
}

.form-field>label {
  height: 19px;
  width: 100%;
  color: #565656;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  margin-bottom: 8px;
  display: flow-root;
}

.form-field>input {
  box-sizing: border-box;
  height: 50px;
  font-size: 16px;
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 8px 25px;
  background-color: #ffffff;
}

.form-field>input.error,
.form-field>input.error[type="password"]:focus,
.form-field>input.error[type="password"] {
  border: 1px solid red !important;
}

.form-field.email-address {
  margin-bottom: 28px;
  position: relative;
  padding: 0 8px;
}

.loginBody .form-field {
  padding: 0 8px;
}

.forgotPass {
  font-weight: 500;
  /* height: 22px; */
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 13px;
  margin-left: 20px;
  /* color: #003ec7; */
  color: #0c1e47;
  position: absolute;
  right: 10px;
}

.forgotPass a {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.86);
}

.forgotPass a:hover {
  color: #4a90e2;
}

.remember-me {
  position: relative;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 30px;
  padding: 0 8px;
}

.remember-me>input {
  position: relative;
  float: left;
  height: 16px;
  width: 16px;
  border: none;
  border-radius: 4px;
  background-color: #ffffff;
  /* -webkit-appearance: none; */
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.08), 0 0 2px 0 rgba(0, 0, 0, 0.16);
}

.form-field>input:not(:empty) {
  background-color: #ffffff;
}

.remember-me>p {
  height: 11px;
  margin: 2px 0px 2px 10px;
  color: rgba(0, 0, 0, 0.86);
  font-size: 12px;
  font-weight: 500;
  line-height: 11px;
}

.sendButton {
  height: 50px;
  width: 100%;
  border-radius: 4px;
  border: none;
  font-size: 18px;
  font-weight: normal;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  /* background-color: #003ec7; */
  background-color: #0c1e47;
  cursor: pointer;
}

.sendButton:hover {
  background-color: #002e98;
}

.sign-up {
  width: 100%;
  color: #565656;
  font-size: 13px;
  text-align: center;
  line-height: 34px;
  font-weight: 500;
  margin-top: 0px;
  margin-bottom: 20px;
  padding: 0 8px;
}

.sign-up a {
  /* color: #003ec7; */
  color: #0c1e47;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.sign-up a:hover {
  /* color: #4a90e2; */
  color: #19356c;
}

.list_container a {
  text-decoration: none;
}

/*******************/
/*  Login CSS End  */
/*******************/

/*******************/
/*  Forgot Password CSS */
/*******************/

.forgotPass_container {
  background: #fafafa;
  border-radius: 4px;
  width: 482px;
  height: 442px;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 16%);
  /* padding: 40px 80px; */
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  flex-direction: column;
}

.forgotPass_container form {
  width: 322px;
}

.forgotPass_container .hello-welcome-back {
  margin-bottom: 20px;
}

.forgotPass_container .form-field {
  margin-bottom: 20px;
}

.forgotPass_container .form-field input {
  height: 44px;
}

.forgotPass_container .form-field input[type="reset"] {
  background-color: #0c1e47;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 44px;
  padding: 0;
  cursor: pointer;
}

.backToLogin {
  width: 100%;
  height: 44px;
  border: 1px solid #0c1e47;
  border-radius: 4px;
  font-weight: 600;
  font-size: 18px;
  color: #0c1e47;
  background-color: #fff;
  cursor: pointer;
}

.backToLogin:hover {
  border-color: var(--NewRed);
}

.form-field.backBtn {
  width: 322px;
}

/*******************/
/*  Forgot Password CSS End  */
/*******************/

/*********************/
/*Register CSS start */
/*********************/

.logo_container {
  position: absolute;
  left: 15px;
  top: 15px;
  width: auto;
  display: flex;
  flex-direction: column;
}

.logo_container img {
  width: 180px;
  height: 70px;
  border-radius: 4px;
  float: left;
}

.logo_container .workfreeli_text {
  width: auto;
  height: 70px;
  float: left;
  line-height: 70px;
  padding-left: 15px;
  color: #0c1e47;
  font-size: 24px;
  font-weight: 600;
}

.previous_btn {
  width: 77px;
  height: 48px;
  border-radius: 10px;
  border: 2px solid #0c1e47;
  float: left;
  margin-top: 18px;
  background-color: #fff;
  cursor: pointer;
  background-image: url("./media/images/back-icon.png");
  background-size: 36%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.register_container .form_container {
  width: 500px;
  padding: 50px 60px;
  height: auto;
}

.register_container .sign-into-your-account {
  height: 40px;
  color: rgba(0, 0, 0, 0.86);
  font-size: 20px;
  font-weight: 500;
  line-height: 35px;
}

.box_container {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  /*padding: 20px 0;*/
  height: 94px;
  text-align: right;
  border: 2px solid #5f86cc;
  border-radius: 10px;
  background-color: #f5f5f5;
  cursor: pointer;
}

.left_area {
  padding-left: 40px;
  padding-right: 20px;
  pointer-events: none;
}

.right_area {
  pointer-events: none;
}

.create_acount_text {
  color: #0c1e47;
  font-size: 18px;
}

.create_acount_name_text {
  font-size: 15px;
  font-weight: 600;
  color: #0c1e47;
}

.input_area {
  margin-bottom: 13px;
}

.input_label {
  font-size: 14px;
  color: #0c1e47;
  font-weight: 600;
  width: 100%;
  display: block;
  margin-bottom: 4px;
}

.input_area input {
  width: 100%;
  border-radius: 4px;
  height: 40px;
  outline: none;
  border: 1px solid #e1e4e8;
  text-indent: 10px;
}

.input_note {
  font-size: 12px;
  color: #8a8580;
  width: 95%;
  line-height: 14px;
}

.input_group {
  width: 100%;
}

.input_group .input_area {
  width: 50%;
  float: left;
}

.registerBtn_group input[type="checkbox"] {
  position: relative;
  cursor: pointer;
  margin-right: 20px;
  border: 1px solid #fff;
  bottom: -3px;
  left: 2px;
}

.registerBtn_group input[type="checkbox"]:before {
  content: "";
  display: block;
  position: absolute;
  width: 18px;
  height: 18px;
  top: 0;
  left: -2px;
  border: 2px solid #e1e4e8;
  border-radius: 3px;
  background-color: #ffffff;
}

.registerBtn_group input[type="checkbox"]:checked:after {
  content: "";
  display: block;
  width: 6px;
  height: 11px;
  border: solid #0c1e47;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 5px;
}

span.agreement_text {
  position: relative;
  top: 6px;
  font-size: 14px;
}

.registerBtn_group .signUp_btn {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  background-color: #0c1e47;
  margin-top: 25px;
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  outline: none;
  border: 0;
  cursor: pointer;
}

.registerBtn_group .cancelBtn {
  height: 40px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid #0c1e47;
  margin-top: 25px;
  color: #0c1e47;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  outline: none;
  cursor: pointer;
}

/*******************/
/*Register CSS End */
/*******************/

/*******************/
/*Popups CSS start */
/*******************/

.profilenavMainSection {
  /* width: 310px;
  height: auto;
  position: fixed;
  box-sizing: border-box;
  padding: 0;
  border-radius: 4px;
  left: 307px;
  top: 145px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(10 31 68 / 10%), 0 18px 18px 0 rgb(10 31 68 / 12%);
  cursor: context-menu;
  z-index: 1;
    border: solid 1px #e3f2ff;
  */
  width: 310px;
  height: auto;
  position: fixed;
  box-sizing: border-box;
  padding: 0;
  border-radius: 12px;
  /* left: 419px; */
  left: 360px;
  top: 82px;
  background-color: #ffffff;
  box-shadow: 0 1px 60px 0 rgba(0, 0, 0, 0.1);
  ;
  cursor: context-menu;
  border: solid 1px #e3f2ff;
  background-color: #fff;


}

.profilenavMainSection::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent #fff transparent transparent;
  left: -20px;
  top: 20px;
}

/* .profilenavMainSection::-webkit-scrollbar {
  height: 6px;              
  width: 6px;              
  border: 1px solid #fafafa;
}
.profilenavMainSection::-webkit-scrollbar-thumb{
    background: #d8d8d8;
    border-radius: 10px;
} */
/* .profilenavMidleArea {
    max-height: 385px;
    overflow: auto;
} */
.createConv_body::-webkit-scrollbar {
  height: 6px;
  width: 6px;
  border: 1px solid #fafafa;
}

.createConv_body::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

.profileNavContainer {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  height: auto;
  background-color: #ffffff;
  /* padding: 10px 32px;
  border-top: 1px solid #f2f2f2; */
  border-bottom: 1px solid #f2f2f2;
}

.profileNavContainer_line {
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  height: auto;
  background-color: #ffffff;
  /* padding: 10px 32px;
  border-top: 1px solid #f2f2f2; */
  border-bottom: 1px solid #f2f2f2;
  /* margin: 10px 0; */
  margin-top: 10px;
  margin-bottom: -3px;
}

.profileNavContainer:hover h1,
.profileNavContainer:hover {
  background-color: #0c1e47;
  color: #fff !important;
}

span.linkAccount.uhover.userName {
  font-weight: bold;
}

#root.dark h1#company_id_name:hover {
  background: #404040;
}

#root.dark .profileNavContainer:hover h1 {
  background: #404040;
}

#root.dark span.workCategoryListTitle {
  color: #fff !important;
}

#root.dark span.addWorkCategoryBtn {
  color: #ffffff;
}

#root.dark label.createUrl_reset {
  color: #028bff;
}

.navTop:hover .linkAccount {
  background-color: #0c1e47;
  color: #fff !important;
}

.navTop:hover .settingEmail {
  /* background-image: url("./media/images/png/edit-icon_new.png"); */

  background-image: url("./media/images/icons/blackTheme/SVG//WorkFreeli_Icons_White_edit.svg");
  background-color: transparent;
}

.profileNavContainer .headNav h1 {
  color: #0c1e47;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  float: left;
  overflow: hidden;
  width: 222px;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.headNav {
  display: block;
  height: auto;
  overflow: hidden;
}

.profilenavMainSection .settingEmail {
  float: right;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  display: block;
  position: absolute;
  top: 27px;
  right: 10px;
  /* background-image: url("./media/images/png/edit-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_edit.svg");
  background-size: 16px;
  background-position: 50%;
  background-repeat: no-repeat;
  z-index: 1;
}

.group_input {
  display: flow-root;
  width: 100%;
  margin-bottom: 4px;
  height: 22px;
  float: left;
}

.switchNav {
  position: relative;
  width: 34px;
  height: 22px;
  float: left;
}

.switchNav input {
  display: none;
}

.sliderNav {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.sliderNav::before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  top: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  box-shadow: 0 4px 4px 0 rgba(10, 31, 68, 0.16);
}

.sliderNav.round {
  border-radius: 34px;
}

.sliderNav.round:before {
  border-radius: 50%;
}

input:checked+.sliderNav {
  /* background-color: var(--BtnC); */
  background-color: #0c1e47;
}

input:checked+.sliderNav:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(12px);
}

.group_input .switchFor {
  float: left;
  width: 205px;
  margin-left: 40px;
  color: rgba(18, 18, 18, 0.5);
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profilenavMainSection .linkAccount {
  /* height: 40px;
  color: #0c1e47;
  font-size: 14px;
  font-weight: 500;
  line-height: 40px;
  padding: 0 32px;
  display: block;
  text-align: left;
  cursor: pointer;
  position: relative;

    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 10px;
    padding-top: 0px; */
  padding: 0 18px;
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  padding-left: 18px;
  padding-top: 0px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b1f47;
  height: 30px;
}

.themeMode:hover,
.profilenavMainSection .linkAccount:hover {
  /* background-color: #0c1e47; 
  color: #fff;
  */
  background-color: #f5f9fc;
  color: #0b1f47;
}

.themeMode.active:hover::after {
  border-right: 2px solid #0b1f47;
  border-bottom: 2px solid #0b1f47;
}

.linkAccount.theme::after {
  content: "";
  position: absolute;
  right: 15px;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-top: 1px solid #c5bdbd;
  border-right: 1px solid #c5bdbd;
  transform: rotate(45deg);
  top: 8px;
}

span.themeMenu {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 100%;
  display: none;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(10 31 68 / 10%), 0 18px 18px 0 rgb(10 31 68 / 5%);
  box-sizing: border-box;
  /* padding: 16px 0px 16px; */
  border-radius: 4px;
}

/* span.themeMenu{
  width: 100%;
    position: absolute;
    top: -85px;
    left: 0%;
    display: none;
    flex-direction: column;
    background-color: #d8d8d8;
    box-shadow: 0 0 1px 0 rgb(10 31 68 / 10%), 0 18px 18px 0 rgb(10 31 68 / 12%);
    box-sizing: border-box;
    padding: 2px 0px 2px;
    border-radius: 4px;
    z-index: 99;
} */
.themeMode {
  height: 40px;
  color: #0c1e47;
  font-size: 14px;
  font-weight: 400;
  /* line-height: 40px; */
  padding: 8px 32px;
  display: block;
  text-align: left;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #eee;
  width: 100%;
}

.themeMode.active::after {
  content: "";
  width: 8px;
  height: 15px;
  border-right: 2px solid #0c1e47;
  border-bottom: 2px solid #0c1e47;
  position: absolute;
  right: 25px;
  top: 10px;
  transform: rotate(35deg);
}

.linkAccount.theme:hover .themeMenu {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

/* span.signout_menu {
  display: none;
} */
span.signout_menu {
  width: 100%;
  position: absolute;
  top: 0px;
  left: 100%;
  display: none;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(10 31 68 / 3%), 0 18px 18px 0 rgb(10 31 68 / 3%);
  box-sizing: border-box;
  /* padding: 16px 0px 16px; */
  border-radius: 4px;
}

span.linkAccount.signout:hover span.signout_menu {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

span.linkAccount.signout::after {
  content: "";
  position: absolute;
  right: 15px;
  width: 10px;
  height: 10px;
  background-color: transparent;
  border-top: 1px solid #c5bdbd;
  border-right: 1px solid #c5bdbd;
  transform: rotate(45deg);
  top: 17px;
}

span.signout_menu a {
  width: 100%;
}

.profilenavMainSection .logOut {
  /* color: #fff; */
  color: #0c1e47;
  font-weight: 500;
  line-height: 50px;
  display: block;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  border-top: 1px solid #f2f2f2;
  /* background-color: #0b1f47; */
  border-radius: 0 0 4px 4px;
}

.profilenavMainSection .logOut:hover {
  /* background-color: #0d2150; */
  background-color: #0c1e47;
  color: #fff;
}

.moreMenuPopup {
  box-sizing: border-box;
  height: auto;
  width: 256px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgba(10, 31, 68, 0.08),
    0 8px 10px 0 rgba(10, 31, 68, 0.1);
  position: absolute;
  right: 100px;
  top: 68px;
  margin-top: 12px !important;
  padding: 8px 0px;
  float: left;
  z-index: 3;

}

.moreMenuPopup:empty {
  display: none;
}

.moreMenuPopup li {
  box-sizing: border-box;
  display: block;
  list-style: none;
  width: 100%;
  color: #53627c;
  font-family: var(--F1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  padding: 10px 16px 10px 40px;
  position: relative;
}

.moreMenuPopup li.active {
  background-color: #0c1e47;
  color: #ffffff;
}

.moreMenuPopup li.active:before {
  color: #ffffff;
}

.only_filter_list {
  position: absolute;
  left: 100%;
  width: 100%;
  top: 0px;
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(10, 31, 68, 0.08),
    0 8px 10px 0 rgba(10, 31, 68, 0.1);
  transition: all 400ms ease-out;
  display: none;
}

.moreMenuPopup li:hover {
  /* background-color: var(--BtnC); */
  background-color: #0c1e47;
  color: #ffffff;
}

.moreMenuPopup span {
  box-sizing: border-box;
  display: block;
  width: 100%;
  color: #53627c;
  font-family: var(--F1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  padding: 10px 16px;
  position: relative;
}

.moreMenuPopup li:hover>.only_filter_list {
  display: block;
  transition: all 400ms ease-out;
}

.moreMenuPopup li:before {
  font-family: "FontAwesome";
  /* color: #53627c; */
  color: #0c1e47;
  font-size: 18px;
  position: absolute;
  margin-right: 8px;
  font-weight: 600;
  left: 10px;
}

#root.dark .moreMenuPopup li:before {
  color: #ffffff;
}

.moreMenuPopup li::after {
  font-family: "FontAwesome";
  color: #53627c;
  font-size: 18px;
  margin-top: -1px;
  position: relative;
  float: right;
  margin-right: 8px;
}

.moreMenuPopup li._files:before {
  content: "\f302";
}

.moreMenuPopup li._muteConv:before {
  content: "";
  background-image: url("./media/images/bell-slash-blue.svg");
  width: 16px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
}

.moreMenuPopup li._muteConv:hover:before {
  background-image: url("./media/images/bell-slash-solid.svg");
}

.moreMenuPopup li._selectMsg:before {
  content: "";
  background-image: url("./media/images/doubleCheckIn.svg");
  width: 16px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
}

.moreMenuPopup li._deleteRoom:before {
  content: "";
  background-image: url("./media/images/trash-red.svg");
  width: 16px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
}

.moreMenuPopup li._clearMsg:before {
  content: "";
  background-image: url("./media/images/clearMsgIn.svg");
  width: 16px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
}

.moreMenuPopup li._muteNotify:before {
  content: "\f0f3";
}

.moreMenuPopup li._notifyTune:before {
  content: "\f0f3";
}

.moreMenuPopup li._notifyTune::after {
  content: "\f105";
}

.moreMenuPopup li._filter:before {
  content: "\f0b0";
}

.moreMenuPopup li._filter::after {
  content: "\f105";
}

.moreMenuPopup li._flagFilter:before {
  content: "";
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: 18px;
  background-image: url("./media/images/Flagged.svg");
}

.moreMenuPopup li._chkListFilter:before {
  content: "\f879";
  font-size: 17px;
}

.moreMenuPopup li._callFilter:before {
  /* content: "\f1da"; */
  content: "";
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: 18px;
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue-01-webp.webp")
}

.moreMenuPopup li._callFilter:hover:before {

  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White-01.svg")
}

#root.dark .moreMenuPopup li._callFilter:before {

  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White-01.svg")
}

/* url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue-01.svg") */
.moreMenuPopup li._threadFilter:before {
  content: "\f086";
}

.moreMenuPopup li._threadFilter.star:before {
  content: "\f005";
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: 18px;
  /* background-image: url("./media/images/star-fill.svg"); */
}

.moreMenuPopup li._threadFilter.tag:before {
  content: "\f02b";
}

.moreMenuPopup li._threadFilter.link:before {
  content: "\f0c1";
}

.moreMenuPopup li._threadFilter.file:before {
  content: "\f0c6";
}

.moreMenuPopup li._threadFilter.voice:before {
  content: "\f130";
}

.moreMenuPopup li._threadFilter.checklist:before {
  content: "\f0ae";
}

li.moreOpt_list._threadFilter.flag:before {
  content: "\f024";
}

.moreMenuPopup li.task_filter:before {
  content: "\f46d";
}

.moreMenuPopup li._threadFilter.lock:before {
  content: "\f023";
}

/*.moreMenuPopup li._removeDelMsg:before {
  content: '';
}*/
/*.moreMenuPopup li._clearMsg:before {
  content: '';
}*/
.moreMenuPopup li._leaveRoom:before {
  content: "\f503";
}

/*.moreMenuPopup li._reOpenRoom:before {
  content: '';
}*/
.moreMenuPopup li._closeRoom:before {
  content: "\f023";
  color: #f55;
}

.moreMenuPopup li._archive_room:before {
  content: "\f023";
  color: #f55;
}

/*.moreMenuPopup li._deleteRoom:before {
  content: '';
}*/

.moreMenuPopup li:hover::after,
.moreMenuPopup li:hover:before {
  color: #ffffff;
}

.backwrap {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  /* z-index: 99; */
  z-index: 999999;
}

.backwrapRoomLock {
  width: calc(100% - 346px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  position: fixed;
  top: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: auto;
  /* z-index: 99; */
  z-index: 98;
}

.backwrap.initialOnboarding {
  background: rgb(155 155 155 / 85%);
}

.backwrap.initialOnboar {
  background: rgb(155 155 155 / 95%);
}

.fileUploadModal {
  height: auto;
  box-sizing: border-box;
  width: 600px;
  border-radius: 4px;
  background-color: #ffffff;
  display: flow-root;
  position: relative;
}

.fileUploadModalHead {
  /* padding: 0 32px;
  display: flex;
  align-items: center;
  height: 85px; */

  width: 100%;
  height: 55px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
}

.fileUploadModalHead .closeModal {
  top: auto;
  right: 32px;
}

.fileUploadModalBody {
  position: relative;
  width: 100%;
  display: flow-root;
  height: auto;
  box-sizing: border-box;
  /* padding: 30px 32px 20px 32px; */
  padding: 15px 32px 20px 32px;
}

.file_upload_label {
  height: 50px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 6px;
  background-color: #0b1f47;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-bottom: 35px; */
  margin-bottom: 20px;
}

.file_upload_label:hover {
  background-color: #0d2150;
}

.upload_file_container {
  max-height: 216px;
  width: 100%;
  display: block;
  overflow-y: auto;
}

.upload_file_container:empty {
  display: none;
}

.chat-file-upload-comment {
  box-sizing: border-box;
  /* height: 130px; */
  overflow-y: auto;
  display: grid;
  margin-top: 22px;
}

.chat-file-upload-comment label {
  /* height: 22px;
  color: rgba(0, 0, 0, 0.56);
  font-size: 16px;
  font-weight: normal;
 
  line-height: 22px;
  text-align: left;
  display: block;
  margin-bottom: 4px; */
  width: 100%;
  color: #002e98;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 6px;
  /* padding-left: 16px; */
}

.secretUserList {
  float: left;
  width: 100%;
  padding: 4px 0px 8px 0px;
  box-sizing: border-box;
}

.secretUserList:empty {
  display: none;
}

.file_comment_box {
  height: 100px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  background-color: #ffffff;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  padding-right: 70px !important;
  padding-left: 30px;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  resize: none;
}

.BorderRedbox {
  border: 1px solid red !important;
}

.file_comment_boxRed {
  height: 100px;
  font-size: 16px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid red !important;
  border-radius: 6px;
  background-color: #ffffff;
  padding: 8px 16px !important;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  resize: none;

}

.file_comment_box:empty:before {
  content: attr(placeholder);
  color: rgba(0, 0, 0, 0.24);
  /*font-family: var(--F1);*/
  cursor: text;
}

.mention_user_list {
  width: 100%;
  min-height: 50px;
  background: #fff;
  position: relative;
  bottom: 6px;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  float: left;
  border: 1px solid;
  max-height: 300px;
  overflow: auto;
}

/*#replymention_user:empty, #mention_user_list_file:empty {
    display: none;
}
#mention_user_list_file {
    position: absolute;
    width: calc(100% - 190px);
    bottom: -28px;
}*/

.uploadbtn {
  height: 42px;
  width: auto;
  border-radius: 5px;
  background: #9a9a9a;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  margin-top: 16px;
  float: right;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  padding: 0 20px;
}

.fileUploadModalBody .tagged {
  height: auto;
  width: 380px;
  float: left;
  cursor: pointer;
  position: relative;
  /* left: 0px; */
  margin-top: 16px;
  float: left;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}

.fileUploadModalBody .tagged .container {
  float: left;
}

.fileUploadModalBody .tagged input {
  border: none;
  width: 250px;
  font-size: 14px;
  line-height: 32px;
  margin-left: 5px;
  float: left;
}

.chooseCreateConv {
  width: 700px;
  height: auto;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  padding: 15px 35px 35px;
}

.chooseCreateConv .popup_title {
  box-sizing: border-box;
  color: #002e98;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  float: left;
  width: 90%;
  margin-bottom: 20px;
  margin-top: 10px;
}

.chooseCreateConv .closePopup {
  right: 35px;
  top: 36px;
}

.closePopup {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 17px;
  top: 17px;
  background-image: url(./media/images/close_button.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
  cursor: pointer;
}

.PopupContent {
  width: 100%;
  background-color: red;
  padding: 0 40px;
}

.createConv_type {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #002e98;
  text-align: center;
  color: #002e98;
  float: left;
  width: calc(50% - 20px);
  box-sizing: border-box;
  font-size: 22px;
  height: 110px;
  letter-spacing: 1px;
  border-radius: 8px;
  cursor: pointer;
  margin: 10px 10px;
  font-weight: 400;
}

.createConv_type:hover {
  border: 1px solid #0c1e47;
  background-color: #0c1e47;
  color: #ffffff;
}

.createConv_type._single .createTypeIcon:before {
  content: "\f500";
  font-family: "FontAwesome";
  margin-right: 5px;
  font-weight: 700;
}

.createConv_type._project .createTypeIcon:before,
.createConv_type._support .createTypeIcon:before,
.createConv_type._group .createTypeIcon:before {
  content: "\f0c0";
  font-family: "FontAwesome";
  margin-right: 5px;
  font-weight: 700;
}

.createConv_type._support {
  pointer-events: none;
  cursor: auto;
  opacity: 0.7;
}

.createSingleConv {
  box-sizing: border-box;
  width: 630px;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 24px 32px;
  height: auto;
  position: relative;
}

.createSingleConv h2 {
  box-sizing: border-box;
  color: #0c1e47;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: 0.5px;
  line-height: 28px;
  float: left;
  width: 90%;
  margin-bottom: 10px;
}

.createSingleConv .closePopup {
  width: 18px;
  height: 18px;
  position: absolute;
  right: 17px;
  top: 17px;
  background-image: url(./media/images/close_button.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
  cursor: pointer;
}

.tinytext {
  float: left;
  width: 100%;
  color: #0c1e47;
  position: relative;
  top: -6px;
  font-size: 12px;
  padding: 4px;
  box-sizing: border-box;
}

.createConvTop_Content {
  width: 100%;
  display: block;
  float: left;
}

.createSingleConv_search {
  width: calc(100% - 80px);
  min-height: 48px;
  background-color: #fff;
  padding: 4px 4px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.88);
  position: relative;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  margin-bottom: 8px;
  float: left;
}

.sub_btn {
  float: left;
  color: #ffffff;
  background-color: #0c1e47;
  font-size: 16px;
  width: 74px;
  text-align: center;
  height: 48px;
  line-height: 48px;
  border-radius: 4px;
  margin-left: 6px;
  cursor: pointer;
}

.createSingleConv_search input {
  text-indent: 10px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.88);
  width: 100%;
  border: none;
  background-color: #fff;
  margin-top: 4px;
  float: left;
  height: 100%;
}

.createSingleConv_search input::placeholder {
  font-size: 20px;
}

.createSingleConv_memList {
  background: transparent;
  width: 100%;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
}

.singleUser {
  width: 100%;
  height: 56px;
  border: 1px solid #dbdbdb;
  margin-bottom: 8px;
  border-radius: 4px;
  cursor: pointer;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  position: relative;
}

.singleUser:hover {
  background-color: #0c1e47;
  color: #ffffff;
}

.singleUser_img {
  width: 40px;
  height: 40px;
  float: left;
  border-radius: 12px;
  position: relative;
}

.singleUser_img.online::after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  left: -2px;
  bottom: -2px;
  background-image: url(./media/images/OnlineStatus.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 24px;
}

.singleUser_img img {
  width: 40px;
  height: 40px;
  border-radius: 12px;
}

.singleUser_name {
  float: left;
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  font-weight: 600;
  /*width: calc(100% - 85px);*/
  padding-left: 15px;
}

.singleUser:hover .singleUser_name {
  color: #ffffff;
}

.singleUser_email {
  float: right;
  color: #9b9b9b;
  font-size: 14px;
  font-weight: 500;
  position: absolute;
  right: 10px;
}

.createGroupConv {
  width: calc(100% - 346px);
  height: 100vh;
  float: left;
  background-color: #fff;
  position: absolute;
  top: -1px;
  left: 346px;
  /* background-color: #f8f8f8; */
  z-index: 999;
}

.updateGroupConv {
  width: 100%;
  height: 100vh;
  float: left;
  background-color: #fff;
  position: absolute;
  top: -1px;
  left: 0;
  background-color: #f8f8f8;
  z-index: 999;
}

.createConv_head {
  position: relative;
  height: 120px;
  width: 100%;
  padding: 30px 30px;
  display: flex;
  align-items: center;
  border-top: 1px solid #dbdbdb;
}

.createConv_title {
  color: #002e98;
  font-size: 20px;
  font-weight: normal;
  line-height: 32px;
  margin-bottom: 30px;
}

.createConv_back {
  position: absolute;
  width: 22px;
  height: 22px;
  background-image: url("./media/images/back.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 22px;
  top: 60px;
  left: 70px;
  cursor: pointer;
}

.createConv_close {
  position: absolute;
  width: 22px;
  height: 22px;
  background-image: url("./media/images/close_button.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 22px;
  top: 60px;
  right: 30px;
  cursor: pointer;
}

.createConv_body {
  position: relative;
  width: 100%;
  height: calc(100% - 174px);
  overflow-y: auto;
  overflow-x: hidden;
  padding: 20px 120px;
}

.name_and_profile {
  float: left;
  width: 50%;
}

/* .name_and_profile + .name_and_profile {
  margin-top: 45px;
} */
.chooseConvType {
  width: 100%;
  margin-bottom: 20px;
  display: flow-root;
}

.chooseConvType .redioGroup {
  float: left;
  margin-right: 15px;
}

.chooseConvType .radioItemName {
  color: #0c1e47;
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  padding-left: 10px;
  cursor: pointer;
}

.chooseConvType .radioItemInput {
  visibility: hidden;
}

.chooseConvType .customRadioMark {
  height: 20px;
  width: 20px;
  background-image: url("./media/images/circle-solid-white.svg");
  box-shadow: 0px 0px 0.5px 1px #c9ced6;
  border-radius: 50%;
  box-sizing: border-box;
  cursor: pointer;
  float: left;
  margin-top: 3px;
}

.chooseConvType .radioItemInput:checked~.customRadioMark:after {
  content: "";
  display: block;
  height: 20px;
  width: 20px;
  background-image: url("./media/images/dot-circle-solid.svg");
  border: none;
  box-sizing: border-box;
  cursor: pointer;
  float: left;
  box-sizing: border-box;
}

.inputGroup {
  width: 100%;
  margin-bottom: 20px;
  display: flow-root;
  padding-right: 35px;
}

.inputGroup select,
.inputGroup input {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  font-family: var(--F1);
  height: 50px;
  padding: 14px 16px;
  font-size: 14px;
}

.inputGroup input[disabled] {
  color: #000;
}

.inputGroup input::placeholder {
  color: #9a9a9a;
}

.paymentInput>input::placeholder {
  color: #c3c2c2;
}

/* .inputGroup .firstName + .rgInputMsg ,
.inputGroup .lastName + .rgInputMsg {
  display: none;
}
.inputGroup .firstName:required:invalid:not(:placeholder-shown) + .rgInputMsg ,
.inputGroup .lastName:required:invalid:not(:placeholder-shown) + .rgInputMsg {
  display: block;
} */
.inputGroup .addMemberBtn {
  width: 100%;
  color: #ffffff;
  border-radius: 4px;
  height: 48px;
  font-family: var(--F1);
  background-color: #0b1f47;
  font-size: 14px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.inputLabel {
  width: 100%;
  display: flow-root;
  color: #002e98;
  /* font-size: 14px; */
  font-size: 14px !important;
  font-weight: 500;
  margin-bottom: 4px;
  display: flex;
  align-items: center;
}

.inputLabelPay {
  width: 100%;
  display: flow-root;
  color: #002e98;
  /* font-size: 14px; */
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
}

.red_star {
  font-size: 16px;
  font-weight: 600;
  color: red;
  position: relative;
  top: 0px;
  left: 1px;
}

.info_tooltip {
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_info.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  display: inline-block;
  background-position: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* border: 2px solid #0c1e47; */
  margin-left: 10px;
  cursor: pointer;
}

.invitedMember_title {
  color: rgba(0, 0, 0, 0.88);
  font-size: 16px;
  margin-bottom: 7.5px;
  display: flex;
  align-items: center;
  font-weight: normal;
}

.upload_section {
  float: left;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.upload_section.user_up {
  justify-content: center;
}

.upload_section .img-div {
  float: left;
  /* width: 60px;
  height: 60px; */
  width: 200px;
  height: 200px;
  margin-right: 0px;
  background-color: #d8d8d8;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* cursor: pointer; */
}

.upload_section.user_up .img-div {
  width: 200px;
  height: 200px;
}

.upload_section .img-div.pointerNone {
  pointer-events: none !important;
}

/* .upload_section .img-div.empty::before {
  content: "\f030";
  font-family: var(--FA);
  font-weight: bold;
  font-size: 28px;
  color: #9a9a9a;
} */

.upload_section .img-div img {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  box-sizing: border-box;
  background: #f5f5f5;
}

span.adminCloseIcon {
  position: absolute;
  width: 26px;
  height: 26px;
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_exit outline.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 26px;
  top: -26px;
  right: 30px;
  cursor: pointer;
}

span.user_Management_title {
  font-size: 20px;
  font-weight: normal;
  color: #0c1e47;
}

a.adminClose {
  position: absolute;
  right: 0;
  top: 36px;
  color: #ffffff;
}

.RoomImgOptDiv {
  position: absolute;
  width: 240px;
  left: 0;
  top: 75px;
  box-shadow: 0 0 1px 0 rgba(10, 31, 68, 0.08),
    0 8px 10px 0 rgba(10, 31, 68, 0.1);
  padding: 10px;
  box-sizing: border-box;
  background-color: #ffffff;
  border-radius: 4px;
  z-index: 99;
  display: none;
}

.guestEmail.error {
  border: 1px solid red;
}

.guestEmail+.errorLabel {
  display: none;
}

.guestEmail.error+.errorLabel {
  display: block;
}

.RoomImgOptDiv li {
  width: 100%;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
  display: block;
  list-style: none;
  color: #53627c;
  font-family: var(--F1);
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  padding: 0 5px;
}

.set_avatar {
  color: #002e98;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 18px;
  position: relative;
  margin-bottom: 15px;
  text-align: center;
}

.roomImgUp_form {
  float: left;
}

.conv_imgUp_label {
  height: 36px;
  width: 99px;
  border: 1px solid #002e98;
  color: #002e98;
  border-radius: 4px;
  font-size: 15px;
  font-weight: normal;
  float: left;
  cursor: pointer;
  position: relative;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.make_private {
  width: 100%;
  float: left;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
}

.make_private .switch {
  position: relative;
  margin-top: 6px;
  width: 65px;
  height: 30px;
  float: left;
}

.make_private .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.make_private .slider.round {
  border-radius: 34px;
}

.make_private input:checked+.slider {
  background-color: #002e98;
}

.make_private .slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 4px;
  bottom: 4px;
  top: 3px;
  background-color: white;
  transition: 0.4s;
  box-shadow: 0 4px 4px 0 rgb(10 31 68 / 16%);
}

.make_private .slider.round:before {
  border-radius: 50%;
}

.make_private input {
  visibility: hidden;
}

.make_private input:checked+.slider:before {
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

.make_private .label_head {
  width: calc(100% - 58px);
  color: #002e98;
  font-size: 16px;
  font-weight: normal;
  float: left;
  display: flex;
  align-items: center;
}

.make_private .label_for {
  color: #5f5f5f;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: normal;
  line-height: 28px;
  float: left;
  display: block;
}

.createConv_foot {
  position: relative;
  width: 100%;
  height: 95px;
  padding: 25px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: #d3e0ff;
}

.createConv_cancel {
  box-sizing: border-box;
  height: 45px;
  width: auto;
  border: none;
  color: #002e98;
  background-color: #ffffff;
  border-radius: 30px;
  font-size: 20px;
  font-weight: normal;
  line-height: 26px;
  cursor: pointer;
  margin-right: 20px;
  padding: 0 25px;
}

.createConv_create {
  height: 45px;
  width: auto;
  border-radius: 30px;
  background-color: #0b1f47;
  border: none;
  color: #ffffff;
  font-size: 20px;
  font-weight: normal;
  line-height: 26px;
  cursor: pointer;
  padding: 0 22px;
  font-size: 16px;
}

.createConv_create:hover {
  background-color: #318fff;
}

.CreateChecklist {
  margin: 0;
  max-height: 272px;
  box-sizing: border-box;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  border: 1px solid #0c1e47;
  width: calc(100% - 30px);
  float: right;
  max-height: 400px;
  border-radius: 4px;
  padding: 8px 12px 8px 20px;
}

.createItemContainer {
  display: inline-block;
  width: 100%;
  max-height: 260px;
  overflow-y: auto;
  margin-bottom: 30px;
}

.checkListPlainText {
  line-height: 44px;
  font-size: 34px;
  font-weight: normal;
  transition: all 0.3s ease;
  width: calc(100% - 80px);
  padding-left: 32px;
  float: left;
  color: #0c1e47;
  position: relative;
}

.checkListPlainText:after {
  content: "\f46d";
  font-family: "FontAwesome";
  color: var(--NewblueC);
  font-size: 25px;
  position: absolute;
  font-weight: bold;
  margin-left: 0;
  margin-right: 0;
  top: 9px;
  left: 22px;
}

.checkListPlainText.title:empty:before {
  content: attr(placeholder);
  color: rgba(0, 0, 0, 0.24);
  font-family: var(--F1);
  font-weight: 500;
}

.settingsIco {
  float: right;
  width: 20px;
  height: 20px;
  position: relative;
  top: 12px;
}

.settingsIco:before {
  content: "\f013";
  font-family: "FontAwesome";
  font-size: 20px;
  color: #d8d8d8;
  cursor: pointer;
}

/* Create Checklist css */

.checkListItem {
  padding: 8px 0px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  float: left;
}

.checkListItem .checkBox {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  background-color: #ffffff;
  border: 2px solid #d8d8d8;
  box-sizing: border-box;
  float: left;
  border-radius: 100%;
  margin-top: 2px;
}

.checkListItem .checkBoxTitle {
  float: left;
  /* margin-left: 8px; */
  color: #0c1e47;
  font-size: 18px;
  width: 100%;
  line-height: 24px;
  height: auto;
  overflow: hidden;
  border-bottom: 1px dashed #c2c2c2;
  box-sizing: border-box;
  padding: 2px 2px;
}

.taskCreatorBody .checkListItem .checkBoxTitle {
  width: calc(100% - 28px);
  margin-left: 8px;
}

.checkListItem .checkBoxTitle:empty:after {
  content: attr(placeholder);
  color: rgba(0, 0, 0, 0.24);
  font-family: var(--F1);
}

.dueDateIcon {
  float: right;
  width: 20px;
  height: 20px;
  position: relative;
  background-image: url(./media/images/dueDate.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 16px;
  cursor: pointer;
  margin: 0 5px;
}

.chk_btnGroup {
  position: fixed;
  bottom: 28px;
  right: 69px;
}

.chk_btnGroup button {
  width: 100px;
  height: 36px;
  border-radius: 4px;
  border: 1px solid #0c1e47;
  font-weight: normal;
  font-size: 17px;
  cursor: pointer;
}

.chk_cancel {
  background-color: #ffffff;
  color: #0c1e47;
}

.chk_submit {
  background-color: #0c1e47;
  color: #ffffff;
  margin-left: 10px;
}

/*******************/
/*Popups CSS End */
/*******************/

.nodecoration {
  text-decoration: none;
  width: 100%;
}

.notificationHeader {
  width: 100%;
  height: 80px;
  background-color: #d9e2e8;
  border-bottom: 1px solid #d9e2e8;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.notificationContainer {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

/* #root.dark .notiBell_icon {
  background-image: url(./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_Notifications.svg);
} */

.notiBell_icon {
  width: 44px;
  height: 44px;
  /* background-image: url("./media/images/bell_icon.svg"); */
  /* background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_notifications.svg"); */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 28px;
}

.action_list {
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 15px;
  color: #0c1e47;
  font-size: 18px;
}

.notiActions {
  position: relative;
  padding: 0 8px;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}

.notiActions.active {
  padding-right: 8px;
}

.notiActions::before {
  content: "";
  width: 2px;
  height: 80%;
  position: absolute;
  /* top: 0; */
  right: 0;
  background-color: #0d2150;
}

.notiActions:last-child:before {
  display: none;
}

.notiActions.active::after {
  content: "\f00c";
  font-family: var(--FA);
  font-size: 16px;
  font-weight: bold;
}

.notiActions:hover {
  color: var(--NewRed);
  cursor: pointer;
}

.view_opts,
.delete_opts {
  display: flex;
  align-items: center;
  justify-content: center;
}

.view_opts:before {
  content: "\f06e";
  font-family: "FontAwesome";
  /* color: #0c1e47; */
  color: #ffffff !important;
  cursor: pointer;
  font-size: 14px;
}

/* .delete_opts:before {
  content: "\f1f8";
  font-family: "FontAwesome";
  color: red;
  cursor: pointer;
} */
.mediaContainer {
  width: 100%;
  display: flow-root;
}

.userMiniModalBody.quick_view_msg .mediaContainer .multi_value.single_file {
  width: calc(100% - 10px);
}

.single_file .img_holder {
  float: left;
  width: 50px;
  height: 45px;
  border-radius: 4px;
  margin-right: 5px;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

.single_file .img_holder img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.single_file .file_name {
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  font-weight: normal;
  /* float: left; */
  overflow: hidden;
  color: #000000;
  font-size: 14px;
  padding-right: 21px;
  line-height: 18px;
  margin-bottom: 5px;
  text-align: left;
}

/* .multi_value.single_file {
  max-width: calc(33% - 10px);
} */

.gt3.single_file {
  max-width: calc(31% - 10px);
  min-width: calc(31% - 20px);
}

.moreAttach {
  width: 55px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  min-height: 66px;
  margin-top: 6px;
  cursor: pointer;
  font-weight: 600;
  border: 1px solid #cfd8dc;
  border-radius: 4px;
  background-color: #fafafa;
  position: relative;
  box-sizing: border-box;
  font-size: 20px;
}

.attachFile.single_file {
  max-width: 60px !important;
  min-width: 60px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
}

p.file_size {
  float: left;
  line-height: 20px;
  font-size: 16px;
  color: #000;
}

.file_hover_option {
  position: absolute;
  border-radius: 4px;
  background-color: #f3f3f3;
  top: 1px;
  height: 40px;
  right: 1px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  display: none;
}

.msgReactionContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 6px;
}

.msgReactionContainer:empty {
  display: none;
}

.msgReaction {
  box-sizing: border-box;
  height: 38px;
  min-width: 67px;
  border: 1px solid #d2dfff;
  border-radius: 4px;
  background-color: #f7f9ff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

span.msgReactionIcon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

span.msgReactionIcon img {
  width: 23px;
}

span.msgReactionCounter {
  height: 23px;
  width: auto;
  color: #028bff;
  font-size: 18px;
  font-weight: normal;
  letter-spacing: 0;
  line-height: 23px;
  margin-left: 7px;
}

.msgReplySection {
  display: flex;
  line-height: 24px;
  font-size: 13px;
  cursor: pointer;
}

.messageReplyIcon {
  background-image: url("./media/images/png/thread-icon.png");
  background-position: 0 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  font-size: 13px;
}

.msgReplySection .rep_left.unread {
  color: var(--NewRed);
}

.msgReplySection .rep_left.read {
  color: var(--Emerald);
}

.msgReplySection .rep_left {
  color: #028bff;
}

.msgReplySection .rep_righ {
  color: #028bff;
}

.msgReplySection .rep_mid,
.msgReplySection .rep_righ {
  margin-left: 6px;
}

.fileOptsCreateDet {
  width: 28px;
  height: 28px;
  overflow: unset;
  display: block;
  margin: 5px;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 20px;
  background-image: url("./media/images/taskDeleteIcon.webp");
}

.fileOptsCreateDet:hover {
  background-image: url("./media/images/icons/other/Red/SVG/WorkFreeli_Icons_Red_trash.svg");
}

.fileOpts {
  width: 28px;
  height: 28px;
  overflow: unset;
  display: block;
  margin: 5px;
  background-color: #0c1e47;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 13px;
}

.fileOpts:hover {
  border-color: var(--NewRed);
}

.fileOpts.star_opts {
  background-image: url("./media/images/star-thin.svg");
  background-size: 15px;
  background-position: 5px 4px;
}

.fileOpts.star_opts.active {
  /* background-image: url("./media/images/star-fill.svg"); */
  background-image: url("./media/images/blueStar/WorkFreeli_Icons_LightBlue_Star.svg");

  background-size: 15px;
  background-position: 5px 4px;
}

.fileOpts.tag_opts {
  background-image: url("./media/images/tag_fff.svg");
}

.fileOpts.download_opts {
  /* background-image: url("./media/images/download_fff.svg"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_download.svg");
}

.fileOpts.fileRestore {
  background-image: url("./media/images/undo_w.svg");
  background-size: 120%;
}

.fileOpts.forward_opts {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_forward.svg");
  /* background-image: url("./media/images/shareHayven_white.svg"); */
  background-size: 16px;
}

.fileOpts.share_opts {
  /* background-image: url("./media/images/share_fff.svg"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_share.svg");
}

.fileOpts.delete_opts {
  /* background-image: url("./media/images/trash-alt-regular.svg"); */
  background-image: url("./media/images/icons/other/Red/SVG/WorkFreeli_Icons_Red_trash.svg");
}

.checklistContainer {
  padding: 6px 10px;
  border-radius: 4px;
  border: 1px solid var(--AIC);
  box-sizing: border-box;
  margin-top: 10px;
  display: flow-root;
  position: relative;
  cursor: pointer;
}

.checklistMsgTitle {
  float: left;
  width: calc(100% - 50px);
  padding-left: 35px;
  color: #0c1e47;
  box-sizing: border-box;
  text-overflow: ellipsis;
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 14px;
  position: relative;
}

.checklistMsgTitle:before {
  content: "";
  /* font-family: "FontAwesome"; */
  /* color: var(--NewblueC); */
  /* font-size: 22px; */
  width: 24px;
  height: 24px;
  background-image: url(./media/images/png/task-list-icon.png);
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 50%;
  position: absolute;
  left: 4px;
  top: 1px;
  font-weight: 600;
}

.checklistFilterBar {
  width: 100%;
  display: flow-root;
}

.accountScroller {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-y: auto;
  padding: 0px 0px 40px 0px;
}

.checkFilterItem {
  position: relative;
  margin-right: 15px;
  position: relative;
  font-size: 14px;
  font-weight: normal;
  cursor: pointer;
  color: var(--NewblueC);
  float: left;
}

.checkFilterItem:last-child {
  margin: 0;
}

.checkFilterItem.active {
  color: #0c1e47;
  font-weight: bold;
}

.checkFilterItem:hover {
  color: var(--NewRed);
}

.checkFilterItem:not(:last-child):after {
  content: "|";
  position: absolute;
  font-size: 14px;
  font-weight: normal;
  color: var(--NewblueC);
  right: -10px;
}

.checklistItem {
  padding: 8px 0px 0px 0px;
  width: 100%;
  height: auto;
  box-sizing: border-box;
  float: left;
  position: relative;
  word-break: break-word;
}

/* .itemContainer.filterRecent .checklistItem, */
.itemContainer.filterAllPending .checklistItem.pending_item,
.itemContainer.filterMyPending .checklistItem,
.itemContainer.filterResRequired .checklistItem,
.itemContainer.filterCompleted .checklistItem.completed_item,
.itemContainer.filterAssigned .checklistItem,
.itemContainer.filterUnassigned .checklistItem,
.itemContainer.filterOverDue .checklistItem,
.itemContainer.filterShowAll .checklistItem {
  display: block;
}

.markItem {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  background: #ffffff;
  border: 2px solid #d8d8d8;
  box-sizing: border-box;
  float: left;
  border-radius: 100%;
  margin-top: 2px;
}

.markItem.marked {
  background-image: url(./media/images/hayven_checked_white.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 70%;
  background-color: var(--NewblueC);
  border: 2px solid var(--NewblueC);
}

.checkBox.active {
  background-image: url(./media/images/hayven_checked_white.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 70%;
  background-color: var(--NewblueC);
  border: 2px solid var(--NewblueC);
}

.itemTitle {
  width: calc(100% - 66px);
  position: relative;

  float: left;
  margin-left: 8px;
  font-family: var(--F1);
  color: #000000;
  font-size: 16px;
  line-height: 24px;
  height: auto;
  overflow: hidden;
  border-bottom: 1px dashed #c2c2c2;
  box-sizing: border-box;
  padding: 2px 2px;
  margin-bottom: 5px;
}

.markItem.marked+.itemTitle {
  text-decoration: line-through;
  pointer-events: none;
  color: var(--NewblueC);
  border-bottom: 1px dashed var(--NewblueC);
}

.item_toolbar {
  width: auto;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 5px;
  border: 1px solid #0c1e47;
  background-color: #ffffff;
  border-radius: 4px;
  display: none;
  align-content: center;
  justify-content: center;
  padding: 3px;
  box-sizing: border-box;
}

.checklistItem:hover .item_toolbar {
  display: flex;
}

.itemOpts {
  width: 32px;
  height: 22px;
  float: left;
  cursor: pointer;
  background-position: 50%;
  background-repeat: no-repeat;
}

.itemOpts._itemAssign {
  background-image: url("./media/images/dueDate.svg");
  background-size: 16px;
}

.itemOpts._itemReply {
  background-image: url("./media/images/thread.svg");
  background-size: 20px;
}

.itemOpts._itemCopy {
  background-image: url("./media/images/copy.svg");
  background-size: 14px;
}

.itemOpts._itemDelte {
  background-image: url("./media/images/trash-red.svg");
  background-size: 14px;
}

.reactionContainer {
  width: auto;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  position: absolute;
  right: 131px;
  bottom: 44px;
  border-radius: 4px;
  z-index: 99;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 8%), 0 6px 6px 0 rgb(0 0 0 / 16%);
  transition: all 0.3s ease;
  padding: 0px 2px;
}

.reactionContainer:hover {
  border: 1px solid #e3f2ff;
  transition: all 0.2s ease;
}

.reactionContainer span {
  padding: 5px 5px;
  box-sizing: border-box;
  height: 42px;
  width: 42px;
  cursor: pointer;
  font-size: 25px;
  line-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reactionContainer span:hover {
  background: #e3f2ff;
  /* background: #d8d8d8; */
  border-radius: 4px;
  transition: all 0.3s ease;
}

.reactionContainer span img {
  width: 30px;
}

.emojiPopup {
  position: fixed;
  box-sizing: border-box;
  /* padding: 4px 18px 18px 18px; */
  bottom: 80px;
  right: 50px;
  height: auto;
  width: 334px;
  border-radius: 8px;
  overflow: auto;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 8%), 0 6px 6px 0 rgb(0 0 0 / 16%);
  float: left;
  z-index: 9;
}

.emoji-mart-bar:last-child {
  display: none !important;
}

.emojiPopup .remove {
  background-image: url("./media/images/times-circle-regular_black.svg");
  border: none;
  background-position: 0px 0px;
  background-repeat: no-repeat;
  background-size: 16px;
  float: right;
  margin-right: 10px;
  width: 18px;
  height: 18px;
  position: relative;
  top: 11px;
  cursor: pointer;
}

.emojiPopup .emoji-container-name {
  /*height: 12px;*/
  color: #546e7a;
  font-family: var(--F1);
  font-size: 16px;
  font-weight: 500;
  line-height: 12px;
  margin: 0px 8px 0px;
  padding: 12px 0px;
}

.emojiPopup .EmojiContainer {
  overflow-y: auto;
}

.emojiPopup .EmojiContainer span {
  width: 47px;
  height: 42px;
  padding: 6px 7.5px;
  cursor: pointer;
  float: left;
  box-sizing: border-box;
  transition: all 300ms ease-out;
  font-size: 22px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.emojiPopup .EmojiContainer span:hover {
  box-sizing: border-box;
  background: #d8d8d8;
  border-radius: 4px;
  padding: 0 1.5px;
  font-size: 26px;
}

.replyThreadContainer,
.checklistEditContainer {
  width: 100%;
  height: 100vh;
  float: left;
  position: absolute;
  top: -1px;
  background-color: #fafafa;
  z-index: 1;
}

.replyThreadContainer_new {
  height: calc(100vh - 0px);
}

.replyMsg_head,
.checklistMsg_head {
  width: 100%;
  height: 80px;
  float: left;
  position: relative;
  background-color: #f0f8ff;
  display: block;
  padding: 30px 30px;
}

.backToChat {
  width: 22px;
  height: 21px;
  background-image: url("./media/images/back.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 22px;
  margin-right: 12px;
  cursor: pointer;
}

.replyHeadTitle,
.checklistHeadTitle {
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
  position: relative;
}

.replyHeadTitle .opt_icons {
  position: absolute;
  right: 0;
}

.replyHeadTitle h3,
.checklistHeadTitle h3 {
  line-height: 21px;
  font-size: 16px;
  font-weight: normal;
  color: #002e98;
}

.replyHeadTitle h3 span,
.checklistHeadTitle h3 span {
  cursor: pointer;
}

.checklistEditClose {
  float: right;
  width: 22px;
  height: 22px;
  background-image: url("./media/images/close_button.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 28px;
}

.checklistEditContainer .itemContainer {
  overflow-y: auto;
  height: calc(100vh - 330px);
  float: left;
  overflow-x: hidden;
  display: block;
  padding: 6px 10px;
  position: relative;
  left: -10px;
  box-sizing: border-box;
  width: calc(100% + 20px);
  margin-top: 10px;
  border-top: 1px solid #d9e2e8;
}

.filter_by {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-image: url("./media/images/filter_active.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 50%;
  border: 1px solid #d8d8d8;
  right: 0px;
  cursor: pointer;
  position: absolute;
  top: -34px;
}

.filter_by:hover {
  border-color: var(--NewRed);
  background-image: url("./media/images/filter_red.svg");
}

.checklistEditContainer .checklistContainer {
  border: none;
}

.msgSeparator,
.replySeparator {
  width: 100%;
  text-align: center;
  position: relative;
  float: left;
  /* margin: 5px 0; */
  margin: 0;
  padding-bottom: 2px;
}

.msgSeparator:after,
.replySeparator:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #e3f2fe;
  position: absolute;
  left: 0;
  top: 44%;
  z-index: 0;
  /*opacity: 0.3;*/
}

.msgSeparator p,
.replySeparator p {
  width: auto;
  display: inline-block;
  font-family: var(--F1);
  position: relative;
  color: #5b6477;
  line-height: 13px;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.2px;
  border-radius: 14px;
  background-color: #0c1e47;
  border: 1px solid #e3f2fe;
  border-radius: 13.5px;
  background-color: #ffffff;
  -webkit-border-radius: 14px;
  -moz-border-radius: 14px;
  -ms-border-radius: 14px;
  -o-border-radius: 14px;
  padding: 5px 10px 5px 11px;
}

.replyMsgContainer {
  position: sticky;
  width: 100%;
  z-index: 1;
  transition: height .3s ease;
  top: 0;
  background-color: #F3F3F3;
  max-height: 240px;
  overflow-y: auto;
}

.replyMsgContainer .user_msg {
  pointer-events: none;
}

.replyForPrivateFile .user_msg {
  pointer-events: auto !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#replyMsgContainerScroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#replyMsgContainerScroll {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.replyMsgFullContainer {
  height: calc(100vh - 210px);
  background-color: #fff;
  width: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.onlyReplyContainer {
  /* height: calc(100vh - 525px); */
  /* height: calc(100vh - 285px); */
  /* overflow: auto; */
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  top: 0
}

.replyCreate_box {
  border: 1px solid #595959;
  width: 100%;
  float: right;
  min-height: 54px;
  max-height: 400px;
  border-radius: 6px;
  font-size: 18px;
  padding: 7px 140px 8px 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  word-break: break-word;
  line-height: 36px;
  resize: none;
}

.replyCreate_box[placeholder]:empty:before {
  content: attr(placeholder);
  color: rgba(0, 0, 0, 0.24);
  font-family: var(--F1);
  cursor: text;
  overflow-y: auto;
}

.msgMorePopup {
  position: absolute;
  right: 2px;
  top: 34px;
  height: auto;
  width: 200px;
  /* width: 256px; */
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(10 31 68 / 8%), 0 8px 10px 0 rgb(10 31 68 / 10%);
  box-sizing: border-box;
  z-index: 2;
  padding: 2px 0px;
  list-style: none;
}

.msgMorePopup.reverse {
  top: -40px;
  bottom: 35px;
}

.msgMorePopup li {
  color: #53627c;
  font-family: var(--F1);
  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  box-sizing: border-box;
  padding: 8px 16px;
  cursor: pointer;
}

.msgMorePopup li:hover {
  background-color: #0c1e47;
  color: #ffffff;
}

.setTimerOpt:before {
  content: "\f017";
  font-family: "FontAwesome";
  color: #53627c;
  font-size: 18px;
  margin-top: -1px;
  position: relative;
  float: left;
  margin-right: 8px;
}

.selectMsgOpt:before {
  content: "\f058";
  font-family: "FontAwesome";
  color: #53627c;
  font-size: 18px;
  margin-top: -1px;
  position: relative;
  float: left;
  margin-right: 8px;
}

.deleteMsgOpt:before {
  content: "";
  /* background-image: url(./media/images/trash-red.svg); */
  background-image: url("./media/images/icons/other/Red/SVG/WorkFreeli_Icons_Red_trash.svg");
  width: 15.75px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 0px;
  background-size: 14px;
  float: left;
  margin-right: 8px;
}

.freeliModal {
  width: 525px;
  height: auto;
  background-color: #ffffff;
  border-radius: 4px;
}

.freeliModalHead {
  width: 100%;
  height: 55px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  position: relative;
  box-sizing: border-box;
}

.popupTitle {
  font-size: 18px;
  font-weight: normal;
  text-align: left;
  font-family: var(--F1);
  color: #0b1f47;
}

.closeModal {
  width: 18px;
  height: 18px;
  position: absolute;
  background-image: url(./media/images/close_button.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  right: 19px;
  top: 20px;
}

.closeModal_call {
  width: 18px;
  height: 18px;
  position: absolute;
  background-image: url(./media/images/close_button.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  right: 30px;
  top: 25px;
}

.freeliModalBody {
  padding: 30px 20px;
  box-sizing: border-box;
  text-align: center;
}

.freeliModalBody .delDesc {
  font-family: var(--F1);
  font-size: 18px;
  color: #0c1e47;
  font-weight: 400;
}

.freeliModalBody .undone {
  text-transform: initial;
  color: #f00;
}

.freeliModalFoot {
  width: 100%;
  height: 75px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
}

.freeliModalFoot:before {
  content: "";
  position: absolute;
  width: calc(100% - 60px);
  height: 1px;
  background-color: #d8d8d8;
  top: 0;
  left: 30px;
}

.freeliModalFoot .btnCancel {
  box-sizing: border-box;
  height: 42px;
  width: auto;
  padding: 0 20px;
  border: 1px solid #0c1e47;
  color: #0c1e47;
  border-radius: 5px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.freeliModalFoot .btnCancel:hover {
  border-color: var(--NewRed);
}

.freeliModalFoot .btnAction {
  height: 42px;
  min-width: 130px;
  width: auto;
  border-radius: 5px;
  background-color: #f55;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  float: right;
  padding: 0 20px;
  box-sizing: border-box;
}

.singleImageView {
  float: left;
  position: relative;
  max-width: 352px;
  min-width: 33%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 5px 22px 0px;
  text-align: center;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #cfd8dc;
  background-color: #ffffff;
  padding: 0px;
}

.singleImageView .img_holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.singleImageView .file_info {
  position: absolute;
  width: 100%;
  line-height: 20px;
  height: 22px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  bottom: -24px;
}

.singleImageView .img_holder img {
  position: relative;
  object-fit: cover;
  max-height: 100%;
  cursor: zoom-in;
  max-width: 330px;
  border-radius: 4px;
  cursor: pointer;
  backface-visibility: hidden;
}

.singleImageView:hover .file_hover_option,
.single_file:hover .file_hover_option {
  display: flex;
}

.tagModal {
  box-sizing: border-box;
  width: 630px;
  background-color: #fafafa;
  border-radius: 4px;
  height: auto;
  position: relative;
}

.tagModalHead {
  width: 100%;
  height: 55px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
}

.tagModalBody {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
}

ul.tagTab {
  width: 100%;
  list-style: none;
  display: flex;
  align-items: center;
  padding: 13px 0;
}

ul.tagTab li {
  /* margin-right: 32px; */
  color: #0c1e47;
  font-family: var(--F1);
  font-size: 18px;
  font-weight: normal;
  line-height: 28px;
  cursor: pointer;
  position: relative;
}

ul.tagTab li.active:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: #2196f3;
  left: 0;
  bottom: -11px;
}

ul.tagTab li.active {
  color: #2196f3;
}

.tagModalFoot {
  width: 100%;
  height: 75px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
}

.tagModalFoot .btnCancel {
  box-sizing: border-box;
  height: 42px;
  width: auto;
  padding: 0 20px;
  border: 1px solid #0c1e47;
  color: #0c1e47;
  border-radius: 5px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.tagModalFoot .btnCancel:hover {
  border-color: var(--NewRed);
}

.tagModalFoot .btnAction {
  height: 42px;
  width: auto;
  border-radius: 4px;
  background-color: #0c1e47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  padding: 0 15px;
  float: right;
  box-sizing: border-box;
}

.tagAddSearch {
  min-height: 48px;
  margin: 15px 0;
  display: flex;
  align-items: center;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  background-color: #ffffff;
}

.tagAddSearch input {
  text-indent: 10px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.88);
  font-family: var(--F1);
  line-height: 26px;
  width: 100%;
  border: none;
  background-color: #fff;
  /*margin-top: 4px;*/
  float: left;
  height: 48px;
  border-radius: 4px;
}

.tagAddSearch input::placeholder {
  font-size: 15px !important;
  color: #9a9a9a;
  font-family: var(--F1);
}

.tagContainer {
  width: 100%;
  height: auto;
  float: left;
  display: block;
  max-height: 312px;
  overflow-y: auto;
  box-sizing: border-box;
}

.tagItem {
  width: 100%;
  /* height: 48px; */
  height: auto;
  font-family: var(--F1);
  font-size: 14px;
  line-height: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 4px 12px;
  margin-bottom: 8px;
  user-select: none;
  box-sizing: border-box;
  background-color: #ffffff;
  position: relative;
  border-left: 4px solid #0c1e47;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.tagItem:hover {
  background-color: #0c1e47;
}

.tagTitle {
  width: calc(100% - 110px);
  float: left;
  padding-left: 24px;
  box-sizing: border-box;
  color: #0c1e47;
}

.tagTitle[contenteditable="true"] {
  background-color: #fff;
  border: 1px solid #d8d8d8;
}

.tagItem:hover .tagTitle[contenteditable="true"] {
  color: #0c1e47;
}

.tagItem:hover .tagTitle {
  color: #ffffff;
}

.tagHoverOptions {
  width: auto;
  height: 22px;
  box-sizing: border-box;
  position: absolute;
  right: 10px;
}

.tagOpt {
  width: 20px;
  height: 20px;
  float: left;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-position: 50%;
  cursor: pointer;
}

.editTag {
  background-image: url(./media/images/editmsg_fff.svg);
  background-size: 20px;
}

.shareTag {
  background-image: url(./media/images/share_green.svg);
  background-size: 15px;
}

.deleteTag {
  background-image: url(./media/images/trash-red.svg);
  background-size: 15px;
}

.tagOpt.deleteTag,
.tagOpt.editTag {
  display: none;
}

.tagItem:hover .tagOpt.deleteTag,
.tagItem:hover .tagOpt.editTag {
  display: block;
}

.single_file .audioFile {
  width: 100%;
  margin-top: 8px;
}

.single_file:not([filetype="mp3"]) .audioFile {
  display: none;
}

.single_file .videoFile {
  max-height: 230px;
  max-width: 100%;
  border-radius: 4px;
  margin-top: 6px;
  margin-right: 5px;
  cursor: pointer;
}

.single_file.multi_value .videoFile {
  display: none;
}

.addMember {
  margin-bottom: 20px;
  padding-right: 35px;
}

.addMember .inputGroup {
  padding: 0;
}

.addGuest {
  margin-bottom: 20px;
}

.addedGuestList {
  padding-right: 35px;
}

.assignModal {
  box-sizing: border-box;
  width: 630px;
  background-color: #fafafa;
  border-radius: 4px;
  height: auto;
  position: relative;
}

.assignModalHead {
  border-bottom: 1px solid #d8d8d8;
  height: 55px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 32px;
}

.assignModalHead .popupTitle {
  box-sizing: border-box;
  color: #0c1e47;
  font-family: var(--F1);
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.5px;
}

.assignModalBody {
  padding: 15px 32px;
  box-sizing: border-box;
}

.assignModalFoot {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  height: 78px;
  box-sizing: border-box;
}

.assignSection {
  width: 100%;
  display: flex;
  align-items: center;
}

.assignSection .dueDiv {
  width: 50%;
  padding-right: 15px;
  box-sizing: border-box;
}

.assignSection .assignDiv {
  width: 50%;
  padding-right: 15px;
  box-sizing: border-box;
  position: relative;
}

.assignSection .dueDiv label,
.assignSection .assignDiv label {
  width: 100%;
  margin-bottom: 8px;
  float: left;
  color: #0c1e47;
}

.dueDateField {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  background-color: #ffffff;
  padding: 8px;
  box-sizing: border-box;
  height: 48px;
  font-size: 15px;
}

.assigneeSrcField {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  padding: 5px 8px;
  box-sizing: border-box;
  height: 48px;
  background-color: #fff;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.assignedUser {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  background-color: #0c1e47;
  border-radius: 4px;
  font-size: 14px;
  padding: 5px 7px;
}

.removeAssign {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: var(--NewRed);
  margin-left: 5px;
  position: relative;
  right: -2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.removeAssign::before {
  content: "\f00d";
  font-family: var(--FA);
  font-size: 11px;
  font-weight: 600;
}

.requestNoteSection {
  width: 100%;
  display: block;
}

.declineNoteSection {
  width: 100%;
  display: block;
}

.alternativeNoteSection {
  width: 100%;
  display: block;
}

.assignModalFoot .btnCancel {
  box-sizing: border-box;
  height: 36px;
  width: auto;
  padding: 0 10px;
  border: 1px solid #0c1e47;
  color: #0c1e47;
  border-radius: 4px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.assignModalFoot .btnCancel:hover {
  border-color: var(--NewRed);
}

.assignModalFoot .btnAction {
  height: 36px;
  width: auto;
  border-radius: 4px;
  background-color: #0c1e47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  padding: 0 15px;
  float: right;
  box-sizing: border-box;
}

.editMsgContainer {
  width: 100%;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  padding: 10px;
  box-sizing: border-box;
  margin-top: 10px;
  background-color: #ffffff;
  position: relative;
  bottom: 0 !important;
}

.msgEditBox {
  min-height: 60px;
  width: 100%;
}

.editMsgBtnSection {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 10px;
}

.editMsgBtnSection .btnCancel {
  box-sizing: border-box;
  height: 36px;
  width: auto;
  padding: 0 10px;
  border: 1px solid #0b1f47;
  color: #0b1f47;
  border-radius: 4px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.editMsgBtnSection .btnCancel:hover {
  border-color: var(--NewRed);
}

.editMsgBtnSection .btnAction {
  height: 36px;
  width: 100px;
  border-radius: 4px;
  background-color: #0b1f47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  float: right;
  box-sizing: border-box;
}

.previewModal {
  width: calc(100% - 346px);
  height: 100vh;
  float: left;
  position: fixed;
  top: 0;
  left: 0;
  left: 346px;
  background-color: #fafafa;
  z-index: 20;
}

.previewModalHead {
  width: 100%;
  height: 64px;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 30px;
}

.previewHeadLabel {
  float: left;
  width: 95%;
  height: 36px;
}

.previewTitle {
  float: left;
  height: 100%;
  border-right: 1px solid #d8d8d8;
  padding-right: 16px;
}

.previewInfo {
  float: left;
  height: 100%;
  padding-left: 16px;
  width: calc(100% - 450px);
}

.nameInfo {
  color: rgba(0, 0, 0, 0.88);
  font-family: var(--F1);
  font-size: 14px;
  font-weight: normal;
  line-height: 16px;
  margin-bottom: 2px;
  width: calc(100% - 2px);
  overflow: hidden;
  height: 18px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dateInfo {
  color: rgba(0, 0, 0, 0.88);
  font-family: var(--F1);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}

.previewModalBody {
  width: 100%;
  height: calc(100vh - 129px);
  background-color: #fafafa;
  position: relative;
}

.previewImgContainer {
  width: 100%;
  overflow: hidden !important;
  height: calc(100vh - 190px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 30px;
}

.transform-component-module_wrapper__1_Fgj {
  overflow: visible !important;
}

.previewImgContainer img {
  /* width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  position: relative;
  cursor: zoom-in; */

  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 4px;
  position: relative;
  cursor: zoom-in;
  object-fit: initial;
}

.previewImgContainer .react-transform-component img {
  min-height: calc(100vh - 520px);
  max-height: calc(100vh - 220px);
}

/* .slideLeft {
  position: absolute;
  box-sizing: border-box;
  height: 65px;
  width: 65px;
  border: 1.5px solid #0c1e47;
  opacity: 0.8;
  background-color: #ffffff;
  border-radius: 50%;
  left: 40px;
  bottom: 47%;
  cursor: pointer;
  z-index: 11;
  background-image: url(./media/images/sliderLeft_blue.svg);
  background-repeat: no-repeat;
  background-size: 73%;
  background-position: 30% 50%;
  user-select: none;
} */

/* .slideRight {
  position: absolute !important;
  box-sizing: border-box;
  height: 65px;
  width: 65px;
  border: 1.5px solid #0c1e47;
  opacity: 0.8;
  background-color: #ffffff;
  border-radius: 50%;
  right: 40px;
  bottom: 47%;
  cursor: pointer;
  z-index: 11;
  background-image: url(./media/images/sliderRight_blue.svg);
  background-repeat: no-repeat;
  background-size: 73%;
  background-position: 70% 50%;
  user-select: none;
} */

/* .slideLeft:hover {
  background-color: var(--NewRed);
  border: 1.5px solid var(--NewRed);
  background-image: url(./media/images/sliderLeft_white.svg);
  box-shadow: 0px 0px 12px var(--NewRed);
}

 */


.slideRight:hover {
  background-color: #0C1E47;
  border: 1.5px solid #0C1E47;
  background-image: url(./media/images/sliderRight_white.svg);
  box-shadow: 0px 0px 12px #0C1E47;
}

.slideLeft:hover {
  background-color: #0C1E47;
  border: 1.5px solid #0C1E47;
  background-image: url(./media/images/sliderLeft_white.svg) !important;
  box-shadow: 0px 0px 12px #0C1E47;
}

.userMiniModal {
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
}

.userMiniModalHead {
  width: 100%;
  height: 50px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.userMiniModalHeadSecurity {
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}



.userMiniModalBody {
  width: 100%;
  padding: 10px 25px 25px;
  box-sizing: border-box;
  display: flow-root;
}

.userImageSection {
  width: 116px;
  height: 108px;
  float: left;
}

.userImageSection img {
  width: 100%;
  border-radius: 14px;
  border: 2px solid #63636338;
  box-sizing: border-box;
  height: 108px;
  object-fit: cover;
  cursor: pointer;
}

.userInfoSection {
  width: calc(100% - 116px);
  float: left;
  height: 108px;
  padding-left: 15px;
  box-sizing: border-box;
}

.userNameSection {
  width: 100%;
  display: flow-root;
}

.userNameTitle {
  font-size: 24px;
  padding-bottom: 0px;
  cursor: pointer;
  color: #0c1e47;
  width: auto;
  float: left;
  max-width: calc(100% - 30px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.userNameTitle:hover {
  color: var(--NewRed);
}

.editUserName {
  width: 20px;
  height: 33px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: 5px;
}

.editUserName:after {
  content: "\f303";
  font-family: "FontAwesome";
  font-size: 12px;
  position: relative;
  top: 1px;
  cursor: pointer;
  font-weight: 600;
}

.userEmailSection {
  font-size: 16px;
  padding-top: 0px;
  color: #0c1e47;
  line-height: 27px;
  width: 100%;
  display: flow-root;
}

.userActionSection {
  width: 100%;
  height: auto;
  float: left;
  padding: 5px;
  box-sizing: border-box;
}

.voiceToUser {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0c1e47;
  margin-right: 12px;
  border: 2px solid #ffffff;
  transition: all 0.2s ease;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 20px;
  background-image: url(./media/images/custom_voice_call.svg);
  cursor: inherit;
}

.videoToUser {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0c1e47;
  margin-right: 12px;
  border: 2px solid #ffffff;
  transition: all 0.2s ease;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 20px;
  background-image: url(./media/images/custom_video_call.svg);
  cursor: pointer;
}

.msgToUser {
  float: left;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0c1e47;
  margin-right: 12px;
  border: 2px solid #ffffff;
  transition: all 0.2s ease;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 18px;
  background-image: url(./media/images/Chat.svg);
  cursor: inherit;
}

.voiceToUser:hover {
  border: 2px solid var(--NewRed);
}

.videoToUser:hover {
  border: 2px solid var(--NewRed);
}

.msgToUser:hover {
  border: 2px solid var(--NewRed);
}

.participantsModal {
  box-sizing: border-box;
  width: 630px;
  background-color: #fafafa;
  border-radius: 4px;
  height: auto;
  position: relative;
  z-index: 999;
}

.participantsModalHead {
  display: flex;
  align-items: flex-end;
  height: 55px;
  width: 100%;
  padding: 0 32px;
  justify-content: flex-start;
}

.participantsModalHead .closeModal {
  top: 35px;
  right: 34px;
}

.participantsModalBody {
  padding: 0 32px 24px;
  box-sizing: border-box;
  width: 100%;
  display: flow-root;
}

.privateModalBody {
  padding: 0 32px 24px;
  box-sizing: border-box;
  width: 100%;
  display: flow-root;
}

.participantsContainer {
  display: flow-root;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  max-height: 280px;
  height: auto;
  overflow-y: auto;
}

.participantsModalBody .searchParticipants {
  width: 100%;
  margin: 15px 0;
  display: flow-root;
}

.privateModalBody .searchParticipants {
  width: 100%;
  margin: 15px 0;
  display: flow-root;
}

.searchParticipants input {
  text-indent: 10px;
  font-size: 20px;
  color: rgba(0, 0, 0, 0.88);
  font-family: var(--F1);
  width: calc(100% - 130px);
  background-color: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
  height: 48px;
  float: left;
  padding-right: 35px;
}

.searchParticipants input::placeholder {
  font-size: 15px !important;
  color: #9a9a9a;
  font-family: var(--F1);

}

.paricipantsSubmit {
  float: right;
  color: #ffffff;
  background-color: var(--PrimaryC);
  font-family: var(--F1);
  font-size: 14px;
  width: 110px;
  border: 1px solid var(--PrimaryC);
  text-align: center;
  height: 48px;
  line-height: 48px;
  border-radius: 4px;
  margin-left: 6px;
  cursor: pointer;
}

.paricipantsSubmit.active {
  background-color: #003ec7;
  border: 1px solid #003ec7;
}

.paricipantsSubmit.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.participantItem {
  height: 56px;
  width: 100%;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 8px;
  cursor: pointer;
  float: left;
  display: flex;
  align-items: center;
  position: relative;
}

.participantTitle {
  color: rgba(0, 0, 0, 0.88);
  font-family: var(--F1);
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
  float: left;
  pointer-events: none;
  white-space: nowrap;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.participantImage {
  float: left;
  width: 68px;
  height: 100%;
  border-radius: 12px;
  padding: 8px 12px 8px 16px;
  box-sizing: border-box;
  overflow: hidden;
  pointer-events: none;
  position: relative;
}

.participantImage img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}


.fileContent {
  width: 100%;
  height: 100%;
  display: flow-root;
  padding: 10px;
  cursor: pointer;
}

.shareLinkModal {
  box-sizing: border-box;
  width: 630px;
  background-color: #fafafa;
  border-radius: 4px;
  height: auto;
  position: relative;
}

.shareLinkModalHead {
  width: 100%;
  height: 55px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
}

.shareLinkModalBody {
  padding: 15px 32px;
  box-sizing: border-box;
  position: relative;
}

.shareLinkModalFoot {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 32px;
  box-sizing: border-box;
}

.shareDesc {
  font-family: var(--F1);
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  margin-bottom: 6px;
}

.shareLinkModalFoot .btnCancel {
  box-sizing: border-box;
  height: 42px;
  width: auto;
  padding: 0 20px;
  border: 1px solid #0c1e47;
  color: #0c1e47;
  border-radius: 4px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.shareLinkModalFoot .btnCancel:hover {
  border-color: var(--NewRed);
}

.shareLinkModalFoot .btnAction {
  height: 42px;
  width: auto;
  border-radius: 4px;
  background-color: #0c1e47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  padding: 0 15px;
  float: right;
  box-sizing: border-box;
}

.assignableUsers {
  width: calc(100% - 15px);
  float: left;
  list-style: none;
  overflow: hidden;
  display: block;
  max-height: 181px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: 76px;
  box-shadow: 0 0 10px 0 rgba(10, 31, 68, 0.08),
    0 8px 10px 0 rgba(10, 31, 68, 0.1);
}

/* .assignableUsers._secrect {
  width: calc(100% - 50%);
  float: right;
  list-style: none;
  overflow: hidden;
  display: block;
  max-height: 181px;
  overflow-y: auto;
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: 206px;
  right: 0;
} */
.show_list {
  float: right;
  position: absolute;
  left: 74px;
  top: 38px;
  font-size: 14px;
  color: #023d67;
  cursor: pointer;
}

.assignableUsers._secrect li.active {
  border-left: 4px solid;
}

.assignableUsers li {
  width: 100%;
  height: 30px;
  font-size: 14px;
  color: #0c1e47;
  cursor: pointer;
  padding: 4px;
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
  border-left: 4px solid #fff;
}

.assignableUsers li img {
  width: 22px;
  height: 22px;
  border-radius: 4px;
  float: left;
}

.assignableUsers li span {
  margin-left: 4px;
  line-height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.galleryModal {
  width: calc(100% - 346px);
  height: 100vh;
  float: left;
  background-color: #fff;
  position: absolute;
  top: -1px;
  left: 346px;
  background-color: #fafafa;
  z-index: 2;
  overflow: hidden;
}

.galleryModalHead {
  width: 100%;
  height: 80px;
  border-top: 1px solid #d8d8d8;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
  padding: 30px 30px;
  position: relative;
  display: flex;
  align-items: center;
}

.tabContainer {
  float: left;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.gTabs {
  padding: 0px;
  border: none;
  background-repeat: no-repeat;
  background-position-x: calc(100% - 4px);
  background-position-y: 50%;
  background-size: 20px;
  display: block;
  border-radius: 14px;
  padding: 0px 6px 0px 6px;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 16px;
  font-weight: normal;
  height: 30px;
  cursor: pointer;
  margin-right: 10px;
  overflow: hidden;
  line-height: 30px;
  transition: all 0.5s ease;
  background-color: #514d4dad;
}

.gTabs:hover {
  background-color: #1059b1;
  transition: all 0.5s ease;
  color: #fff !important;
}

.gTabs.active {
  padding: 0px 32px 0px 12px;
  background-image: url(./media/images/circleCheckgreen.svg);
  /* background-image: url('./media/images/icons/other/Green/SVG/WorkFreeli_Icons_Green_check outlines.svg'); */
  background-repeat: no-repeat;
  background-position-x: calc(100% - 4px);
  background-position-y: 50%;
  background-size: 20px;
  color: #fff;
  background-color: #0c1e47;
}

.galleryModalBody {
  padding: 0 30px;
  width: 100%;
  height: calc(100vh - 60px);
  background-color: #ffffff;
  box-sizing: border-box;
}

.gFilter {
  width: 100%;
  height: 56px;
  padding: 10px 0;
  position: relative;
  display: flex;
  align-content: center;
  justify-content: space-between;
}

.gFileContainer {
  width: 100%;
  /* height: calc(100% - 56px); */
  height: 100%;
  overflow-y: auto;
}

.dateByFiles {
  display: flow-root;
  margin-bottom: 10px;
}

.gDateTitle {
  width: 100%;
  color: #0c1e47;
  font-family: var(--F1);
  font-size: 16px;
  font-weight: normal;
  line-height: 28px;
  margin-bottom: 5px;
}

.gFiles {
  border-radius: 4px;
  float: left;
  background-color: #ffffff;
  border: 1px solid #d8d8d8;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
}

.gListView .gFiles {
  width: 100%;
  min-height: 65px;
  padding: 14px 12px;
  margin-bottom: 5px;
}

.gListView .gFiles .markFile {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 2px solid #0c1e47;
  box-sizing: border-box;
  float: left;
  border-radius: 100%;
  position: absolute;
  left: 10px;
  z-index: 1;
  cursor: pointer;
  display: none;
}

.gListView .gFiles:hover .markFile {
  display: block;
}

.gListView .gFiles .markFile.marked {
  display: block;
  /* background-image: url(./media/images/circleCheckgreen.svg); */
  background-image: url("./media/images/icons/other/Green/SVG/WorkFreeli_Icons_Green_check outlines.svg");
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 30px;
  border: unset;
}

.gListView .gFileHolder {
  width: 60px;
  height: 65px;
  background-color: transparent;
  margin-left: 35px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.gListView .gFileHolder img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.gListView .gFileInfo {
  height: auto;
  width: calc(100% - 180px);
  margin-left: 25px;
  cursor: pointer;
}

.galleryLink .gFileInfo {
  width: calc(100% - 60px);
}

.gListView .gFileCustomName {
  color: #0c1e47;
  font-family: var(--F1);
  font-size: 20px;
  font-weight: normal;
  line-height: 26px;
}

.gListView .gFileName {
  color: rgba(0, 0, 0, 0.88);
  font-family: var(--F1);
  /* font-size: 14px; */
  font-size: 16px;
  line-height: 25px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  width: 75%;
}

.gListView .gFileUpInfo {
  display: block;
  float: left;
  font-size: 16px;
  font-family: var(--F1);
  color: #9b9b9b;
}

.gFileHoverOpt {
  width: auto;
  height: 40px;
  border: 1px solid #0c1e47;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  /* top: 20px; */
  right: 20px;
  background-color: #f3f3f3;
  display: none;
  z-index: 1;
}

.gFileHoverLists {
  width: auto;
  height: 40px;
  border: 1px solid #0c1e47;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  /* top: 20px; */
  right: 20px;
  background-color: #f3f3f3;
  display: none;
  z-index: 1;

}

/* li._afileName.fileName_list:hover .gFileHoverLists{
  display: flex;
} */
ul.fileBodyRow.inFileTagRow:hover .gFileHoverLists {
  display: flex;
}

.gFileHoverListsLink {
  width: auto;
  /* height: 40px; */
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  bottom: 50%;
  right: 20px;
  background-color: #f3f3f3;
  display: none;
  z-index: 1;
}

.gFiles:hover .gFileHoverOpt {
  display: flex;
}

.fileBodyRow.inFileLinkRow:hover .gFileHoverListsLink {
  display: flex;

}

/*.gFiles {
    border-radius: 4px;
    float: left;
    background-color: #FFFFFF;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
}*/

.gGridView .gFiles {
  margin-right: 32px;
  width: 30%;
  margin-bottom: 35px;
  height: 200px;
}

.gGridView .gFiles:nth-child(3n) {
  margin-right: 0;
}

.gGridView .gFiles .markFile {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 2px solid #0c1e47;
  box-sizing: border-box;
  float: left;
  border-radius: 100%;
  position: absolute;
  left: 10px;
  z-index: 1;
  cursor: pointer;
  top: 24px;
  display: none;
}

.gGridView .gFiles:hover .markFile {
  display: block;
}

.gGridView .gFiles .markFile.marked {
  display: block;
  background-image: url(./media/images/circleCheckgreen.svg);
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 24px;
}

.gGridView .gFileHolder {
  width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
}

.gGridView .gFileHolder img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

.gFileGridName,
.gGridView .gFileCustomName,
.gGridView .gFileName,
.gGridView .gFileUpInfo {
  display: none;
}

.gGridView .gFileGridName {
  color: rgba(0, 0, 0, 0.88);
  font-family: var(--F1);
  font-size: 12px;
  line-height: 21px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: -23px;
  left: 0;
}

span.gridName {
  max-width: calc(100% - 70px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 3px;
}

.gFilterOption {
  background-color: aliceblue;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  float: right;
}

.gFilterOption div {
  width: 38px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
}

.gFilterOption div::before {
  content: "";
  font-family: "FontAwesome";
  font-size: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0c1e47;
  font-weight: 600;
}

.gFilterOption div.gGridViewIcon:before {
  content: "\f00a";
}

.gFilterOption div.gListViewIcon:before {
  content: "\f03a";
}

.gFilterOption div.gGridViewIcon.active,
.gFilterOption div.gListViewIcon.active {
  background-color: #0c1e47;
}

.gFilterOption div.gGridViewIcon.active:before,
.gFilterOption div.gListViewIcon.active:before {
  color: #ffffff;
}

.gFilterOption div.gInfoIcon {
  background-image: url("./media/images/conv-i-new-bb.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 18px;
}

.gFilterOption div.gInfoIcon:before {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  border: 2px solid #0c1e47;
}

.gSearchSection {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.gSearchSection button {
  width: 38px;
  height: 78%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: normal;
  color: #fff;
  background-color: #fff;
  border-radius: 0 20px 20px 0;
  cursor: pointer;
  position: absolute;
  right: 0;
  border: none;
  border-left: 1px solid #d8d8d8;
}

/* .gSearchSection button::before {
  /* content: "\f002"; 
  content: "";
  font-family: var(--FA);
  font-weight: 600;
  color: #0c1e47;
  font-size: 20px;
  background-image:url('./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_search.svg')
} */
.gSearchSection button {
  content: "";
  font-family: var(--FA);
  font-weight: 600;
  color: #0c1e47;
  font-size: 20px;
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_search.svg");
  background-size: 20px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.gSearchInput {
  height: 100%;
  background-color: #fff;
  text-indent: 20px;
  width: 100%;
  border: 1px solid #d8d8d8;
  padding-right: 75px;
  border-radius: 4px;
}

.allSearchSection {
  height: 100%;
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  padding: 0 12px;
  position: relative;
  justify-content: right;
}

#root.dark .allSearchSection svg {
  color: #fff;
}

.searchPartsContainer {
  height: 40px;
  background-color: #f1f6fa;
  border-radius: 20px;
  width: calc(100% - 150px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-right: 40px;
}

.searchSelectionSection {
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 10px;
  background-color: #f1f6fa;
  border-radius: 20px 0 0 20px;
  color: #fff;
  position: relative;
  left: -1px;
  cursor: pointer;
}

.searchSelectionSection.active {
  pointer-events: none;
}

/* .searchSelectionSection:before {
  content: "\f002";
  font-family: "FontAwesome";
  font-size: 16px;
  color: #0c1e47;
  font-weight: 600;
  margin-left: 8px;
} */
.searchSelectionSection span {
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  margin-left: 6px;
}

.searchSelectionSection img {
  width: 12px;
  margin: 0 8px;
}

.allSearchInput {
  width: calc(100% - 90px);
  height: 100%;
  border: none;
  background-color: transparent;
  font-size: 15px;
  font-family: var(--F1);
  overflow: hidden;
  padding-right: 100px;
}

.clearAllSrcTest {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 40px;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
}

.clearAllSrcTest::before {
  content: "\f057";
  font-family: var(--FA);
  font-weight: 600;
  color: var(--NewRed);
  font-size: 20px;
}

.clearAllSrcTestMain {
  width: 24px;
  height: 24px;
  position: absolute;
  right: 222px;
  cursor: pointer;
  display: block;
  z-index: 999999;
  bottom: 39px;

}

.Kalend__Calendar__root,
.Kalend__Calendar__root-dark {

  display: flex;
  width: auto !important;
  height: 100%;
  flex-direction: column;
  font-size: 10px;
  overflow-x: hidden;
  background: white;
}

.clearAllSrcTestMain::before {
  content: "\f057";
  font-family: var(--FA);
  font-weight: 600;
  color: var(--NewRed);
  font-size: 20px;
}


.allSearchInput:not(:placeholder-shown)+.clearAllSrcTest {
  display: flex;
}

input#_inputBar_tagdetails:not(:placeholder-shown)+.clearAllSrcTest {
  display: flex;
}

input._inputBar.searchLeft:not(:placeholder-shown)+.clearAllSrcTest {
  display: flex;
}

input#_inputBar_tagdetails:not(:placeholder-shown)+.clearAllSrcTest {
  display: flex;
}

input._inputSearchBar.mainSerach:not(:placeholder-shown)+.clearAllSrcTest {
  display: flex;
}

input._inputBar.searchRight:not(:placeholder-shown)+.clearAllSrcTest {
  display: flex;
}

.srcBtnSection {
  width: 35px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 3px;
  background-color: #f1f2f4;
  border-radius: 0 20px 20px 0;
  /* border-left: 1px solid #d8d8d8; */
  cursor: pointer;
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_search.svg");
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50% 50%;
}

.srcBtnSectionLink {
  width: 35px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  /* border-radius: 0 20px 20px 0; */

  cursor: pointer;
  background-image: url("./media/images/search_iconNew.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 50% 50%;
}

.srcBtnSectionLink2 {
  width: 35px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;

  /* border-radius: 0 20px 20px 0; */

  cursor: pointer;
  background-image: url("./media/images/search_iconnew2.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 50% 50%;
}

.srcBtnSectionTaskPop {
  width: 35px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  /* border-radius: 0 20px 20px 0; */

  cursor: pointer;
  background-image: url("./media/images/search_iconnew2.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 50% 50%;
}



ul.searchSelectionPopup {
  position: absolute;
  height: auto;
  background: white;
  border-radius: 2px;
  padding: 10px 15px;
  z-index: 3;
  width: 170px;
  list-style: none;
  top: 60px;
  left: 0;
  display: inline-table;
  box-shadow: 0 0 1px 0 #f8f8f81a, 0 18px 18px 0 rgb(10 31 68 / 12%);
}

ul.searchSelectionPopup li {
  height: 40px;
  width: 100%;
  border-bottom: 1px solid #f8f8f8;
  padding-left: 15px;
  color: #0c1e47;
  font-size: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

ul.searchSelectionPopup li:hover {
  background-color: #0c1e47;
  color: #fff;
}

.muteModal {
  box-sizing: border-box;
  width: 630px;
  background-color: #fafafa;
  border-radius: 4px;
  height: auto;
  position: relative;
}

.muteModalHead {
  width: 100%;
  height: 55px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
}

.muteModalBody {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  display: flow-root;
}

.muteModalFoot {
  width: 100%;
  height: 75px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
}

.muteModalFoot .btnCancel {
  box-sizing: border-box;
  height: 42px;
  width: auto;
  padding: 0 20px;
  border: 1px solid #0c1e47;
  color: #0c1e47;
  border-radius: 5px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.muteModalFoot .btnCancel:hover {
  border-color: var(--NewRed);
}

.muteModalFoot .btnAction {
  height: 42px;
  width: auto;
  border-radius: 5px;
  background-color: #0c1e47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  padding: 0 20px;
  float: right;
  box-sizing: border-box;
}

.mutePopLabel {
  height: auto;
  margin: 15px 0;
  display: flex;
  align-items: center;
}

.mutePopLabel span {
  line-height: 16px;
  font-size: 12px;
  color: #8e8e8e;
  font-family: var(--F1);
  display: block;
  font-weight: normal;
}

.muteContainer {
  width: 100%;
  height: auto;
  float: left;
  display: block;
  max-height: calc(100vh - 250px);
  overflow-y: auto;
  box-sizing: border-box;
}

.muteItem {
  height: 50px;
  border: 1px solid #dbdbdb;
  margin-bottom: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 40px;
  cursor: pointer;
  position: relative;
}

.muteItem:hover {
  border-color: #0c1e47;
  background-color: #0c1e47;
}

.muteRadio {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
  position: absolute;
  left: 14px;
  box-sizing: border-box;
  pointer-events: none;
}

.muteItem.active {
  border: 1px solid #0c1e47;
  background-color: #0c1e47;
}

.muteItem.active .muteRadio {
  /* border: 5px solid #0c1e47; */
  border: 5px solid #ffffff;
}

.muteItem:hover .muteRadio {
  /* border: 5px solid #0c1e47; */
  border: 5px solid #ffffff;
}

.muteItem:hover .muteTitle {
  color: #ffffff;
}

.muteItem.active .muteTitle {
  /* border: 5px solid #0c1e47; */
  color: #ffffff;
}

.muteTitle {
  width: calc(100% - 110px);
  float: left;
  box-sizing: border-box;
  color: #0c1e47;
  pointer-events: none;
}

.filterModal {
  box-sizing: border-box;
  width: 630px;
  background-color: #fafafa;
  border-radius: 4px;
  height: auto;
  position: relative;
}

.filterModalHead {
  width: 100%;
  height: 65px;
  padding: 0 32px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
}

.filterPopupSearch {
  float: left;
  width: 250px;
  height: 36px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 14px;
}

.filterPopupSearch::placeholder {
  font-size: 14px;
  color: #bebebe;
}

.filterModalBody {
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  display: flow-root;
}

.filterContainer {
  max-height: calc(100vh - 200px);
  overflow-y: auto;
  margin: 14px 0;
}

.filterSection {
  margin-bottom: 20px;
}

.filterSectionLabel {
  font-size: 16px;
  color: #0c1e47;
  float: left;
  width: 100%;
  line-height: 34px;
  border-bottom: 1px solid #d8d8d8;
}

.filterItem {
  width: 100%;
  border-bottom: 1px solid #f1efef;
  box-sizing: border-box;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filterItemLabel {
  color: #bebebe;
  font-size: 16px;
  width: calc(100% - 60px);
}

.filterItem.active .filterItemLabel {
  color: #0c1e47;
}

.filterItemActivator {
  width: 60px;
  background-color: #bebebe;
  height: 25px;
  float: right;
  border-radius: 4px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}

.filterItemActivatorLabel {
  width: 17px;
  height: 17px;
  background: #fff;
  border-radius: 4px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  float: left;
}

.filterItem.active .filterItemActivator {
  background-color: #0c1e47;
}

.filterItem.active .filterItemActivatorLabel {
  float: right;
}

.filterModalFoot {
  height: 75px;
  width: 100%;
  padding: 0 32px;
  border-top: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.filterModalFoot .btnCancel {
  box-sizing: border-box;
  height: 36px;
  width: auto;
  padding: 0 10px;
  border: 1px solid #0c1e47;
  color: #0c1e47;
  border-radius: 4px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.filterModalFoot .btnCancel:hover {
  border-color: var(--NewRed);
}

.filterModalFoot .btnAction {
  height: 36px;
  width: auto;
  border-radius: 4px;
  background-color: #0c1e47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  padding: 0 15px;
  float: right;
  box-sizing: border-box;
}

/****************/
/**companyPage**/
/***************/

.companyPage {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.companyPageBox {
  box-sizing: border-box;
  width: 65%;
  height: auto;
  border: 1px solid #003466;
  padding: 30px 60px;
  box-shadow: 3px 2px 8px -2px rgb(142 142 142 / 73%);
  -webkit-box-shadow: 3px 2px 8px -2px rgb(142 142 142 / 73%);
}

.company_names_head {
  font-size: 20px;
  text-align: center;
  color: #000;
}

.companyContainer {
  display: flex;
  align-items: center;
  max-height: calc(100vh - 240px);
  justify-content: flex-start;
  align-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
}

/* .companyContainer {} */

.companyItem {
  border: 1px solid #0c1e47;
  border-radius: 4px;
  width: 100%;
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0;
  cursor: pointer;
  color: rgb(255 255 255);
  font-size: 20px;
  font-weight: 600;
  padding: 10px;
  /* background-color: #003ec7; */
  background-color: #0c1e47;

  /* border: none; */
}

.companyItem:focus,
.companyItem:hover {
  background-color: #002e98;
}

/* .companyItem:focus,
.companyItem:hover {
  background-color: #19356c;
} */

/*******************/
/**companyPage End**/
/*******************/

.btn_loader {
  background-color: #fff;
  background-image: url('./media/images/loginBefore/WorfreeliCircularDots.svg');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 50px;
  border: 1px solid #0c1e47;
  pointer-events: none;
}

.dot_loader {
  background-color: #ffffff;
  background-image: url('./media/images/loginBefore/WorfreeliCircularDots.svg');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 135px;
  min-height: 200px;
}

.ListLoader {
  background-color: #ffffff;
  background-image: url('./media/images/loginBefore/WorfreeliCircularDots.svg');
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 135px;
  min-height: 500px;
}

.switchLoaderMain {
  width: calc(100% - 346px);
  height: calc(100vh - 0px);
  float: left;
  position: absolute;
  right: 0;
  background-color: #fff;
  background-image: url(./media/images/loginBefore/WorfreeliCircularDots.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.loaderMain {
  width: 100%;
  height: calc(100vh - 0px);
  float: left;
  position: absolute;
  right: 0;
  background-color: #fff;
  background-image: url(./media/images/loginBefore/WorfreeliCircularDots.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 130px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
}

.notFoundContainer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
}

.notFoundBox {
  color: #fff;
  box-shadow: 0 2px 8px 0 rgb(0 0 0 / 16%);
  width: 600px;
  height: 400px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.notFoundBox h2 {
  color: #0c1e47;
  font-size: 40px;
  font-weight: bolder;
  line-height: 72px;
}

.notFoundBox ul {
  color: #0c1e47;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
}

.notFoundBox p.red {
  color: red;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  padding: 0px 100px;
  box-sizing: border-box;
  margin-top: 15px;
  text-align: center;
}

.notFoundBox a,
.notFoundBox span {
  line-height: 40px;
  text-decoration: none;
  border: 1px solid #0c1e47;
  padding: 0 33px;
  margin-top: 15px;
  border-radius: 30px;
  color: #0c1e47;
  cursor: pointer;
}

.notFoundBox a:hover,
.notFoundBox span:hover {
  border-color: var(--NewblueC);
  color: var(--NewblueC);
}

.scrollLoader {
  width: 100%;
  height: 60px;
  float: left;
  position: absolute;
  right: 0;
  background-image: url(./media/images/loginBefore/WorfreeliCircularDots.svg);
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: 50%;
  z-index: 1;
  background-size: 100px;
}

.conversationsModal {
  width: calc(100% - 346px);
  height: 100vh;
  float: left;
  position: absolute;
  top: -1px;
  left: 346px;
  background-color: #fafafa;
  border-top: 1px solid #d8d8d8;
  z-index: 2;
}

.conversationsModalHead {
  width: 100%;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
}

.conversations_close {
  position: absolute;
  width: 22px;
  height: 22px;
  background-image: url("./media/images/close_button.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 22px;
  top: 29px;
  right: 30px;
  cursor: pointer;
}

.conversationsSrcSection input {
  width: 100%;
  height: 40px;
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid #d8d8d8;
  border-radius: 45px;
  padding: 9px 16px 10px 44px;
  background-image: url("./media/images/search_icon_blue.svg");
  background-repeat: no-repeat;
  background-position: 15px 50%;
}

.conversationsSrcSection {
  width: 25%;
  position: absolute;
  right: 90px;
}

.conversationsModalBody {
  height: calc(100vh - 82px);
  overflow-y: auto;
  padding: 0 30px;
  box-sizing: border-box;
}

.roomsSection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.roomsSectionLabel {
  width: 100%;
  line-height: 50px;
  padding: 10px 0;
  font-weight: normal;
  font-size: 22px;
  color: #0c1e47;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.privateRoomContainer,
.publicRoomContainer,
.closedRoomContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.roomsGrid {
  width: calc(33.33% - 16px);
  min-height: 122px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%), 0 3px 4px 0 rgb(0 0 0 / 10%);
  float: left;
  position: relative;
  cursor: pointer;
  padding: 16px;
  margin-right: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.roomsGrid:hover {
  box-shadow: 0 20px 25px 0 rgb(0 0 0 / 8%), 0 10px 28px 0 rgb(0 0 0 / 10%);
}

.roomIconSec {
  width: 45px;
  height: 100%;
}

.roomInfoSec {
  width: calc(100% - 105px);
  height: 100%;
  padding-left: 10px;
}

.roomIconSec img {
  width: 45px;
  height: 45px;
  border-radius: 14px;
}

.goToRoom {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0c1e47;
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}

.roomTitle {
  color: #0c1e47;
  font-family: var(--F1);
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 0.23px;
  line-height: 21px;
  padding-left: 22px;
  margin-bottom: 5px;
  box-sizing: border-box;
}

.closedRoomContainer .roomTitle,
.privateRoomContainer .roomTitle {
  background-image: url(./media/images/PrivateLockBlue.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: -2px 50%;
}

.publicRoomContainer .roomTitle {
  background-image: url(./media/images/PublicHashBlue.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: -2px 50%;
}

.roomAdmin,
.roomMemberC {
  color: #0c1e47;
  font-family: var(--F1);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 21px;
  padding-left: 22px;
  margin-bottom: 5px;
  box-sizing: border-box;
  background-image: url(./media/images/Users.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position: -2px 50%;
}

.passwordModal {
  box-sizing: border-box;
  width: 420px;
  background-color: #f8f8f8;
  border-radius: 4px;
  height: auto;
  position: relative;
}

.passwordModalHead {
  padding: 0 32px;
  display: flex;
  align-items: center;
  height: 66px;
  border-bottom: 1px solid #d8d8d8;
}

.passwordModalBody {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  display: flow-root;
  height: auto;
  box-sizing: border-box;
  padding: 20px 32px;
}

.passInputGroup {
  display: flow-root;
  width: 100%;
  height: auto;
  padding: 6px 0;
  box-sizing: border-box;
  position: relative;
}

.passInputLabel {
  height: 22px;
  color: #565656;
  font-size: 16px;
  font-weight: normal;
  line-height: 22px;
  text-align: left;
  display: block;
  margin-bottom: 8px;
}

.passInput {
  width: 100%;
  display: flow-root;
  height: 50px;
  border-radius: 4px;
  background-color: #fff !important;
  padding: 14px 46px !important;
  margin-bottom: 10px !important;
  box-sizing: border-box;
  border: 1px solid #d6d6d6;
}

.passInput.error {
  border-color: red;
}

.passInput+.errorLabel {
  display: none;
  color: red;
}

.passInput.error+.errorLabel {
  display: block;
  position: relative;
  top: -3px;
}

.passwordModalFoot {
  width: 100%;
  height: 60px;
  padding: 0 32px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
}

.passwordModalFoot .btnCancel {
  box-sizing: border-box;
  height: 42px;
  width: auto;
  padding: 0 20px;
  border: 1px solid #0c1e47;
  color: #0c1e47;
  border-radius: 5px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.passwordModalFoot .btnCancel:hover {
  border-color: var(--NewRed);
}

.passwordModalFoot .btnAction {
  height: 42px;
  width: auto;
  border-radius: 5px;
  background-color: #0c1e47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  padding: 0 20px;
  float: right;
  box-sizing: border-box;
}

.msgSelector {
  width: 25px;
  height: 25px;
  border: 2px solid #0c1e47;
  position: absolute;
  border-radius: 50%;
  top: 19px;
  left: 15px;
  cursor: pointer;
  display: none;
  transition: all 0.2s ease-out;
}

.msgSelector:hover {
  border-color: #8e8a8a;
}

.msgSelector.selected {
  background-image: url(./media/images/hayven_checked_white.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 70%;
  background-color: #0c1e47;
  border: 2px solid #0c1e47;
}

/* .msgSelectionOn  for selection design*/

.msgSelectionOn .user_msg {
  transition: all 0.2s ease-out;
}

.msgSelectionOn .user_msg .msgSelector {
  display: block;
}

.roomsInfo {
  width: 17px;
  height: 17px;
  background-color: #ffffff;
  border-radius: 50%;
  background-image: url(./media/images/conv-i-new-bb.png);
  background-size: 18px;
  background-position: 50%;
  background-repeat: no-repeat;
  cursor: pointer;
  position: absolute;
  left: 214px;
  top: 14px;
  display: none;
}

/* 
.userUpdatePopup .createConv_body {
  height: calc(100% - 64px);
} */

/* .userUpdatePopup .name_and_profile { */
/* margin-top: 45px; */
/* } */

.callerIdBtnGroup {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.callerIdBtnGroup button {
  margin-left: 8px;
  height: 36px;
  padding: 0 18px;
  border-radius: 4px;
  cursor: pointer;
}

.callerIdBtnGroup .btnCancel {
  border: 1px solid #0c1e47;
  background-color: #ffffff;
  font-size: 15px;
  color: #0c1e47;
}

.callerIdBtnGroup .btnCancel:hover {
  border: 1px solid var(--NewRed);
}

.callerIdBtnGroup .btnAction {
  border: 1px solid var(--NewRed);
  background-color: var(--NewRed);
  color: #ffffff;
  font-size: 18px;
}

.addedMemberList {
  padding-right: 0;
  display: flex;
  flex-direction: column;
}

.memberItem {
  box-sizing: border-box;
  border-radius: 4px;
  background-color: #ffffff;
  /* padding: 18px 16px; */
  padding: 11px 16px;
  margin-bottom: 8px;
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.memberItem:hover {
  background-color: #94b2ff;
}

.memberItemImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: left;
  position: relative;
}

.online .memberItemImage::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url("./media/images/OnlineStatus.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 50%;
  bottom: -3px;
  left: -3px;
}

.offline .memberItemImage::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  background-image: url("./media/images/OfflineStatus.svg");
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 50%;
  bottom: -3px;
  left: -3px;
}

.memberItemImage img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.memberItemData {
  margin-left: 10px;
  float: left;
  display: flex;
  align-items: center;
}

.memberItemTitle {
  color: rgba(0, 0, 0, 0.88);
  font-family: var(--F1);
  font-size: 14px;
  font-weight: normal;
  cursor: default;
}

.memberItemDesignation {
  color: #9b9b9b;
  font-family: var(--F1);
  font-size: 14px;
  position: relative;
  top: -1px;
}

/* #root.dark .memberItemDesignation:hover {
  color: #9b9b9b;
} */

.memberItem:hover .memberItemDesignation {
  color: #fff;
}

.memberItemEmail {
  color: #9b9b9b;
  font-family: var(--F1);
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.23px;
  display: block;
}

.memberItemAction {
  position: absolute;
  right: 9px;
  /* bottom: 28px; */
  bottom: -3px;
  display: none;
}

/* span.userProfileName {
  font-size: 16px;
} */
.memberItemAction span {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  margin-left: 8px;
  color: #fff;
  padding: 3px 12px;
  border-radius: 10px;
  cursor: pointer;
  display: block;
  margin-bottom: 5px;
  text-align: center;
}

span.createConv_back.updateRoom {
  top: 28px;
}

span.createConv_close.updateRoom {
  top: 28px;
}

span.createConv_back.profileBack {
  top: 25px;
}

span.createConv_close.profileClose {
  top: 28px;
}

.makeAdmin {
  background-color: #0c1e47;
}

.removeAdmin {
  background-color: var(--NewRed);
}

.memberItem:hover .memberItemAction {
  display: inherit;
}

/* Task Panel Style */

.searchAndFilter {
  height: 56px;
  width: 100%;
  background-color: #fff;
  /* padding: 8px 13px; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

input._inputBar {
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  /* border-radius: 4px 0 0 4px; */
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  text-indent: 10px;
  /* background-image: url("./media/images/png/search-icon.png"); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_search.svg");
  background-repeat: no-repeat;
  background-position: 99%;
  background-size: 20px;
}

.btn_active input._inputBar {
  background-image: none;
}

.searchAndFilter .oneTimeSearchButton {
  height: 40px;
  display: none;
  align-items: center;
  justify-content: center;
  background: #0c1e47;
  color: #fff;
  cursor: pointer;
  border-right: 1px solid #fff;
  padding: 0 10px;
  border-radius: 0 4px 4px 0;
}

.searchAndFilter .oneTimeSearchButton:before {
  content: "\f002";
  font-family: "FontAwesome";
  color: #fff;
  font-size: 16px;
  margin-right: 6px;
  font-weight: 600;
}

.searchAndFilter .oneTimeSearchButton:after {
  content: "";
  display: none;
}

.searchAndFilter .clrChkSrc {
  width: 50px;
  text-align: center;
  height: 40px;
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: #fff;
  cursor: pointer;
  position: relative;
  left: -1px;
  border: 1px solid #d8d8d8;
  border-right: none;
  border-left: none;
}

.searchAndFilter .clrChkSrc:before {
  content: "\f057";
  font-family: "FontAwesome";
  font-size: 26px;
  color: #0c1e47;
  font-weight: 600;
}

.searchAndFilter.btn_active .clrChkSrc,
.searchAndFilter.btn_active .oneTimeSearchButton {
  display: flex !important;
}

.submit_inputBar {
  width: 50px;
  height: 40px;
  border-radius: 0 4px 4px 0;
  background-color: #0c1e47;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submit_inputBar:before {
  content: "\f107";
  font-family: "FontAwesome";
  font-size: 26px;
  color: #fff;
  font-weight: bold;
}

.taskPanelHeadSection {
  display: none;
}

.taskPanelHeadSection {
  width: 100%;
  height: 100%;
  display: flex;
}

.taskPanelheadPart {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
}

.taskPanelheadPart._right {
  justify-content: flex-end;
}

.taskPanelTitle {
  color: #0c1e47;
  font-family: var(--F1);
  font-size: 18px;
  padding-left: 30px;
  position: relative;
}

.taskPanelTitle:before {
  content: "\f46d";
  font-family: "FontAwesome";
  color: #0c1e47;
  font-size: 22px;
  position: absolute;
  left: 0px;
  top: 0px;
  font-weight: 600;
}

#taskAss_pagination {
  text-align: center;
}

.showingSelect {
  float: left;
  box-sizing: border-box;
  font-size: 15px;
  color: #0c1e47;
  user-select: none;
}

.showingSelect span {
  float: left;
  line-height: 30px;
  margin-right: 4px;
  color: #000;
  font-size: 14px;
}

#chk_pageValue {
  border: 1px solid #0c1e47;
  box-sizing: border-box;
  border-radius: 4px;
  color: #000;
  margin-left: 4px;
  cursor: pointer;
  user-select: none;
  float: left;
  font-size: 14px;
  background: #fff;
  height: 30px;
  margin-right: 5px;
}

#chk_pageChange {
  float: left;
  list-style: none;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

#chk_pageChange li {
  border: 1px solid #0c1e47;
  color: #000;
  font-size: 14px;
  width: 54px;
  cursor: pointer;
  height: 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 4px;
  user-select: none;
  margin-right: 5px;
}

#chk_pageChange li[disabled] {
  pointer-events: none;
  opacity: 0.5;
}

#chk_pageChange li.prev,
#chk_pageChange li.next {
  background-color: #0c1e47;
  color: #fff;
}

#gotopage {
  position: absolute;
  width: 90px;
  right: 85px;
  background: #fff;
  border-radius: 4px;
  margin-top: 30px;
  z-index: 9999;
  color: #0c1e47;
  box-shadow: 0 1px 1px 0 rgb(65 69 73 / 30%), 0 1px 3px 1px rgb(65 69 73 / 15%);
}

#gotopage input {
  width: calc(100% - 16px);
  height: 30px;
  border-radius: 4px;
  border: 1px solid #0c1e47;
  margin: 8px;
  padding: 4px;
  box-sizing: border-box;
  text-align: center;
  font-size: 15px;
  color: #0c1e47;
}

.pageNumberList {
  padding: 8px;
  text-align: center;
  max-height: 200px;
  overflow-y: auto;
}

#gotopage span {
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

#gotopage span:hover {
  background-color: #0c1e47;
  color: #fff;
}

.taskPanelHeader {
  width: 100%;
  height: 80px;
  background-color: #d9e2e8;
  border-bottom: 1px solid #d9e2e8;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.taskListTableHead {
  width: 100%;
  padding: 0 15px;
}

.taskListTableBody {
  width: 100%;
  padding: 0 15px;
  /* height: calc(100vh - 156px); */
  height: calc(100vh - 180px);
  position: relative;
  top: -1px;
  overflow: auto;
}

.taskListTableHead ul,
.taskListTableBody ul {
  list-style: none;
  display: flex;
  align-items: center;
  border: 1px solid #e5eef4;
  border-left: none;
  border-right: none;
  border-top: none;
  /* border-bottom: none; */
  align-items: center;
  justify-content: flex-start;
}

.maintenanceFileListTableHead ul {
  list-style: none;
  display: flex;
  align-items: center;
  border: 1px solid #e5eef4;
  border-left: none;
  border-right: none;
  align-items: center;
  justify-content: flex-start;
}

#root.dark .taskListTableHead ul,
#root.dark .taskListTableBody ul {
  border: 1px dashed #3e3e3e;
  border-left: none;
  border-bottom: none;
  border-right: none;
}

#root.dark input[type="email"]:disabled,
input[type="text"]:disabled {
  color: #9b9b9b;
}

#root.dark .checkListPlainText.title:empty:before {
  color: #9b9b9b;
}

.taskListTableHead ul li,
.taskListTableBody ul li {
  border-right: 1px dashed #d8d8d8;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  position: relative;
  cursor: pointer;
}

.taskListTableHead ul li {
  font-size: 14px;
  color: #000000;
  font-weight: 600;
  padding-left: 0;
  padding-right: 15px;
  /* justify-content: center; */
}

.taskListTableBody ul li {
  font-size: 14px;
  color: #000000;
}

/* .taskListTableHead li.active:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #f55;
  top: 18px;
  margin-left: 2px;
} */

.taskListTableHead ul li:last-child,
.taskListTableBody ul li:last-child {
  border-right: none;
  flex-basis: 8%;
}

.taskListTableHead ul {
  height: 42px;
  /* border-top: none; */
}

.taskListTableBody ul {
  border-bottom: none;
  height: 48px;
  position: relative;
}

.taskListTableBody ul:last-child {
  border-bottom: 1px dashed #d8d8d8;
}

._roomName {
  width: 25%;
}

._taskName {
  width: 35%;
}

._taskUpdatedBy {
  width: 20%;
}

._taskUpdatedOn {
  width: 20%;
}

._searchForm {
  width: calc(100% - 26px);
  right: 17px;
  height: auto;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: normal;
  position: absolute;
  z-index: 9;
  background: #fff;
  top: 49px;
  left: 13px;
  padding: 20px;
  box-shadow: 5px 10px 18px #888888;
}

.popheader {
  width: 100%;
  font-weight: normal;
  color: #0c1e47;
  font-size: 24px;
}

.filter_group {
  padding: 8px 0px;
  box-sizing: border-box;
  float: left;
  width: 100%;
}

.filter_group h3 {
  font-size: 16px;
  font-weight: normal;
  color: #0c1e47;
  padding-bottom: 8px;
  box-sizing: border-box;
  float: left;
  width: 100%;
}

h3 .label_text {
  float: left;
  margin-right: 8px;
}

.filter_group input {
  border: none;
  border-bottom: 1px solid #d8d8d8;
  width: 100%;
  float: left;
  padding-bottom: 8px;
  box-sizing: border-box;
  font-size: 16px;
}

.allUser_list {
  float: left;
  width: auto;
  height: auto;
  max-height: 218px;
  list-style: none;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  position: absolute;
  margin-top: 64px;
  min-width: 300px;
  padding: 6px 0px;
  box-sizing: border-box;
  color: #0c1e47;
  overflow-y: auto;
  z-index: 1;
  display: none;
}

.clearDate {
  float: left;
  margin-left: -20px;
  margin-top: 3px;
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.select_startDate.clearDate:after,
.select_endDate.clearDate:after {
  /* content: "\f073"; */
  content: "";
  font-family: "FontAwesome";
  color: #d8d8d8;
  cursor: pointer;
  position: absolute;
  pointer-events: none;
}

.button_ {
  float: right;
  color: #0c1e47;
  background-color: #fff;
  border-radius: 4px;
  width: auto;
  height: auto;
  font-size: 16px;
  padding: 8px 22px;
  box-sizing: border-box;
  margin: 0px 6px;
  border: 1px solid #d8d8d8;
  cursor: pointer;
}

.button_.active {
  background-color: #0c1e47;
  border: 1px solid #0c1e47;
  color: #fff;
}

#select_startDate,
#select_endDate {
  width: 145px;
}

.andText {
  float: left;
  margin: 0 12px;
}

#_inputBar::placeholder,
#select_startDate::placeholder,
#select_endDate::placeholder,
#search_for::placeholder,
#allUser_input::placeholder {
  font-size: 14px;
  color: #bebebe;
}

.pointerNone {
  pointer-events: none;
}

/*Flag Panel*/

.flagPanelHeader {
  width: 100%;
  height: 80px;
  background-color: #d9e2e8;
  border-bottom: 1px solid #d9e2e8;
  padding: 0 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.flagPanel_icon {
  width: 44px;
  height: 44px;
  background-image: url("./media/images/NotFlagged.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 33px;
}

.convSeparetor {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.convSeparetor:after {
  content: "";
  width: 100%;
  border-bottom: solid 1px #9b9b9b;
  position: absolute;
  left: 0;
  top: 50%;
}

.convSeparetor_name {
  background-color: #0c1e47;
  color: #fff;
  height: 76%;
  padding: 0 10px;
  z-index: 1;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  padding: 0 32px 0 22px;
  position: relative;
  cursor: pointer;
}

.convSeparetor_name:after {
  content: "";
  width: 20px;
  height: 20px;
  background: url(./media/images/Down_Chevron_white.svg) 50% 50% no-repeat;
  position: absolute;
  right: 6px;
  transition: all 0.4s ease-out;
}

.msgFlagContainer {
  height: 22px;
  position: relative;
  width: 22px;
  margin-left: 12px;
  display: flex;
  align-items: center;
}

.msgFlagContainer:before {
  content: "";
  position: absolute;
  left: -7px;
  height: 17px;
  width: 2px;
  background: #0c1e47;
  top: 3px;
}

.msgFlagged {
  height: 20px;
  width: 22px;
  background-color: var(--NewRed);
  border-radius: 4px;
  background-image: url(./media/images/Flagged_white.svg);
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 4px 2px;
  cursor: pointer;
}

/* File Panel */

.filePanelHeader {
  width: 100%;
  height: 58px;
  background-color: #fff;
  border-bottom: 1px solid #d9e2e8;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* border-top: 1px solid #d9e2e8; */
}

.filePanelHeadSection {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px;
  position: relative;
}

.filePanelheadPart {
  width: 61%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  position: relative;
}

.filePanelheadPart._right {
  justify-content: flex-end;
  width: 39%;
}

._afileMark {
  width: 2%;
  justify-content: center;
  border-right: none !important;
  border-left: none !important;
  padding: 0 !important;
}

._afileName {
  width: 70%;
  border-right: none !important;
}

._afileDate span,
._afileName span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

._afileSize {
  width: 11%;
  border-right: none !important;
  padding-right: 0 !important;
}

._afileDate {
  width: 20%;
  border-right: none !important;
}

._afileBy {
  width: 20%;
  border-right: none !important;
}

._afileBy span {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

._afileRoom {
  width: 20%;
  border-right: none !important;
}

._afileMark:before {
  display: none;
}

/* .fileRestore {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  cursor: pointer;
  background-image: url(./media/images/undo.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
} */
/* .fileRestore::before {
  content: '\f895';
  font-family: var(--FA);
  font-size: 18px;
  font-weight: 900;
} */
.fileMarker {
  width: 22px;
  height: 22px;
  border: 2px solid #0c1e47;
  border-radius: 50%;
  cursor: pointer;
  /* position: absolute; */
  background-color: #fff;
}

.fileMarker.active {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
  background-image: url(./media/images/circleCheckgreen.svg);
}

.tagMainFilterLi .fileMarker,
.fileBodyRow .fileMarker {
  visibility: hidden;
}

.tagMainFilterLi .fileMarker.active,
.fileBodyRow .fileMarker.active {
  visibility: visible;
}

.tagMainFilterLi:hover .fileMarker,
.fileBodyRow:hover .fileMarker {
  visibility: visible;
}

.fileBodyRow:hover .gFileHoverOpt {
  display: flex;
  top: auto;
}

.assign_info {
  float: right;
  line-height: 30px;
  font-size: 13px;
  color: #0c1e47;
  font-family: var(--F1);
  font-style: italic;
  margin-right: 33px;
  margin-left: 30px;
  width: auto;
  word-break: break-word;
  text-align: right;
  transition: all 200ms;
  display: none;
}

.itemContainer.filterAuditTrail .checklistItem .assign_info {
  display: block;
}

/* [contenteditable] {padding:12px;} */

.createItem .itemTitle[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #888;
  font-style: italic;
  font-size: 12px;
}

.userDateText,
.userEmailText {
  width: 210px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.filterBy_popup {
  position: absolute;
  right: 0px;
  top: -2px;
  width: 195px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(10 31 68 / 8%), 0 8px 10px 0 rgb(10 31 68 / 10%);
  z-index: 1;
  padding-top: 35px;
}

.filterBy_popup:before {
  content: "Filter Checklist by";
  height: 35px;
  width: 100%;
  background-color: #0c1e47;
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #fff;
  border-bottom: 1px solid #d8d8d8;
  padding: 0 16px;
  box-sizing: border-box;
}

.item_more_items {
  padding: 8px 16px;
  font-size: 14px;
  font-family: var(--F1);
  color: #0c1e47 !important;
  cursor: pointer;
}

.item_more_items:hover {
  color: #ffffff !important;
  background-color: #0c1e47;
}

.item_more_items.assigneeF.active:after,
.item_more_items.filter_me.active:after,
.item_more_items.filter_other.active:after {
  content: "\f00c";
  font-family: "FontAwesome";
  font-size: 12px;
  position: absolute;
  right: 9px;
  top: 10px;
}

.item_more_items.assigneeF.active:after {
  top: 11px;
}

.uploaded_file {
  height: auto;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  background-color: #ffffff;
  padding: 10px 13px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  margin-bottom: 4px;
}

.uploaded_file[data-status="1"] {
  background-image: url(./media/images/check-circle_pro.svg);
  background-position: calc(100% - 10px) 12px;
  background-size: 18px;
  background-repeat: no-repeat;
}

.uplogo {
  height: auto;
  width: 50px;
  display: flex;
  padding: 5px;
  box-sizing: border-box;
  margin-right: 10px;
  float: left;
}

.uplogo img {
  max-width: 100%;
}

.upFileDetail {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  width: calc(100% - 60px);
  float: left;
}

span.upFileName {
  width: calc(100% - 45px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 25px;
}

span.upFileName[contenteditable="true"] {
  text-overflow: initial;
  border: 1px solid rgba(0, 0, 0, 0.12);
  padding: 2px 5px;
  border-radius: 2px;
}

span.upFileName[contenteditable="true"] .ext {
  display: none;
}

span.upFileSize {
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
}

.first_task_area .vsCalenderIcon svg {
  width: 16px !important;
  height: 16px !important;
}

/* .first_task_area .vsCalenderIcon{
  top: 24px;
  right: 16px;
} */
.editFile {
  /* background-image: url("./media/images/png/edit-icon.png"); */
  background-image: url("./media/images/task_img/editIcon.webp");
  /* width: 18px;
  height: 18px; */
  width: 20px;
  height: 15px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  /* border: 2px solid #FFF; */
  border-radius: 100%;
  background-position-x: 50%;
  background-position-y: 50%;
  /* background-size: 16px; */
  background-size: 11px;
  position: absolute;
  right: 35px;
  top: 12px;
  cursor: pointer;
  display: none;
}

.editFileFileUploadNew {
  background-image: url("./media/images/task_img/editIcon.webp");
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  border-radius: 100%;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 12px;
  /* position: absolute;
  right: 50px;
  top: 10px; */
  cursor: pointer;
  display: none;
}

.fileUploadListCon:hover .editFileFileUploadNew {
  display: block;
}

.editFileFileUpload {
  background-image: url("./media/images/task_img/editIcon.webp");
  width: 20px;
  height: 15px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  border-radius: 100%;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 11px;
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  display: block;
}

.fileUploadListCon:hover .editFileFileUpload {
  display: block;
}

.editFileTaskCheck {
  background-image: url("./media/images/task_img/editIcon.webp");
  width: 20px;
  height: 16px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  /* border: 2px solid #FFF; */
  border-radius: 100%;
  background-position-x: 50%;
  background-position-y: 50%;
  /* background-size: 16px; */
  background-size: 12px;
  position: absolute;
  right: 35px;
  top: 9px;
  cursor: pointer;
}

span.upFileName[contenteditable="true"]+.editFile {
  display: none;
}

.fileUpAction {
  display: none;
}

span.upFileName[contenteditable="true"]+.editFile+.fileUpAction {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 58px;
  top: 44px;
}

span.savefEdit,
span.cancelfEdit {
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 3px;
  padding: 0 8px;
  font-size: 15px;
  cursor: pointer;
  margin-left: 8px;
}

#root.dark span.savefEdit,
#root.dark span.cancelfEdit,
span.savefEdit:hover,
span.cancelfEdit:hover {
  background-color: #0b1f47;
  color: #fff;
}

.removeFile {
  background-image: url("./media/images/Remove.svg");
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  border: 2px solid red;
  border-radius: 100%;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 10px;
  position: absolute;
  right: 10px;
  top: 12px;
  cursor: pointer;
  display: none;
}

.removeFileTaskCheck {
  background-image: url("./media/images/Remove.svg");
  width: 16px;
  height: 16px;
  box-sizing: border-box;
  background-repeat: no-repeat;
  border: 2px solid red;
  border-radius: 100%;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 10px;
  position: absolute;
  right: 6px;
  top: 8px;
  cursor: pointer;
}

.checkList_area .checklistArea .labelText {
  overflow-wrap: break-word;
  width: 100%;
}

.uploaded_file:hover {
  background-image: none;
}

.uploaded_file[data-status="0"] .removeFile,
.uploaded_file:hover .editFile,
.uploaded_file:hover .removeFile {
  display: block;
}

.create_box[data-placeholder]:empty:before {
  content: attr(data-placeholder);
  color: #d8d8d8;
  font-size: 15px;
  font-weight: normal;
}

.selectedParticipants {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* min-height: 60px; */
  /* padding: 8px; */
  margin-bottom: 8px;
  margin-top: 8px;
  border-radius: 4px;
  flex-wrap: wrap;
  float: left;
  overflow-y: auto;
  max-height: 75px;
}

p.participantEmail {
  color: #d0d0d0;
  font-family: var(--F1);
  font-size: 16px;
  font-weight: normal;
  line-height: 21px;
  pointer-events: none;
  position: absolute;
  right: 12px;
}

.miniItem {
  width: auto;
  float: left;
  height: 34px;
  box-sizing: border-box;
  background: #003ec7;
  border-radius: 20px;
  padding: 4px 4px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 8px;
}

.miniItem_name {
  color: #ffffff;
  font-size: 14px;
  white-space: nowrap;
}

.miniItem_image {
  width: 28px;
  height: 28px;
  border-radius: 100%;
  margin-right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image i {
  color: #ffffff;
}

.miniItem_image img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.miniItem_image.call::before {
  content: "\f879";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.tag::before {
  content: "\f02b";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.link::before {
  content: "\f0c1";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.star::before {
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  content: "";
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 18px;
  background-image: url(./media/images/star-fill_w.svg);
}

.miniItem_image.media::before {
  content: "\f0c6";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.voice::before {
  content: "\f130";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.notification_ico::before {
  content: "\f0f3";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.flag::before {
  content: "";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./media/images/png/flagPanel_active.png");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 15px;
}

.miniItem_image.all_reply::before {
  content: "\f1da";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.unread::before {
  content: "\f086";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.private::before {
  content: "\f023";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_image.search::before {
  content: "\f002";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_remove {
  width: 28px;
  height: 28px;
  background-color: #0b1f47;
  border-radius: 100%;
  margin-left: 6px;
  cursor: pointer;
  box-sizing: border-box;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.miniItem_remove:hover {
  background-color: #318FFF;
}

.miniItem_remove::before {
  content: "\f00d";
  font-family: var(--FA);
  font-size: 15px;
  font-weight: bold;
  color: #fff;
}

.mentionUser {
  margin-right: 5px;
  margin-left: 5px;
  color: #2196f3;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
}

.mentionUser:after {
  content: attr(data-mention);
}

.progressContainer {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.progressStatus {
  width: 50px;
  text-align: end;
  font-size: 15px;
  color: #0c1e47;
}

.progressBar {
  width: calc(100% - 65px);
  height: 16px;
  border-radius: 15px;
  /* box-shadow: 0 2px 3px #0C1E4747; */
  border: 1px solid rgba(0, 0, 0, 0.12);
}

.progressColor {
  background-color: #0c1e47;
  /* width: 50%; */
  height: 100%;
  float: left;
  border-radius: 15px;
  max-width: 100%;
  color: white;
  font-size: 11px;
  text-align: center;
}

.uploaded_file[data-status="1"] .progressContainer {
  display: none;
}

.refConInput input::placeholder {
  font-size: 12px !important;
}

/* 
.privateMsg_btn {
  width: auto;
  height: 36px;
  position: absolute;
  left: 71px;
  bottom: 33px;
  cursor: pointer;
  border-radius: 18px;
  background-color: #9a9a9a;
  padding: 0 20px 0 43px;
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  background-image: url("./media/images/png/lock-icon.png");
  background-repeat: no-repeat;
  background-position: 14px 50%;
  background-size: 24px;
} */
.privateMsg_btn {
  width: auto;
  height: 29px;
  position: absolute;
  z-index: 1;
  left: 58px;
  bottom: 37px;
  cursor: pointer;
  border-radius: 18px;
  background-color: #9a9a9a;

  padding: 0 10px 1px 28px;
  font-size: 12px;
  color: #fff;
  display: flex;
  align-items: center;
  /* background-image: url("./media/images/black_theme/Private Room_White.svg"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_private room.svg");
  background-repeat: no-repeat;
  background-position: 10px 49%;
  background-size: 10px;
}

.privateMsg_btn.active {
  background-color: #0D2150;
}

.uploadbtn.active {
  background-color: #0b1f47;
}

.uploadbtn.inactive {
  pointer-events: none;
}

.mentionUserContainer {
  width: calc(100% - 30px);
  min-height: 50px;
  background: #fff;
  position: relative;
  bottom: 6px;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
  float: right;
  border: 1px solid #0c1e47;
  max-height: 300px;
  overflow: auto;
}

.mentionUserContainer .mentionUserLi {
  float: left;
  width: calc(100% - 28px);
  padding: 6px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  color: #0c1e47;
  display: flex;
  align-items: center;
}

.mentionUserContainer .mentionUserLi:hover {
  background: #d9e2e8;
  color: #0c1e47;
}

.mentionUserLi .mentionUserImg {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  background-color: antiquewhite;
  margin-right: 10px;
}

.mentionUserImg.everyone {
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: normal;
  color: var(--NewblueC);
  background-color: #fff;
}

.closeMention {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-image: url(./media/images/cancel_round_blue.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 90%;
  cursor: pointer;
  position: absolute;
  right: 7px;
}

.closeMention:hover {
  background-image: url(./media/images/cancel_round_red.svg);
}

.uploading_img {
  float: left;
  overflow: hidden;
  border-radius: 4px;
  max-height: 40px;
  object-fit: cover;
}

.silent_delete {
  color: #f00;
  cursor: pointer;
  font-size: 14px;
  font-style: normal;
}

.msgEditBox {
  overflow-y: auto;
  max-height: 250px;
  font-size: 20px;
  resize: none;
  border: none;
}

.create_box[placeholder]:empty:before {
  content: attr(placeholder);
  color: rgba(0, 0, 0, 0.24);
  font-family: var(--F1);
  cursor: text;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 28px;
}

.edit_history_msg {
  padding: 6px 4px;
  float: left;
  width: 100%;
  border-radius: 1px;
  color: #0b1f47;
  margin-bottom: 2px;
  background-color: #d2dfff;
  border-left: 6px solid #0b1f47;
  word-break: break-word;
}

.show_edited_msg.false .edit_history_msg {
  display: none;
}

.show_edited_msg {
  display: grid;
  width: 100%;
}

.show_more_reply {
  color: #028bff;
  cursor: pointer;
}

.show_edited_msg.true .show_more_reply {
  margin-bottom: 5px;
}

.flaggedStatus {
  margin-left: 10px;
  border-radius: 4px;
  font-size: 16px;
  line-height: 23px;
  padding: 0 5px;
  color: #fff;
  background-color: #0c1e47;
  cursor: pointer;
  display: none;
}

.user_msg:hover .flaggedStatus {
  display: inline-block;
}

.msgFlagReplyOption {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* margin-top: 6px; */
  margin-top: 2px;
}

.msgFlagReplyOption div {
  visibility: hidden;
  height: 22px;
  width: auto;
  border-radius: 13px;
  background-color: #0b1f47;
  padding: 0px 12px;
  margin-right: 6px;
  font-size: 12px;
  padding-bottom: 0px;
  font-weight: normal;
  letter-spacing: 0;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* transition: all 150ms ease-in-out; */
  user-select: none;
}

.msgFlagReplyOption div.flaged,
/* .user_msg:hover .msgFlagReplyOption div, */
.single_user_msg:hover .msgFlagReplyOption div {
  visibility: visible;
}

.msgFlagBtn.flaged {
  /* background-image: url("./media/images/Flagged_white.svg"); */
  /* background-image: url("./media/images/png/flagPanel_active.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_flag.svg");
  background-repeat: no-repeat;
  background-position: 13px 50%;
  background-size: 11px;
  padding-left: 30px;
  background-color: #003ec7;
}

.timerContainer {
  margin-left: 10px;
  color: #fff;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 23px;
  background-color: #f55;
  padding: 0 5px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.removeTimer {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 100%;
  margin-left: 5px;
  cursor: pointer;
  background-image: url("./media/images/Remove.svg");
  background-size: 12px;
  background-repeat: no-repeat;
  background-position: 50%;
  padding: 0px 2px;
  box-sizing: border-box;
  line-height: 20px;
}

/* .fileTagHolder {
  width: 100%;
  background-color: transparent;
  position: relative;
  bottom: 1px;
  left: -5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  top: 6px;
} */
.fileTagHolder {
  width: 100%;
  background-color: transparent;
  position: relative;
  /* bottom: 1px; */
  /* left: -5px; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  /* top: 6px; */

  padding: 10px 10px;
}

.fileTagHolder:empty {
  display: none;
}

span.fileTag {
  background-color: #141414;
  color: #fff;
  border-radius: 4px;
  /* padding: 0 5px 0 22px; */
  /* background-image: url("./media/images/tag_fff.svg"); */
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 5px 50%;
  margin-left: 5px;
  cursor: pointer;
  max-width: 100%;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-bottom: 2px;
  padding: 5px 10px;
}

.tagTitle.active {
  background-image: url(./media/images/Check-Circle.svg);
  background-repeat: no-repeat;
  background-size: 18px;
  background-position: calc(100% - 100%);
}

p.clearMsgOpts {
  float: left;
  width: 100%;
  height: auto;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  color: #0c1e47;
  display: flex;
  align-items: center;
}

span.checkClrOpt {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  border: 2px solid #0c1e47;
  border-radius: 50%;
}

span.checkClrOpt.checked {
  background-image: url(./media/images/hayven_checked_white.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #0c1e47;
  background-size: 70%;
}

span.clrOptLabel {
  float: left;
  margin-left: 8px;
  font-size: 18px;
  color: #0c1e47;
}

h2.unique_code {
  color: #0c1e47;
  text-align: center;
  letter-spacing: 14px;
  font-size: 30px;
  margin-bottom: 10px;
}

.unique_codeHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.unique_codeHolder input {
  height: 40px;
  border: 1px solid #0c1e47;
  border-radius: 4px;
  font-size: 30px;
  color: #0c1e47;
  letter-spacing: 14px;
  width: 100%;
  font-weight: normal;
  text-align: center;
}

.notFound_container {
  width: 100%;
  height: 100%;
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0c1e47;
  position: relative;
}

.notFound_container h2 {
  font-weight: normal;
  font-size: 16px;
}

.notFound_container.empty_msg {
  flex-direction: column;
  padding: 0 140px;
}

.notFound_container.empty_msg p {
  position: absolute;
  width: auto;
  top: 30px;
  text-align: center;
  padding: 13px 25px;
  font-size: 14px;
  color: #0c1e47;
  border-radius: 4px;
  background-color: #efeced;
}

.notFound_container.empty_msg p i {
  font-size: 11px;
  margin-right: 5px;
}

/* .notFound_container.empty_msg h2{
  
} */
.privateMsgContainer {
  width: auto;
  display: inline-block;
  border-radius: 30px;
  /* background-color: #5c5c5c; */
  background-color: #F1F6FA;
  color: #023d67;
  padding: 12px 25px 12px 56px;
  /* background-image: url(./media/images/png/lock-icon.png); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_private room.svg");
  background-repeat: no-repeat;
  background-position: 22px 50%;
  margin-top: 10px;
  cursor: pointer;
  align-items: center;
  background-size: 18px;
  font-size: 14px;
}

.privateMsgContainer:hover {
  /* background-color: #c7c2c2; */
  /*background-color: #766d6f; */
  background-color: #DDE6FF;
}

.privateMsgV {
  position: relative;
  margin-left: 7px;
  padding-left: 7px;
}

.privateMsgV::before {
  content: "";
  width: 1px;
  height: 15px;
  position: absolute;
  background-color: #023d67;
  left: 0;
  top: 3px;
  font-weight: normal;
}

.privateUserContainer {
  width: 100%;
  position: relative;
  top: -5px;
  display: flex;
  flex-wrap: wrap;
}

.privateUserContainer .privateUser {
  margin: 0 3px 0 3px;
  border-radius: 4px;
  padding: 3px 4px;
  background: #0c1e47;
  color: white;
  display: flex;
  align-items: center;
}

.privateUserContainer .privateLabel {
  margin: 0 3px 0 0px;
  border-radius: 4px;
  padding: 3px 4px;
  color: #0c1e47;
  display: flex;
  align-items: center;
}

.privateUserContainer .privateMsgBackLabel {
  padding: 0 10px;
  background: #0b1f47;
  cursor: pointer;
}

.privateUserContainer .miniItem {
  margin: 3px 0px 3px 10px;
}

.privateUserContainer span.removeUser {
  margin-left: 5px;
  cursor: pointer;
}

.privateUserContainer .removeUser::after {
  content: "\f057";
  font-family: "FontAwesome";
  font-size: 14px;
  color: #fff;
}

.group_containerThread {
  width: 100%;
  /* height: auto; */
  /* height: calc(100% - 328px); */
  height: calc(100% - 496px);
  padding-top: 10px;
  /* overflow-y: auto; */
  background-color: #0d2150;
}

.bactToAll {
  height: 35px;
  padding-right: 0;
  /* border-radius: 40px; */
  /*background-color: rgb(115 43 226 / 27%);*/
  /* background-color: #29287F; */
  /* width: 339px; */
  width: 100%;
  margin-left: 3px;
  display: flex;
  align-items: center;
  padding: 7px 50px;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  background-image: url('./media/images/backIcom.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  /* background-position: 21px 42%; */
  background-position: 300px 45%;
  cursor: pointer;
  position: relative;
  top: -11px;
  line-height: 2px;
}

.bactToAllArchive {
  height: 35px;
  padding-right: 0;
  width: 100%;
  margin-left: 3px;
  display: flex;
  align-items: center;
  padding: 7px 50px;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  background-image: url('./media/images/backIcom.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 310px 45%;
  cursor: pointer;
  position: relative;
  top: -11px;
  line-height: 2px;
}

.bactToAllArchiveBottom {
  width: 25px;
  margin-left: 15px;
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  background-image: url('./media/images/backIcom.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  cursor: pointer;
  position: relative;
  top: 2px;
}

.bactToAllArchive:hover {
  background-color: #0C2854;
}

.bactToAll:hover {
  background-color: #0C2854;
}

.threadConvCont {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
}

.unreadCounter.only_private,
.unreadCounter.only_flagged {
  display: none;

}

.moreOpt_list.active i {
  float: right;
  font-size: 14px;
  padding: 3px;
}

input.generateUrlInput {
  width: 100%;
  height: 40px;
  margin-top: 8px;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  padding: 5px 10px;
  font-size: 16px;
}

.floatingActionContainer {
  position: fixed;
  top: calc(50% - 75px);
  right: 0;
  z-index: 99;
  background-color: #0d2150;
  border-radius: 8px 0 0 8px;
  padding: 14px 10px 14px 36px;
  transition: all 0.3s ease;
}

.floatingActionContainer.rightSwap {
  right: -100px
}

.floatingActions {
  height: 40px;
  color: #fff;
  padding-left: 40px;
  cursor: pointer;
  font-size: 18px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.floatingActions::before {
  font-size: 16px;
  font-weight: 600;
  position: absolute;
  width: 30px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 50%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
}

._actionForward::before {
  /* content: "\f064"; */
  content: "";
  font-family: var(--FA);
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_forward.svg");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

._actionDownload::before {
  content: "\f381";
  font-family: var(--FA);
}

._actionClose::before {
  /* content: "\f00d"; */
  content: "";
  font-family: var(--FA);
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_exit outline.svg");
  background-size: 32px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

span.floatingSwapBtn {
  width: 35px;
  left: 0;
  height: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  cursor: pointer;
}

span.floatingSwapBtn::before {
  content: "";
  background-image: url(./media/images/leftArrow.svg);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: 50%;
  transform: rotate(180deg);
  transition: all 0.3s ease;
}

.rightSwap span.floatingSwapBtn::before {
  transform: rotate(0deg);
}

.playerContainer {
  box-sizing: border-box;
  width: 700px;
  background-color: #fafafa;
  border-radius: 4px;
  height: auto;
  position: relative;
}

.playerHeader {
  width: 100%;
  height: 55px;
  border-bottom: 1px solid #d8d8d8;
  border-radius: 4px 4px 0 0;
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #f3f3f3;
}

.playerBody {
  width: 100%;
  min-height: 390px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fileNotFound {
  position: absolute;
  background-color: #fff;
  width: 100%;
  height: calc(100% - 80px);
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d2150;
}

.fileNotFound h2 {
  font-weight: normal;
}

.emoji-mart {
  border: none !important;
}

.notify_boundary {
  height: calc(100vh - 80px);
  width: 100%;
  overflow-y: auto;
}

.custom_clock_size {
  font-size: 12px;
}

.internet_action {
  width: 100%;
  height: 100vh;
  background-color: #0d2150c2;
  position: fixed;
  pointer-events: none;
  z-index: 999999999;
  cursor: not-allowed;
  display: flex;
  float: left;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.typingIndicator {
  position: absolute;
  top: 2px;
  /* top:20px; */
  /* left:30px; */
  left: 48px;
  font-size: 14px;
  color: #8a8886;
  transition: all 0.3s ease;
}

.TranslatorIcon {
  display: inline;
  position: relative;
}

.translatorButton {
  background: var(--PrimaryC);
  ;
  padding: 5px 40px;
  font-size: 20px;
  border: none;
  justify-content: space-between;
  border-radius: 20px;
  margin: 20px;
  color: white;
}

.userMiniModal select {

  padding: 5px;
  margin: auto 10px;
  border-radius: 10px;

}

.type_indicate {
  background-image: url(./media/images/loginBefore/light.gif);
  background-position: 100% 50%;
  width: auto;
  background-repeat: no-repeat;
  background-size: 38px;
  padding-right: 45px;
  padding-left: 4px;
}

p.file_name {
  width: 100%;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}

span.conv_list_title {
  /* width: calc(100% - 50px); */
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 300;
}

.user_msg[data-highlight="true"] {
  background: #b1bf4040;
}

.tagFilter {
  background-image: url(./media/images/png/filter_icon.png);
  background-size: 26px;
  width: 30px;
  height: 26px;
  margin: 0 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
}

.moreMenuPopup.tagFilterPopup {
  box-sizing: border-box;
  max-height: 300px;
  width: 256px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 10px 0 rgb(10 31 68 / 8%), 0 8px 10px 0 rgb(10 31 68 / 10%);
  position: absolute;
  right: 0;
  top: 47px;
  margin-top: 12px !important;
  padding: 8px 0px;
  float: left;
  z-index: 6;
  overflow-y: auto;
}

.moreMenuPopup li._tagList:before {
  content: "";
  background-image: url(./media/images/tag_blue.svg);
  width: 16px;
  background-size: 15px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
}

.gfileTagContainer {
  width: 100%;
  margin-top: 5px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  float: left;
}

.gfileTagContainer:empty {
  display: none;
}

.gfileTagContainer span.fileTag {
  margin-left: 0;
  margin-right: 5px;
  margin-top: 5px;
}

.isDisable {
  /* opacity: 0.5; */
  background-color: #d8d8d8 !important;
  pointer-events: none;
  border: 1px solid #d6d6d6 !important;
  pointer-events: none;
  color: #a999a1 !important;
}

.NotDisable {
  background-color: unset !important;
  pointer-events: none;
  border: 1px solid unset !important;
  pointer-events: none;
  color: unset !important;
}

button.btnCancel.clear_btn.danger {
  background-color: var(--NewRed) !important;
  color: #fff !important;
  border: none !important;
  margin-left: 15px;
}

.leftSearchCon {
  width: 100%;
  height: 40px;
  display: flex;
  padding: 0px 0;
  position: relative;
  align-items: center;
  padding-bottom: 9px;
}

.leftSearchCon .createIcon {
  position: absolute;
  right: -52px;
  top: 13px;
  width: 24px;
  height: 24px;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  background-color: #94b2ff;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.leftSearchCon .createIcon::before {
  content: "\f500";
  font-family: "FontAwesome";
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  color: #025fb3;
}

input.leftSearchInput {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  /* border: 1px solid #d9d9d9; */
  /* border: 1px solid rgba(227, 242, 255, 0.26); */
  border: unset;
  /* background-color: transparent; */
  background-color: #031333;
  font-size: 14px;
  color: #d9d9d9;
  padding: 5px 65px 5px 12px;
  margin: 0 17px;
}

#root.dark input.leftSearchInput {
  background-color: rgba(227, 242, 255, 0.08);
}

.leftSearchCloseBtn {
  /* display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 25px;
  top: 15px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100%;
  height: 20px;
  width: 20px; */
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -12px;
  top: 9px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

.leftSearchCloseBtn::before {
  content: "\f057";
  font-family: var(--FA);
  font-weight: 600;
  color: var(--NewRed);
  font-size: 22px;
}

.leftSearchActionBtn {
  /* width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 18px;
  color: #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border: none; */
  width: 35px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -18px;
  color: #d9d9d9;
  border-left: 1px solid #d9d9d9;
  border: none;
  top: 3px;
}

.leftSearchActionBtn::before {
  content: " ";
  background-image: url('./media/images/searchIcon.webp');
  background-size: contain;
  width: 20px;
  height: 24px;
  background-repeat: no-repeat;


}

input.leftSearchInput:not(:placeholder-shown)+.leftSearchCloseBtn {
  display: flex;
}

input.leftSearchInput::placeholder {
  font-family: Poppins;
  font-size: 12px !important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #bfdcff !important;
}

.taskCreatorPopup {
  box-sizing: border-box;
  width: 630px;
  background-color: #f8f8f8;
  border-radius: 4px;
  height: auto;
  position: relative;
}

.taskCreatorHead {
  width: 100%;
  height: 55px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #d8d8d8;
  box-sizing: border-box;
}

.taskCreatorBody {
  width: 100%;
  padding: 10px 30px;
  box-sizing: border-box;
  max-height: calc(100vh - 200px);
  overflow-y: auto;
}

.taskCreatorBody .assignSection {
  margin-bottom: 15px;
}

.taskCreatorBody .createItemContainer {
  margin-bottom: 20px;
}

.taskCreatorBody .checkListPlainText {
  font-size: 26px;
}

#root.dark .taskCreatorBody .checkListPlainText:after {
  /* background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_tasks1.svg"); */
}

.taskCreatorBody .checkListPlainText:after {
  content: "";
  position: absolute;
  top: 10px;
  left: 2px;
  /* background-image: url(./media/images/png/task-list-icon.png); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_tasks1.webp");
  background-size: 24px;
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
}

.taskCreatorFoot {
  width: 100%;
  height: 75px;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
}

.taskCreatorFoot button {
  width: auto;
  height: 42px;
  border-radius: 5px;
  border: 1px solid #0c1e47;
  font-weight: normal;
  padding: 0 20px;
  font-size: 18px;
  cursor: pointer;
}

.dueDateIcon.img {
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 100%;
  background-image: none;
}

.dueDateIcon.img img {
  width: 100%;
  height: 100%;
}

.checkBox.active {
  background-image: url(./media/images/hayven_checked_white.svg);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 70%;
  background-color: var(--NewblueC);
  border: 2px solid var(--NewblueC);
}

.NotifyPopup {
  width: 310px;
  height: auto;
  min-height: 100px;
  max-height: 420px;
  position: fixed;
  box-sizing: border-box;
  padding: 15px;
  border-radius: 4px;
  left: 37px;
  top: 145px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(10 31 68 / 10%), 0 18px 18px 0 rgb(10 31 68 / 12%);
  cursor: context-menu;
  z-index: 9;
  overflow-y: auto;
}

.notiUser {
  width: 38px;
  height: 38px;
  overflow: hidden;
  border-radius: 50%;
}

.notiUser img {
  width: 100%;
  height: 100%;
}

.notificationItem {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 5px;
  border-bottom: 1px solid #e9ecef;
  cursor: pointer;
}

.notificationItem:last-child {
  border-bottom: none;
}

.notiUserInfo {
  width: calc(100% - 48px);
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

p.notiUserName {
  font-size: 15px;
  font-weight: normal;
  color: #0c1e47;
  width: calc(100% - 50px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

p.notiUserMsg {
  font-size: 14px;
  color: #0c1e47;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 4px 0;
}

small.text-muted {
  font-size: 12px;
  color: #0c1e47;
}

small.float-right {
  position: absolute;
  right: 0;
  font-size: 12px;
  top: 2px;
  color: #0c1e47;
}

.viewAllNoti {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  text-align: center;
  color: #0c1e47;
  cursor: pointer;
}

.notiNotFound {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  color: #0c1e47;
  cursor: pointer;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notificationItem.unread_noti,
.user_msg.unread_noti {
  background-color: #e4ff0226;
  display: block;
  width: 100%;
}

p.titleBar {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: #2196f3;
  font-weight: bold;
  font-size: 14px;
}

span.msgLinkTitle {
  font-size: 16px;
  color: #2196f3;
  float: left;
  margin-right: 5px;
  font-weight: bold;
}

span.editTpen {
  width: auto;
  height: auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  margin-right: 4px;
  display: none;
  margin-top: -5px;
}

.editTpenLink {
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_edit.svg");
  background-size: 13px;
  width: 13px;
  height: 13px;
  cursor: pointer;
  margin-left: 5px;
  display: none;
  margin-top: 2px;
  content: "\f303";
  font-size: 14px;
  font-family: var(--FA);
  color: #2196f3;
  font-weight: bold;
}

.LinkBar:hover .editTpenLink {
  display: block;
}

.titleBar:hover .editTpen {
  display: block;
}

span.editTpen::before {
  content: "\f303";
  font-size: 14px;
  font-family: var(--FA);
  color: #2196f3;
  font-weight: bold;
}

span.editTpen:hover:before {
  color: #e91e63;
}

.customTitleModal {
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
}

.customTitleHead {
  width: 100%;
  height: auto;
  padding: 16px 25px 11px;
  display: flex;
  align-items: flex-start;
  box-sizing: border-box;
  flex-direction: column;
  padding-top: 16px;
}

.customTitleHead .popupTitle {
  margin-bottom: 5px;
}

.customTitleHead p {
  font-size: 15px;
}

#root.dark .customTitleHead p {
  color: #ffffff;
}

#root.dark .btnCancel {
  color: #ffffff;
  border: 1px solid #fff;
}

.customTitleBody {
  width: 100%;
  padding: 10px 0px 0px;
  box-sizing: border-box;
  display: flow-root;
}

.voiceTitleBody {
  width: 100%;
  padding: 0px 0px 10px 0px;
  box-sizing: border-box;
  display: flow-root;
}

.refTitleInput {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  height: 40px;
  padding: 8px 16px !important;
  font-size: 16px;
}

.fileRefBody,
.fileRefType {
  width: 100%;
  padding: 0px 0px 10px 0px;
  box-sizing: border-box;
  display: flow-root;
}

.fileRefHolder {
  width: 100%;
  background-color: transparent;
  /* position: relative; */
  /* bottom: 1px; */
  /* left: -5px; */
  /* display: flex; */
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap: wrap;
  /* top: 6px; */
}

span.fileRef {
  background-color: #141414;
  color: #fff;
  border-radius: 4px;
  padding: 0 5px 0 5px;
  /* background-image: url("/images/tag_fff.svg"); */
  /* background-repeat: no-repeat; */
  /* background-size: 12px; */
  /* background-position: 5px 50%; */
  margin-left: 5px;
  cursor: pointer;
  max-width: 100%;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  margin-bottom: 2px;

}

.customTitleInput {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  height: 40px;
  padding: 5px 10px;
  font-size: 16px;
}

.customTitleFoot {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 6px 0px 0px;
}

.cancelTitle {
  box-sizing: border-box;
  height: 36px;
  width: auto;
  padding: 0 10px;
  border: 1px solid #0c1e47;
  color: #0c1e47;
  border-radius: 4px;
  font-family: var(--F1);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.cancelTitle:hover {
  border: 1px solid var(--NewRed);
}

.saveTitle {
  /* height: 36px;
  width: auto;
  border-radius: 4px;
  background-color: #0d2150;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  float: right;
  padding: 0 15px;
  box-sizing: border-box; */
  background-color: #0d2150;
  font-family: var(--F1);
  margin: 0px 0px 0px 15px;
  box-sizing: border-box;
  height: 42px;
  width: auto;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  margin-top: 0px;
  float: right;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  padding: 0 20px;
}

.comingSoonPopup {
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
}

.guestSoonPopup {
  width: 500px;
  height: auto;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  position: relative;
  text-align: center;
}

.comingText {
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
  color: #002e98;
  font-weight: normal;
}

.comingText_call {
  padding: 20px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #002e98;
  font-weight: normal;
}

.comingBtnGrp {
  width: 100%;
  padding: 0 35px 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.comingBtnGrp_call {
  width: 100%;
  padding: 0 30px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

button.comingBtn {
  padding: 8px 40px;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  background-color: #0b1f47;
  color: #fff;
  font-size: 18px;
  font-weight: normal;
}

button.comingBtn:hover {
  background-color: #0d2150;
}

.leftFeb {
  box-sizing: border-box;
  width: 50%;
  height: 100%;
  padding: 25px 0px 0px 60px;
  /* background-color: #002e98; */
  background-color: #0c1e47;
  /* background-image: url(/imgpsh_fullsize_anim.png);
  background-position: 100% 135%;
  background-size: 70% 60%;
  background-repeat: no-repeat; */
}

.FebHeader {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.FebHeader img {
  width: 190px;
  height: auto;
  position: relative;
  left: -8px;
}

/* .FebHeader span {
  color: #FCE153;
  font-size: 24px;
  margin-left: 8px;
  font-weight: normal;
  letter-spacing: 1.5px;
} */
.FebBody {
  padding-top: 40px;
  /* padding-bottom: 50px; */
  height: calc(100% - 33px);
  background-image: url(./media/images/loginBefore/imgpsh_fullsize_anim.webp);
  background-position: 100% 135%;
  background-size: 70% 63%;
  background-repeat: no-repeat;
}

.FebBody.encryption {
  /* padding-top: 0; */
  /* padding-bottom: 50px; */
  /* height: calc(100% - 22px); */
  background-image: url(./media/images/loginBefore/Encryption.png);
  background-position: bottom center;
  background-size: 95%;
  background-repeat: no-repeat;
}

.FebBody.collaboration {
  /* padding-top: 0;
  padding-bottom: 50px;
  height: calc(100% - 22px); */
  background-image: url("./media/images/loginBefore/collaboration.png");
  background-position: bottom center;
  background-size: 100%;
  background-repeat: no-repeat;
}

input.short_id_input,
input.team-name.short_id_input {
  padding-right: 50px;
}

svg.arrow_go_icon.clear {
  margin-left: 12px;
}

@media screen and (min-width: 1440px) {
  .FebBody {
    background-position: 101% 135%;
    background-size: 80% 58%;
  }

  .createConv_body {
    padding: 20px 50px !important;
  }

  input.team-name.short_id_input {
    padding-right: 50px;
  }

  input.short_id_input {
    padding-right: 50px;
  }

  .createConv_back {
    left: 50px;
  }
}

p.FebBodyText1 {
  font-size: 40px;
  font-weight: normal;
  line-height: 44px;
  /* color: #65b9ff; */
  color: #ffffff;
  letter-spacing: 1.5px;
  margin-bottom: 38px;
  font-family: Rubik, sans-serif;
  text-align: left;
  padding-right: 30px;
}

p.FebBodyText2 {
  line-height: 32px;
  /* color: #65b9ff; */
  color: #ffffff;
  letter-spacing: 1px;
  margin-bottom: 38px;
  font-family: Rubik, sans-serif;
  font-weight: 300;
  font-size: 1.3em;
  text-align: left;
  padding-right: 30px;
}

.formHeader {
  color: #1f1f1f;
  font-size: 26px;
  font-weight: bolder;
  letter-spacing: 1px;
  margin-bottom: 40px;
  text-align: center;
}

.policyCheck {
  width: 20px;
  height: 20px;
  border: 1px solid #003ec7;
  background-color: #fff;
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
  position: relative;
}

#root.dark .policyCheck {
  border: 1px solid #003ec7;
}

.policyCheck.active {
  background-color: #003ec7;
  /* background-color: #0c1e47; */
}

#root.dark .policyCheck.active {
  /* background-color: #003ec7; */
  background-color: #003ec7;
}

.policyCheck.active::before {
  content: "\f00c";
  font-family: var(--FA);
  font-weight: bold;
  color: #fff;
  position: absolute;
  left: 2px;
  top: -1px;
  font-size: 14px;
}

.policyText {
  font-size: 13px;
  margin-left: 8px;
  color: #565656;
  width: calc(100% - 28px);
}

.policyText b {
  color: #003ec7;
  cursor: pointer;
}

.msgReplySection .rep_left.unread {
  color: #e91e63;
}

.selectPlaceholder {
  font-size: 15px !important;
  color: #9a9a9a !important;
  font-family: var(--F1);
}

.selectPlaceholderRoom {
  font-size: 15px !important;
  color: #656565 !important;
  font-family: var(--F1);
}

.css-1fhf3k1-control {
  border: none !important;
  background-color: #fff !important;
}

#notifications_container_pop .notificationItem:hover {
  background: #b0c1ac87;
  border-radius: 4px;
}

.digitHolder {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.digitHolder input {
  height: 100%;
  width: 54px;
  border: 1px solid #d6d6d6;
  background: white;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  color: #1f1f1f;
}

span.passwordInfoLabel {
  color: #565656;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  left: 0;
}

#root.dark .passwordInfoLabel {
  color: #9f9898;

}

span.companyDivider {
  width: 100%;
  height: 1px;
  background-color: #f9f6f1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 35px 0;
}

span.companyDivider::before {
  content: "OR";
  width: 60px;
  height: 60px;
  border: 1px solid #f9f6f1;
  color: #9b9a98;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  background-color: #fff;
}

.filterActionPreview {
  position: fixed;
  top: 60px;
  transform: translateX(-50%);
  z-index: 999;
  max-width: 400px;
  left: 60%;
}

.filterActionPreview .miniItem {
  width: 100%;
}

.filterActionPreview .miniItem_name {
  /* width: calc(100% - 60px); */
  overflow: hidden;
  text-overflow: ellipsis;
}

.filterActionPreview .miniItem_remove {
  background: #FFF;
}

.filterActionPreview .miniItem_remove:before {
  content: none;
}

.select-ecosystem .css-1pahdxg-control {
  padding: 0 !important;
}

.css-1uccc91-singleValue {
  top: 55%;
}

.select-ecosystem .css-yk16xz-control {
  padding: 0 !important;
  border: 1px solid #d6d6d6 !important;
}

.css-g1d714-ValueContainer {
  /* height: 50px;
  padding: 0px 12px !important; */
  min-height: 50px !important;
  padding: 5px 5px !important;
  /* overflow-y: auto !important; */
  display: flex !important;
  justify-content: flex-start;
  align-items: center !important;
  margin-top: 5px;
}

.css-g1d714-ValueContainer .css-b8ldur-Input {
  height: 25px;
}

.css-g1d714-ValueContainer .css-1rhbuit-multiValue {
  margin-top: 8px;
}

.css-xb97g8:hover {
  cursor: pointer;
}

.resendBTN {
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  color: #002e98;
  float: right;
  margin-top: 5px;
}

.customTip {
  z-index: 9;
}

.react-tooltip-lite {
  background-color: #000;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}

.react-transform-component {
  cursor: grab;
}

span.clearGallerySearch {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  right: 40px;
  display: none;
}

span.clearGallerySearch::before {
  content: "\f057";
  font-family: var(--FA);
  font-weight: 600;
  color: var(--NewRed);
  font-size: 20px;
}

.gSearchInput:not(:placeholder-shown)+.clearGallerySearch {
  display: block;
}

button.sendButton:focus,
button.policyCheck:focus {
  /* background-color: #002e98; */
  background-color: #003ec7;
}

button.policyCheck:focus {
  /* border: 1px solid #002e98; */
  border: 1px solid #003ec7;
  background-color: #fff;
}

button.policyCheck.active:focus {
  /* border: 1px solid #002e98;
  background-color: #003ec7; */
  border: 1px solid #003ec7;
  background-color: #003ec7;
}

a:focus {
  color: #002e98;
}

/* div:focus {
  background-color: #002e98;
} */

/* span:focus{
  background-color: #002e98;
} */
span.errorLabel {
  color: red;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  left: 0;
  /* display: none; */
  visibility: hidden;
  top: 5px;
}

input.error+.errorLabel {
  /* display: block; */
  visibility: visible;
}

.combineInputField {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emailAt {
  position: absolute;
  width: 39px;
  height: 48px;
  background-color: #e4e4e4;
  border-radius: 4px 0 0 4px;
  background-image: url("./media/images/@.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 17px;
  top: 28px;
  left: 1px;
}

.email-address .emailAt,
.loginBody .emailAt {
  left: 9px;
}

.loginBody .passwordLock {
  left: 9px;
}

.loginBody .passwordView.active,
.loginBody .passwordView {
  right: 9px;
}

.passwordLock {
  position: absolute;
  width: 39px;
  height: 48px;
  background-color: #e4e4e4;
  border-radius: 4px 0 0 4px;
  background-image: url("./media/images/lock.webp");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  top: 28px;
  left: 1px;
}

.removeEmail {
  position: absolute;
  width: 39px;
  height: 48px;
  background-color: #e4e4e4;
  border-radius: 0 4px 4px 0;
  background-image: url(./media/images/close_button.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 14px;
  top: 13px;
  right: 9px;
  cursor: pointer;
}

.passwordView {
  position: absolute;
  width: 39px;
  height: 48px;
  background-color: #e4e4e4;
  border-radius: 0 4px 4px 0;
  background-image: url("./media/images/eyeNot.webp");

  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 19px;
  top: 28px;
  right: 1px;
  cursor: pointer;
}

.passwordView.active {
  position: absolute;
  width: 39px;
  height: 48px;
  background-color: #e4e4e4;
  border-radius: 0 4px 4px 0;
  background-image: url("./media/images/eye.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 21px;
  top: 28px;
  right: 1px;
  cursor: pointer;
}

input#email {
  padding-left: 50px;
}

.carousel.carousel-slider {
  bottom: -23px;
}

.carousel .slider-wrapper {
  height: calc(100vh - 96px);
  position: relative;
  bottom: -22px;
}

.carousel .slider {
  height: 100%;
}

.msgSeparator.unread:after,
.replySeparator.unread:after {
  border-bottom: solid 1px #e82a87;
}

.msgSeparator.unread p,
.replySeparator.unread p {
  color: #e82a87;
  font-style: italic;
  border: 1px solid #e82a87;
}

.newRegister_container {
  background: #fff;
  width: 50%;
  height: 100%;
  padding: 0 9%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  flex-direction: column;
}

.rg_container {
  width: 100%;
  /* max-height: calc(100% - 80px);
     overflow-y: auto;
    overflow-x: hidden; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.rgformHeader {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 15px 8px;
  /* border-bottom: 1px solid #d8d8d8; */
}

.rgformTitle {
  color: #1f1f1f;
  font-size: 26px;
  font-weight: bolder;
  letter-spacing: 1px;
  margin-bottom: 5px;
}

.rgformSubTitle {
  color: #1f1f1f;
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 1px;
}

.rgformBody {
  width: 100%;
  /* height: calc(100% - 200px); */
  overflow-y: auto;
}

.rgInputGroup {
  width: 100%;
  position: relative;
  border-radius: 3px;
  padding: 12px 8px;
  margin-bottom: 12px;
}

.rgInputGroup.focused {
  background-color: #f1f5ff;
}

.rgInputGroup:last-child {
  margin-bottom: 0px;
}

.rgInputLabel {
  height: auto;
  width: 100%;
  color: #565656;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 8px;
  display: flow-root;
}

.rgInputLabel:empty {
  display: none;
}

.rgInputf {
  box-sizing: border-box;
  height: 50px;
  font-size: 16px;
  width: 100% !important;
  border: 1px solid #d6d6d6 !important;
  border-radius: 4px !important;
  padding: 8px 15px;
  background-color: #ffffff !important;
  margin: 0px !important;
}

/* .rgInputf:focus {
  border: 1px solid #799fff !important;
  outline: 2px solid #c0d1ff;
} */
.rgInputf.unavailable {
  border: 1px solid red !important;
}

.rgTextAreaf {
  box-sizing: border-box;
  height: 150px;
  font-size: 16px;
  width: 100% !important;
  border: 1px solid #d6d6d6 !important;
  border-radius: 4px !important;
  padding: 8px 25px !important;
  background-color: #ffffff !important;
  margin: 0px !important;
  resize: none;
}

.rgTextAreaf:focus {
  /* border: 1px solid #799fff !important;
  outline: 2px solid #c0d1ff; */
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}

.rgInputf.error {
  border: 1px solid red !important;
  outline: 2px solid #f58a8a;
}

.rgInputf.error+.passwordPlaceholder+.rgInputMsg,
.rgInputf.error+.rgInputMsg {
  color: red;
}

.rgTextAreaf::placeholder,
.rgInputf::placeholder {
  color: #c3c2c2;
}

.i_agree {
  position: relative;
  width: 100%;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.i_agree .policyCheck {
  border: 1px solid #d6d6d6;
}

.i_agree .policyText {
  font-size: 14px;
}

.rgInputMsg {
  color: #9f9898;
  font-size: 13px;
  margin-top: 5px;
  display: block;
  width: 100%;
  /* late added */
  /* margin-bottom: 50px; */
}

.rgformFooter {
  width: 100%;
  /* height: 100px; */
  /* padding: 20px 8px; */
  padding: 0px 8px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border-top: 1px solid #d8d8d8; */
  margin: 20px 0px;
}

.rgFromBack,
.rgFromNext {
  padding: 10px 30px;
  border-radius: 30px;
  color: #fff;
  border: 1px solid #0c1e47;
  background-color: #0c1e47;
  font-size: 16px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 2px, rgb(0 0 0 / 23%) 0px 3px 6px;
}

.rgFromBack:hover,
.rgFromNext:hover {
  background-color: #002e98;
}

select.rgInputf:required:invalid {
  color: #c3c2c2;
}

select.rgInputf option[value=""][disabled] {
  display: none;
}

select.rgInputf option {
  padding: 8px 0;
  color: black;
}

.finalPlat {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 50%;
}

.finalPlatImg {
  width: 100px;
  height: 100px;
  border-radius: 3px;
  border: 1px solid #d6d6d6;
  margin-bottom: 30px;
  /* background-image: url(/WorkFreeli.png); */
  background-repeat: no-repeat;
  background-position: 50%;
  background-color: #002e98;
}

.finalPlatText1 {
  color: #1f1f1f;
  font-size: 26px;
  font-weight: bolder;
  letter-spacing: 1px;
  margin-bottom: 5px;
  margin-bottom: 30px;
  text-align: center;
}

.finalPlatText2 {
  color: #1f1f1f;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 5px;
  margin-bottom: 30px;
  text-align: center;
}

.finalPlatText3 {
  color: #1f1f1f;
  font-size: 20px;
  font-weight: normal;
  letter-spacing: 1px;
  margin-bottom: 5px;
  text-align: center;
}

.teamMate_inviteBtn,
.skipBtn {
  width: calc(100% - 30px);
  padding: 15px 30px;
  color: #1f1f1f;
  font-size: 24px;
  font-weight: bolder;
  background-color: #003ec7;
  border-radius: 5px;
  border: none;
  color: #fff;
  cursor: pointer;
}

.teamMate_inviteBtn.active,
.skipBtn.active {
  background-color: #002e98;
}

.teamMate_inviteBtn {
  margin-bottom: 20px;
}

.sentInvitation {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(./media/images/paper_plan.svg);
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: 50%;
}

.sentInvitation.success_icon {
  background-image: url(./media/images/successImg.svg);
}

.multiCompany {
  width: calc(100% - 346px);
  height: 100vh;
  float: left;
  position: absolute;
  top: -1px;
  left: 346px;
  background-color: #f8f8f8;
  z-index: 3;
  overflow: hidden;
}

.rgInputGroup .emailAt {
  top: 40px;
  left: 9px;
}

.rgInputGroup.invGrp .emailAt {
  top: 13px;
  left: 9px;
}

.rgInputGroup .passwordLock {
  top: 40px;
  left: 9px;
}

.rgInputGroup .passwordView {
  top: 40px;
  right: 9px;
}

.passInputGroup .passwordLock {
  top: 37px;
  left: 1px;
}

.passInputGroup .passwordView {
  top: 37px;
  right: 1px;
}

.rgInputGroup .rgInputf[type="password"],
.rgInputGroup .rgInputf[type="email"] {
  padding: 8px 48px !important;
}

.rgInputf:not(:valid)+.passwordPlaceholder,
.rgInputf:not(:valid)+.passwordPlaceholder {
  position: absolute;
  left: 48px;
  top: 24px;
  line-height: 40px;
  font-size: 113px;
  font-family: auto;
  letter-spacing: -6px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adb5bdd1;
  pointer-events: none;
}

/* .dots {
  background-image: url("./media/images/dot.svg");
  background-repeat: no-repeat;
  background-size: 15px;
  float: left;
  width: 10px;
  height: 10px;
  display: flex;
} */
.transform-component-module_content__2jYgh img {
  max-height: calc(100vh - 250px);
  min-width: 50px;
}

.otherAccountContainer {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 30px 0px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  overflow: hidden;
  transition: all 400ms ease-out;
}

h2.accountsTitle {
  color: #243661;
  position: absolute;
  left: 100px;
  top: 60px;
  z-index: 1;
}

.accountSelector .acountLogo {
  box-sizing: border-box;
  height: 65px;
  width: 65px;
  border: 2px solid #023d67;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  -webkit-user-select: none;
  user-select: none;
  margin-bottom: 25px;
}

.accountSelector .acountLogo img {
  width: 55px;
  height: 55px;
  border-radius: 50%;
  -webkit-user-select: none;
  user-select: none;
}

.accountSelector {
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: 40px;
  transition: margin-left 500ms ease-out;
  float: left;
}

.accountSelector:first-child {
  margin-left: 0px;
}

.acountName {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  transition: all 500ms ease-out;
  opacity: 1;
  overflow: hidden;
}

.acountDesignation {
  font-size: 18px;
  font-weight: normal;
  text-transform: uppercase;
  transition: all 500ms ease-out;
  opacity: 1;
  overflow: hidden;
}

span.accountCounter {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #e82a87;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  right: -15px;
  bottom: 0;
}

.otherAccountContainerMsgC {
  width: 100%;
  height: calc(100% - 306px);
  overflow-y: auto;
  transition: all 500ms ease-out;
}

.accountMsgContaier {
  width: 100%;
  box-sizing: border-box;
  padding: 0 100px;
}

.accountMsg {
  background-color: #fff;
  border-radius: 4px;
  padding: 30px 40px;
  margin-bottom: 20px;
}

.accountMsgHead {
  margin-bottom: 25px;
}

.acMsgInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 8px;
}

span.msgInfoName {
  font-size: 18px;
  font-weight: 600;
  color: #313131;
  cursor: pointer;
}

span.msgInfoAcName {
  font-size: 18px;
  font-weight: normal;
  color: #565656;
  margin-left: 10px;
}

span.msgInfoCounter {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #e82a87;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.msgInfoDesignation {
  font-size: 18px;
  font-weight: bold;
  color: #565656;
}

p.msgText {
  font-size: 17px;
  color: #020202;
  margin-bottom: 10px;
}

.msgTime {
  font-size: 17px;
  font-weight: normal;
  color: #565656;
}

.moreMsg {
  font-size: 16px;
  font-weight: bold;
  color: #e82a87;
  margin-top: 10px;
  cursor: pointer;
}

.multiAccount_close {
  position: absolute;
  width: 22px;
  height: 22px;
  background-image: url("./media/images/close_button.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 22px;
  top: 60px;
  right: 60px;
  cursor: pointer;
  z-index: 1;
}

.multiCompany.small_container .acountName,
.multiCompany.small_container .acountDesignation {
  /* visibility: hidden; */
  opacity: 0;
  height: 0;
  width: 0;
}

.multiCompany.small_container .accountSelector .acountLogo {
  margin-bottom: 18px;
}

.multiCompany.small_container .otherAccountContainer {
  padding-bottom: 15px;
  height: 230px;
}

.multiCompany.small_container .otherAccountContainerMsgC {
  height: calc(100% - 230px);
}

.previewImgContainer .tools {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 30px;
}

.previewImgContainer .tools button {
  padding: 8px 20px;
  box-sizing: border-box;
  border: 1px solid #0c1e47;
  margin: 0px 4px;
  border-radius: 20px;
  min-width: 50px;
  font-size: 16px;
  background: #fff;
  color: #0c1e47;
  cursor: pointer;
  font-weight: 600;
}

.previewImgContainer .tools button:hover {
  color: #fff;
  background: #0c1e47;
}

.replyMsgContainer .loaderMain.background_none {
  top: 102px;
  height: calc(100vh - 103px);
}

.galleryModalBody .loaderMain {
  height: calc(100vh - 62px);
}

.profilenavMainSection .logOut {
  border-top: 0px solid #f2f2f2;
  /* background-color: #0b1f47; */
  border-radius: 0 0 0px 0px;
}

.profilenavMainSection .border_bottom.logOut {
  border-bottom: 1px solid #fff;
}

.closeConnectSearch {
  position: absolute;
  width: 22px;
  height: 22px;
  background-image: url("./media/images/close_button.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 22px;
  top: 28px;
  right: 10px;
  cursor: pointer;
}

.participantImage.online::after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-image: url("./media/images/OnlineStatus.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 24px;
}

.participantImage.offline::after {
  content: "";
  width: 13px;
  height: 13px;
  position: absolute;
  right: 8px;
  bottom: 8px;
  background-image: url("./media/images/OfflineStatus.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 24px;
}

.participantImage.offline.group::after {
  display: none;
}

.pricingPlan {
  width: 100%;
  height: 100%;
  padding: 80px 80px 0;
}

.pricingPlanHeader {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #525ff5;
  margin-bottom: 20px;
}

.pricingPlanHeader h2 {
  color: #fff;
  font-size: 28px;
  margin-bottom: 15px;
}

.pricingPlanHeader p {
  color: #fff;
  font-size: 18px;
}

.pricePlanContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.pricePlan {
  margin: 10px;
  width: calc(20% - 20px);
  background-color: #edecece8;
  padding: 10px 10px 20px;
  box-sizing: border-box;
  border-radius: 2px;
}

.planHead {
  border-bottom: 3px solid #525ff5;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #525ff5;
}

.planTitle {
  font-weight: bold;
}

.planSecTitle {
  font-size: 13px;
}

.planBody {
  height: 320px;
  width: 100%;
  padding: 10px 10px;
  position: relative;
}

h2.planPrice {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  color: #525ff5;
  margin: 8px 0 20px;
  height: 50px;
}

.planPriceSub {
  font-size: 15px;
}

.includeText {
  color: #525ff5;
}

.planList {
  color: #525ff5;
  font-size: 14px;
  padding-left: 10px;
  list-style: disc;
  margin-left: 8px;
  margin-bottom: 20px;
}

.planStartBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 15px;
  background-color: #525ff5;
  color: #fff;
  border-radius: 4px;
  border: none;
  width: 100%;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
}

.needMoreContainer {
  padding: 35px;
  width: 100%;
  background-color: #edecece8;
  margin: 40px 0 40px;
}

.needMoreTitle {
  font-size: 18px;
  color: #525ff5;
  font-weight: bold;
  margin-bottom: 10px;
}

.needMoreSecTitle {
  font-size: 17px;
  color: #525ff5;
  margin-bottom: 25px;
}

.additionPlanContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.additionPlan {
  width: calc(33% - 10px);
  padding: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #52cbf55e;
}

.planAddBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 15px;
  background-color: #525ff5;
  color: #fff;
  border-radius: 4px;
  border: none;
  width: 180px;
  cursor: pointer;
  margin-top: 10px;
}

.addPlanTitle {
  font-weight: bold;
  font-size: 16px;
  color: #525ff5;
  text-align: center;
}

.downloadingPopup {
  width: 400px;
  height: auto;
  position: fixed;
  right: 15px;
  bottom: 120px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(10, 31, 68, 0.08),
    0 8px 10px 0 rgba(10, 31, 68, 0.1);
  z-index: 5;
}

.downloadingHead {
  border-radius: 4px 4px 0 0;
  background-color: #323232;
  width: 100%;
  height: 50px;
  color: #fff;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.downloadingTitle {
  font-size: 18px;
}

.downloadingHead .closePopup {
  background-image: url("./media/images/close_button_w.svg");
}

.downloadingBody {
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
}

.downloadingFile {
  width: 100%;
  display: flex;
  height: 50px;
  align-items: center;
  padding: 10px 20px;
  font-size: 16px;
}

.checkFilterItem.btn_s {
  color: #fff;
  border-radius: 4px;
  padding: 0px 12px;
}

/* select.taskStatus {
  width: 120px;
  height: 30px;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  float: left;
} */

select.taskStatus {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #d8d8d8;
  padding: 5px 8px;
  box-sizing: border-box;
  height: 48px;
  background-color: #fff;
  font-size: 15px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.internet_action {
  width: 100%;
  height: 100vh;
  background-color: #0d2150c2;
  position: fixed;
  pointer-events: none;
  z-index: 999999999;
  cursor: not-allowed;
  display: flex;
  left: 0;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.loginTheme {
  position: absolute;
  top: 10px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

span.loginThemeModes {
  font-size: 16px;
  cursor: pointer;
  color: #002e98;
  padding: 2px 8px;
}

span.loginThemeModes.active {
  background-color: #003ec7;
  /* background-color: #0c1e47; */
  color: #fff;
  border-radius: 3px;
}

.flatpickr-day {
  max-width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}

.inputDivider {
  padding: 6px 8px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
  color: #003ec7;
}

.link_title {
  font-weight: bold;
  font-size: 16px;
  color: #2196f3;
}

.link_title span {
  color: #2196f3;
}

.edit_link_title {
  background-image: url("./media/images/png/edit-icon_new.png");
  background-size: 18px;
  background-repeat: no-repeat;
}

p.profileTitle {
  position: absolute;
  left: 85px;
  top: 58px;
  font-size: 18px;
  color: #002e98;
  font-weight: normal;
}

.rdw-editor-toolbar {
  width: auto;
  display: flex;
  justify-content: flex-start;
  /* position: absolute; */
  /* right: 50px; */
  /* bottom: 12px; */
  z-index: 3;
}

.rdw-list-wrapper,
.rdw-text-align-wrapper,
.rdw-link-wrapper,
.rdw-history-wrapper,
.rdw-inline-wrapper {
  display: flex;
  justify-content: flex-start;
}

.rdw-option-wrapper {
  margin-right: 0;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-image: url("./media/images/png/reaction-icon.png"); */
  background-size: 24px;
  cursor: pointer;
}

.rdw-emoji-wrapper .rdw-option-wrapper img {
  display: none;
}

/*.rdw-emoji-modal {
  overflow: auto;
  position: absolute;
  bottom: 42px;
  right: -50px;
  display: flex;
  flex-wrap: wrap;
  width: 334px;
  height: 180px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 8px;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 8%), 0 6px 6px 0 rgb(0 0 0 / 16%);
}*/
.rdw-emoji-modal {
  overflow: auto;
  position: fixed;
  bottom: 79px;
  right: 50px;
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 500px);
  height: 300px;
  border: 1px solid #f1f1f1;
  padding: 15px;
  border-radius: 8px;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 0 6px 0 rgb(0 0 0 / 8%), 0 6px 6px 0 rgb(0 0 0 / 16%);
}

span.rdw-emoji-icon {
  padding: 6px;
  line-height: 36px;
  cursor: pointer;
  border-radius: 50%;
  font-size: 30px
}

span.rdw-emoji-icon:hover {
  background-color: #f4f4f4;
}

.editMsgContainer .demo-wrapper,
.bottom_bar .demo-wrapper {
  border: 1px solid #b8d4ed;
  width: 100%;
  /* width: 96%; */
  float: right;
  min-height: 54px;
  max-height: 350px;
  border-radius: 6px;
  font-size: 16px;
  padding: 11px 140px 8px 10px;
  display: flex;
  justify-content: flex-start;
  word-break: break-word;
  line-height: 28px;
  resize: none;
  position: relative;
  flex-direction: column;
}

.editMsgContainer .demo-wrapper {
  padding: 2px;
  border: none;
}

.redAlertBorder .demo-wrapper {
  border: 1px solid red !important;
}

.demo-editor {
  width: 100%;
  max-height: 350px;
  overflow-y: auto;
  text-align: left;
}

.editorFileUploadComment {
  width: 100%;
  height: 370px;
  overflow-y: auto;
  text-align: left;
  margin-top: 48px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #b8d4ed;
}

.editorFileUploadTaskComment {
  width: 100%;
  height: 250px;
  overflow-y: auto;
  text-align: left;
  /* margin-top: 48px; */
  margin-bottom: 20px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #b8d4ed;
}

.editorMaintenanceComment {
  width: 100%;
  height: 100px;
  border-radius: 10px;
  font-size: 16px;
  box-sizing: border-box;
  border: 1px solid rgba(0, 0, 0, .12);
  border-radius: 6px;
  background-color: #fff;
  text-align: left;
  overflow-y: auto;
  overflow-x: hidden;
  resize: none;
  padding: 8px 16px 8px 10px !important;
}

.rdw-suggestion-dropdown {
  width: calc(100% + 4px);
  min-height: 50px;
  background: #fff;
  position: absolute;
  border-radius: 3px;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #d6d6d695;
  /* max-height: 300px; */
  max-height: 200px;
  overflow: auto;
  box-shadow: 0 0 0px 0 rgb(10 31 68 / 8%), 0 8px 10px 0 rgb(10 31 68 / 10%);
  left: -2px !important;
  z-index: 1;
}

#msgEditBox .rdw-suggestion-dropdown {
  height: 200px;
}

#file_comment_box .rdw-suggestion-dropdown {
  width: calc(100% - 63px);
  left: 31px !important;
  bottom: 200px
}

.rdw-suggestion-option {
  font-size: 15px;
  float: left;
  width: 100%;
  padding: 4px 6px;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
  color: #0c1e47;
  display: flex;
  align-items: center;
  line-height: 24px;
}

.buttonContainerTop {
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  height: 34px;
  align-items: center;
}

.buttonContainerTop div:first-child {
  /* width: 34px; */
  width: 38px;
  height: 34px;
  /* padding: 7px 10px; */
  background-color: #a6d068;
  display: flex;
  align-items: center;
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  justify-content: center;
  cursor: pointer;
}

.buttonContainerTop div:first-child:hover {
  background-color: #318fff;
}

.buttonContainerTop div:last-child {
  /* width: 56px; */
  width: 62px;
  height: 34px;
  /* padding: 7px 10px; */
  background-color: #0b1f47;
  display: flex;
  align-items: center;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  font-family: Poppins;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  justify-content: center;
  cursor: pointer;

}

#root.dark .buttonContainerTop div:last-child {
  border: 1px solid white;
}

.line-top {
  width: 1px;
  height: 76px;
  margin: 0px 10px 0 15px;
  background-color: #e3f2fe;
}

.buttonContainerTop div:last-child:hover {
  background-color: #318fff;
}

span.rdw-suggestion-option:hover,
span.rdw-suggestion-option.rdw-suggestion-option-active {
  background-color: #d6d6d6;
}

.mentionUserContainer .mentionUserLi:hover {
  background: #d9e2e8;
  color: #0c1e47;
}

#root.dark .darkTextWhite {
  color: white !important;
}

#root.dark .darkTextWhite p {
  background-color: #232425 !important;
}

.bottom_bar.group_conv .demo-wrapper {
  padding-left: 110px;
  /* padding-left: 100px; */
}

.right_container.admin_right .loaderMain {
  height: calc(100vh - 122px);
}

/* .notranslate.public-DraftEditor-content {
  position: relative;
} */
#root.dark .msgReplySection .rep_righ,
#root.dark .msgReplySection .rep_left,
#root.dark .gFileName._link a,
#root.dark ._afileName a,
#root.dark .msg_txt a {
  /* color: #007eff; */
  color: #028bff;
}

#root.dark .right_container.admin_right,
#root.dark .right_container_fileHub {
  background-color: #181818 !important;
}

#root.dark .gFilterOption div.gGridViewIcon.active {
  background-color: #028bff;
}

#root.dark .gFilterOption div.gListViewIcon.active {
  background-color: #028bff;
}

#root.dark .gTabs.active {
  background-color: #028bff;
}

#root.dark .replyMsgFullContainer {
  background-color: #181818;
}

#root.dark span.user_Management_title {
  color: #94b2ff;
}

#root.dark .opts.reply_opts {
  /* background-image: url(./media/images/png/thread-icon_new.png); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White-01.svg");
  background-size: 20px;
}

#root.dark .closeAdminPanel {
  background-image: url("./media/images/back_new.svg");
  color: #ffffff;
}

#root.dark .msgReaction {
  /* border-color: #d6d6d608; */
  background-color: transparent;
}

#root.dark .opts:hover {
  background-color: #404040;
  background-repeat: no-repeat;
  background-size: 24px;
}

#root.dark .opts_show:hover {

  background-size: 20px;
  background-color: #000;

}

.view_opts.opts_show {
  margin-top: 2px !important;
}


#root.dark .group_containerThread,
#root.dark .left_container {
  background-color: rgb(16 16 16 / 99%);
  /* background-color: #000000; */
}

#root.dark .playerHeader,
#root.dark .playerContainer,
#root.dark .themeMenu,
#root.dark .profilenavMainSection,
#root.dark .profileNavContainer,
#root.dark .loaderMain,
#root.dark .chooseCreateConv,
#root.dark .taskCreatorPopup,
#root.dark .fileNotFound,
#root.dark .otherAccountContainerMsgC,
#root.dark .otherAccountContainer,
#root.dark .passwordModal,
/* #root.dark .file_comment_box, */
#root.dark .fileUploadModal,
#root.dark .createGroupConv,
#root.dark .updateGroupConv,
#root.dark .galleryModalBody,
#root.dark .galleryModal,
#root.dark .replyMsg_head,
#root.dark .checklistMsg_head,
#root.dark .replyMsgContainer,
#root.dark .replyThreadContainer,
#root.dark .checklistEditContainer,
#root.dark .replySeparator,
#root.dark .msg_Container,
#root.dark .bottom_bar,
#root.dark .muteModal,
#root.dark .previewModal,
#root.dark .participantsModal,
#root.dark .previewModalBody,
#root.dark .userMiniModal,
#root.dark .notificationHeader,
#root.dark .notify_boundary,
#root.dark .freeliModal,
#root.dark .tagModal,
#root.dark .shareLinkModal,
#root.dark .moreMenuPopup,
#root.dark .msg_hover_options,
#root.dark .msgMorePopup,
#root.dark .reactionContainer,
/* #root.dark .filepanel, */
#root.dark .filePanelHeader,
#root.dark .searchAndFilter,
#root.dark .customTitleModal,
#root.dark .right_container,
#root.dark .userInfoModal,
#root.dark .multiCompany,
#root.dark .createTeamModal,
#root.dark .createUserModal,
#root.dark .rdw-suggestion-dropdown,
#root.dark .Filehub_header,
#root.dark .tag_files_popup,
#root.dark .top_bar,
#root.dark .kanban-task,
#root.dark .task_fulsearch_area,
#root.dark .right_container1,
#root.dark .taskListTable,
#root.dark .taskListTableDivArea,
#root.dark .subRightContainer,
#root.dark .taglistBodyArea,
#root.dark .showRelatedTagsAre,
#root.dark .subRightContainer,
#root.dark .salesReportMainCon {
  background-color: #181818 !important;
}

#root.dark .AddKeywordsPopup_task_settings.insightTaskBoard {
  border: 8px solid #424242;
  background-color: #1d1f26;
}

#root.dark .AddKeywordsPopup_task_settings.insightTaskBoard .keyword_list_task_settings {
  background-color: #1d1f26;
}

#root.dark .AddKeywordsPopup_task_settings.insightTaskBoard .keyword_list_task_settings {
  color: #fff;
}

#root.dark .darkMode {
  background-color: #232425;
}

#root.dark .msg_txt p>span,
#root.dark button.toggleDropDown {
  color: white;
}

#root.dark .tableTopHeader {
  background-color: #424242 !important;
}

#root.dark .notiActions::before {
  background-color: #fff;
}

#root.dark span.checkClrOpt {
  border: 2px solid #fff;
}

#root.dark .fileMarker {
  border: 2px solid #fff;
}

#root.dark .taglistBodyArea input {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .crTeam_inputGroup label,
#root.dark .teamInfoName,
#root.dark .teamInfoDate,
#root.dark .view_opts:before,
#root.dark .editUserName,
#root.dark p.profileTitle,
#root.dark .itemTitle,
#root.dark .checkFilterItem.active,
/* #root.dark span.msgLinkTitle, */
/* #root.dark span.editTpen::before, */
#root.dark .middleBar,
#root.dark .showingSelect span,
#root.dark .taskListTableHead ul li,
#root.dark .taskListTableBody ul li,
#root.dark span.clrOptLabel,
#root.dark .freeliModalBody .delDesc,
#root.dark .msg_body,
#root.dark .notiActions,
#root.dark .passInputLabel,
#root.dark .replyHeadTitle h3,
#root.dark .checklistHeadTitle h3,
#root.dark .chooseCreateConv .popup_title,
#root.dark .checkListItem .checkBoxTitle,
#root.dark .assignSection .assignDiv label,
#root.dark .assignSection .dueDiv label,
#root.dark .make_private .label_head,
#root.dark .set_avatar,
#root.dark .acountName,
#root.dark .acountDesignation,
#root.dark .inputLabel,
#root.dark .createConv_title,
#root.dark .notFound_container h2,
#root.dark .chat-file-upload-comment label,
#root.dark .popupTitle,
#root.dark .participantTitle,
#root.dark .tinytext,
#root.dark .nameInfo,
#root.dark .teamManagementTitle,
#root.dark .dateInfo,
#root.dark .gGridView .gFileGridName,
#root.dark .fileNotFound h2,
#root.dark .muteTitle,
#root.dark .usersTabList,
#root.dark .replyCreate_box,
#root.dark p.file_name,
#root.dark .sender_name,
#root.dark .msg_txt,
#root.dark .msg_time,
#root.dark .is_delivered,
#root.dark .checklistMsgTitle,
#root.dark .create_box,
#root.dark .conv_info span,
#root.dark .conv_info_gray span,
#root.dark .msgReplySection .rep_mid,
#root.dark .userNameTitle,
#root.dark .userEmailSection,
#root.dark .file_size,
#root.dark .gListView .gFileName,
#root.dark ul.tagTab li,
#root.dark .cu_inputGroup label,
#root.dark .tagTitle,
#root.dark .shareDesc,
#root.dark h2.unique_code,
#root.dark .profileNavContainer .headNav h1,
#root.dark .profilenavMainSection .linkAccount,
#root.dark .privateUserContainer .privateLabel,
#root.dark .themeMode,
#root.dark .show_list,
#root.dark .progressStatus,
#root.dark span.upFileName,
#root.dark span.upFileSize,
#root.dark .taskCreatorBody .checkListPlainText,
#root.dark .playerHeader div,
#root.dark .file_comment_box,
#root.dark .userInfoName,
#root.dark .userInfoDate,
#root.dark .userInfo_row .userInfo_column,
#root.dark .userInfoTabSection li,
#root.dark .userTableBody ._userList>li,
#root.dark .notFound_container.empty_msg p,
#root.dark .conv_name,
#root.dark .TaskBody td,
#root.dark .tableTopHeader td,
#root.dark .tableTopHeader td svg,
#root.dark p.charecteLeft,
#root.dark p.task_title_text,
#root.dark .assignedToLabel span,
#root.dark .taskCreateRightPart p,
#root.dark .mostRecentTagsFlexBox p {
  color: #fff !important;
}

#root.dark .conv_name {
  /* modify by Manzu */
  color: #94b2ff !important;
}

#root.dark .contentHover:hover {
  background-color: #616161;
}

#root.dark .viewOnly,
#root.dark .darkColor,
#root.dark .gFilterOption div::before {
  color: #fff;
}

#root.dark .contentHover:hover svg.VscCalendarIcon {
  color: #fff !important;
}

#root.dark .contentHover:hover div#delete svg {
  color: #df1e39;
}

#root.dark .colTileDark {
  background-color: #424242 !important;
  color: white !important;
}

#root.dark .darkColorBtn {
  background-color: #BFDCFF !important;
  color: #0c1e47 !important;
}

#root.dark .kanbanTaskColCon {
  border: 1px solid #616161 !important;
}

#root.dark .muteItem.active .muteRadio {
  border: 5px solid #fff;
}

#root.dark .replyCreate_box[placeholder]:empty:before,
#root.dark .create_box[placeholder]:empty:before {
  color: #ffffff40;
}

#root.dark .user_msg:not(.checklistEditContainer .user_msg):hover {
  background-color: #f3f3f305;
}

#root.dark .replySeparator p,
#root.dark .msgSeparator p {
  color: #fff;
  border: 1px solid #d6d6d615;
  background-color: #131313;
}

#root.dark .checklistMsgTitle:before {
  background-image: url("./media/images/png/task-list-icon_new.png");
}

#root.dark .conv_filter {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_filter2.svg");
}

#root.dark .conv_filter.active {
  /* background-image: url("./media/images/png/filter_icon_active.png"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_filter2.svg");
}

#root.dark .adminUserFilterTask {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_filter2.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
}

#root.dark .conv_privatefilter {
  border: 1px solid #d6d6d6;
}

#root.dark .conv_tasks {
  background-image: url("./media/images/png/task-list-icon_new.png");
}

#root.dark .conv_tasks.active {
  background-image: url("./media/images/png/task-list-icon-active.png");
}

#root.dark .conv_flags {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_flag.svg");
}

#root.dark .conv_flags.active {
  /* background-image: url("./media/images/png/flagPanel-active.png"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_flag.svg");
}

#root.dark .conv_mute {
  /* background-image: url("./media/images/bellNot_new.svg"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_notifications_off.svg");
}

#root.dark .conv_mute.active {
  /* background-image: url("./media/images/bellNot_blue.svg"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_notifications_off.svg");
}

#root.dark .conv_voice {
  /* background-image: url("./media/images/png/call-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_call.svg");
}

#root.dark .conv_unpin {
  /* background-image: url("./media/images/png/pin-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_pinned.svg");
}

#root.dark .conv_unpin.active {
  /* background-image: url("./media/images/png/pin-icon_active.png"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_pinned.svg");
  transform: none;
  -webkit-transform: none;
  -moz-transform: none;
  -ms-transform: none;
  -o-transform: none;
}

#root.dark .conv_search {
  /* background-image: url("./media/images/png/search-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_search.svg");
}

#root.dark .conv_more {
  background-image: url("./media/images/png/menu-icon_new.png");
}

#root.dark .conv_info {
  /* background-image: url("./media/images/Users_new.svg"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_2 users.svg");
}

#root.dark .conv_info_gray {
  /* background-image: url("./media/images/Users_new.svg"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_2 users.svg");
}

#root.dark .createConv_back,
#root.dark .backToChat {
  background-image: url("./media/images/back_new.svg");
}

#root.dark .backToChat.closeBtn {
  /* background-image: url("./media/images/close_button_W.svg"); */
  background-image: url("./media/images/black_theme/closebutton_w.svg");
}

#root.dark .attachment_selector {
  /* background-image: url("./media/images/png/attachment-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_attach_file.svg");
}

#root.dark .rdw-option-wrapper,
#root.dark .emoji_selector {
  background-image: url("./media/images/png/reaction-icon_new.png");
}

#root.dark .msgSend_btn {
  /* background-image: url("./media/images/png/send-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_send.svg");
}

#root.dark .filter_by {
  background-image: url("./media/images/filter_active_w.svg");
}

#root.dark .editFile {
  background-image: url("./media/images/png/edit-icon_new.png");
}

#root.dark .userActionIcon {
  background-image: url("./media/images/gear_w.svg");
}

#root.dark .msgSeparator:after,
#root.dark .replySeparator:after {
  border-bottom: solid 1px #d6d6d608;
}

#root.dark .cancelTitle,
#root.dark .saveTitle,
#root.dark .paricipantsSubmit,
#root.dark .shareLinkModalFoot .btnCancel,
#root.dark .shareLinkModalFoot .btnAction,
#root.dark .createUserModalFoot .btnCancel,
#root.dark .createUserModalFoot .btnAction,
#root.dark .createTeamModalFoot .btnAction,
#root.dark .tagModalFoot .btnAction,
#root.dark .tagModalFoot .btnCancel,
#root.dark .freeliModalFoot .btnCancel,
#root.dark .freeliModalFoot .btnAction,
#root.dark .muteModalFoot .btnAction,
#root.dark .muteModalFoot .btnCancel,
#root.dark .passwordModalFoot .btnAction,
#root.dark .passwordModalFoot .btnCancel {
  color: #fff;
  border: 1px solid #fff;
  /* height: 42px; */

}

#root.dark .closeConnectSearch,
#root.dark .closeModal,
#root.dark .multiAccount_close,
#root.dark .createConv_close,
#root.dark .closePopup,
#root.dark .checklistEditClose {
  background-image: url("./media/images/close_button_w.svg");
}

#root.dark .tagFilter {
  background-image: url("./media/images/png/filter_icon_new.png");
}

#root.dark .createConv_foot {
  background-color: #d3e0ff08;
}

#root.dark .emojiPopup,
#root.dark .emoji-mart {
  background-color: #181818;
}

#root.dark .top_bar {
  border-color: #d6d6d615;
}

#root.dark .deletedText,
#root.dark .write_msg_label {
  color: #ffffff;
}

#root.dark div#file_comment_box .file_comment_box {
  border: 1px solid #595959 !important;
}

#root.dark .fileUploadModalBody button,
#root.dark .taskCreatorFoot button,
#root.dark .file_upload_label,
#root.dark .msgFlagReplyOption div,
#root.dark .file_opt {
  border: 1px solid #d6d6d6;
  color: #ffffff;
}

.emoji-mart-search input {
  margin-bottom: 5px;
}

#root.dark .createConv_type {
  border: 1px solid #d6d6d6;
  color: #fff;
}

#root.dark .file_comment_box,
#root.dark .singleImageView,
#root.dark .gFiles,
#root.dark .uploaded_file,
#root.dark .fileActiveActions #root.dark .single_file {
  background-color: transparent;
  border: 1px solid #d6d6d608;
}

#root.dark .fileActiveActions {
  border-left: 1px solid #d6d6d635;
}

#root.dark .create_box {
  border: 1px solid #d6d6d620;
}

#root.dark .editMsgContainer {
  background-color: #181818;
  border: 1px solid #595959;
  color: #fff;
}

#root.dark h2.accountsTitle,
#root.dark .msgInfoDesignation,
#root.dark .msgTime,
#root.dark p.msgText,
#root.dark span.msgInfoName {
  color: #fff;
}

#root.dark .accountMsg {
  background-color: #d6d6d605;
}

#root.dark .checklistContainer {
  border-color: #d6d6d608;
}

#root.dark .editMsgBtnSection .btnAction {
  border: 1px solid #d6d6d6;
}

#root.dark .editMsgBtnSection .btnCancel {
  border: 1px solid #d6d6d6;
  color: #fff;
}

#root.dark .tagItem {
  background-color: transparent;
}

#root.dark .participantItem,
#root.dark .muteItem {
  border: 1px solid #d6d6d635;
}

#root.dark .muteItem.active {
  background-color: #404040;
  border: 1px solid #404040;
}

#root.dark ul.searchSelectionPopup {
  border: 1px solid #d6d6d635;
  background: #181818;
}

#root.dark ul.searchSelectionPopup li {
  color: #d6d6d6;
  border-bottom: none;
}

#root.dark .msgMorePopup li,
#root.dark .moreMenuPopup li {
  color: #d6d6d6;
}

#root.dark .msgReplySection .rep_left.unread {
  color: #e91e63;
}

#root.dark .opts.task_opts {
  /* background-image: url("./media/images/png/task-list-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_tasks1.svg");
}

#root.dark .opts.copy_opts {
  background-image: url("./media/images/file_w.svg");
}

#root.dark .opts.emoji_opts,
#root.dark .emojiPlus {
  background-image: url("./media/images/png/reaction-icon_new.png");
}

#root.dark .opts.forward_opts {
  /* background-image: url("./media/images/png/forward-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_forward.svg");
}

#root.dark .opts.edit_opts {
  /* background-image: url("./media/images/png/edit-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_edit.svg");
}

#root.dark .opts.more_opts {
  /* background-image: url("./media/images/png/meatball-menu-icon_w.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_more options.svg");
}

#root.dark .profilenavMainSection .settingEmail {
  /* background-image: url("./media/images/png/edit-icon_new.png"); */
  background-image: url("./media/images/icons/blackTheme/SVG//WorkFreeli_Icons_White_edit.svg");
  background-color: transparent;
}

#root.dark .uploaded_file[data-status="1"] {
  background-image: url("./media/images/check-circle_pro_w.svg");
}

#root.dark .uploaded_file[data-status="1"]:hover {
  background-image: none;
}

#root.dark .profileNavContainer {
  border-top: 1px solid #d6d6d620;
  border-bottom: 1px solid #d6d6d620;
}

#root.dark .progressBar {
  border: 1px solid #d6d6d608;
}

#root.dark .searchTeamUser {
  background-color: transparent;
  color: #ffffff;
}

.tooltiparea {
  display: none;
  /* Or display: none, depending on how you want it to behave */
}

span.tooltip-on-hover.user_Management_title:hover .tooltiparea {
  display: block;
}

#root.dark .themeMode.active::after {
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}


#root.dark .createTeamModal,
#root.dark .createUserModal,
#root.dark .playerContainer,
#root.dark .reactionContainer,
#root.dark .themeMenu,
#root.dark .msg_hover_options,
#root.dark .moreMenuPopup,
#root.dark .emojiPopup,
#root.dark .shareLinkModal,
#root.dark .tagModal,
#root.dark .freeliModal,
#root.dark .taskCreatorPopup,
#root.dark .participantsModal,
#root.dark .fileUploadModal,
#root.dark .chooseCreateConv,
#root.dark .muteModal,
#root.dark .msgMorePopup,
#root.dark .profilenavMainSection,
#root.dark .rdw-suggestion-dropdown,
#root.dark .customTitleModal,
#root.dark .passwordModal {
  border: 1px solid #d6d6d635;
}

#root.dark .profilenavMainSection .logOut:hover,
#root.dark ul.searchSelectionPopup li:hover,
#root.dark .msgMorePopup li:hover,
#root.dark .profilenavMainSection .linkAccount:hover,
#root.dark .themeMode:hover,
#root.dark .userTableBody ._userList:hover,
#root.dark .moreMenuPopup li:hover {
  background-color: #404040;
}

#root.dark .profilenavMainSection .logOut {
  background-color: #40404085;
}

/* #root.dark .conv_list.selected {
  background-color: #40404050;
} */
#root.dark .conv_list:hover {
  background-color: #40404050;
}

#root.dark ._userRole select {
  border: 1px solid #d8d8d8;
  background-color: #181818;
  color: #ffffff;
  font-size: 14px;
}

#root.dark .userSearchInput {
  background: #181818;
  color: #ffffff;
}

input.userSearchInput:not(:placeholder-shown)+.leftSearchCloseBtn,
input.participantsSearch:not(:placeholder-shown)+.leftSearchCloseBtn,
input.searchTeamUser:not(:placeholder-shown)+.leftSearchCloseBtn {
  display: flex;
}

#root.dark h3.list_container_title.admin_left {
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
}

.taskCreatorPopup .inactive {
  pointer-events: none;
  opacity: 0.5;
}

#root.dark .form_container .dot_loader,
#root.dark .newRegister_container,
#root.dark .form_container {
  background-color: #131313;
}

#root.dark .policyText,
#root.dark .form-field>label,
#root.dark .formHeader {
  color: #ffffff !important;
}

#root.dark .rgformTitle,
#root.dark .rgformSubTitle,
#root.dark .rgInputLabel,
#root.dark span.loginThemeModes,
#root.dark .sign-up {
  color: #ffffff;
}

#root.dark .forgotPass,
#root.dark .sign-up a {
  color: #94b2ff;
}

#root.dark .rgInputGroup.focused {
  background-color: #13131385;
}

#root.dark span.upFileName[contenteditable="true"] {
  border: 1px solid #d6d6d640;
}

#root.dark .passwordView,
#root.dark .passwordLock,
#root.dark .emailAt {
  background-color: #13131390;
}

#root.dark .notificationItem.unread_noti,
#root.dark .user_msg.unread_noti {
  background-color: #40404050;
}

span.passwordLock.passwoordcreate {
  top: 52px;
}

.passwordView.passwoordcreate {
  top: 52px;
}

.back_loader {
  background-color: transparent;
  width: 100%;
  height: 40px;
  float: left;
  border: none;
}

#root.dark .notFound_container.empty_msg p {
  background-color: #efeced24;
}

#root.dark .conv_img img {
  border: 2px solid #94b2ff;
}

#root.dark .userTableHead ul li {
  color: #ffffff;
}

#root.dark .supportAndBillingPlan .userTableHead ul li {
  color: #fff;
}

span.readOnly {
  color: red;
}

#root.dark .participantItem p.participantTitleTeams {
  color: #9b9b9b;
}

#root.dark .darkBorder {
  border: 1px solid #595959 !important;
}

@media screen and (max-width: 1080px) {
  .leftFeb {
    display: none;
  }

  .form_container {
    width: 100%;
    padding: 40px 20%;
  }
}

.italic_text {
  font-style: italic;
}

.lazy-load-image-background {
  width: 100%;
  height: 100%;
  position: relative;
}

.single_file .img_holder .lazy-load-image-background {
  display: flex !important;
  justify-content: center;
}

.lazy-load-image-background.blur {
  filter: blur(0px) !important;
}

.lazy-load-image-background::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background-image: url("./media/images/Spinner.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  left: 0;
}

.lazy-load-image-background.lazy-load-image-loaded::after {
  display: none;
}

/* .wysiwyg-mention {
  pointer-events: none;
} */
.public-DraftEditorPlaceholder-inner {
  position: absolute;
  color: rgba(0, 0, 0, 0.24);
  pointer-events: none;
}

#root.dark .public-DraftEditorPlaceholder-inner {
  color: hsla(0, 0%, 100%, 0.25098039215686274);
}

#root.dark .rdw-suggestion-option {
  color: #d6d6d6;
}

#root.dark span.rdw-suggestion-option.rdw-suggestion-option-active,
#root.dark .rdw-suggestion-option:hover {
  background-color: #d6d6d620;
}

#root.dark a.rdw-mention-link {
  color: #2196f3;
}

#root.dark .userInfoTabSection li.active::before,
#root.dark .usersTabList.active:before {
  background-color: #fff;
}

#root.dark .userInfoRole::before {
  border: 1px solid #ffffff;
}

body#connectBody iframe {
  display: none !important;
}

body#connectBody #jitsiMeeting-0 iframe {
  display: block !important;
}


body#connectBody iframe.custom {
  display: block !important;
}

body#connectBody iframe.custom {
  width: 100%;
  height: 100vh;
}

.chat-file-upload-comment .rdw-editor-toolbar {
  display: none;
}

#root.dark .navTop:hover .linkAccount {
  background-color: #404040;
  color: #fff !important;
}

#root.dark .profileNavContainer:hover,
#root.dark .profileNavContainer:hover .headNav h1,
#root.dark .profileNavContainer .headNav h1:hover {
  background-color: #404040;
  color: #fff !important;
}

.btn_loader {
  color: transparent;
}

#root.dark .btn_loader {
  background-color: transparent;
}


/* START TOOLTIP STYLES */
/* [tooltip] {
  position: relative; 
} */

/* Applies to all tooltips */
/* [tooltip]::before,
[tooltip]::after {
  text-transform: none; 
  font-size: 16px; 

  user-select: none;

  position: absolute;
  display: none;
  opacity: 0;
  font-weight: normal !important;
}
[tooltip]::before {
  content: '';
  border: 5px solid transparent; 
  z-index: 1001; 
} */
/* [tooltip]::after {
  content: attr(tooltip);
  text-align: left;
  width: 400px;
  height: auto;
  white-space: normal;
  overflow: auto;
  text-overflow: ellipsis;
  padding: 8px;
  border-radius: 5px;
  box-shadow: 0 1em 2em -0.5em rgb(0 0 0 / 30%);
  background: #0c1e47;
  color: #fff;
  z-index: 1000;
  font-weight: normal !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  border: 5px solid #028BFF;
} */

/* [tooltip]:hover::before,
[tooltip]:hover::after {
  display: block;
}


[tooltip='']::before,
[tooltip='']::after {
  display: none !important;
}


[tooltip]:not([flow])::before,
[tooltip][flow^="up"]::before {
  bottom: 100%;
  border-bottom-width: 0;
  border-top-color: #333;
}
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::after {
  bottom: calc(100% + 5px);
}
[tooltip]:not([flow])::before,
[tooltip]:not([flow])::after,
[tooltip][flow^="up"]::before,
[tooltip][flow^="up"]::after {
  left: 50%;
  transform: translate(-50%, -.5em);
  -webkit-transform: translate(-50%, -.5em);
  -moz-transform: translate(-50%, -.5em);
  -ms-transform: translate(-50%, -.5em);
  -o-transform: translate(-50%, -.5em);
}


[tooltip][flow^="down"]::before {
  top: 100%;
  border-top-width: 0;
  border-bottom-color: #333;
}
[tooltip][flow^="down"]::after {
  top: calc(100% + 5px);
}
[tooltip][flow^="down"]::before,
[tooltip][flow^="down"]::after {
  left: 50%;
  transform: translate(-50%, .5em);
  -webkit-transform: translate(-50%, .5em);
  -moz-transform: translate(-50%, .5em);
  -ms-transform: translate(-50%, .5em);
  -o-transform: translate(-50%, .5em);
}


[tooltip][flow^="left"]::before {
  top: 50%;
  border-right-width: 0;
  border-left-color: #333;
  left: calc(0em - 5px);
  transform: translate(-.5em, -50%);
}
[tooltip][flow^="left"]::after {
  top: 50%;
  right: calc(100% + 5px);
  transform: translate(-.5em, -50%);
}


[tooltip][flow^="right"]::before {
  top: 50%;
  border-left-width: 0;
  border-right-color: #333;
  right: calc(0em - 5px);
  transform: translate(.5em, -50%);
}
[tooltip][flow^="right"]::after {
  top: 50%;
  left: calc(100% + 5px);
  transform: translate(.5em, -50%);
}

@keyframes tooltips-vert {
  to {
    opacity: .9;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -moz-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
}
}

@keyframes tooltips-horz {
  to {
    opacity: .9;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    -moz-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    -o-transform: translate(0, -50%);
}
}

[tooltip]:not([flow]):hover::before,
[tooltip]:not([flow]):hover::after,
[tooltip][flow^="up"]:hover::before,
[tooltip][flow^="up"]:hover::after,
[tooltip][flow^="down"]:hover::before,
[tooltip][flow^="down"]:hover::after {
  animation: tooltips-vert 300ms ease-out forwards;
  -webkit-animation: tooltips-vert 300ms ease-out forwards;
}

[tooltip][flow^="left"]:hover::before,
[tooltip][flow^="left"]:hover::after,
[tooltip][flow^="right"]:hover::before,
[tooltip][flow^="right"]:hover::after {
  animation: tooltips-horz 300ms ease-out forwards;
  -webkit-animation: tooltips-horz 300ms ease-out forwards;
} */
button.userCreateBtn.tooltip5 {
  padding-bottom: 4px;
}

/* Tooltip user management*/
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  /* visibility: hidden; */
  width: 400px;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -198px;
  top: 36px;
  font-size: 14px;
  font-weight: normal;
  border: 2px solid #94b2ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

#root.dark .tooltip .tooltiptext {
  background-color: #032e84;
  color: #ffffff;
}

/* .tooltip:hover .tooltiptext {
  visibility: visible;
} */
span.tooltiptext3.phoneNumber {
  margin-left: -184px;
  top: 34px;
  width: 376px;
}

span.tooltiptext2.roomCat {
  margin-left: -173px;
}

span.tooltiptext2.roomCat::before {
  left: 164px;
}

.tooltip .tooltiptext::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 13px 8px 0;
  border-color: transparent #0c1e47 transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: -14px;
  left: 190px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

span.tooltiptext2._actionDownload::before {
  margin-left: -140px;
}

span.tooltiptext2.tagInfo::before {
  left: 131px;
}

span.tooltiptext2.tagInfo {
  margin-left: -140px;
  width: 460px;
}

.tooltip .tooltiptext.inviteadmin {
  margin-left: -334px;
}

.tooltip .tooltiptext.inviteadmin::before {
  left: 327px;
}

span.tooltiptext2.personal {
  width: 300px;
}

span.tooltiptext2.teamtags {
  margin-left: -142px;
}

span.tooltiptext2.teamtags::before {
  left: 133px;
}

/* Tooltip Team management*/
.tooltip1 {
  position: relative;
  display: inline-block;
}

.tooltip1 .tooltiptext1 {
  /* visibility: hidden; */
  width: 550px;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -197px;
  top: 35px;
  font-size: 14px;
  font-weight: normal;
  border: 2px solid #94b2ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

#root.dark .tooltip1 .tooltiptext1 {
  background-color: rgb(16 16 16 / 99%);
}

/* .tooltip1:hover .tooltiptext1 {
  visibility: visible;
} */
.tooltip1 .tooltiptext1::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 13px 8px 0;
  border-color: transparent #0c1e47 transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: -16px;
  left: 190px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.tooltip1 .info_tooltip_new {
  margin-left: 2px;
}

/* Tooltip Room create*/
.tooltip2 {
  position: relative;
  display: inline-block;
}

.tooltip2 .tooltiptext2 {
  /* visibility: hidden; */
  width: 405px;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px 20px 10px 20px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -200px;
  top: 35px;
  font-size: 14px;
  font-weight: normal;
  border: 2px solid #94b2ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  white-space: pre-wrap;
}

#root.dark .tooltip2 .tooltiptext2 {
  background-color: rgb(16 16 16 / 99%);
}

/* .tooltip2:hover .tooltiptext2 {
  visibility: visible;
} */
.tooltip2 .tooltiptext2::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 13px 8px 0;
  border-color: transparent #0c1e47 transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: -16px;
  left: 190px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.tooltip2 .info_tooltip_new {
  margin-left: 2px;
}

span.info_tooltip_new.info_tooltip4 {
  top: 3px;
  position: relative;
}

.tooltip3 {
  position: relative;
  display: inline-block;
}

.tooltip3 .tooltiptext3 {
  /* visibility: hidden; */
  width: 400px;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -187px;
  top: 36px;
  font-size: 14px;
  font-weight: normal;
  border: 3px solid #94b2ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

#root.dark .tooltip3 .tooltiptext3 {
  background-color: rgb(16 16 16 / 99%);
}

/* .tooltip3:hover .tooltiptext3 {
  visibility: visible;
} */
.tooltip3 .tooltiptext3::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 13px 8px 0;
  border-color: transparent #0c1e47 transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: -16px;
  left: 176px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.tooltip2 .tooltiptext2.workspace {
  margin-left: -258px;
}

.tooltip2 .tooltiptext2.workspace::before {
  left: 248px;
}

.tooltip4 {
  position: relative;
  display: inline-block;
}

span.info_tooltip_new.info_tooltip_phone {
  margin-left: 2px;
  position: relative;
  top: 4px;
}

.tooltip4 .tooltiptext4 {
  /* visibility: hidden; */
  width: 300px;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 50%;
  margin-left: -138px;
  top: 30px;
  font-size: 14px;
  font-weight: normal;
  border: 2px solid #94b2ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

#root.dark .tooltip4 .tooltiptext4 {
  background-color: rgb(16 16 16 / 99%);
}

/* .tooltip4:hover .tooltiptext4 {
  visibility: visible;
} */
.tooltip4 .tooltiptext4::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 13px 8px 0;
  border-color: transparent #0c1e47 transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: -13px;
  left: 131px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.tooltip5 {
  position: relative;
  display: inline-block;
}

.tooltiptext5 {
  /* visibility: hidden; */
  width: 285px;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 94%;
  margin-left: -237px;
  top: 110px;
  font-size: 12px;
  font-weight: normal;
  border: 2px solid #94b2ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

#root.dark .tooltiptext5 {
  background-color: rgb(16 16 16 / 99%);
}

/* .tooltip5:hover .tooltiptext5 {
  visibility: visible;
} */

.tooltiptext5.conversionText {
  /* visibility: hidden; */
  width: 320px;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 637px;
  margin-left: -234px;
  top: 332px;
  font-size: 20px;
  font-weight: normal;
  border: 2px solid #0c1e47;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  height: 124px;
  z-index: 99999999999999;
}

span.tooltiptext5.conversionText::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 26px 8px 0;
  border-color: transparent #fafafa transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: 44px;
  left: -28px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.tooltipClose.conversionClose {
  /* background-image: url(./media/images/close_button_w.svg); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_exit outline.svg");
  background-repeat: no-repeat;
  background-size: 26px;
  position: absolute;
  top: 4px;
  width: 20px;
  height: 20px;
  right: 3px;
  background-color: #0c1e47;
  border-radius: 50%;
  background-position: 50%;
}

.tooltiptext5.prfileNavTooltip {
  /* visibility: hidden; */
  width: 320px;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 637px;
  margin-left: -234px;
  top: 62px;
  font-size: 20px;
  font-weight: normal;
  border: 2px solid #0c1e47;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  height: 124px;
  z-index: 99999999999999;
}

span.tooltiptext5.prfileNavTooltip::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 26px 8px 0;
  border-color: transparent #fafafa transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: 44px;
  left: -26px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

#root.dark span.tooltiptext5.prfileNavTooltip.forUserprofile::before {
  border-color: transparent #032e84 transparent transparent;
}

.tooltiptext5.userManagerTooltip {
  /* visibility: hidden; */
  width: 320px;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 637px;
  margin-left: -234px;
  top: 39px;
  font-size: 20px;
  font-weight: normal;
  border: 2px solid #0c1e47;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  height: auto;
  z-index: 99999999999999;
  padding-right: 16px;
}

.tooltiptext5.userManagerTooltip::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 26px 8px 0;
  border-color: transparent #fafafa transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: 44px;
  left: -28px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.tooltiptext5.teamTooltip {
  /* visibility: hidden; */
  width: 320px;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 637px;
  margin-left: -234px;
  top: 75px;
  font-size: 20px;
  font-weight: normal;
  border: 2px solid #0c1e47;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  height: auto;
  z-index: 99999999999999;
  padding-right: 16px;
}

.tooltiptext5.teamTooltip::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 26px 8px 0;
  border-color: transparent #fafafa transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: 44px;
  left: -28px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.tooltiptext5.roomCategoryTooltip {
  /* visibility: hidden; */
  width: 320px;
  background-color: #ffffff;
  color: #000000;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  left: 637px;
  margin-left: -234px;
  top: 112px;
  font-size: 20px;
  font-weight: normal;
  border: 2px solid #0c1e47;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  height: auto;
  z-index: 99999999999999;
  padding-right: 16px;
}

.tooltiptext5.roomCategoryTooltip::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 26px 8px 0;
  border-color: transparent #fafafa transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: 44px;
  left: -28px;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

#root.dark .tooltiptext5.roomCategoryTooltip {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #0c1e47;
  top: 100px;
}

#root.dark .tooltiptext5.userManagerTooltip {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #0c1e47;
  top: 30px;
}

#root.dark .tooltiptext5.teamTooltip {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #0c1e47;
  top: 68px;
}

#root.dark .tooltiptext5.prfileNavTooltip {
  background-color: #032e84;
  color: #ffffff;
  border: 2px solid #0c1e47;
}

#root.dark .tooltiptext5.prfileNavTooltip p {
  color: #ffffff !important;
}

#root.dark .tooltiptext5.conversionText {
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #0c1e47;
}

.blinkArea {
  animation: blinkMe 2s linear infinite;
  color: #fff;
  position: absolute;
  left: 250px;
  /* background-image: url(); */
  background-size: 26px;
  background-repeat: no-repeat;
  top: 166px;
  font-size: 16px;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #5c80d1;
}

@keyframes blinkMe {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

#root.dark .info_tooltip_new {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_info.svg");
}

.info_tooltip_new {
  /* background-image: url(./media/images/conv-i-new-bb.png); */
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_info.svg");
  background-repeat: no-repeat;
  background-size: 18px;
  display: inline-block;
  background-position: 50%;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  /* border: 2px solid #0c1e47; */
  margin-left: 6px;
  margin-top: 9px;
}

.info_tooltip_new.info_tooltip5 .info_tooltip_new {
  margin-left: 2px;
  position: relative;
  top: 3px;
}

span.info_tooltip_new.info_tooltip6 {
  margin-top: 0;
  position: relative;
  margin-left: 0px;
  top: 4px;
}

span.info_tooltip_new.info_tooltip5 {
  position: relative;
  top: 2px;
  margin-left: 2px;
}

.rg_container {
  position: relative;
}

/* span.info_tooltip_new.tooltipActivation {
  position: absolute;
  top: 7px;
  left: 395px;
  z-index: 9999;


} */
span.tooltiptext.tooltipActivationText {
  top: -12px;
  margin-left: 23px;
  z-index: 99999;
  left: 0px;
}

span.info_tooltip_new.tooltipActivation {
  position: absolute;
  top: -52px;
  left: 410px;
  z-index: 9999;
  -webkit-left: 390px !important;
  -ms-left: 410px !important;
  -moz-left: 410px !important;
}

span.info_tooltip_new.tooltipActivation:-moz-read-only {
  position: absolute;
  top: -52px;
  left: 410px;
  z-index: 9999;
}

span.tooltiptext.tooltipActivationText:-moz-read-only {
  top: -12px;
  margin-left: 126px;
  z-index: 99999;
  left: 12px;
}

.tooltip3.user_Management_title.phoneNumber:-moz-read-only {
  left: 184px !important;
}

.tooltip2.user_Management_title.newCss:-moz-read-only {
  left: 248px !important;
}

span.tooltiptext.tooltipActivationText::before {
  left: 372px;
}

.adminUserFilter {
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_filter2.svg");
  background-size: 20px;
  width: 30px;
  height: 26px;
  border: 2px solid transparent;
  margin: 0;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%;
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  pointer-events: none;
}

.adminUserFilterTask {
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_filter2.svg");
  background-size: 20px;
  width: 20px;
  height: 20px;
}

.adminUserFilter._tagf {
  background-image: url("./media/images/tag_blue.svg");
}

.adminUserFilter._searchFileIcon {
  background-image: url("./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_search.svg");
  background-size: 20px;
}

#root.dark .adminUserFilter._searchFileIcon {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_search.svg");
}

#root.dark .adminUserFilter {
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_filter2.svg");
}

#root.dark .adminUserFilter._tagf {
  background-image: url("./media/images/tag_fff.svg");
}

.usersTabList.active .adminUserFilter {
  pointer-events: auto;
}

.guestEmptyMsg {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 80px;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
}

.backToChat.closeBtn {
  float: right;
  /* width: 18px; */
  /* height: 18px; */
  background-image: url(./media/images/close_button.svg);
  background-repeat: no-repeat;
  background-size: 100%;
  cursor: pointer;
  position: absolute;
  right: 19px;
}

.TeamAddDetails {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start !important;
}

.createUrl_reset {
  box-sizing: border-box;
  width: auto;
  border: none;
  color: #028bff;
  font-size: 16px;
  font-weight: normal;
  cursor: pointer;
  margin-bottom: 6px;
  padding: 0 15px;
  align-items: center;
  display: flex;
  border-right: 1px solid;
}

.__react_component_tooltip.show {
  opacity: 1 !important;
}

.createUrl_reset:hover {
  color: #94b2ff !important;
}

.reset-link-div {
  display: flex;
  justify-content: space-between;
}

.reset-link-div .inputLabel {
  white-space: nowrap;
}

.reset-link-div .createUrl_reset {
  white-space: nowrap;
}

.reset-link-div .opt_icons {
  margin-bottom: 6px;
  height: auto;
}

.reset-link-div .conv_voice {
  background-size: 22px;
}

.linkAccount .unreadCounter {
  float: right;
  display: flex;
  margin-top: 8px;
}

button.sweetChange {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 22px;
  height: 22px;
  background-image: url("./media/images/close_button.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 22px;
  background-color: transparent;
}

p.warningBody {
  padding: 20px;
}

@media (max-width: 1300px) {
  p.FebBodyText2 {
    font-size: 1.1em;
  }
}

.loader_sec {
  height: 50px;
  border: none;
}

.convSeparetor_name.active::after {
  transform: rotate(180deg);
}

.loaderRight {
  width: calc(100% - 346px);
  z-index: 9;
}

.fileViewPopup {
  box-sizing: border-box;
  height: auto;
  width: 350px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 rgba(10, 31, 68, 0.08),
    0 8px 10px 0 rgba(10, 31, 68, 0.1);
  position: absolute;
  right: -3px;
  top: 36px;
  margin-top: 12px !important;
  padding: 8px 0px;
  float: left;
  z-index: 3;
}

.tagMainFiltr {
  background-color: #fff;
}

#root.dark .filterBy,
#root.dark .fileTypeLi {
  color: #fff;
}

#root.dark .fileTypeLi.list:hover {
  background-color: #404040;
}

.fileViewPopup .filterBy {
  box-sizing: border-box;
  display: block;
  width: 100%;
  color: #53627c;
  font-family: var(--F1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
  padding: 10px 16px;
  position: relative;
}

#root.dark .fileViewPopup {
  background-color: #181818;
  border: 1px solid #d6d6d635;
}

.fileTypeLi {
  box-sizing: border-box;
  display: block;
  list-style: none;
  width: 100%;
  color: #53627c;
  font-family: var(--F1);
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  padding: 10px 18px;
  position: relative;
}

.fileTypeLi .dateLabel {
  text-align: left;
  display: block;
  margin-bottom: 8px;
  width: 100%;
  color: #53627c;
  font-family: var(--F1);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.fileTypeLi input {
  width: 100%;
  height: 45px;
  padding: 5px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  font-size: 14px;
}

._tagIconD {
  background-color: #e4e4e4;
  position: absolute;
  height: 100%;
  width: 10px;
  left: 0;
  top: 0;
  /* border-bottom: 1px solid #f3e8e8; */
  /* background-image: url("./media/images/tag_fff.svg");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: 50%; */
}

li.fileTypeLi.list._tagD {
  padding-left: 21px;
  border-bottom: 1px solid #f3e8e8;
}

li.fileTypeLi.list._tagD.selected {
  /* background-color: #0c1e47; */
  /* color: #ffffff; */
  background-image: url(./media/images/Check-Circle.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: 96%;
}

.dateFilterCancel {
  width: 46%;
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
}

.dateFilter {
  width: 46%;
  display: inline-flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  background-color: #0c1e47;
  color: #fff;
}

.fileTypeLi.list:hover {
  background-color: #0c1e47;
  color: #ffffff;
}

.fileTypeLi.list.tagMainFilterLi:hover {
  background-color: #0c1e470f;
  color: #53627c;
}

.sharedIcon {
  min-width: 28px;
  min-height: 28px;
  overflow: unset;
  display: block;
  margin: 2px;
  /* background-color: #0c1e47;
  border-radius: 50%; */
  cursor: pointer;
  /* border: 2px solid #fff; */
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  /* background-image: url("./media/images/share_fff.svg"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_share.svg");

  background-size: 17px;
}

.starredIcon {
  min-width: 28px;
  min-height: 28px;
  overflow: unset;
  display: block;
  margin: 2px;
  /* background-color: #0c1e47;
  border-radius: 50%; */
  cursor: pointer;
  /* border: 2px solid #fff; */
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-image: url("./media/images/blueStar/WorkFreeli_Icons_LightBlue_Star.svg");
  background-size: 17px;
}

/* .sharedIcon {
  width: 28px;
  height: 28px;
  overflow: unset;
  display: block;
  margin: 5px;
  background-color: #0c1e47;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 12px;
  background-image: url("./media/images/share_fff.svg");
}

.starredIcon {
  width: 28px;
  height: 28px;
  overflow: unset;
  display: block;
  margin: 5px;
  background-color: #0c1e47;
  border-radius: 50%;
  cursor: pointer;
  border: 2px solid #fff;
  box-sizing: border-box;
  background-repeat: no-repeat;
  background-position: 50% 40%;
  background-image: url(./media/images/star-fill.svg);
  background-size: 15px;
} */

.fileDiv {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fileDiv img,
.fileDiv audio,
.fileDiv video {
  max-width: 100%;
  max-height: 100%;
}

.fileDiv .download_btn {
  background: #003466;
  color: #fff;
  padding: 14px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;
  list-style: none;
}

.download_btn::after {
  content: "\f358";
  font-family: "FontAwesome";
  font-size: 18px;
  margin-left: 12px;
}

/* .sharedIcon, */
.alreadyShared .fileOpts.share_opts {
  /* background-image: url(./media/images/share_green.svg); */
  /* background-image: url("./media/images/icons/other/Red/SVG/WorkFreeli_Icons_Red_share.svg"); */
  background-image: url("./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_share.svg");
}

.filePanelHeadSection .miniItem {
  /* position: absolute; */
  top: auto;
  /* right: 56%; */
}

.sweet-alert p {
  justify-content: right !important;
}

input[type="email"]:disabled,
input[type="text"]:disabled {
  background-color: #d8d8d84d !important;
}

#root.dark .rgInputf[type="email"]:disabled,
#root.dark .rgInputf[type="text"]:disabled {
  color: #ffffff;
}

.emptyMsg_container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0c1e47;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
}

#root.dark .emptyMsg_container {
  color: #ffffff;
}

.removeAndChange {
  position: absolute;
  width: 200px;
  text-align: center;
  left: 37%;
  display: block;
}

.removeButton {
  background-color: #e82a87;
  border-radius: 7px;
  border: 1px solid #e82a87;
  color: #ffffff;
  padding: 1px 4px 2px 4px;
  cursor: pointer;
  position: absolute;
  left: -7px;
  top: 45px;
  width: 65px;
  font-size: 12px;
}

.changeButton {
  background-image: url("./media/images/camera-icon-white-png.png");
  background-repeat: no-repeat;
  /* background-size: cover; */
  width: 44px;
  height: 44px;
  background-size: 44px;
  position: absolute;
  top: -10px;
  cursor: pointer;
}

label.img-div .content-overlay {
  background: rgb(0 0 0 / 20%);
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  transition: all 0.4s ease-in-out;
  width: 200px;
  height: 200px;
  border-radius: 100%;
}

label.img-div:hover .content-overlay {
  opacity: 1;
}

.single_file .sharedBtn {
  height: 22px;
  width: 60px;
  border-radius: 13px;
  background-color: #0b1f47;
  margin-right: 6px;
  font-size: 13px;
  padding: 0 12px 2px;
  font-weight: 400;
  letter-spacing: 0;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  position: relative;
  top: 4px;
}

.leftbar_loader {
  height: 100px;
  background-color: transparent;
  border: none;
}

.toasterDismiss {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("./media/images/close_button.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 18px;
  background-color: transparent;
  cursor: pointer;
  color: red !important;
}

.btnFor_filter {
  padding: 8px 16px;
  box-sizing: border-box;
  margin-top: 12px;
  background-color: #0c1e47;
  color: #fff;
  cursor: pointer;
  border-radius: 14px;
}

span.fileTag._private_ico {
  background-image: url(./media/images/png/lock-icon.png);
  background-color: rgb(233, 30, 99);
}

.for_loader {
  min-height: 100px;
  border: none;
  background-color: transparent;
}

.sweet-alert h2 {
  text-align: left !important;
  font-size: 18px !important;
  position: relative !important;
  top: -9px;
  color: #0b1f47;
  font-family: var(--F1);
}

.sweet-alert p {
  font-size: 16px;
}

.createTeamModalBody .css-tlfecz-indicatorContainer,
.createTeamModalBody .css-1gtu0rj-indicatorContainer {
  display: none;
}

.createTeamModalBody .css-1okebmr-indicatorSeparator+.css-tlfecz-indicatorContainer,
.createTeamModalBody .css-1okebmr-indicatorSeparator+.css-1gtu0rj-indicatorContainer {
  display: block;
}

.InfiniteScrollLoader {
  width: 100%;
  height: 60px;
  background-image: url("./media/images/Spinner.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100px;
  display: flex;
  background-color: transparent;
}

span._fileIcon {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

span._fileIcon img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.tag_create_btn {
  height: 48px;
  width: 135px;
  border-radius: 4px;
  background-color: #0c1e47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  padding: 0 15px;
  float: right;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.btnAction.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.btnAction {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_loader {
  color: transparent !important;
}

li.conv_list.filepanel._open::after {
  content: "";
  background-image: url(./media/images/down_angle.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 18px;
  height: 14px;
  display: flex;
  transition: all 0.3s ease;
  position: absolute;
  right: 18px;
}

li.conv_list.filepanel._close::after {
  content: "";
  background-image: url(./media/images/down_angle.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  width: 18px;
  height: 14px;
  display: flex;
  transition: all 0.3s ease;
  position: absolute;
  right: 18px;
  transform: rotate(-90deg);
}

li.conv_list.filepanel._leftTag {
  background-image: url(./media/images/tag_fff.svg);
}

.tag_color {
  width: 25px;
  height: 25px;
  margin: 4px 4px 0px 0px;
  border-radius: 6px;
}

._tagColor {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ColorList {
  width: 100%;
  height: auto;
  margin-bottom: 8px;
  float: left;
}

.ColorList .color_desgin {
  width: 25px;
  height: 25px;
  margin: 4px 4px 0px 0px;
  border-radius: 6px;
  float: left;
  cursor: pointer;
}

.ColorList .color_desgin.active {
  background-image: url(./media/images/circleCheckgreen.svg);
  width: 40px;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50%;
  cursor: default;
}

._afileName span.fileTag:first-child {
  margin-left: 0px;
}

.file_nameSection {
  display: flex;
}

.selectedParticipants.tag_container_mini {
  margin-top: 0px;
}

.tag_container_mini .miniItem {
  height: 24px;
}

.tag_container_mini .miniItem_name {
  margin-left: 4px;
  font-size: 14px;
}

.tag_container_mini .miniItem_remove {
  width: 18px;
  height: 18px;
}

.tag_container_mini .miniItem_remove::before {
  font-size: 10px;
}

span.sortIcons {
  width: 30px !important;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

}

.asce .sortIcons:before {
  content: "\f161";
  font-family: "FontAwesome";
  margin-left: 4px;
  font-size: 16px;
  position: absolute;
  font-weight: 600;
  left: 0px;
}

.desc .sortIcons:before {
  content: "\f160";
  font-family: "FontAwesome";
  margin-left: 4px;
  font-size: 16px;
  position: absolute;
  left: 0px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.sort_active .sortIcons::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  left: 3px;
  top: -17px;
}

.muteModalHead span.closeModal {
  right: 30px;
}

.tagModalHead span.closeModal {
  right: 28px;
}

.createUserModalHead span.closeModal {
  right: 30px;
}

.createTeamModalHead span.closeModal {
  right: 30px;
}

.passwordModalHead span.closeModal {
  right: 30px;
}

#root.dark .fileTypeLi input,
#root.dark input._inputBar {
  background: #181818;
  color: #ffffff;
}

#root.dark .fileTypeLi .dateLabel {
  color: #fff;
}

/* span.sortIcons:before {
    content: "\f161";
    font-family: "FontAwesome";
    margin-left: 4px;
    font-size: 16px;
    position: absolute;
    left: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
} */
.userTableHead li.sort_active .sortIcons:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  left: 3px;
  top: 3px;
}

.inputClassRoom .flag-dropdown {
  border: none !important;
}

.inputClassRoom .selected-flag:hover {
  background-color: #f3f4f5 !important;
}

.inputClassRoom .country-list {
  top: -160px !important;
  height: 150px !important;
}

.react-tel-input .form-control {
  position: relative;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-left: 0;
  background: #ffffff;
  line-height: 25px;
  outline: none;
  width: 100% !important;
  /* border: 1px solid #d6d6d6 !important;
  border-radius: 4px !important; */
  font-family: var(--F1) !important;
  /* height: 50px !important; */
  /* padding: 14px 16px !important; */
  font-size: 16px !important;
  padding-left: 50px !important;
}

.react-tel-input .special-label {
  display: none !important;
}


/* .react-tel-input .form-control:focus {
  box-shadow: unset !important;
} */
.starredFile {
  background-image: url("./media/images/star-thin_black.svg");
  background-size: 18px;
  background-position: 50%;
  width: 20px !important;
  height: 20px !important;
  background-repeat: no-repeat;
  /* margin-left: 10px; */
  position: relative;
  top: -1px;
  visibility: hidden;
}

.taskListTableBody ul:hover .starredFile {
  visibility: visible;
}

#root.dark .starredFile {
  background-image: url("./media/images/star-thin.svg");
}

.starredFile.active {
  background-image: url("./media/images/star-fill_black.svg");
  visibility: visible;
}

#root.dark .starredFile.active {
  background-image: url("./media/images/star-fill.svg");
}

/* .taggedDesign .starredFile {
  position: absolute;
  top: unset;
  right: 5px;
} */

/* .fileActiveActions {
  position: absolute;
  right: 1px;
  top: 7px;
  width: auto;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
} */

.fileActiveActions {
  position: absolute;
  right: -4px;
  top: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  right: 0px !important;
  align-items: center;
  border-left: 1px solid #cfd8dc;
  justify-content: center;
}

.fileActiveActions.file_g {
  top: unset;
  right: 21px;
}

.btnFor_filter.for_head {
  margin-top: 0px;
  font-size: 14px;
  padding: 4px 12px;
  border-radius: 8px;
  background-color: #e91e62;
  border: none;
}

.fileNameOptions {
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: space-between;
  width: auto !important;
}

.fileNameOptions .sharedIcon {
  display: inline-flex;
  width: auto !important;
}

.fileNameOptions span {
  margin: 0 6px;
}

#root.dark .single_file {
  background-color: transparent;
  border: 1px solid #d6d6d635;
}

#root.dark .file_hover_option {
  border: 1px solid #d6d6d620;
  background-color: #181818;
  padding-right: 5px;
  padding-left: 5px;
}

span.imgPreviewTags {
  /* position: fixed; */
  bottom: 88px;
  width: calc(100% - 346px);
  right: 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  justify-content: flex-start;
}

/* Support and billing css*/
.supportAndBillingContainer {
  width: 100%;
  background-color: transparent;
  padding: 0 40px;
  margin-top: 30px;
  overflow-y: auto !important;
  padding-bottom: 100px;
}

.supportBillingDivs {
  display: flex;
  width: 100%;
}

.supportAndBillingPlan {
  width: 100%;
  max-width: 40%;
  height: 700px;
  border: 1px solid #ccc;
  /* box-shadow: 1px 0px 4px 1px rgb(0 0 0 / 30%); */
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  position: relative;
  background-color: #f6f9fb;
}

.supportAndBillingSubsctiption {
  width: 100%;
  max-width: 32%;
  height: 700px;
  border: 1px solid #ccc;
  margin-left: 40px;
  border-radius: 7px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  position: relative;
  /* box-shadow: 1px 0px 4px 1px rgb(0 0 0 / 30%); */
}

.supportAndBillingHead {
  background-color: #0c1e47;
  color: #ffffff;
  padding: 25px 30px;
  border-radius: 7px 7px 0px 0px;
  position: relative;
  width: 100%;
  /* left: -1.5px; */
}

.supportAndBillingHead .currency {
  top: 60px;
  position: absolute;
  right: 25px;
  z-index: 99999;
  font-size: 20px;
  color: #e8e8e8;
}

.supportAndBillingHead p {
  color: #94b2ff;
  font-size: 16px;
}

.supportAndBillingHead h3 {
  font-size: 28px;
  font-weight: 500;
  color: #e8e8e8;
}

/* .supportAndBillingUsers {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
} */
.supportAndBillingUsers .sbUsersIcon img {
  width: 34px;
  margin-right: 20px;
}

span.sbUserNumber {
  color: #0c1e47;
  font-size: 16px;
  font-weight: 600;
}

span.sbUsersTotal {
  color: #9b9b9b;
  font-size: 14px;
  margin-left: 4px;
  font-weight: 600;
}

.supportAndBillingUsers {
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.supportBillingBottom {
  display: flex;
  position: absolute;
  bottom: 0;
  padding: 15px 30px;
  background-color: #0c1e47;
  width: 100.3%;
  border-radius: 0 0 7px 7px;
  left: -1px;
  cursor: pointer;
}

.upgradeText {
  font-size: 16px;
  font-weight: 600;
  color: #e8e8e8;
}

.upgradeIcon {
  position: absolute;
  right: 25px;
  background-image: url("./media/images/back_white.svg");
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  cursor: pointer;
}

.supportAndBillingStorage {
  padding: 40px 30px;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #eee;
  position: relative;
}

.supportAndBillingStorage .progress {
  width: 70%;
  position: absolute;
  right: 20px;
  top: 62px;
  height: 14px;
  border-radius: 25px;
}

.archive_room_con:last-child {
  margin-bottom: 10px;
}

.supportAndBillingStorage .progress .progress-bar {
  background-color: #0c1e47;
  border-radius: 25px;
}

span.sbUsersIcon {
  position: relative;
  top: 4px;
}

p.sbStorage {
  color: #0c1e47;
  font-size: 16px;
  font-weight: 600;
  padding-bottom: 12px;
}

span.sbUserNumberSt {
  color: #0c1e47;
  font-size: 16px;
  font-weight: 600;
}

.storageLeftarea {
  /* width: 185px; */
  width: 220px;
  display: inline-block;
}

.storagePrograsssBar {
  width: 75%;
  display: inline-block;
  padding-left: 20px;
}

.light-grey-area {
  background-color: #e1e4e8;
  border-radius: 7px;
  margin-top: 42px;
  height: 16px;
}

.lightBlueArea {
  width: 75%;
  background-color: #0c1e47;
  height: 16px;
  border-radius: 7px;
}

.subsctiptionPayment {
  font-size: 16px;
  color: #0c1e47;
  font-weight: 600;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #e1e4e8;
  border-radius: 7px 7px 0 0;
}

.recurringbillingArea {
  display: flex;
  width: 100%;
  padding-top: 20px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.recurringbilling {
  width: 60%;
  padding-left: 30px;
  display: inline-block;
}

.editRecurringbilling {
  width: 50%;
  padding-right: 30px;
  text-align: left;
  display: inline-block;
  position: relative;
}

p.editrecurringbillingText {
  position: absolute;
  right: 30px;
  border-bottom: 1px solid #9b9b9b;
  color: #9b9b9b;
  cursor: pointer;
}

p.recurringbillingText {
  color: #0d2150;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.2px;
  /* font-family: "SourceSansPro"; */
  font-family: "Poppins" !important;

}

.recurringbillingNot {
  font-size: 18px;
  letter-spacing: 1px;
  padding-top: 10px;
  color: #9b9b9b;
}

.recurringbillingArea.freePlan {
  border-bottom: unset;
}

.freePlanActiveArea {
  padding: 0 30px;
  margin-top: 10px;
  color: #bc7a81;
  font-weight: 600;
  font-size: 20px;
}

.closeAccount {
  float: right;
}

.subsctiptionPaymentBotton {
  position: absolute;
  bottom: 0;
  padding: 15px 30px;
  background-color: #f6f9fb;
  border-top: 1px solid #eee;
  width: 100%;
  border-radius: 0 0 7px 7px;
}

span.viewHistory {
  float: right;
  color: #0cbcd3;
  text-decoration: underline;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  cursor: pointer;
}

span.subscriptionHistory {
  color: #0d2150;
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 0.2px;
  /* font-family: "SourceSansPro"; */
  font-family: "Poppins";
}

.UpgradePlanLeftArea {
  display: flex;
  width: 100%;
  max-width: 650px;
  border-top: 1px solid #eee;
  padding-top: 20px;
}

.UpgradePlanConter {
  background-color: #0c1e47;
  width: 32px;
  height: 32px;
  color: #ffffff;
  border-radius: 50%;
  padding: 4px 10px;
  text-align: center;
  font-size: 22px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.selectUpgradePlan {
  display: inline-block;
  font-size: 30px;
  font-weight: 500;
  color: #0c1e47;
  margin-top: -4px;
}

.currentPlan {
  display: inline-block;
  margin-left: auto;
  color: #9b9b9b;
  text-align: right;
  font-size: 18px;
  order: 2;
}

#root.dark .currentPlan {
  color: #e8e8e8;
}

.firstLeftArea {
  display: flex;
  /* display: block; */
  padding-bottom: 25px;
  border-bottom: 1px solid #eee;
}

.essentialLeftArea {
  background-color: #0c1e47;
  color: #ffffff;
  width: 220px;
  padding: 25px;
  border-radius: 7px 0px 0px 7px;
}

.secoundLeftArea {
  display: flex;
  background-color: #eef5f9;
  border: 1px solid #e4edf2;
  margin-top: 25px;
  border-radius: 7px;
  margin-bottom: 25px;
  position: relative;
}

.essentialText {
  font-size: 36px;
  color: #e8e8e8;
}

.essentialUserCurrency {
  color: #3792ff;
  font-size: 18px;
  padding-top: 8px;
  letter-spacing: 0.5px;
}

.numberOfUsers {
  margin-top: 30px;
}

.numberOfUsersInput {
  border: unset;
  width: 145px;
  height: 60px;
  border-radius: 7px;
  position: relative;
}

.everymonth {
  font-size: 14px;
  color: #94b2ff;
}

.essentialRightArea {
  padding-left: 40px;
  padding-top: 30px;
  padding-bottom: 30px;
}

ul.essentialUL li {
  list-style: none;
  color: #0c1e47;
  font-size: 12px;
  padding-bottom: 3px;
}

ul.essentialUL li::before {
  content: "";
  display: inline-block;
  height: 16px;
  width: 25px;
  background-image: url("./media/images/tick.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position-y: 4px;
}


.markRound {
  width: 30px;
  height: 30px;
  background: #e8e8e8;
  border: 2px solid #0c1e47;
  box-sizing: border-box;
  float: left;
  border-radius: 100%;
  position: absolute;
  right: 25px;
  z-index: 1;
  cursor: pointer;
  display: none;
  top: 45px;
}

.markRound.marked {
  display: block;
  /* background-image: url(./media/images/circleCheckgreen.svg); */
  background-image: url("./media/images/greenTick.png");
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  background-size: 60px;
  border: unset;
}

.planBoxArea {
  display: flex;
  width: 100%;
  margin-top: 25px;
}

.planBox {
  width: 33%;
  background-color: #0c1e47;
  position: relative;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.planBox:hover>.markRound {
  display: block;
}

.storageTextGB {
  color: #e8e8e8;
  font-size: 32px;
}

.storageTextGBpara {
  color: #e8e8e8;
  padding-top: 5px;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.activePlan {
  top: 48px;
  position: absolute;
  right: 5px;
}

.supportAndBillingContainer.upgradePlan {
  display: flex;
}

.UpgradePlanRightArea {
  margin-left: 70px;
  width: 100%;
  max-width: 400px;
  border-radius: 7px;
  border: 1px solid #ccc;
  /* height: auto;*/
  height: 610px;
  position: relative;
}

.orderSummary {
  padding: 20px 25px;
  background-color: #0c1e47;
  color: #e8e8e8;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 7px 7px 0 0;
}

.essentialPlanRightside {
  display: flex;
  width: 100%;
  padding: 30px 25px;
  border-bottom: 1px solid #eee;
}

.essentialsPlanDiv {
  width: 50%;
}

.essentialsPlanCost {
  width: 50%;
  display: block;
}

.essentialsPlanCost h3 {
  text-align: right;
  color: #0c1e47;
  font-size: 16px;
  letter-spacing: 0.5px;
}

#root.dark .essentialsPlanCost h3 {
  color: #e8e8e8;
}

.essentialsPlanCost p {
  text-align: right;
  color: #0c1e47;
  font-size: 14px;
  letter-spacing: 0.2px;
}

#root.dark .essentialsPlanCost p {
  color: #e8e8e8;
}

.essentialsPlanDiv h3 {
  color: #0c1e47;
  font-size: 14px;
  letter-spacing: 0.5px;
  width: 150px;
}

.essentialsPlanDiv p {
  color: #0c1e47;
  font-size: 14px;
  letter-spacing: 0.2px;
}

#root.dark .essentialsPlanDiv p {
  color: #e8e8e8;
}

.essentialPlanTotalText {
  width: 50%;
  font-size: 16px;
  color: #0c1e47;
  font-weight: 600;
}

.essentialPlanTotal {
  display: flex;
  padding: 30px 25px;
  border: 1px solid #0c1e47;
  border-radius: 7px;
  /* width: 104%; */
  width: 100%;
  z-index: 9999;
  background-color: #eef5f9;
  margin-left: 0%;
  cursor: pointer;
}

.essentialPlanTotal:hover {
  width: 104%;
  margin-left: -2%;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}

.essentialsPlanTotalCost {
  width: 50%;
}

.essentialsPlanTotalCost h3 {
  color: #0c1e47;
  font-size: 18px;
  letter-spacing: 0.5px;
  text-align: right;
}

.essentialsPlanTotalCost p {
  text-align: right;
  color: #0c1e47;
  font-size: 14px;
  letter-spacing: 0.2px;
}

.essentialPlanBottomside {
  width: 100%;
  position: absolute;
  bottom: 12px;
}

.proceedToPayment {
  width: 94%;
  text-align: center;
  background-color: #0c1e47;
  color: #e8e8e8;
  margin-left: 3%;
  padding: 10px;
  border-radius: 7px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
  cursor: pointer;
}

h3.billingAddress {
  color: #002e98;
  font-size: 18px;
  letter-spacing: 0.3px;
}

.PlanLeftAreaInner.checkout {
  width: 100%;
}

.PlanLeftAreaInner {
  width: 100%;
}

.billingCheckout {
  border-bottom: 1px solid #ddd;
  padding-bottom: 25px;
}

.billingAddressForm {
  margin-top: 20px;
}

.billingAddressFormInput {
  display: flex;
  margin-bottom: 15px;
}

.billingInput50 {
  width: 50%;
}

.billingInput100 {
  width: 100%;
}

.billingInput25 {
  width: 33.3%;
}

.billingAddressFormInput input.isDisable {
  cursor: none;
  width: 100%;
  height: 45px;
  padding: 5px 16px;
  border: none;
  border-color: none;
  border-radius: 5px;
  font-size: 16px;
  background-color: #d8d8d8;

}

.billingAddressFormInput input {
  width: 100%;
  height: 45px;
  padding: 5px 16px;
  border: 2px solid rgb(230, 230, 230);
  border-radius: 5px;
  font-size: 16px;
}

/* .billingAddressFormInput input:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%);
} */

.inputGroup input:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}


.cu_inputGroup input:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%);
}

.cu_inputGroup_select input:focus {
  outline: 0;
  border: none !important;
  box-shadow: none !important;
}

.css-t3ipsp-control:hover {
  border: none !important;
}

.css-t3ipsp-control {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}

/* 
.crTeam_inputGroup input:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%);
} */

/* input.gSearchInput:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%);
} */

.billingAddressFormInput input::placeholder {
  color: #77787e;
}

.inputLabel {
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 0px;
}

button.paymentButton {
  width: 100%;
  background-color: transparent;
  height: 40px;
  outline: unset;
  border: 2px solid #002e98;
  border-radius: 7px;
  color: #002e98;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
}

button.paymentButton.btnActive {
  color: #e8e8e8;
  background-color: #0c1e47;
}

.paymentMethod {
  border-top: 1px solid #eee;
  padding-top: 20px;
  /* margin-top: 40px; */
  margin-top: 0px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.UpgradePlanLeftArea.review {
  border: unset;
  padding-top: 0px;
  margin-top: -6px;
  display: inline-block;
}

.reviewArea {
  display: flex;
  background-color: #eef5f9;
  height: 120px;
  padding: 20px;
  width: 100%;
  border-radius: 7px;
  margin-bottom: 25px;
  position: relative;
  margin-top: 8px;
}

.reviewAreaBilling {
  display: flex;
  background-color: #eef5f9;
  height: 120px;
  padding: 20px;
  width: 92%;
  border-radius: 7px;
  margin-bottom: 25px;
  position: relative;
  margin-top: 8px;
}

.billingreview {
  margin-right: 0px;
}

.billingReviewName {
  font-size: 18px;
  color: #002e98;
  font-weight: 500;
  margin-left: 50px;
}

.billingReviewAddress {
  font-size: 16px;
  color: #002e98;
  font-weight: lighter;
  margin-left: 50px;
}

.editAddress {
  color: #0c1e47;
  margin-top: 31px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

p.cardExpirayDate {
  font-size: 14px;
  font-weight: 100;
}

.cardImage {
  position: absolute;
  right: 25px;
  top: 50px;
}

p.congratulationsText {
  padding-top: 50px;
  font-size: 24px;
  font-weight: 600;
  color: #0c1e47;
}

#root.dark p.congratulationsText {
  color: #e8e8e8;
}

#root.dark p.congratsPara {
  color: #e8e8e8;
}

p.congratsPara {
  padding: 0px 90px;
  color: #002e98;
  font-size: 18px;
  padding-bottom: 100px;
}

.usersData {
  width: 165px;
  height: 50px;
  background-color: #e8e8e8;
  border-radius: 7px;
  /* padding-left: 15px;
  padding-top: 8px; */
}

span.uparrow {
  background-image: url("./media/images/uparrow.png");
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 50%;
  position: absolute;
  left: 140px;
  top: 3px;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

span.uparrow:hover {
  background-image: url("./media/images/downArrow.png");
  transform: rotate(180deg) !important;
  background-size: 33px;
  left: 133px;
  top: 0px;
  width: 33px;
  height: 30px;
}


span.downArrowLeftSide {
  position: relative;
  top: 0px;
}

span.downarrow {
  /*background-image: url('./media/images/downArrow.png');
  */
  background-image: url("./media/images/uparrow.png");
  transform: rotate(180deg) !important;
  background-repeat: no-repeat;
  background-size: 25px;
  background-position: 50%;
  position: absolute;
  left: 138px;
  top: 24px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

span.downarrow:hover {
  background-image: url("./media/images/downArrow.png");
  transform: rotate(0deg) !important;
  background-size: 30px;
  left: 137px;
  top: 21px;
  width: 30px;
  height: 30px;
}

span.usersStorage {
  color: #0c1e47;
  font-size: 24px;
  letter-spacing: 0.5px;
  text-align: right;
  font-weight: 800;
  padding-right: 10px;
}

span.usersnum {
  color: #0c1e47;
  font-size: 18px;
  letter-spacing: 0.5px;
  text-align: right;
  font-weight: 500;
}

span.supportBillingInactive {
  font-size: 14px;
  font-weight: bold;
  color: #9ba5bc;
  cursor: pointer;
}

span.supportBillingActive {
  color: #0c1e47;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

span.rightArrow {
  margin-left: 2px;
  margin-right: 2px;
  background-image: url("./media/images/rightArrow.png");
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-repeat: no-repeat;
  position: relative;
  top: 4px;
}

/*=====End Billing parts css ======*/
.scrollBarClass>div:last-child>div {
  background-color: rgba(193, 193, 193, 0.8) !important;
}

.galleryB .infinite-scroll-component {
  overflow-y: hidden !important;
}

.secrectUserName {
  display: flex;
  flex-wrap: wrap;
}

.secrectUserName li {
  margin: 2px 5px 2px 0;
  background-color: #85797b;
  color: #fff;
  border-radius: 4px;
  padding: 2px 8px 2px 8px;
  cursor: pointer;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  height: 22px;
  line-height: 18px;
  float: left;
}

.secrectUserName li.withTooltip:hover {
  /* background-color: #0c1e47; */
  background-color: #85797b;
}

.secrectUserName li.withTooltip.inactive_part {
  background-color: #c1b6b8;
  cursor: default;
}

.secrectUserName li.withTooltip.inactive_part:hover {
  background-color: #c1b6b8;
  cursor: default;
}

.allfilesTags {
  display: flex;
  white-space: normal !important;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
}

.allfilesTags span {
  width: auto !important;
  font-size: 12px;
  margin: 2px 2px 2px 0;
}

/* .dot_leftbar{
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: red;
  position: absolute;
  left: 222px;
  top: 16px;
} */
.dot_leftbar {
  width: 18px;
  height: 20px;
  border-radius: 0;
  background-color: transparent;
  position: absolute;
  right: 19px;
  /* top: 9px; */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_flag.svg");
  background-repeat: no-repeat;
  background-position-x: 51%;
  background-size: 38%;
  background-position-y: 51%;
}

.unreadCounter.black_white {
  /* background-color: #fff;
  color: #000000; */
  background-color: transparent;
  color: #ffffff00;
  font-size: 24px;
  background-image: url(./media/images/mention.webp);
  background-repeat: no-repeat;
  background-position: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  right: 48px;
  top: 6px;
  background-size: 24px;
}

/*Admin tool tip design area*/
/* ul.list_container.settingsList {
  width: 80%;
  position: relative;
} */
.clickTooltip1 {
  position: absolute;
  color: #0c1e47;
  right: 42px;
  top: 6px;
  font-size: 18px;
  background-color: #f7eb07;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  padding: 0px 0px;
  font-weight: 600;
  cursor: pointer;
  line-height: 24px;
}

.clickTooltip2 {
  position: absolute;
  color: #0c1e47;
  right: -48px;
  top: 42px;
  font-size: 18px;
  background-color: #f7eb07;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  padding: 0px 0;
  font-weight: 600;
  cursor: pointer;
}

.clickTooltip3 {
  position: absolute;
  color: #0c1e47;
  right: -48px;
  top: 76px;
  font-size: 18px;
  background-color: #f7eb07;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  text-align: center;
  padding: 0px 0;
  font-weight: 600;
  cursor: pointer;
}

.user_msg.rootReply .single_file:hover .file_hover_option {
  display: none !important;
}

.createUser_email.error+.errorLabel {
  display: block;
}

span.downloadingFileName {
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
}

.inputGroup.conferenceLink {
  position: relative;
}

img.copyMe {
  position: absolute;
  width: 25px;
  right: 52px;
  top: 44px;
  cursor: pointer;
}

.copyIcon {
  width: 30px;
}

.inputGroup.roomConferenceLink {
  position: relative;
}

img.copyMeLink {
  position: absolute;
  width: 30px;
  right: 45px;
  top: 50px;
  cursor: pointer;
}

img.copyShareLink {
  position: absolute;
  width: 20px;
  right: 52px;
  top: 82px;
  cursor: pointer;
}

.refiltertag {
  width: 16px;
  height: 16px;
  display: flex;
  float: right;
  background-color: transparent;
  border-radius: 50%;
  margin-left: 5px;
}

.refiltertag::before {
  content: "\f057";
  font-family: var(--FA);
  font-weight: 600;
  color: var(--NewRed);
  background-color: #fff;
  font-size: 16px;
  border-radius: 100%;
}

.refiltertag._private::before {
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.upgrade_section {
  height: auto;
  background-color: #0d2150;
  width: 550px;
  position: relative;
  left: -42px;
  top: -10px;
  margin-bottom: 12px;
  padding: 30px 50px;
  z-index: 1;
  border-radius: 3px;

}

.upgrade_title {
  /* line-height: 24px; */
  /* font-size: 20px; */
  /* font-weight: 600; */
  color: #fff;
  position: relative;
  left: 40px;
  line-height: 29px;
  font-size: 20px;
  font-weight: 600;
  /* left: 30px; */
  padding-left: 40px;
}

.upgrade_body {
  line-height: 28px;
  /* margin-bottom: 5px; */
  color: #fff;
  position: relative;
  /* left: 30px; */
  font-size: 14px;
  padding-left: 40px;
}

.upgrade_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-top: 20px;
}

.upgrade_btn button {
  width: 100px;
  border: none;
  height: 30px;
  border-radius: 2px;
  font-size: 15px;
  cursor: pointer;
  background-color: white;
}

.reloadIcon {
  background-image: url("./media/images/WFLogo.webp");
  /* background-size: 60px; */
  width: 86px;
  height: 60px;
  background-repeat: no-repeat;
  position: absolute;
  left: 15px;
}

span.dateFilter._goFilter {
  width: auto;
  padding: 0 25px;
  border-radius: 25px;
  position: absolute;
  right: 19px;
  top: 0px;
  font-size: 16px;
}

li.fileTypeLi.list._tagD.tagMainFilterLi {
  padding-left: 10px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

span._tagIconD._squareColor {
  width: 25px;
  height: 25px;
  border-radius: 6px;
  /* top: unset; */
  /* left: 8px; */
  position: relative;
  margin: 0 16px;
}

#root.dark p.recurringbillingText {
  color: #ffffff;
}

#root.dark span.supportBillingActive {
  color: #94b2ff;
}

#root.dark .selectUpgradePlan {
  color: #94b2ff;
}

#root.dark .essentialsPlanDiv h3 {
  color: #94b2ff;
}

#root.dark h3.billingAddress {
  color: #94b2ff;
}

#root.dark button.paymentButton.btnActive {
  border: 2px solid #94b2ff;
  color: #ffffff;
  background-color: #3792ff;
}

#root.dark button.paymentButton {
  border: 2px solid #94b2ff;
  color: #94b2ff;
}

.essentialsPlanDiv p {
  color: #0c1e47;
}

.main_container[user-role="Guest"] .createIcon,
.main_container[user-role="Guest"] .inputGroup.roomConferenceLink,
.main_container[user-role="Guest"] .part_top_bar .conv_voice {
  display: none;
}

.addGuest .miniItem_name {
  /* padding-left: 8px; */
  margin-right: 4px;
}

.supportAndBillingContainer ._planName {
  text-transform: capitalize;
}

.billing_address_area {
  font-size: 14px;
  letter-spacing: 1px;
  padding-top: 10px;
  color: #9b9b9b;
  padding-left: 30px;
  border-bottom: 1px solid #eee;
  padding-bottom: 20px;
}

.addMoreButton {
  color: #e8e8e8;
  background-color: #0c1e47;
  outline: none;
  border: none;
  width: auto;
  height: 25px;
  border-radius: 6px;
  /* float: right; */
  margin-top: 5px;
  padding: 5px;
  position: absolute;
  right: 0;
  cursor: pointer;
}

.addmoreArea {
  position: relative;
  margin-bottom: 20px;
  height: 10px;
}

button.addMoreButton.removebtn {
  background-color: #ff0000;
}

.additioalCard {
  color: #002e98;
  font-size: 18px;
  letter-spacing: 0.3px;
  font-weight: 900;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

.userTextArea {
  background-image: url("./media/images/PopUpBubble_BlueBackground.png");
  background-size: contain;
  width: 585px;
  height: 200px;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  position: relative;
  padding-top: 65px;
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 65px;
  opacity: 0.8;
}

.PopUpGraphic_ConvoBubble {
  background-image: url("./media/images/YouChannel_PopUpGraphic_ConvoBubble.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 50px;
  height: 50px;
}

.PopUpGraphic_ConvoBubble_text {
  padding-left: 45px;
}

.PopUpGraphic_ConvoBubble_text h2 {
  color: #fcd200;
}

.PopUpGraphic_ConvoBubble_text p {
  background: unset !important;
  position: unset !important;
  padding: unset !important;
  color: #ffffff !important;
  letter-spacing: 0.3px;
  font-size: 14px !important;
  text-align: left !important;
}

span.tooltiptext5.prfileNavTooltip.foradminarea {
  background-color: #032e84;
  border: none;
  color: #ffffff;
  font-size: 14px;
  padding-top: 30px;
  height: 105px;
  width: 300px;
  top: -21px;
  left: 565px;
}

span.tooltiptext5.prfileNavTooltip.foradminarea::before {
  top: 39px;
}

span.tooltiptext5.prfileNavTooltip.foradminarea::before {
  top: 39px;
  border-color: transparent #032e84 transparent transparent;
  left: -26px;
}

span.checkBoxLabel {
  color: #0c1e47;
  font-size: 18px;
  letter-spacing: 0.5px;
}

select.storagePlan {
  width: 100%;
  height: 35px;
  padding-left: 5px;
  border: 1px solid #0c1e47;
  border-radius: 7px;
  font-size: 16px;
}

input.chose_plan_users {
  width: 60px;
  padding-left: 15px;
  height: 50px;
  border-radius: 7px;
  border: unset;
  background-color: transparent;
  font-size: 25px;
}

/* .usersData input[type="number"] {
  -moz-appearance: textfield;
} */

.usersData input::-webkit-outer-spin-button,
.usersData input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.essentialText.AdditionalStorageText {
  /* font-size: 22px; */
  font-size: 30px;
}

.essentialLeftArea.additioal {
  border-radius: 7px;
}

span.usersnum.storageGB {
  position: relative;
  top: -2px;
  font-size: 22px;
  font-weight: 900;
  left: 5px;
}

span.tooltiptext.tooltipFilesText {
  position: absolute;
  z-index: 9999999;
  width: 138px;
  top: 35px;
  left: -149px;
  background-color: #fcd200;
  padding: 15px;
  border-radius: 7px;
}

input.error {
  border: 1px solid red;
}

ul.essentialUL {
  margin-top: 10px;
}

span.tooltiptext.filetooltip p {
  font-size: 14px;
  font-weight: bold;
  color: #0c1e47;
}

span.tooltiptext.filetooltip {
  width: 155px;
  background-color: #f3da60;
  border-color: #f3da60;
  padding: 20px;
  margin-left: -123px;
  top: 30px;
  z-index: 99;
}

span.tooltiptext.filetooltip::before {
  left: 84px;
  border-color: transparent #f3da60 transparent transparent;
}

span.tooltiptext.msgtooltip p {
  font-size: 14px;
  font-weight: bold;
  color: #0c1e47;
}

span.tooltiptext.msgtooltip {
  width: 135px;
  background-color: #f3da60;
  border-color: #f3da60;
  padding: 15px;
  margin-left: 172px;
  top: -104px;
}

span.tooltiptext.msgtooltip::before {
  left: 84px;
  border-color: transparent #f3da60 transparent transparent;
  top: 68px;
  transform: rotate(150deg);
}

span.tooltiptext.threadmsgtooltip {
  width: 200px;
  background-color: #f3da60;
  border-color: #f3da60;
  padding: 20px;
  margin-left: -38px;
  top: -125px;
}

span.tooltiptext.threadmsgtooltip::before {
  left: -9px;
  border-color: transparent #f3da60 transparent transparent;
  top: 51px;
  transform: rotate(235deg);
}

span.tooltiptext.threadmsgtooltip p {
  font-size: 14px;
  font-weight: bold;
  color: #0c1e47;
}

.notificationIcon {
  text-align: center;
}

.notificariontextArea {
  text-align: center;
}

.notificationIcon img {
  width: 90px;
  margin-bottom: 30px;
}

h2.notificationHeading {
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 30px;
}

.notificationIconImages img {
  width: 280px;
  margin-bottom: 30px;
}

.notificationIconPara p {
  font-size: 16px;
  font-weight: bold;
}

.notificationIconPara {
  margin-bottom: 30px;
}

.buttonText {
  border: 1px solid #0c1e47;
  width: 280px;
  border-radius: 25px;
  padding: 5px;
  font-size: 18px;
  margin-left: 115px;
  background-color: #0c1e47;
  color: #ffffff;
  letter-spacing: 1px;
}

.chooseCreateConv.firstChat {
  background: transparent;
  width: auto;
  margin-left: 400px;
}

.PopupContent.firstChatContent {
  background-color: #032e84;
  padding: 50px 80px;
  border-radius: 10px;
  width: 525px;
}

.PopupContent.firstChatContent p {
  position: relative;
  background-color: unset;
  padding: 0;
  color: #ffffff;
  top: 0px;
  left: 40px;
  font-size: 16px;
}

.youChannel_ConvoBubbles {
  background-image: url("./media/images/YouChannel_ConvoBubbles.png");
  width: 60px;
  height: 60px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  left: 90px;
}

.chatfirstClose {
  /* background-image: url("./media/images/close_button_w.svg"); */
  background-image: url("./media/images/closeIcon.png");
  background-repeat: no-repeat;
  background-size: 20px;
  position: absolute;
  top: 23px;
  width: 20px;
  height: 20px;
  right: 40px;
  cursor: pointer;
}

span.tooltiptext5.prfileNavTooltip.forUserprofile {
  width: 155px;
  background-color: #f3da60;
  border-color: #f3da60;
  padding: 20px;
  height: auto;
  left: 306px;
  top: -31px;
}

span.tooltiptext5.prfileNavTooltip.forUserprofile p {
  font-size: 16px;
  font-weight: bold;
  color: #0c1e47;
}

span.tooltiptext5.prfileNavTooltip.forUserprofile::before {
  border-color: transparent #f3da60 transparent transparent;
}

/* Popup tooltip first user impressions css */
.wf_Onboarding .prev,
.next {
  position: absolute;
  padding: 1rem;
  background-color: transparent;
  z-index: 2;
  outline: none;
  color: #333;
  display: block;
  border: none;
  bottom: 22px;
  width: 20px;
  height: 20px;
  background-size: 30px 30px;
  background-repeat: no-repeat;
}

.wf_Onboarding .prev:hover,
.next:hover {
  cursor: pointer;
}

.wf_Onboarding .prev {
  background-image: url("./media/images/first_time/userManagement/Leftarrows.png");
  left: 30px;
}

.wf_Onboarding .prev.inactive {
  background-image: url("./media/images/first_time/userManagement/arrows.png");
  left: 30px;
}

.wf_Onboarding .next {
  background-image: url("./media/images/first_time/userManagement/rightArrows.png");
  right: 30px;
}

.wf_Onboarding {
  display: flex;
  margin: 0 auto;
  margin-top: -70px;
}

.wf_Onboarding p {
  font-size: 18px;
  margin-bottom: 2rem;
  text-align: center;
}

.wf_Onboarding .slider {
  position: relative;
  width: 700px;
  top: 20%;
  height: 400px;
}

.wf_Onboarding .slide {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  perspective: 100rem;
  overflow-x: unset;
  padding: 0px 172px;
}

.wf_Onboarding .slide.active {
  opacity: 1;
}

.wf_Onboarding .slide.active .slide__title,
.slide.active .slide__subtitle {
  transform: translateX(0%);
}

.wf_Onboarding .slide .slide__title {
  color: #fff;
  margin-top: 180px;
  font-size: 24px;
  left: 120px;
}

.wf_Onboarding .slide .slide__image {
  position: absolute;
  width: 45px;
  object-fit: cover;
  z-index: -1;
  left: 325px;
  top: 80px;
}

.wf_Onboarding .totalSlides {
  position: absolute;
  bottom: 30px;
  left: 330px;
  color: #fce053;
  font-size: 24px;
}

.settingUp {
  position: absolute;
  right: 32px;
  bottom: 30px;
  background-color: #fce053;
  padding: 13px 10px;
  border-radius: 25px;
  font-size: 16px;
  color: #0c1e47;
  font-weight: bold;
  letter-spacing: 1px;
  cursor: pointer;
  top: 345px;
  line-height: 4px;
  height: 30px;
}

.slide__skipText {
  position: absolute;
  right: 40px;
  top: 30px;
  color: #ffffff;
  font-size: 16px;
  letter-spacing: 0.7px;
  cursor: pointer;
}

img#slide__image0 {
  width: 90px;
}

img#slide__image1 {
  width: 60px;
}

img#slide__image3 {
  width: 64%;
  left: 120px;
  top: 95px;
}

.wf_Onboarding .slide #slide__image4 {
  width: 45px !important;
}

.slide__title.catTitle {
  margin-top: 112px !important;
  color: #fce053 !important;
}

.slide.roomCat {
  padding: 20px;
}

p#catTitle1 {
  padding: 0 75px;
  margin-top: 55px !important;
  margin-bottom: 10px;
}

p#catTitle0 {
  padding: 0 120px;
}

p#sub_title1 {
  font-size: 24px;
  color: #ffffff;
  padding: 0 110px;
}

.tagImageArea {
  position: absolute;
  top: 50px;
  left: 230px;
}

.tagImageArea img {
  width: 250px;
}

.slide.tagSlide {
  padding: 0px 30px;
}

p.slide__title.tagTitle0 {
  padding: 0 75px;
}

p.slide__title.tagTitle1 {
  margin-top: 72px !important;
  padding: 0 90px;
  font-size: 26px !important;
  color: #fce056 !important;
}

p.slide__subtitle.tagsubTitle1 {
  font-size: 26px !important;
  color: #ffffff;
  padding: 0 109px;
  margin-top: -15px;
}

p.slide__title.tagTitle2 {
  margin-top: 95px !important;
  padding: 0 100px;
  font-size: 26px !important;
  color: #fce056 !important;
}

p.slide__title.tagTitle3 {
  font-size: 26px !important;
  padding: 0 94px;
  margin-top: 108px !important;
  color: #fce056 !important;
}

.prev.initialprev.inactive {
  bottom: -40px;
}

.next.initialnext {
  bottom: -40px;
}

.prev.initialprev {
  bottom: -40px;
}

.totalSlides.initialTotal {
  bottom: -40px;
}

.sliderImage_area img {
  width: 260px;
}

.sliderImage_area {
  margin-top: 130px;
  margin-left: 0px;
  text-align: center;
}

.sliderLogo_area img {
  width: 310px;
}

.sliderLogo_area {
  margin-top: 15px;
  margin-left: -25px;
  text-align: center;
}

h3.slide__welcome.initialheading {
  position: absolute;
  top: 50px;
  left: 270px;
  font-size: 26px;
  color: #f3da60;
  font-weight: 500;
}

.settingUp.initialsettings {
  bottom: -37px;
}

.slide.initialSlide {
  padding: 20px;
}

h4.slide__heading.initialheading {
  text-align: center;
  margin-top: 90px;
  font-size: 24px;
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 1px;
}

h3.slide__pharese.initialpharese {
  font-size: 14px;
  font-weight: 300;
  color: #ffffff;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 12px;
}

.slide_icons {
  display: block;
  text-align: center;
  width: 200px;
  float: left;
  position: relative;
}

.iconFullArea {
  float: left;
  margin-top: 50px;
  margin-left: 30px;
}

.iconTextPortion {
  color: #ffffff;
  padding-top: 15px;
  font-size: 16px;
}

.iconTextPortion.last1 {
  position: absolute;
  width: 100px;
  left: 50px;
  top: 37px;
}

.iconFullArea.areaid2 {
  width: 100%;
}

.iconFullArea.areaid2 .slide_icons {
  width: 50%;
  text-align: center;
}

.iconFullArea.areaid2 .slide_icons img {
  width: 25px;
}

.iconFullArea.areaid2 .slide_icons .iconTextPortion {
  text-align: center;
  padding: 10px 74px;
}

.iconFullArea.areaid3 img {
  width: 25px;
}

.iconFullArea.areaid3 .iconTextPortion {
  padding: 25px 26px;
}

.iconFullArea.areaid4 img {
  height: 25px;
}

.iconFullArea.areaid4 .iconTextPortion {
  padding: 20px 22px;
}

.iconFullArea.areaid1 img {
  height: 25px;
}

.hideIcon {
  background-image: url("./media/images/closeIcon.png");
  background-size: 20px;
  width: 25px;
  height: 25px;
  background-repeat: no-repeat;
  position: absolute;
  top: 20px;
  right: 15px;
  cursor: pointer;
}

span.tooltiptext5.prfileNavTooltip.foradminarea.user {
  width: 360px;
  height: 176px;
  padding-left: 20px;
  font-size: 14px;
  left: 565px;
}

span.left_switchAccount {
  /* background-image: url("./media/images/first_time/initial/multipleAccounts.png"); */
  /* background-size: 50px; */
  width: 50px;
  height: 50px;
  background-repeat: no-repeat;
  position: absolute;
  right: 110px;
  cursor: pointer;
  border: 2px solid #e82a87;
  border-radius: 50%;
  background-size: auto;
}

span.left_switchAccountPoint {
  background-color: #e82a87;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  right: 111px;
  top: 101px;
}

span.tooltiptext5.prfileNavTooltip.foradminarea.switch {
  width: 275px;
  /* top: 76px;
  left: 160px; */
  top: 168px;
  left: 470px;
}

span.tooltiptext5.prfileNavTooltip.foradminarea.switch::before {
  top: -20px;
  transform: rotate(90deg);
  left: 13px;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.upgrade_area {
  padding-left: 25px;
}

span.buttonTextUpgrade {
  width: 50px;
  background-color: #94b2ff;
  color: #ffffff;
  border-radius: 20px;
  padding: 4px 10px;
  font-size: 14px;
  cursor: pointer;
}

span.upgradePlanButton {
  position: absolute;
  right: 25px;
  top: 135px;
}

span.ButtonUpgradeIcon {
  width: 24px;
  height: 24px;
  background-image: url("./media/images/plus.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 12px;
  background-color: #94b2ff;
  border-radius: 50%;
  position: absolute;
  cursor: pointer;
}

span.upgradePlanButton span.ButtonUpgradeIcon {
  right: 0px;
}

.upgrade_area span.ButtonUpgradeIcon {
  right: 25px;
}

#root.dark .tooltip .tooltiptext {
  background-color: #032e84;
  color: #ffffff;
  border: 2px solid #0c1e47;
}

#root.dark .tooltip .tooltiptext p {
  color: #ffffff;
}

#root.dark span.tooltiptext.filetooltip::before {
  left: 84px;
  border-color: transparent #032e84 transparent transparent;
}

#root.dark span.tooltiptext.msgtooltip::before {
  border-color: transparent #032e84 transparent transparent;
}

#root.dark span.tooltiptext.threadmsgtooltip::before {
  border-color: transparent #032e84 transparent transparent;
}

li.moreOpt_list._threadFilter.flag {
  margin-bottom: 0;
}

#root.dark .moreMenuPopup li._pingConv:before {
  /* background-image: url("./media/images/icons/BlackTheme/SVG/WorkFreeli_Icons_White_pinned.svg"); */
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_pinned.svg");
}

#root.dark .moreMenuPopup li._muteConv:before {
  content: "";
  background-image: url("./media/images/bell-slash-solid.svg");
}

li.optionalList.moreOpt_list::before {
  content: "\f00c";
  font-size: 14px;
}

#root.dark li.optionalList.moreOpt_list::before {
  content: "\f00c";
  color: white;
}

.moreMenuPopup li.optionalList.reopen::before {
  content: "";
  background-image: url("./media/images/icons/reopenBlackIcon.png");
  width: 22px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
  background-size: 22px;
}

#root.dark .moreMenuPopup li.optionalList.reopen::before {
  content: "";
  background-image: url("./media/images/icons/reopenWhiteIcon.png");
}

.moreMenuPopup li.optionalList.reopen:hover:before {
  content: "";
  background-image: url("./media/images/icons/reopenWhiteIcon.png");
}

#root.dark .tagTitle.darkClass {
  color: #ffffff !important;
}

#root.dark .tagTitle[contenteditable="true"] {
  background-color: #9b9b9b;
  border: 1px solid #d8d8d8;
}

li.optionalList.moveIcon::before {
  content: "";
  background-image: url("./media/images/box-archive-black.svg");
  width: 16px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
  background-size: 16px;
}

li.optionalList.moveIcon:hover:before {
  content: "";
  background-image: url("./media/images/box-archive-solid.svg");
}

#root.dark li.optionalList.moveIcon:before {
  content: "";
  background-image: url("./media/images/box-archive-solid.svg");
  width: 16px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
  background-size: 16px;
}

span.flipNavIcon.tooltip5 {
  display: block;
}

input.team-name.isDisable {
  /* background-color: #d8d8d8 !important;
  /* opacity: 0.25; */
  /* color: #9b9b9b; */
  /* color: #5b6477b5 !important; */
  background-color: #d8d8d8 !important;
  color: #9b9b9b;
}

.memberItem.readonlyBg {
  background-color: #d8d8d8;
  border: 1px solid #d6d6d6;
}

.showCopyArea {
  background: #31b76a;
  border-radius: 4px;
  height: 50px;
  padding: 14px 16px;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  position: relative;
}

span.showTextWithIcon:before {
  content: "\f00c";
  font-family: "FontAwesome";
  color: #ffffff;
  font-size: 18px;
  position: absolute;
  margin-right: 8px;
  font-weight: 600;
  right: 10px;
}

img.copyMeLink.profile {
  top: 42px;
}

h3.list_container_title.create_conv.thread.threadConv {
  margin-top: 0px;
}

input.team-name.short_id_input {
  /* background-color: #d8d8d8; */
  color: #000000;
  cursor: pointer;
}

input.team-name.short_id_input:hover {
  background: #d8d8d8;
  font-weight: 600;
  font-family: sans-serif;
}

.copyIcon:hover+input.team-name.short_id_input {
  background: #d8d8d8;
  font-weight: 600;
  font-family: sans-serif;
}

.select-ecosystem.isDisable .css-1fhf3k1-control {
  border: none !important;
  /* background-color: #fff !important; */
  background-color: #d8d8d8 !important;
}

.memberItem.online.readonlyBg p.memberItemTitle {
  opacity: 0.65;
}

.memberItem.online.readonlyBg .memberItemImage {
  opacity: 0.65;
}

.select-ecosystem.isDisable .css-1fhf3k1-control .css-1hb7zxy-IndicatorsContainer {
  opacity: 0.4;
}

li.optionalList.lock::before {
  content: "";
  background-image: url("./media/images/icons/private-lock.png");
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  margin-top: 1px;
  background-size: 18px;
}

#root.dark li.optionalList.lock::before {
  content: "";
  background-image: url("./media/images/icons/private-lock-white.png");
}

li.optionalList.lock:hover:before {
  content: "";
  background-image: url("./media/images/icons/private-lock-white.png");
}

li.optionalList.unlock::before {
  content: "\f09c";
  font-size: 14px;
}

.upload_section.user_up .teamImage {
  width: 120px;
  height: 120px;
}

.changeButton.inRoom {
  width: 25px;
  height: 25px;
  background-size: 25px;
}

.removeButton.roomPicRemove {
  font-size: 10px;
  width: 45px;
  top: 19px;
  left: -5px;
}

.changeButton.inRoom {
  width: 25px;
  height: 25px;
  background-size: 25px;
  left: 3px;
}

.upload_section.user_up.roomRelated {
  justify-content: initial;
  /* background: #ffffff; */
  padding: 15px 20px;
}

.teamTextArea .set_avatar {
  font-weight: 600;
  margin-bottom: 5px;
  text-align: left;
}

.set_avatar_click {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 18px;
  position: relative;
  margin-bottom: 15px;
  text-align: center;
}

.addGuest.teamGuest {
  margin-top: 177px;
  padding: 15px 20px;
}

.teamTextArea p {
  margin-left: 12px;
  text-align: left;
}

.selectedParticipants.tag_container_mini.teamTag {
  display: block;
}

.shareTeamTagText {
  color: #1b43a2;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}

.inputGroup.teamInput {
  padding-right: 0;
  margin-bottom: 40px;
  border-top: 1px solid #d6d6d6;
  padding-top: 25px;
}

.inputGroup.guestButton {
  padding-right: 0;
}

.teamGuest {
  padding-right: 0;
}

.cu_inputGroup.inTeam {
  width: 47% !important;
}

/* li.public_tag.inTeam {
    font-size: 18px;
}
li.my_tag.inTeam {
    font-size: 18px;
} */
label.img-div.teamImage img {
  width: 120px;
  height: 120px;
}

.backGroundCololor {
  background-color: #ffffff;
}

span.fileTag.inteam {
  padding: 4px 25px;
  background-size: 14px;
  padding-right: 7px;
  border-radius: 20px;
  position: relative;
  top: 2px;
}

.noGust {
  color: #002e98;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}

#root.dark .noGust {
  color: #ffffff;
}

span.tooltiptext2.roomGuest {
  width: 435px;
  top: -245px;
  height: 226px;
}

span.tooltiptext2.roomGuest::before {
  top: 217px;
  left: 179px;
  transform: rotate(147deg);
}

span.tooltiptext2.roomGuest h3 {
  padding-bottom: 2px;
  text-align: center;
}

.shareTeamTagText span.info_tooltip {
  top: 5px;
  position: relative;
}

span.tooltiptext2.roomShared {
  top: 18px;
  left: -15px;
}

span.tooltiptext2.roomMem {
  width: 262px;
  margin-left: -145px;
  top: 19px;
}

span.tooltiptext2.roomMem::before {
  left: 120px;
}

.addArea {
  width: 100%;
  height: 35px;
}

span.tooltiptext2.inRoom {
  margin-left: -123px;
}

span.tooltiptext2.inRoom::before {
  left: 114px;
}

span.tooltiptext2.buttonGuest {
  width: 297px;
  margin-left: -200px;
  top: 13px;
}

span.tooltip2.roomGuest.add.user_Management_title {
  display: block;
}

p.NomemberFound {
  text-align: center;
}

.sweetCancel {
  width: 120px;
  /* font-size: 18px; */
  /* font-weight: 400 !important; */
}

.sweetCancel:hover {
  border-color: var(--NewRed);
}

span.tooltiptext2.tooltipConference {
  margin-left: -109px;
}

span.tooltiptext2.tooltipConference::before {
  left: 101px;
}

/* Switch in Email notification*/
.myCheckbox {
  display: none;
}

.switch {
  align-items: center;
  background-color: gray;
  border-radius: 500px;
  cursor: pointer;
  display: flex;
  height: 25px;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  user-select: none;
  width: 65px;
}

.myCheckbox:checked~.switch {
  background-color: #0c1e47;
}

.myCheckbox:not(:checked)~.switch {
  background-color: gray;
}

.switch__left,
.switch__right {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.myCheckbox:checked~.switch .switch__left {
  visibility: hidden;
}

.myCheckbox:not(:checked)~.switch .switch__right {
  visibility: hidden;
}

.switch__circle {
  height: 25px;
  padding: 5px;
  position: absolute;
  transition: all 0.1s linear;
  width: 25px;
}

.myCheckbox:checked~.switch .switch__circle {
  left: 0;
  right: calc(100% - 27px);
}

.myCheckbox:not(:checked)~.switch .switch__circle {
  left: calc(100% - 27px);
  right: 0;
}

.switch__circle-inner {
  background-color: white;
  border-radius: 50%;
  display: block;
  height: 100%;
  width: 100%;
}

span.existLabel {
  color: red;
  font-size: 13px;
  position: absolute;
  bottom: 0;
  left: 0;
  display: none;
  top: 5px;
}

.createUser_email.errorEmail+.errorLabel {
  display: block;
}

button.addMoreButton.inUserUpdated {
  right: 85px;
}

button.addMoreButton.removebtn {
  right: 35px;
}

.smtp_marginTop {
  margin-top: 40px;
  border-top: 1px solid #d6d6d6;
  margin-bottom: 25px;
  margin-right: 35px;
}

.borderRed {
  border: 2px solid #df1b41 !important;
}

.start_Date_task.colorRed {
  color: red !important;
}

input.start_Date_task.errorDate {
  border: 1px solid red !important;
}

.borderNormal {
  border: 1px solid #d6d6d6 !important;
}

.smtpTap {
  width: 60%;
}

.createUser_first.error+.errorLabel {
  display: block;
}

.createUser_last.error+.errorLabel {
  display: block;
}

.firstName.error+.errorLabel {
  display: block;
}

.forgotPass.otp {
  right: 165px;
}

.createUserModal {
  width: 465px;

}

.cu_inputGroup label {
  width: 100% !important;
  color: #002e98 !important;
  margin-bottom: 6px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding-bottom: 6px !important;
}

.crTeam_inputGroup label {
  width: 100% !important;
  color: #002e98 !important;
  margin-bottom: 6px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding-bottom: 6px !important;
}

.searchParticipants input:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%);
}

.workCategoryCreateSection input:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%);
}

input.userSearchInput:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%);
}

.select-ecosystem .css-b8ldur-Input input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.select-ecosystem .css-1pahdxg-control {
  outline: 0 !important;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}

span.removeEmail.guest {
  background-color: transparent;
  top: 0;
}

.form-field>input:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}

.rgInputf:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}

.addPhoneAndCompany {
  color: #565656;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  margin-bottom: 8px;
  float: right;
  position: relative;
  top: -12px;
  cursor: pointer;
}

#root.dark .addPhoneAndCompany {
  color: #fff;
}

#root.dark .removeEmail {
  background-image: url('./media/images/close_button_w.svg');
}

.heightClass {
  height: calc(100% - 250px);
}

#root.dark a {
  color: #028bff;
}

input::placeholder {
  font-size: 15px !important;
  color: #9a9a9a !important;
  font-family: var(--F1);
}

::-webkit-input-placeholder {
  /* Edge */
  font-size: 15px !important;
  color: #9a9a9a !important;
  font-family: var(--F1);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-size: 15px !important;
  color: #9a9a9a !important;
  font-family: var(--F1);
}

::placeholder {
  font-size: 15px !important;
  color: #9a9a9a !important;
  font-family: var(--F1);
}

.public-DraftEditorPlaceholder-inner {
  font-size: 15px !important;
  color: #9a9a9a !important;
  font-family: var(--F1);

}

.btnCancel {
  box-sizing: border-box;
  height: 42px;
  width: auto;
  padding: 0 20px;
  border: 1px solid #0b1f47;
  color: #0b1f47;
  border-radius: 5px;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
}

.btnCancel:hover {
  border-color: var(--NewRed);
}

.customTitleInput:focus {
  /* border: 1px solid #799fff !important;
  outline: 2px solid #c0d1ff; */
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}

.addedGuestList.teamGuest {
  padding-left: 20px;
}

.tagAddSearch input:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}

.participantItem:hover {
  background-color: #0c1e47;
}

.participantItem:hover p.participantTitle {
  color: #ffffff;
}

.participantItem:hover .participantTitleTeams {
  color: #028bff;
}

#newPass:focus,
#confirmPass:focus,
#oldPass:focus,
#confPass:focus,
#password:focus,
#password2:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%) !important;
  box-shadow: 0px 1px 1px rgb(0 0 0 / 3%), 0px 3px 6px rgb(0 0 0 / 2%), 0 0 0 3px hsl(210deg 96% 45% / 25%), 0 1px 1px 0 rgb(0 0 0 / 8%) !important;
}

#root.dark p.NomemberFound {
  color: #ffffff;
}

#root.dark .memberItem:hover {
  color: rgba(0, 0, 0, 0.88);
}

/* #root.dark .shareTeamTagText {
    color: #ffffff;
  } */
.sender_img.nameL span.nameLetters {
  width: 41px !important;
  height: 41px !important;
}

.css-1pahdxg-control input:focus {
  outline: 0 !important;
  border-color: transparent !important;
  box-shadow: none !important;

}

span.tooltiptext2.roomMem.hideTooltip {
  margin-left: 45px;
  top: 14px;
  width: 185px;
}

a.clickedLink:visited {
  color: #3861cb !important;
}

a.clickedLink:hover {
  color: #002e98 !important;
  font-weight: 600;
}

#root.dark a.clickedLink:hover {
  color: #028bff !important;
}

#root.dark .microphone_audio_on {
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_micon.svg');
  background-size: 20px;
  margin-right: 15px !important;
}

.tip {
  position: absolute;
  background-color: #FCE053;
  margin: 20px auto;
  width: 455px;
  height: auto;
  line-height: 20px;
  color: #023d67;
  text-align: left;
  border-radius: 10px;
  padding: 20px;
  top: 440px;
  left: 49%;
  z-index: 999;

}

.tip::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 8px solid;
}

.leftAngel::after {
  border-right-color: #FCE053;
  top: 50%;
  right: 100%;
  margin-top: -9px;
  color: transparent;
}

.downAngel::after {
  border-right-color: #318FFF;
  transform: rotate(270deg);
  top: 100%;
  right: 50%;
  /* margin-top: -9px; */
  color: transparent;
}

.tip.leftAngel p {
  font-weight: 600;
}

.calltipzone {
  width: 452px;
  margin: 0 auto;
  position: absolute;
  /* top: 0; */
  left: 0;
  right: 0;
  bottom: 8%;
  margin: auto;
}

.callfirstzone {
  width: 452px;
  position: absolute;
  top: 3%;
  left: 3%;
}

.callfirstzone .tip::after {
  display: none;
}

.calltipzone p,
.callfirstzone p {
  font-weight: 600;
  color: white;
}

.tip.userfirst {
  width: 222px;
  top: unset;
  left: -23%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;

}

.tip.adminfirst {
  width: 360px;
  top: unset;
  left: -8%;
  position: relative;
  background-color: #318FFF;

}

.tip.security {
  width: 440px;
  top: unset;
  left: 39%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;

}

.tip.lobby {
  width: 320px;
  top: unset;
  left: 39%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;

}

.tip.presentation {
  width: 326px;
  top: unset;
  left: 39%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;

}

.calltipbold {
  font-weight: 600;
  margin-bottom: 10px;
}

.calltipnormal p {
  font-weight: normal;
  /* margin-bottom: 5px; */
}

.tip.sharescreen {
  width: 222px;
  top: unset;
  left: -17%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;
}

.tip.camera {
  width: 355px;
  top: unset;
  left: -34%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;
}

.tip.callmsg {
  width: 250px;
  top: unset;
  left: -4%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;
}

.tip.admincallmsg {
  width: 250px;
  top: unset;
  left: -11%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;
}

.tip.invite {
  width: 250px;
  top: unset;
  left: 20%;
  /* bottom: 7%; */
  position: relative;
  background-color: #318FFF;
}

.tipClose {

  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_exit outline.svg');
  background-repeat: no-repeat;
  background-size: 18px;
  position: absolute;
  top: 4px;
  width: 20px;
  height: 20px;
  right: 3px;
  cursor: pointer;
}

.wf_Onboarding.roomCat.flagBoard .slider {
  left: 30%;
}

.wf_Onboarding.roomCat.flagBoard .slide .slide__image {
  position: absolute;
  width: 308px !important;
  object-fit: cover;
  z-index: -1;
  left: 185px;
  top: 42px;
}

.wf_Onboarding.roomCat.flagBoard #sub_title0 {
  font-size: 18px !important;
  color: #ffffff !important;
  width: 320px !important;
  position: relative;
  left: 147px;
  top: -15px;
}

.wf_Onboarding.roomCat.flagBoard .slide.flaged {
  padding: 30px;
}

.wf_Onboarding.roomCat.flagBoard p#catTitle1 {
  font-size: 14px;
  margin-top: 20px !important;
  font-weight: 300;
}

.wf_Onboarding.roomCat.flagBoard img#slide__image1 {
  position: relative;
  top: 0px;
}

.wf_Onboarding.roomCat.flagBoard p#sub_title1 {
  position: relative;
  top: -25px;
  width: 610px !important;
  left: 30px;
  font-size: 16px;
  font-weight: 400;
}

.wf_Onboarding.roomCat.flagBoard .slideImage.filehub img {
  width: 155px !important;
  left: 265px;
  top: 60px;
}

.wf_Onboarding.roomCat.flagBoard.filehub p#sub_title0 {
  width: 348px !important;
  font-size: 20px !important;
  top: -28px;
}

.wf_Onboarding.roomCat.flagBoard.filehub img#slide__image1 {
  width: 100px !important;
  margin-bottom: 50px;
}

.wf_Onboarding.roomCat.flagBoard.filehub p#sub_title1 {
  font-size: 20px;
  width: 347px !important;
}

.wf_Onboarding.roomCat.flagBoard.filehub p#sub_title1 {
  font-size: 20px;
  width: 520px !important;
}

.wf_Onboarding.roomCat.flagBoard.filehub img#slide__image2 {
  width: 65px !important;
  left: 330px;
  top: 60px;
}

.wf_Onboarding.roomCat.flagBoard.filehub p#sub_title2 {
  font-size: 20px !important;
  color: #ffffff;
  width: 360px;
  position: relative;
  left: 145px;
  top: -52px;
}

.wf_Onboarding.Teamarea .slide {
  padding: 0px 50px;
}

.wf_Onboarding.Teamarea .slide p#slide__title0 {
  color: #FCE053;
}

.wf_Onboarding.Teamarea .slide p#slide__title1 {
  margin-top: 80px;
  font-size: 20px;
  left: 75px !important;
  width: 450px;
  position: relative;
  color: #FCE053;
}

.wf_Onboarding.Teamarea .slide p#slide__title21 {
  color: #ffffff;
  width: 375px;
  position: relative;
  left: 120px;
  top: -15px;
}

.wf_Onboarding.Teamarea .slide p#slide__title2 {
  margin-top: 125px;
  font-size: 24px;
  left: 120px;
  color: #ffffff;
}

.wf_Onboarding.Teamarea .slide p#slide__title22 {
  margin-top: -30px;
  font-size: 24px;
  color: #FCE053;
}

.wf_Onboarding.Teamarea .slide p#slide__title3 {
  margin-top: 133px;
  font-size: 24px;
  margin-bottom: 0;
}

.wf_Onboarding.Teamarea .slide p#slide__title23 {
  position: relative;
  top: 0px;
  font-size: 24px;
  color: #FCE053;
}

.fileNotFoundArea .headingFiles {
  font-size: 24px;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;

}

.fileNotFoundArea {
  padding-top: 20%;
  width: 100%;
  text-align: center;
}

.file_icon_area {
  display: flex;
  width: 100%;
  cursor: pointer;
  /* padding: 0 20%; */
}

.file_icon img {
  width: 70px;
  height: 65px;
}

.fileNotFoundArea .file_text {
  color: #2E5299;
  font-weight: 600;
  padding: 30px;
}

div#sliderImage_area0 img {
  margin-top: -70px;
}

div#slide_icons_one2 {
  position: relative;
  right: 28px;
}

.left_container.adminLeftArea .group_container {
  height: auto;
}

label.container_checkbox {
  position: absolute;
  top: 5px;
  width: 246px;
  left: 70px;
  display: flex;
}

label.container_checkbox input[type="checkbox"] {
  width: 14px;
  position: relative;
  top: -9px;
  left: 4px;
}

label.container_checkbox input[type="checkbox"]:focus {
  outline: none;
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.buttonArea {
  display: flex;
  padding-right: 7px;
}

/*Tag file panel new design*/
.Filehub_header {
  width: 100%;
  border-bottom: 1px solid #d9e2e8;
  height: 80px;
}

.tagFile_header {
  display: flex;
  width: 100%;
  height: 80px;
  /* border-bottom: 1px solid #d9e2e8; */
  padding-top: 25px;
  padding-left: 0;
  max-width: calc(1920px - 400px);

}

.tagFile_column {
  width: 50%;
}

ul.tagFileLists {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  padding-right: 110px;
  justify-content: flex-start;

}

.tagFile_column.first_column {
  text-overflow: ellipsis;
  cursor: pointer;
  text-indent: 16px;
  /* margin-left: 10px; */
  font-size: 18px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0b1f47;
  padding-right: 10px;
}

ul.tagFileLists li {
  font-size: 18px;
  color: #002e98;
  cursor: pointer;
}

.tagFileLists_tab {
  flex: 0 0 30%;
  box-sizing: border-box;
  margin-right: 0.66%;
  cursor: pointer;
}

ul.tagFileLists .tagFileLists_tab.activeTab {
  color: #2196f3;
  position: relative;
}

ul.tagFileLists .tagFileLists_tab.activeTab:after {
  content: "";
  position: absolute;
  width: 127px;
  height: 5px;
  left: -50px;
  bottom: 43px;
  border-radius: 4px;
  background-color: #318fff;
}

input._inputSearchBar {
  width: calc(100% - 634px);
  box-sizing: border-box;
  height: 33px;
  text-indent: 0px;
  /* background-image: url(./media/images/png/search-icon.png); */
  /* background-image: url('./media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_search.svg'); */
  background-repeat: no-repeat;
  background-position: 99%;
  background-size: 15px;
  margin-left: 30px;
  margin-right: 30px;
  /* padding-right: 50px; */
  font-size: 14px !important;
  line-height: 35px;
  /* margin: 0 11px 0 0; */
  padding: 6px 30px 7px 10px;
  border-radius: 16.3px;
  border: solid 1px #d9e2e8;
  background-color: #fff;
}

input._inputSearchBar:focus {
  outline: none !important;
  border-color: #2196f3;
  box-shadow: 0 0 3px #2196f3;
}

.SearchButton {
  width: 60px;
  background-color: #2196f3;
  color: #fff;
  padding: 6px 7px 4px 18px;
  margin-left: 10px;
  margin-right: 40px;
  border-radius: 20px;
  cursor: pointer;
  height: 35px;
}

.tagsContainerBoxCon {
  border-radius: 5px;
  padding: 0px;
  width: 23%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 23%;
  cursor: pointer;
  margin: 5px;

}

.tagsContainerBoxContent {
  padding: 15px 0px 0px 15px;
  display: flex;
  justify-content: flex-start;
  margin: 0px 50px 0px 25px;
  max-width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  grid-row-gap: 15px;
  row-gap: 15px;
  overflow: auto;
  border-radius: 10px;
  border: solid 1px #f4f9fc;
  background-color: #f5f9fc;
}

.tagsContainerBox {
  display: flex;
  justify-content: space-between;
  height: 43px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  align-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;

}

#root.dark .tagsContainerBox:hover {
  background-color: #d9d9d985;
}

.tagsBoxColor {
  width: 8px;
  height: 100%;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.tagsContainerBoxLeft {
  display: flex;
  height: 100%;
  align-items: center;
  flex-basis: 62%;
  justify-content: flex-start;
}

.tagsContainerBoxRight {
  display: flex;
  height: 100%;
  align-items: center;
  flex-basis: 42%;
  justify-content: flex-end;
}

.tagsBoxText p {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: rgba(11, 31, 71, 0.5);
}

.tagsContainerBoxLeft p {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #732be2;
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
}

.tagsBoxStarIcon {
  padding-right: 10px;
  color: #FEBF3A;
  /* margin-top: 3px; */
  margin-left: 6px;
  height: 100%;
  display: flex;
  align-items: center;
}

.tagsHeadText {
  font-size: 18px;
  color: #0b1f49;
  padding: 10px 10px 0px 10px;
  margin-bottom: 15px;
}

.tagsHeadText p {
  width: 100%;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  text-indent: 16px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #032e84;

}

.grid_container {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: auto auto;
  padding: 10px 15px;
  /* height: calc(100vh - 318px); */
  margin-left: 30px;
  /* overflow: auto; */
  grid-template-columns: 50% 50%;
  min-height: 100px;
}

.grid_container.allLinkPanel_grid {
  height: calc(100vh - 205px);
}

.fileBody_area.linkDetails_design {
  height: calc(100vh - 200px);
}

.grid_item {
  padding: 0;
  font-size: 16px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  height: 43px;
  align-content: center;
  align-items: center;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0 2px 15px 0 rgb(11 31 71 / 6%);
  border: solid 1px #e5eef4;
  background-color: #fff;
}

.boxAreaContent {
  margin-right: 40px;
  overflow: auto;
  margin-top: 30px;
}

.boxAreaContent .MenuIcon {
  color: #E2E8F3;
  font-size: 25px;
  position: relative;
  top: 7px;
  left: 20px;
  cursor: pointer;
}

.boxAreaContent .boxContentHead {
  display: flex;
}

.boxContentHead .box_column {
  width: 50%;
  padding-right: 15px;
}

.box_column.first_box {
  display: flex;
}

ul.box_column_list_area {
  flex-direction: row;
  justify-content: flex-end;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: 20px;
}

li.box_column_list {
  color: #858fa3;
  ;
  cursor: pointer;
  font-size: 12px;
}

li.box_column_list.activeList {
  background-color: #318fff;
  color: #fff;
  padding: 4px 10px;
  border-radius: 10px;
  padding: 3px 12px 3px 9px;
}

.createConv_close.exittag {
  top: 25px;
  cursor: pointer;
  z-index: 9;
}

.total_files {
  color: #2196f3;
  font-size: 14px;
  font-weight: 400;
}

/* .tag_lists {
  flex-direction: row;
  width: calc(100% - 35px);
  grid-gap: 5px;
  gap: 5px;
  margin-left: 10px;
} */
.tag_lists {
  width: calc(100% - 20px);
  gap: 5px;
  margin-left: 7px;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

}

.tag_lists span {
  text-overflow: ellipsis !important;

}


.slick-next:before,
.slick-prev:before {
  color: #6F8DBF;
}

.tags_Color {
  color: #6f8dbf;
  font-weight: 400;
  padding: 2px 10px;
  border-radius: 4px;
  background-color: #e3f2fe;
  font-size: 14px;
  display: flex;
  /* width: 150px; */
  padding-left: 0px;
}

.tags_Icon_close {
  cursor: pointer;
  color: rgb(0, 0, 0);
  border: 1px solid rgb(155, 155, 155);
  position: relative;
  border-radius: 50%;
  padding: 2px;
  top: 4px;
  margin-left: 8px;
}

li._afileName p.room_name {
  position: absolute;
  left: 61px;
  top: 30px;
}

.fileBody_area {
  height: calc(100vh - 400px);
  overflow: auto;
}

.fileBody_area.linkBody {
  height: calc(100vh - 375px);
  overflow: auto;
}

.fileBody_areaLink {
  height: calc(100vh - 215px) !important;
  overflow: auto;
}

.Advance_search_top {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 100%;
  padding: 0px 30px;
  border-bottom: 1px solid #d9e2e8;

}

/* .newClass{
  display: flex;
  padding: 0px 0px !important;
  justify-content:  !important;
} */
.Advance_item {
  display: flex;
}

.Advance_search_text.Advance_item {
  /* flex-basis: 15%; */
  flex-basis: 148px !important;
  padding: 20px 0px;
  color: #2196f3;
  font-size: 14px;
  border-right: 1px solid #d9e2e8;
  cursor: pointer;

}

.Advance_search_list.Advance_item select.Advance_search_box {
  height: 30px;
  width: 92%;
  border: 1px solid #d9e2e8;
}

.Advance_search_list.Advance_item {
  flex-basis: 25%;
  padding: 15px 20px 0 20px;
  border-right: 1px solid #d9e2e8;
}

.Advance_search_list.Advance_item {
  flex-basis: 20%;
  position: relative;
}

.Advance_search_bottom .searchAndFilterBar {
  flex-basis: 50%;
}

.Advance_search_bottom {
  display: flex;
  min-height: 90px;
  border-bottom: 1px solid #d9e2e8;
  max-height: 200px;
}

input._inputBar.searchLeft {
  height: 33px !important;
  background-size: 15px !important;
  border-radius: 25px;
  background-image: unset;
  border: solid 1px #d9e2e8;
  background-color: #fff;
  font-size: 14px;
  padding-right: 30px;

}

input._inputBar.searchLeft:focus {
  outline: none !important;
  border-color: #2196f3;
  box-shadow: 0 0 3px #2196f3;

}

input._inputBar.searchRight:focus {
  outline: none !important;
  border-color: #2196f3;
  box-shadow: 0 0 3px #2196f3;
  border: solid 1px #318fff;
  background-color: #fff;

}

.start_Date:focus {
  outline: none !important;
  border-color: #2196f3;
  box-shadow: 0 0 3px #2196f3;
  border: solid 1px #318fff;
  background-color: #fff;

}

.errorDate {
  outline: none !important;
  border-color: red;
  box-shadow: 0 0 3px red;
  border: solid 1px red;
  background-color: #fff;

}

input._inputBar.searchRight {
  height: 33px !important;
  background-size: 15px !important;
  border-radius: 25px;
  background-image: unset;
  border: solid 1px #d9e2e8;
  background-color: #fff;
  font-size: 14px;
  padding-right: 30px;
}

.searchAndFilterBar.LeftSide {
  border-left: 1px solid #d9e2e8;
}

.searchAndFilterBar.righSide {
  border-right: 5px solid #d9e2e8 !important;
}

.searchAndFilterBar .searchAndFilter {
  padding: 16px 30px 8px;
  height: 47px;
  box-sizing: border-box;
}

.selectedRoomCont {
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: 5px;
  overflow: auto;
  max-height: 100px;
}

.selectedRoomCont span {
  margin-right: 5px;
  margin-bottom: 5px;
}

.Advance_search {
  background-color: #FCFDFE;
}


.Advance_search_text.Advance_item span {
  margin-left: 5px;
}

.reset_icon.Advance_item {
  padding-left: 20px;
  padding-top: 20px;
  flex-basis: 10%;
  cursor: pointer;
}

span.tagNameText {
  position: relative;
  top: -6px;
  left: 5px;
}

span.tagWith_icon {
  position: relative;
  margin-left: 40px;
  top: 5px;
}

span.calenderIcon {
  padding-left: 15px;
  padding-top: 20px;
  padding-right: 10px;
  color: var(--PrimaryC);
}

#root.dark span.calenderIcon {
  color: #ffff;
}

span.calenderIcon2 {
  /* padding-left: 20px; */
  padding-top: 20px;
  padding-right: 13px;
  color: var(--PrimaryC);
}

#root.dark span.calenderIcon2 {
  color: #ffff;
}

input.end_Date {
  width: 100%;
  border: 1px solid #d9e2e8;
  margin-top: 15px;
  height: 35px;
  padding-left: 20px;
  border-radius: 5px;
  color: #9b9b9b;
  font-size: 14px;
  line-height: 35px;
}

input.start_Date {
  width: 100%;
  border: 1px solid #d9e2e8;
  margin-top: 15px;
  height: 35px;
  padding-left: 20px;
  border-radius: 5px;
  color: #9b9b9b;
  font-size: 14px;
  line-height: 35px;
}

input.start_Date:focus {
  border-color: #2196f3;
  box-shadow: 0 0 3px #2196f3;
}

span.fromDate {
  padding-top: 20px;
  padding-right: 20px;
}

span.fromTo {
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 35px;
}

span.refreshText {
  color: #fff;
  font-size: 16px;
  padding-right: 10px;
  line-height: 50px;
}

svg.refresh_reload_icon {
  color: #fff;
  cursor: pointer;
}

.tagFlieList_body {
  margin-top: 20px;
  max-width: calc(1920px - 400px);
}

.Advance_search_list.Advance_item select option:first-child {
  color: green;
}

select[name="fontSize"] option:nth-child(1) {
  color: green;
}

input#_inputBar_tagdetails:focus {
  outline: none !important;
  border-color: #2196f3;
  box-shadow: 0 0 3px #2196f3;
}

.tag_room_list {
  position: absolute;
  left: 30px;
  width: calc(100% - 60px);
  background-color: #ffffff;
  top: 49px;
  z-index: 99;
  box-sizing: border-box;
  padding: 5px 5px;
  max-height: 336px;
  overflow-y: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 5px;
}

.tag_room_list p {
  border-bottom: 1px solid #eee;
}

.tag_room_list p:hover {
  background-color: #0c1e47;
  color: #ffffff;
}

p._tag_rooms {
  border-bottom: 1px solid #e3f2ff;
  font-size: 14px;
  padding: 4px 10px;
}

p._tag_rooms:hover {
  color: #fff;
}

.custom_dropdown {
  width: 100%;
  border-radius: 5px;
  background-color: white;
  height: 35px;
  border: 1px solid #d9e2e8;
}

.custom_dropdown-header {
  padding: 6px 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #9b9b9b;
  font-size: 13px;
}

.custom_dropdown-body {
  padding: 5px;
  border-top: 1px solid #E5E8EC;
  display: none;

}

.custom_dropdown-body.open {
  display: block;
  position: absolute;
  top: 50px;
  width: 86%;
  background-color: #ffffff;
  z-index: 99;

}

.custom_dropdown-item {
  padding: 7px;
  border-bottom: 1px solid #eee;
  color: #0d2150;
  font-size: 13px;
}

.custom_dropdown-item:hover {
  cursor: pointer;
  background-color: #023d67;
  color: #fff
}

.custom_dropdown-item-dot {
  opacity: 0;
  color: #023d67;
  transition: all .2s ease-in-out;
}

.custom_dropdown-item-dot.selected {
  opacity: 1;
}

.custom_dropdown-item.selected {
  background-color: #023d67;
  color: #fff;
}

.custom_icon {
  font-size: 13px;
  color: #91A5BE;
  transform: rotate(0deg);
  transition: all .2s ease-in-out;
}

.custom_icon.open {
  transform: rotate(90deg);
}

p.tagNotFound {
  font-size: 14px;
}

.slick-slide span.tags_Color {
  width: auto !important;
}

.slick-slide {
  width: auto !important;
  margin: 8px 5px;
}

.slick-slide.slick-cloned {
  width: auto !important;
  margin: 8px 5px;
}

/* .slick-track {
  width: auto !important;
} */
div#infiniteScroll .fileBodyRow ._afileSize {
  padding-left: 0px !important;
}

.slick-track {
  display: flex;
  justify-content: flex-start;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

.react-datepicker-popper[data-placement^=bottom] {

  inset: -15px auto auto 0px !important;
}

.fromDate_One_tags .react-datepicker-popper {
  left: -66px !important;
}

.fromDate_One_tags .react-datepicker-popper .react-datepicker__triangle {
  left: 160px !important;
}

.fromDate_two_tags .react-datepicker-popper {
  left: -66px !important;
}

.fromDate_two_tags .react-datepicker-popper .react-datepicker__triangle {
  left: 160px !important;
}

/*For black theme*/
#root.dark .tagFile_column.first_column {
  color: #ffffff;
}

#root.dark ul.tagFileLists .tagFileLists_tab {
  color: #ffffff;
}

#root.dark ul.tagFileLists .tagFileLists_tab.activeTab {
  color: #2196f3;
}

#root.dark .tagsHeadText p {
  color: #ffffff;
}

/* .slick-list {
    margin: 0 0 0 34px;
} */
/* .slick-prev {
    left: 5px;
} */
/* .slick-slide {
     left: 20px;;
  } */

span.clearInput::before {
  content: "\f057";
  font-family: var(--FA);
  font-weight: 600;
  color: var(--NewRed);
  font-size: 20px;
  position: relative;
  top: 20px;
  left: -40px;
  cursor: pointer;
}

p.tagSearchNotFound {
  position: relative;
  left: 40%;
  top: 50%;
}

.tagNotFound {
  text-align: center;
}

.search_total_area {
  display: flex;
  /* background-color: #d9e2e8; */
  max-width: calc(1920px - 400px);
  border-right: 1px solid #d9e2e8;
}

.search_right_area {
  width: 84%;
  border-right: 1px solid #d9e2e8;
}

.serarchButton.area {
  border-bottom: 1px solid #eee;
  /* flex-basis: 16%; */
  width: 16%;
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
}

.buttonList {
  padding: 15px 15px;
  width: 160px;
}

.buttonsDesign {
  text-align: center;
  background-color: #2196f3;
  color: #fff;
  border-radius: 25px;
  height: 40px;
  margin-bottom: 10px;
  cursor: pointer;
}

span.goText {
  color: #fff;
  font-size: 14px;
  padding-right: 10px;
  line-height: 39px;
}

span.columnNum {
  font-weight: normal;
  color: #858fa3;
  font-size: 12px;
  width: auto;
}

.fileBody_area.newHeight {
  height: calc(100vh - 254px);
}

.Advance_search_text.Advance_item span {
  position: relative;
  top: 3px;
}

input._inputSearchBar::-webkit-input-placeholder {
  /* Edge */

  font-size: 14px !important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: rgba(11, 31, 71, 0.5) !important; */
  color: #858fa3 !important;

}

input._inputSearchBar:-ms-input-placeholder {
  /* Internet Explorer 10-11 */

  font-size: 14px !important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(11, 31, 71, 0.5) !important;
}

input._inputSearchBar::placeholder {
  font-size: 14px !important;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /* color: rgba(11, 31, 71, 0.5) !important; */
  color: #858fa3 !important;
}

.tagFileLists_tab {
  font-size: 18px !important;
  font-weight: normal;
}

#root.dark ol.custom_breadcrumb li:first-of-type {
  color: #fff !important;
}

#root.dark .custom_breadcrumb li+li:before {
  color: #ffffff;

}

span.counter {
  position: relative;
  left: 5px;
  top: -6px;
}

._inputBar.searchRight::placeholder {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #858fa3 !important;
}

._inputBar.searchLeft::placeholder {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #858fa3 !important;
}

input.start_Date::placeholder {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(11, 31, 71, 0.5);
}

input.end_Date::placeholder {
  font-size: 14px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: rgba(11, 31, 71, 0.5);
}

/* fromDate_One */
.searchAndFilterBar.rightSide .searchAndFilter {
  height: 50px;
  display: inline-block;
}

.searchAndFilterBar.LeftSide .searchAndFilter {
  height: 50px;
  display: inline-block;
}

.tagFlieList_body .taskListTableHead ul {
  border: solid 1px #e5eef4;
  border-top: unset;
}

.starredFiles {
  padding: 6px 14px 7px 12px;
  border-radius: 16px;
  background-color: #318fff;
  width: 180px;
  height: 33px;
  margin-left: 30px;
  cursor: pointer;
}

.starredFiles span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-left: 7px;
  position: relative;
  top: -2px;

}

.filesText {
  width: 115px;
  height: 33px;
  margin: 0 11px 1px 13px;
  padding: 6px 14px 7px 13px;
  border-radius: 16px;
  background-color: #318fff;
  cursor: pointer;
}

.filesText span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-left: 7px;
  position: relative;
  top: -2px;
}

.filesRefType {
  width: 205px;
  height: 33px;
  margin: 0 11px 1px 0px;
  padding: 6px 14px 7px 13px;
  border-radius: 16px;
  background-color: #318fff;
  cursor: pointer;
}

.filesRefType span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin-left: 7px;
  position: relative;
  top: -2px;
}

.workfreeliFileText {
  color: #032e84;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  position: relative;
  top: -4px;
  text-overflow: ellipsis;
  width: auto;
  overflow: hidden;
  line-height: 16px;
}

#root.dark .workfreeliFileText {
  color: #ffffff;
}

#root.dark .Advance_search_bottom,
#root.dark .Advance_search_top {
  background-color: #181818;
}

#root.dark li._afileSize span {
  color: #ffffff !important;
}

#root.dark li._afileSize p {
  color: #fff;
}

.workfreeliDOCX {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #858fa3;
  position: absolute;
  left: 59px;
  top: 20px;
}

.timeAndDate {
  font-size: 12px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #032e84;
}

ul.colorGroup {
  list-style-type: none;
  margin: 0 0 0 5px;
  padding: 0;
  border: unset !important;
  display: flex;
  /* z-index: 9; */

}

li.colorGroupList {
  width: 14px;
  height: 14px !important;
  border-radius: 50%;
  border: 1px solid #fff;
  display: inline-block !important;
  position: relative !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  -webkit-transition: .2s ease;
  transition: .2s ease;
}

/* li.colorGroupList:nth-child(1) { background: #e82b87; }
li.colorGroupList:nth-child(2) { background: #318fff; }
li.colorGroupList:nth-child(3) { background: #a6d068; } */
li.colorGroupList:nth-child(n+2) {
  margin-left: -7px;
}

.ovalCircle {
  width: 9px;
  height: 9px;
  background-color: #e3f2fe;
  border-radius: 50%;
}

.ovalCircle.ovalActive {
  background-color: #318fff
}


.tagareaList {
  position: relative;
  z-index: 999;
  max-height: 100px;
  max-width: 400px;
  border-radius: 3.2px;
  box-shadow: 0 2px 32px 0 rgb(0 0 0 / 18%);
  border: solid 0.8px #e3f2fe;
  background-color: #fff;
  padding: 40px 30px 30px 30px;
  right: 29px;
  top: -10px;
  justify-content: flex-start;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  min-width: 250px;
}

.tagareaList span {
  padding: 3px 8px 4px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
  margin: 5px 3px 0 3px;
  width: auto;

}

.fromDate_One {
  width: 50%;
  position: relative;
  padding-right: 14px !important;
}

.fromDate_One_tags {
  width: 50%;
  position: relative;
  padding-right: 14px !important;
}

.fromDate_One_tags .react-datepicker-wrapper {
  width: 100% !important;
}

.fromDate_two_tags {
  width: 50%;
  position: relative;
  padding-right: 14px !important;
}

.fromDate_two_tags .react-datepicker-wrapper {
  width: 100% !important;
}

.fromDate_OneLink {
  width: 50%;
  margin-right: 20px;
}

span.clearInput {
  position: absolute;
}

.fromDate_two {
  width: 50%;
  /* margin-left: 20px; */
  position: relative;
  padding-right: 14px !important;
}


.fromDate_twoLink {
  width: 50%;
  margin-left: 20px;
}

.buttonsDesign.reset_button.inactiveButton,
.buttonsDesign.go_button.inactiveButton,
.SearchButton.inactiveButton {
  opacity: 0.5;
  pointer-events: none;

}

.fileBody_area.advanceFlieList_body {
  height: calc(100vh - 260px);
}

.fileBody_area.advanceFlieList_full_body {
  height: calc(100vh - 420px);
}

.grid_item:hover {
  background-color: #d9d9d985;
}

.fileBody_area .fileBodyRow:hover {
  background-color: #d9d9d985;
}

#root.dark svg.iconColor {
  color: #fff !important;
}

/*End tag new design*/
/* start design checkTrusted_area */
.checkTrusted_area {
  width: 100%;
}

/* .rad-label {
  display: flex;
  align-items: center;

  border-radius: 100px;
  padding: 14px 16px;
  margin: 10px 0;

  cursor: pointer;
  transition: .3s;
}

.rad-label:hover,
.rad-label:focus-within {
  background: hsla(0, 0%, 80%, .14);
}

.rad-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.rad-design {
  width: 22px;
  height: 22px;
  border-radius: 100px;

  background: linear-gradient(to right bottom, hsl(154, 97%, 62%), hsl(225, 97%, 62%));
  position: relative;
}

.rad-design::before {
  content: '';

  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;

  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: .3s;
}

.rad-input:checked+.rad-design::before {
  transform: scale(0);
}

.rad-text {
  color: #565656;
  margin-left: 14px;
  text-transform: none;
  font-size: 18px;
  font-weight: normal;
  transition: .3s;
}

.rad-input:checked~.rad-text {
  color: hsl(0, 0%, 40%);
} */
.radioButtons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.radioButtons label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
  /* Accessible outline */
  /* Remove comment to use */
  /*
  	&:focus-within {
  			outline: .125em solid $primary-color;
  	}
  */
}

.radioButtons label input {
  position: absolute;
  left: -9999px;
}

/* .radioButtons label input:checked + span {
  background-color: #d6d6e5;
} */
.radioButtons label input:checked+span:before {
  box-shadow: inset 0 0 0 0.4375em #003ec7;
}

.radioButtons label span {
  display: flex;
  /* align-items: center; */
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 99em;
  transition: 0.25s ease;
  line-height: 22px;
}

#root.dark .radioButtons label span {
  color: #ffffff;
}

.radioButtons label span:hover {
  background-color: #d6d6e5;
}

#root.dark .radioButtons label span:hover {
  color: #000 !important;
}

.radioButtons label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #003ec7;
}

.container_selectButton {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
}

._afileMark.ovalClick {
  padding: 16px 4px !important;
}

.conv_more.fileIconHover {
  display: none;
}

ul.fileBodyRow:hover .conv_more.fileIconHover {
  display: block;
}

.circleTag {
  display: inline-block;
  border-radius: 50%;
  min-width: 14px;
  min-height: 14px;
  padding: 5px;
  background: #ee6723;
  color: #ffffff;
  text-align: center;
  line-height: 13px;
  box-sizing: content-box;
  white-space: nowrap;
  /* left: 88%;
  transform: translate(5%,0%);
  position: absolute; */
  /* z-index: 1; */
}

.circleTag:before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
  height: 0;
}

.circleTag p {
  display: inline-block;
  vertical-align: middle;
}

.swiper-slide {
  width: auto !important;
}

ul.react-multi-carousel-track li {
  border: none;
  width: auto !important;
  padding: 0;
}

.react-multi-carousel-list {
  max-width: 99%;
}

.react-multi-carousel-list.container ul.react-multi-carousel-track {
  border: unset !important;
}

.react-multi-carousel-track {
  border: unset !important;
}

.react-multiple-carousel__arrow {
  /* background: transparent !important; */
  min-width: 20px !important;
  min-height: 20px !important;
}

.react-multiple-carousel__arrow::before {
  color: #fff;
  font-size: 12px !important;

}

.react-multiple-carousel__arrow--left {
  left: 1px !important;
}

.react-multiple-carousel__arrow--right {
  right: 1px !important;
}

/* .custom-button-group {
  position: absolute;
} */
svg.boardArrow_left {
  position: absolute;
  left: -19px;
  top: 8px;
}

svg.boardArrow_right {
  position: absolute;
  right: 5%;
  top: 8px;
}

.gFileHoverLists.hiddenPop {
  display: none;
}

.gFileHoverListsLink.hiddenPop {
  display: none;
}

.carousel__slider-tray.carousel__slider-tray--horizontal {
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

.carousel * {
  overflow: hidden;
  padding-bottom: 0px !important;
}

.carousel__slide.carousel__slide--visible {
  width: auto !important;
}

.carousel__slide.carousel__slide--hidden {
  width: auto !important;
}

/* .react-datepicker__day.react-datepicker__day--today {
  background-color: #318fff;
  color: #fff;
} */
/* End design checkTrusted_area */


.userMiniModalBody.quick_view_msg .msgReactionContainer:empty {
  display: none;
}

.userMiniModalBody.quick_view_msg .msgReplySection {
  display: none;
}

#root.dark .edit_action_U {
  color: #ffffff;
}

#root.dark span.columnNum.changeColor {

  color: #858fa3 !important;

}

#root.dark ::-webkit-scrollbar {
  border: 1px solid #18181800;
  ;
}

ul.fileBodyRow {
  cursor: pointer;
}

li._afileSize.lastCloumn {
  margin-left: 30px !important;
}

button.buttonBack___1mlaL.carousel__back-button {
  position: absolute;
  z-index: 9999;
  left: -20px;
  top: -2px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: black;
  cursor: pointer;
}

button.buttonNext___2mOCa.carousel__next-button {
  position: absolute;
  z-index: 9999;
  right: -20px;
  top: -2px;
  background: transparent;
  border: none;
  font-size: 20px;
  color: black;
  cursor: pointer;
}

.conv_more.fileIconHover.newone {
  background-size: 20px;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);


}

.rc-tooltip-inner {
  background-color: #222 !important;
  border-radius: 2px !important;
  padding: 8px 7px !important;

}

.rc-tooltip-placement-left .rc-tooltip-arrow,
.rc-tooltip-placement-leftTop .rc-tooltip-arrow,
.rc-tooltip-placement-leftBottom .rc-tooltip-arrow {
  border-left-color: #222 !important;
}

.rc-tooltip {
  font-size: 13px !important;
  opacity: 1 !important;
}

.show_search_headerText {
  padding: 20px 0px;
  color: #2196f3;
  font-size: 16px;
  border-right: 1px solid #d9e2e8;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
}

span.expand_text {
  padding-right: 2px;
}

.show_search_results_area {
  padding: 0 30px;
  width: 100%;
  border-bottom: 1px solid rgb(238, 238, 238);
  display: flex;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 10px;
  max-width: calc(1920px - 400px);
  border-right: 1px solid #d9e2e8;

}

.show_results_eachfiles {
  width: 100%;
  min-height: auto;
}

span.expand_icon {
  position: relative;
  top: 4px;
  cursor: pointer;
  right: 18px;
}

ul.file_count_list {
  display: flex;
  width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

ul.file_count_list li {
  padding-right: 6px;
  padding-left: 10px;
  cursor: pointer !important;
  display: flex;
  align-items: center;
  height: 28px;
  margin: 0 2px;
  padding-top: 2px;
}

.show_search_headerText.newt {
  border-right: unset !important;
  width: 212px;
  padding-top: 0;
  padding-bottom: 0;
}

li.file_ul_list.liststyl {
  /* border-right: 1px solid #d9e2e8; */
  cursor: pointer;

}

li.file_ul_list {
  cursor: pointer;
  background-color: #2196f3;
  border-radius: 25px;
}

span.file_count {
  margin-right: 2px;
  color: #002e98;
  font-size: 12px;
  font-weight: 300;
}

li.file_ul_list.active,
li.file_ul_list:hover {
  background-color: #0b1f47;
}

#root.dark span.file_count {
  color: #ffffff;

}

/*======================*/
ul.your_tags {
  display: flex;
  align-items: center;
  list-style: none;
  max-width: 100%;
  overflow-x: scroll;
  scroll-behavior: smooth;
  border: unset !important;
}

ul.your_tags::-webkit-scrollbar {
  background: transparent;
  /* make scrollbar transparent */
  -webkit-appearance: none;
  width: 0;
  height: 0;
}

ul.your_tags li {
  color: #fff;
  padding: 0px !important;
  border-radius: 4px;
  white-space: nowrap;
  border: unset !important;
}

ul.your_tags button {
  border: 0;
  color: #777;
  font-size: 24px;
  margin: 0 8px;
}

button.back_tags {
  background: transparent;
  border: none;
  top: 1px;
  cursor: pointer;
  position: relative;
}

button.prev_tags {
  background: transparent;
  border: none;
  top: 1px;
  cursor: pointer;
  position: relative;
}

#root.dark svg.tagPrevtIcon {
  color: #fff;
}

#root.dark svg.tagNextIcon {
  color: #fff;
}

svg.tagNextIcon.disable {
  background-color: transparent !important;
  color: transparent;
  display: none;
}

svg.tagPrevtIcon.disable {
  background-color: transparent !important;
  color: transparent;
  display: none;
}

button.prev_tags.disable {
  display: none;
  background-color: transparent !important;
}

button.back_tags.disable {
  background-color: transparent !important;
}

.file_count_num {
  display: inline-block;
  color: #000000;
  text-align: center;
  line-height: 3px;
  border-radius: 50%;
  font-size: 12px;
  min-width: 22px;
  min-height: 22px;
  padding: 0px 0px;
  cursor: pointer;
}

.file_count_num span {
  display: inline-block;
  padding-top: 50%;
  padding-bottom: 50%;
  margin-left: 1px;
  margin-right: 1px;
}

#root.dark .file_count_num span {
  color: #ffffff !important;
}

.mediaContainer.chatScreenArea {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  /* justify-content: flex-start; */
}

ul.file_count_list li:hover span {
  color: #ffffff;
}

ul.file_count_list li span {
  color: #ffffff;
}

span.counter_section span {
  color: #ffffff !important;
  font-size: 20px !important;
}

.show_results_eachfiles.innerCounter {
  position: relative;
  top: 10px;
}

h2.popupTitle.tagfileTitle {
  box-sizing: border-box;
  color: #002e98;
  font-size: 20px;
  line-height: 30px;
  font-weight: normal;
  float: left;
  width: 90%;
  margin-bottom: 15px;
  margin-top: 0;
}

#root.dark h2.popupTitle.tagfileTitle {
  color: #002e98 !important;
}

button.saveTitle.deactiveTitlebtn {
  pointer-events: none;
  opacity: 0.5
}

button.saveTitle.activeTitlebtn {
  pointer-events: auto;
  opacity: 1
}

.customTitleFoot.newTitle .saveTitle.activeTitlebtn.saveBtn:hover {
  background-color: #318fff !important;
}

ul.your_tags.your_tags_warp {
  height: auto;
  flex-wrap: wrap;
  margin-left: 12px;
  display: flex;
  justify-content: left;
}

ul.your_tags.your_tags_warp li {
  margin: 3px 0px;
  width: auto;
  max-width: 100%;
}

ul.your_tags.your_tags_warp li span {
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  min-width: auto;
  max-width: 100%;
  color: white;
  display: inline;
}

.filenameAndIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.notFound_container.empty_msg.for_Tag {
  position: absolute;
  top: 20%;
}

.tagsContainerBoxContent.removeBackgroundColor {
  background-color: unset;
  background-color: unset;
  padding-top: 0;
  border: unset;
  margin-top: -10px;
  margin-bottom: -10px;
}

span.closeModal.forTag {
  background-image: url('./media/images/close_button.svg') !important;
}

#root.dark label.container_checkbox {
  color: #fff !important;
}

.miniItem_image.msg_title::before {
  content: "\f086";
  font-family: var(--FA);
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.viewTasks {
  font-size: 14px;
  font-weight: 400;
}

.dashboardBtnArea {
  height: 46px;
  margin: 11px 18px 16px 17px;
  padding: 13px 14px;
  object-fit: contain;
  background-color: #1c3056;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 12px;
  cursor: pointer;
}

.dashboarText {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}

.dashboardIcon {
  background-image: url('./media/images/dashboardIcom.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
}

.leftBtn_area .nodecoration {
  text-decoration: none;
  width: 48%;

}

.backTonavigationBtn {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #2C2A6E;
  height: 50px;
  padding: 0 25px;
  z-index: 9;
  cursor: pointer;
}

#root.dark .backTonavigationBtn {
  background-color: rgba(227, 242, 255, 0.08);
}

.backTonavigationArea {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 12px;
}

.backTonavigationText {
  color: #ffffff;
  font-size: 14px;
  font-weight: 300;
}

.backTonavigationIcon {
  background-image: url('./media/images/backIcom.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 16px;
  width: 16px;
  height: 16px;
}

div#VoiceUploadPopupDiv {
  z-index: 1000 !important;
}

.left_adminList p.list_container_title {
  background-image: url('./media/images/backIcom.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 300px 8px;
  margin-left: 0;
  background-color: transparent;
}

.left_adminList p.list_container_title:hover {
  background-color: #0C2854;

}

.group_container.adminLists {
  margin-top: 0px;
}

.threadConvCont .custom-scrollbars>div {
  margin-bottom: 0 !important;
}

.borderLine {
  border-top: 0.067px solid #475674;
  padding-bottom: 10px;
}

svg.adminsetting_icon {
  position: absolute;
  left: 20px;
}

span.threadIcon {
  content: "";
  background-image: url('./media/images/threadIcom.webp');
  background-repeat: no-repeat;
  background-position: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 12px;
  background-size: 20px;
}

span.privateIcon {
  content: "";
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_private room.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 20px;
  top: 9px;
  background-size: 14px;
}

span.flagIconLeft {
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_flag.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  width: 16px;
  height: 16px;
  position: absolute;
  left: 20px;
  top: 11px;
  background-size: 16px;
}

.Toastify__toast-body {
  font-size: 14px !important;

}

.tagname_icon.fullTagLists {
  display: flex;
  align-items: center;
  gap: 7px;

}

.tagIndivisual span.tagNameText {
  color: #ffffff !important;
  top: -1px !important;
  left: -9px !important;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: auto;
  min-width: auto;
  max-width: 100%;
}

.tagIndivisual {
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 5px;
}

@media screen and (max-width: 1920px) {
  .right_container.tagFileArea.advancedFilePanel {
    width: calc(100% - 350px);
    height: 100vh;
    float: left;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    transition: width 400ms;
    max-width: calc(1920px - 346px);
    border-right: 1px solid #d9e2e8;
  }
}

@media screen and (max-width: 1824px) {

  .date_area.Advance_item.responsive_serch {
    flex-basis: 65% !important;
  }

  .date_area.Advance_item.responsive_item_link {
    flex-basis: 85% !important;
  }
}

@media screen and (max-width: 1600px) {
  .supportAndBillingPlan {
    width: 100%;
    max-width: 45%;
  }

  .supportAndBillingSubsctiption {
    width: 100%;
    max-width: 35%;
  }

  .date_area.Advance_item.responsive_serch {
    flex-basis: 63% !important;
  }

  .date_area.Advance_item.responsive_item_link {
    flex-basis: 83% !important;
  }
}

@media screen and (max-width: 1460px) {
  .supportAndBillingPlan {
    width: 100%;
    max-width: 50%;
  }

  .supportAndBillingSubsctiption {
    width: 100%;
    max-width: 45%;
  }

  .teamManagementTitle {
    font-size: 14px;
    color: #0c1e47;
  }

  .date_area.Advance_item.responsive_serch {
    flex-basis: 60% !important;
  }

  .date_area.Advance_item.responsive_item_link {
    flex-basis: 82% !important;
  }
}

@media screen and (max-width: 1440px) {

  .teamMate_inviteBtn,
  .skipBtn {
    font-size: 26px;
  }

  .conv_more.fileIconHover.newone {
    position: relative;
    left: 10px;
  }

  .gFileHoverLists {
    right: 65px !important;
  }

  .starredFiles {
    width: 244px;
  }

  .filesText {
    width: 140px;
  }

  .filesRefType {
    width: 284px;
  }
}

@media screen and (max-width: 1366px) {
  .FebBody {
    background-position: 100% 130%;
    background-size: 64% 55%;
  }

  .chooseCreateConv {
    width: 600px;
  }

  .participantsModal {
    width: 600px;
  }

  .userActionPopup {
    right: 15px !important;
    width: 160px;
  }

  .createConv_body {
    padding: 20px 40px !important;
  }

  .createConv_back {
    left: 25px;
  }

  input.team-name.short_id_input {
    padding-right: 50px;
    background-color: #0376da;
    color: #ffffff;
  }

  input.short_id_input {
    padding-right: 50px;
  }

  .PlanLeftAreaInner {
    width: 573px !important;
  }

  .supportAndBillingPlan {
    width: 100%;
    max-width: 50%;
  }

  .supportAndBillingSubsctiption {
    width: 100%;
    max-width: 45%;
  }

  .freePlanActiveArea {
    font-size: 18px;
  }

  .selectUpgradePlan {
    font-size: 16px;
    margin-top: 5px;
  }

  .currentPlan {
    font-size: 10px;
  }

  .billingAddressFormInput.divBlock {
    display: block;
    float: left;
    width: 100%;
  }

  .billingAddressFormInput.divBlock .billingInput25 {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    margin-top: 10px;
  }

  .smtpTap {
    width: 70%;
  }

  .teamManagementTitle {
    font-size: 14px;
    color: #0c1e47;
  }

  .billingreview {
    margin-right: 0;
    width: 200px;
  }

  .editAddress {
    color: #0c1e47;
    margin-top: 8px;
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }

  .starredFiles {
    /* width: 195px; */
    width: 254px;

  }

  .filesRefType {
    width: 296px;
  }

  .filesText {
    width: 150px;
  }

  .date_area.Advance_item.responsive_serch {
    flex-basis: 59% !important;
  }

  .date_area.Advance_item.responsive_item_link {
    flex-basis: 79% !important;
  }

  .conv_more.fileIconHover.newone {
    position: relative;
    left: 10px;
  }

  .gFileHoverLists {
    right: 65px !important;

  }

  .tagsContainerBoxCon {
    flex-basis: 32%;

  }

  p.editrecurringbillingText {
    position: absolute;
    right: 30px;
    border-bottom: 1px solid #9b9b9b;
    color: #9b9b9b;
    cursor: pointer;
    font-size: 14px !important;
  }

  .profilenavMainSection {
    max-height: calc(100% - 99px) !important;
  }

  span#company_id_name {
    line-height: 15px;
  }

  .profilenavMainSection .linkAccount {
    height: 28px;
    line-height: 28px;

  }

  .themeMode {
    /* height: 36px; */
    height: 44px;
    padding-top: 7px
  }

  .starredFiles span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-left: 7px;
    position: relative;
    top: -2px;

  }

  .filesText span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-left: 7px;
    position: relative;
    top: -2px;
  }

  .filesRefType span {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-left: 7px;
    position: relative;
    top: -2px;
  }


}

/*========= End 1366px media query */
/*1200px */

.parentSummary {
  float: left;
  width: 100%;
  height: 360px;
  overflow: auto;
}

.supportAndBillingPlan .userTableHead {
  height: 312px;
  float: left;
  width: 100%;
  overflow: auto;
}

span.downarrow.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.smallText {
  font-size: 12px;
}

.billingreview.hidden {
  visibility: hidden;
}

.uparrow.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.backwrap.for_thread {
  width: calc(100% - 346px);
  height: 100vh;
  float: left;
  position: absolute;
  top: -1px;
  left: 346px;
}

.archive_ico {
  background-image: url('./media/images/box-archive-solid.svg');
  width: 18px;
  height: 18px;
  margin-right: 4px;
}

.conv_list.line_through .conv_list_title {
  -webkit-text-decoration-line: line-through;
  text-decoration-line: line-through;
}

.conversation_close .user_msg,
.conversation_close .fileBodyRow,
.conversation_close .opt_icons.conv_voice,
.conversation_close .inputGroup,
.conversation_close .addMemberBtn,
.conversation_close .name_and_profile {
  pointer-events: none !important;
}

.conversation_archived .fileOpts.delete_opts,
.conversation_archived .opts.more_opts,
.conversation_archived .opts.forward_opts,
.conversation_archived .opts.task_opts,
.conversation_archived .opts.tag_opts,
.conversation_archived .opts.emoji_opts,
.conversation_archived .opts.reply_opts,
.conversation_archived .opts.edit_opts,
.conversation_archived .fileOpts.star_opts,
.conversation_archived .fileOpts.tag_opts,
.conversation_archived .fileOpts.share_opts,
.conversation_archived .msgFlagReplyOption,
.conversation_archived .fileOpts.forward_opts {
  display: none;
}

.for_show_msg {
  width: 100%;
  height: 35px;
  background-color: var(--NewRed);
  display: flex;
  align-items: center;
  color: #fff;
  font-weight: normal;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  position: absolute;
  /* bottom: 80px; */
  bottom: 0px;
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
  z-index: 11;
}

.my_room::before {
  content: "\f0b0";
  font-family: "FontAwesome";
  color: #fff;
  font-size: 16px;
  position: absolute;
  font-weight: 600;
  left: -15px;
  top: 6px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;

}

.my_room.active::before {
  color: var(--NewRed);
}

.for_user_mini_loader {
  width: calc(100% - 132px);
  height: 100px;
  border: none;
  float: right;
}

.no_result_left {
  width: 100%;
  display: block;
  padding: 8px 20px;
  box-sizing: border-box;
  color: #fff;
  /* background: #2295f3; */
}

.no_result_left span {
  /* width: 100%; */
  float: left;
  text-align: center;
  padding: 4px 0px;
  line-height: 26px;
}

.btn_middle .quick_btn {
  width: auto;
  padding: 6px 14px;
  border-radius: 4px;
  margin-top: 12px;
  background: #FFF;
  border: none;
  cursor: pointer;
  color: #0d1e47;
}

.btn_middle .quick_btn:hover {
  background: #e91e63;
  color: #FFF;
}

.btn_middle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.optionalList.moreOpt_list.inactive::before {
  content: "";
}

.filtering_option {
  position: absolute;
  background: #fff;
  z-index: 1;
  box-sizing: border-box;
  border-radius: 4px;
  width: auto;
  right: 50px;
  color: #0d1e47;
}

.filtering_option li {
  padding: 0px 32px;
}

.filtering_option li:before {
  font-family: "FontAwesome";
  font-weight: 600;
  position: absolute;
  left: 12px;
  margin-top: 2px;
}

.filtering_option li:hover {
  background-color: rgba(22, 118, 234, 0.38);
}

.event_none {
  pointer-events: none;
}

.quick_private_view {
  position: absolute;
  background: #0d1e47;
  color: #fff;
  z-index: 1;
  padding: 12px 12px;
  box-sizing: border-box;
  border-radius: 4px;
}

.ttd._1_ {
  width: 80%;
  text-indent: 78px;
  font-size: 18px;
}

.ttd._2_ {
  width: 20%;
  font-size: 18px;
}

li.fileTypeLi.list._tagD.tagMainFilterLi.tagListHeader {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  z-index: 1;
}

.quick_view_msg .file_hover_option {
  display: none !important;
}

.quick_view_msg .fileTagHolder {
  pointer-events: none;
}

.quick_view_msg .user_msg:hover .msgFlagReplyOption div {
  visibility: hidden;
}

.quick_view_msg .secrectUserName li.withTooltip {
  display: none !important;
}

.quick_view_msg .sender_name {
  pointer-events: none;
}

.quick_view_msg .sender_img {
  pointer-events: none;
}

.guestJoinPopup {
  width: 50px;
  padding-top: 35px;
}

.for_show_msg .button {
  width: auto;
  padding: 4px 8px;
  background-color: #FFF;
  color: #0d1e47;
  border-radius: 4px;
  margin-left: 20px;
  cursor: pointer;
  border: none;
}

.privateMsgContainer .privateMsgV:hover {
  text-decoration: underline;
}

.billingCardHolderName:focus {
  outline: 0;
  border-color: hsla(210, 96%, 45%, 50%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.03), 0px 3px 6px rgba(0, 0, 0, 0.02), 0 0 0 3px hsla(210, 96%, 45%, 25%), 0 1px 1px 0 rgba(0, 0, 0, 0.08);
}

.span-text-red {
  color: rgb(223, 27, 65);
  font-size: 15px;

}


.miniItem_image .nameLetters {
  width: 26px;
  height: 26px;
  font-size: 12px;
}

.memberItemDesignation.rightAlign {
  position: absolute;
  right: 10px;
  top: 20px;
}

.removeAdmin.rightAlign {
  background-color: #002e98;
}

.participantTitleTeams {
  font-size: 12px;
  margin-left: 4px;
  color: #003ec7;
}

.hide {
  display: none !important;
}

.opts.edit_opts.edit_newpen {
  background-image: url(./media/images/icons/whiteTheme/SVG/edit_pen.svg);
}

.saveTitle.inactive {
  opacity: 0.5;
}

.send_voice_opt {
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* right: 58px; */
  /* bottom: 24px; */
}

.send_voice_opt>div {
  /* width: 34px; */
  /* height: 34px; */
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 34px;
  /* margin: 10px; */
  /*background-color: #0C1E47;*/
  /* border-radius: 50%; */
  /* margin: 0 7px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: 50%; */
  /* background-size: 100%; */
}

.voice-icon.del {
  color: red !important;
}

.voice_upload_label.voice-start {
  background-image: url("./media/images/icons/whiteTheme/SVG/voice-icon-play.svg");

}

.voice_upload_label.voice-pause {
  background-image: url("./media/images/icons/whiteTheme/SVG/voice-icon-pause.svg");

}

.voice_upload_label.voice-stop {
  background-image: url("./media/images/icons/whiteTheme/SVG/voice-icon-stop.svg");

}

.voice_device_error {
  text-align: center;
  color: red;
}

.voice-icon {
  color: #0A256A;
  font-size: 45px;
}

.MyUl {
  box-sizing: border-box;
  font-size: 20px;
  line-height: 32px;
  color: #E91E63;
}

.temp_user:hover .temp_user_remove {
  display: block;
}

.temp_user_remove {
  display: none;
}


.edit_action_U {
  margin-left: 6px;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer;
}

.checkBOxCustom {
  width: 23px;
  height: 23px;
  float: left;
  border-radius: 100%;
  margin-right: 8px;
  border: 2px solid #d8d8d8;
  cursor: pointer;
}

.checkBOxCustom.active {
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
  background-image: url('./media/images/circleCheckgreen.svg');
  border-color: green;
}

span.fileUploadWarning {
  margin-bottom: 6px;
  display: block;
  color: red;
}

.fileTypeLi.list.active {
  background-image: url(./media/images/Check-Circle.svg);
  background-repeat: no-repeat;
  background-size: 14px;
  background-position-x: 90%;
  background-position-y: 50%;
}

.inactiveUser {
  color: red;
  margin-left: 4px;
  font-size: 12px;
}

.quick_view_msg .miniItem {
  width: auto;
  float: left;
  height: 31px;
  box-sizing: border-box;
  background: #003ec7;
  border-radius: 4px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 10px;
}

.quick_view_msg .user_msg {
  padding: 4px;
}

.tagsContainer {
  height: calc(100% - 150px);
  overflow: auto;
}

.emojiContainer {
  position: absolute;
  top: -410px;
  /* overflow: auto;
  height: 320px; */
}

.flex-column {
  height: 350px !important;
}

.emoji-mart,
.emoji-mart-light {
  bottom: 5px;
  position: absolute !important;
  /* right: 52px; 
   left:52px; */
  border: 1px solid #d9d9d9 !important;
  width: auto !important;

}

.emojiPlus {
  background-image: url('./media/images/AddEmoji_White.svg');
  /* background-position: 100%; */
  background-size: 100%;
  margin-right: 15px !important;
}

.emojiPlus_file {
  background-image: url('./media/images/AddEmoji_White.svg');
  /* background-position: 100%; */
  background-size: 100%;
  margin-right: 10px !important;
}

#root.dark .emojiPlus_file {
  background-image: url('./media/images/AddEmoji_Dark.svg');
  /* background-position: 100%; */
  background-size: 100%;
  margin-right: 10px !important;
}

.emojiClose {
  background-image: url("./media/images/cancel_round_red.svg");
  /* background-position: 100%; */
  background-size: 100%;
  margin-right: 15px !important;
}

.emojiClose_file {
  background-image: url("./media/images/cancel_round_red.svg");
  /* background-position: 100%; */
  background-size: 100%;
  margin-right: 10px !important;
}

.emojiCloseNew {
  background-image: url(./media/images/cancel_round_red.svg);
  background-repeat: no-repeat;
  cursor: pointer;
  z-index: 9999;

  width: 20px;
  height: 20px;
  margin: auto 10px;

}

.emoji-mart,
.emoji-mart-light {
  width: 100% !important;
}

.filUpload-wrapper {
  /* border: 1px solid #b8d4ed; */
  width: 100%;
  float: right;
  height: 250px;
  /* border-radius: 6px; */
  font-size: 16px;
  /* padding: 11px 200px 8px 45px; */
  display: flex;
  justify-content: flex-start;
  word-break: break-word;
  line-height: 28px;
  resize: none;
  position: relative !important;
}

.editMsgContainer .demo-wrapper {
  padding: 10px;
}

.emoji-mart-emoji span {
  width: 35px !important;
  height: 35px !important
}

.emoji-mart-anchors svg,
.emoji-mart-anchors img {
  fill: currentColor;
  height: 20px;
  width: 30px;
}

.taskEmoji .emoji-mart-emoji span {
  width: 25px !important;
  height: 25px !important
}

.taskEmoji .emoji-mart-anchors svg,
.taskEmoji .emoji-mart-anchors img {
  fill: currentColor;
  height: 15px;
  width: 15px;
}

.linkTextTable {
  word-break: break-all;
  color: #032e84;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #032e84;
  position: relative;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  line-height: 20px;
  /*  white-space: nowrap;*/
}

ul.fileBodyRow:hover .workfreeliFileTextLink,
ul.fileBodyRow:hover .linkTextTable {
  color: #032e84;
}

.fileBody_area .fileBodyRow:hover .linkTextTable.newText {
  color: #000000;
  font-weight: normal;
}

#root.dark ul.fileBodyRow:hover .workfreeliFileTextLink,
ul.fileBodyRow:hover .linkTextTable.newText {
  color: #ffffff;
}

#root.dark .linkTextTable.newText {
  color: #ffffff;
}

#root.dark .tagsBoxText.files p {
  color: #ffffff !important;
}

#root.dark .grid_item:hover .tagsBoxText.files p {
  color: #ffffff !important;
}

#root.dark ul.fileBodyRow:hover a.linkTextTable {
  color: #94B2FF;
  font-weight: normal;
}

.msg_hover_optionsLink {
  min-width: auto;
  border-radius: 4px;
  border: 1px solid #858fa3;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  bottom: 15px;
  background-color: #ffffff;
  z-index: 1;
}

.workfreeliFileTextLink {
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  position: relative;
  top: -2px;
  color: #032e84;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

#root.dark .workfreeliFileTextLink {
  color: white;
}

#root.dark .msg_hover_optionsLink {
  background-color: black;
}

.td_conversation_name {
  width: 15%;
  padding-left: 0px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dissableReset {
  opacity: 0.5;
  pointer-events: none;
}

@media (max-width: 1320px) {
  .responsive_item {
    flex-basis: 70% !important;
  }
}

@media (max-width: 1100px) {
  .responsive_item {
    flex-basis: 60% !important;
  }

  ul.tagFileLists .tagFileLists_tab.activeTab:after {
    content: "";
    position: absolute;
    width: 55px;
    height: 5px;
    left: -6px;
    bottom: 43px;
    border-radius: 4px;
    background-color: #318fff;
  }

  .right_container_fileHub ul.tagFileLists {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    position: relative;
    padding-right: 80px;
    justify-content: flex-start;
  }

  .right_container_fileHub .tagFileLists_tab {
    flex: 0 0 30%;
    box-sizing: border-box;
    margin-right: 2.66%;
    cursor: pointer;
  }

}

.view_opts.color_big:before {
  color: #0c1e47 !important;
  font-size: 20px;
}

p._tag_rooms:hover {
  background-color: #023d67;
  color: white;
}

.selected {
  background-color: #243A66 !important;
  color: white !important;
}

#root.dark .selected {
  background-color: #40404050 !important;
}

.react-datepicker__header select {
  padding: 5px;
  font-size: 16px;
  font-weight: bolder;
  border: none !important;
  background-color: white;
}

.react-datepicker__header button {
  font-size: 20px;
  font-weight: bolder;
  border: none !important;
  /* color: #2a87d0; */
  /* padding: 0px 10px; */
  color: #0b1f47;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;


}

.CalendarDiv {
  margin: 10px;
  display: flex;
  justify-content: space-evenly;
  background-color: white;
  border: 1px solid #2a87d0;
  border-radius: 20px;
  padding: 1px 0px;

}

.ShowAllBtn {

  font-size: 16px;
  font-weight: 400;
  text-align: center;
  background-color: #2196f3;
  color: #fff;
  border-radius: 20px;
  height: 40px;
  /* margin-bottom: 10px; */
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  padding: 10px 28px;
}


/* .counter_section{
  border-radius: 4px;
  padding: 8px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding-left: 0 !important;
} */
.counter_section {
  border-radius: 4px;
  padding: 8px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding-left: 0 !important;
  position: absolute;
  left: 7px;
  padding-right: 5px;
  bottom: 10px;
}

.counter_section.innnerResult {
  padding: 8px;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: auto;
  padding-left: 0 !important;
  position: relative;
  padding-top: 0 !important;
}

span.counterText.innnerText:hover {
  background-color: #003ec7;
}

.counterText {
  font-size: 20px;
  color: #2196f3;
}

.counterN {
  /* background: #ee6723; */
  color: #000000;
  padding: 6px;
  width: auto;
  border-radius: 100%;
  margin-left: 1px;
  font-size: 16px;
  padding-left: 0px;
}

span.counterText.innnerText {
  color: #fff !important;
  font-size: 14px !important;
  background-color: #2196f3;
  border-radius: 25px;
  padding: 2px 8px 4px 8px;
}

span.counterText.innnerText span.counterN {
  color: #fff !important;
  font-size: 14px !important;
}

#root.dark span.counterText.innnerText {
  color: #ffffff !important;
}

#root.dark span.counterText.innnerText span.counterN {
  color: #ffffff !important;
}

.securityPopup {
  height: 400px;
  width: 100%;

}

.newDevice {
  background-color: #318FFF;
  border-radius: 15px;
  color: white;
  padding: 2px 10px;
  margin-left: 7px;
}

.security_body {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0px 20px 20px 20px;
}

.security_body .body1 {
  font-weight: bold;
  font-size: 20px;
  color: #0C1E47;
  padding-bottom: 5px;
}

.security {
  padding: 15px;
  border: 1px solid #d8d8d8;
  width: 90%;
  /* height:calc(100% - 300px); */
  height: 360px;
  border-radius: 10px;
  margin-top: 40px;
  ;
  /* display: table; */
  overflow: hidden;
  background-image: linear-gradient(142deg, #FDF0F6 33%, rgba(166, 208, 104, 0.4) 70%);
}

.security_head {
  /* margin:10px; */
  font-weight: bold;
  font-size: 20px;
  padding: 0px 10px 10px 10px;
}

.security table {
  width: 100%;
  padding-bottom: 10px;
  height: 240px;
  overflow-y: scroll;
  display: block;
  background-color: white;
}

.security table tr {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d8;
  padding: 13px 0px 13px 10px;
  color: gray;
  font-size: 15px;
  cursor: pointer;
}

.security table tr:hover {
  background-color: #EBEBEB;
  cursor: pointer;
}

.HasColor {

  color: gray;
  font-size: 15px;

}

.deviceInfo {
  display: flex;
  justify-content: left;
  width: auto;
  padding: 10px 0px;

}

.deviceInfo2 {
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
}

.deviceInfo1 {
  width: 80px;
  height: 60px;
  margin: auto 20px auto 0px;
}

.ButtonGroup {
  display: flex;
  justify-content: space-between;
  padding: 10px 0px 0px 0px;
  width: 100%;
}

.ButtonGroup div {
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  width: 50%;
  padding: 5px 10px;

}

.NotSecure {
  margin-right: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: red;
}

.YesSecure {
  margin-left: 5px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 15px;
  color: #318FFF;
}

span.yesIcon::before {
  width: 24px;
  height: 24px;
  content: "\f00c";
  font-family: var(--FA);
  font-weight: 600;
  color: #318FFF;
  font-size: 15px;
  padding: 3px 10px 0px 0px;

}

span.noIcon::before {
  width: 24px;
  height: 24px;
  content: "\f00d";
  font-family: var(--FA);
  font-weight: 600;
  color: red;
  font-size: 15px;
  padding: 3px 12px 0px 0px;
}

.listening {
  width: 100px;
}

.closeConnectSearchVoice {
  position: absolute;
  width: 22px;
  height: 22px;
  /* background-image: url("/voice.png"); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 22px;
  top: 28px;
  right: 40px;
  cursor: pointer;
}

.voiceIcon {
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  justify-content: center;
  background-size: 99%;
  background-repeat: no-repeat;
  padding: 13px;
  right: 10px;
  position: absolute;
  background-image: url(./media/images/loginBefore/gsearch.webp);
  margin: 0px 27px 0px 10px;
  border-radius: 100%;
  z-index: 9999 !important;
  cursor: pointer;
}

.voiceIcon1 {
  cursor: pointer;
  justify-content: center;
  background-size: 99%;
  justify-content: center;
  background-repeat: no-repeat;
  padding: 13px;
  right: 10px;
  /* z-index: 5; */
  position: absolute;
  background-image: url(./media/images/loginBefore/gsearch.webp);
  margin: 0px 27px 0px 10px;
  border-radius: 100%;
}

.openAiIconMsgBox {
  width: 20px;
  height: 20px;
  justify-content: center;
  background-size: 99%;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url(./media/images/openai-svgrepo-com.svg);
  border-radius: 100%;
  cursor: pointer;
  top: 42px;
  left: 50px;
}

#root.dark .openAiIconMsgBox {
  width: 20px;
  height: 20px;
  justify-content: center;
  background-size: 99%;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url(./media/images/openai-svgrepo-com_white.svg);
  border-radius: 100%;
  cursor: pointer;
  top: 42px;
  left: 50px;
}

.voiceIconMsgBox {
  width: 22px;
  height: 22px;
  animation-name: pulse;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  justify-content: center;
  background-size: 99%;
  background-repeat: no-repeat;
  position: absolute;
  background-image: url(./media/images/loginBefore/gsearch.webp);
  border-radius: 100%;
  cursor: pointer;
}

.voiceIcon1MsgBox {
  width: 26px;
  height: 26px;
  cursor: pointer;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(./media/images/loginBefore/gsearch.webp);
  border-radius: 100%;
  position: absolute;
}

.bottom_bar .voiceIconMsgBox,
.bottom_bar .voiceIcon1MsgBox {
  bottom: 37px;
  left: 10px;
}

.voiceIconForNotes {
  cursor: pointer;
  justify-content: center;
  background-size: 99%;
  justify-content: center;
  background-repeat: no-repeat;
  right: -30px;
  top: -10px;
  z-index: 9999 !important;
  position: absolute;
  background-image: url(./media/images/loginBefore/gsearch.webp);
  border-radius: 100%;
}

.discussionArea .voiceIcon1MsgBox {
  margin-left: 19px !important;

}

.discussionArea .voiceIconMsgBox {
  margin-left: 19px !important;
}

.vl {
  width: 1px;
  height: 70%;
  background-repeat: no-repeat;
  right: 68px;

  background-color: #d8d8d8;
  position: absolute;
  /* padding: 0px 20px; */
  /* margin-right: 20px; */
  /* margin-top: 25px; */


}

.userActionIcon.gearDeactive {
  pointer-events: none;
}

@keyframes pulse {
  0% {
    box-shadow: 0px 0px 5px 0px rgba(173, 20, 0, 7.3);
  }

  65% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0.3);
  }

  90% {
    box-shadow: 0px 0px 5px 13px rgba(173, 0, 0, 0);
  }
}



.threadConvCont.threadConv .unreadCounter {
  display: none;
}

.file_ul_list.refreshIco,
.file_ul_list.refreshIco:hover {
  background: unset;
}

.file_ul_list.refreshIco::after {
  content: '\f2f9';
  font-weight: bold;
  font-family: "FontAwesome";
  color: #318fff;
  margin-top: 6px;
}

.file_count_list.blur_effect li {
  filter: blur(2px);
}

.archiveBar {
  /* background-image: url(./media/images/png/pin_w.png); */
  background-image: url('./media/images/box-archive-solid.svg');
  background-position: 24px 50%;
  background-repeat: no-repeat;
  background-size: 14px;
}

/* .select-ecosystem.tagSelect .css-1wa3eu0-placeholder {
    margin-top: 10px;
} */
.userSearchInput {
  padding-right: 32px !important;
}

.select-ecosystem.tagSelect .css-1wa3eu0-placeholder {
  top: 40%;
}

.css-g1d714-ValueContainer .css-b8ldur-Input {
  height: 20px;
  overflow: hidden;
}

.select-ecosystem.tagSelect .css-g1d714-ValueContainer {
  max-height: 70px !important;
  overflow: auto;

}

li._tagTeam.breakWords {
  word-break: break-word;
}

.playerWrapper {
  width: 93%;
}

.attachment_selector.has_reply_attach {
  background-color: unset !important;
  background-repeat: no-repeat !important;
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 10px !important;
  background-image: url('./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_attach file.svg');
}

#root.dark .attachment_selector.has_reply_attach {
  background-color: unset !important;
  background-repeat: no-repeat !important;
  width: 20px;
  height: 20px;
  margin-top: 8px;
  margin-right: 10px !important;
  background-image: url('./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_attach file.svg');
}

.bactToAll.flagged {
  justify-content: space-between !important;
  padding-right: 0;
  justify-content: space-between !important;
  padding-right: 0;
  /* border-radius: 40px; */
  /* background-color: rgb(115 43 226 / 27%); */
  width: 100%;
  margin-left: 0;
  border-radius: unset;
  margin-top: 6px;
  margin-bottom: -7px;
}

/*Start Filter leftBar area*/
ul.userActionPopup.filterRight::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent #fff transparent transparent;
  left: -20px;
  top: 15px;
}

ul.userActionPopup.filterRight {
  /* position: absolute;
    right: 10px !important;
    top: 36px;
    z-index: 99999; */
  position: absolute;
  left: 360px !important;
  top: 369px;
  z-index: 99999;
}

ul.userActionPopup.filterRight li {
  border-bottom: 1px solid #d8d8d8;
}

ul.userActionPopup.filterRight li:last-child {
  border-bottom: unset;
}

ul.userActionPopup.filterRight li.active {
  background-color: #0c1e47;
  color: #ffffff;
}

.filterActionPopup {
  width: 310px;
  height: auto;
  position: absolute;
  box-sizing: border-box;
  padding: 0;
  border-radius: 12px;
  left: 360px;
  top: 305px;
  background-color: #ffffff;
  box-shadow: 0 1px 60px 0 rgba(0, 0, 0, 0.1);
  cursor: context-menu;
  border: solid 1px #e3f2ff;
  background-color: #fff;
  z-index: 999;
}

.filterActionPopup::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent #fff transparent transparent;
  left: -20px;
  top: 20px;
}

ul.filterActionList li:hover {
  background-color: #f5f9fc;
  color: #0b1f47;
}

ul.filterActionList li {
  cursor: pointer;
  position: relative;
  padding: 0 15px;
  display: flex;
  align-items: center;
  grid-gap: 10px;
  grid-gap: 10px;
  gap: 10px;
  padding-top: 0px;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b1f47;
  height: 30px;
  padding-left: 16px;
}

.filterListKanbanLi {
  border-bottom: 1px solid #d9d9d9;
}

/* ul.filterActionList li.filter_list.active::before{
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #2196f3;
    position: absolute;
    left: 7px;
} */
ul.filterActionList li.active::after {
  content: "";
  width: 8px;
  height: 15px;
  border-right: 2px solid #0b1f47;
  border-bottom: 2px solid #0b1f47;
  position: absolute;
  right: 25px;
  top: 4px;
  transform: rotate(35deg);
}

.tagBorderLine {
  width: 1px;
  height: 30px;
  flex-grow: 0;
  background-color: #e3f2ff;
}

/*End Filter leftBar area*/
.bactToAll.flagged .unreadCounter {
  background-color: #003ec7 !important;
}

.divReplySection {
  display: flex;
  align-items: center;
}

#root.dark .grid_item {
  background-color: transparent;
}

#root.dark .tagsBoxText p {
  color: #ffffff !important;
}

#root.dark .tagsContainerBoxContent {
  background-color: transparent;
}

#root.dark .tagsContainerBox {
  box-shadow: 0 2px 15px 0 rgb(11 31 71 / 6%);
  border: solid 1px #e5eef4;
}

#root.dark .memberItemTitle {
  color: #ffffff !important;
}

#root.dark .memberItem {
  box-shadow: 0 2px 15px 0 rgb(11 31 71 / 6%);
  border: solid 1px #e5eef4;
  background-color: transparent;

}

#root.dark .info_tooltip {
  background-image: url(./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_info.svg);
}

#root.dark .grid_item:hover {
  background-color: #d9d9d985;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@supports (-moz-appearance:none) {

  #newPass,
  #confirmPass,
  #oldPass,
  #confPass,
  #password,
  #password2 {
    /* //font-size: x-large; */
    font-size: 45px;
  }
}

.custom-scrollbars .track {
  opacity: 0 !important;
  transition: opacity 200ms ease !important;
}

.custom-scrollbars:hover .track {
  opacity: 1 !important;
}

/* .custom-scrollbars > div:last-child {
opacity: 0;
transition: opacity 200ms ease;
}

.custom-scrollbars:hover > div:last-child {
opacity: 1;
} */
li.conv_list.strict.task_Panel {
  background-image: url(./media/images/png/taskPanel.png);
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.MuiBox-root.MuiBox-root-6 {
  display: none;
}

/* .MuiDialog-paperWidthSm {
    max-width: 300px !important;
    max-height: 300px !important;
} */
.MuiDialog-paperWidthSm {
  max-width: 100% !important;
}

.MuiDialogContent-root.MuiDialogContent-dividers {
  padding: 0 !important;
}

.MuiBox-root.MuiBox-root-4 img {
  width: 400px;
  height: 300px;
}

#root.dark .voice-icon {
  color: #fff !important;
}

#root.dark .voice_upload_text {
  color: #fff !important;
}

li.optionalList.moreOpt_list.location::before {
  content: "\f041";
  font-size: 14px;
}

li.conv_list.strict.TaskManagement {
  /* background-image: url(./media/images/png/task-list-icon-active.png); */
  background-image: url('./media/images/png/task-list-icon-active.png');
  background-position: 45px 50%;
  background-repeat: no-repeat;
  background-size: 18px;
}

.userInfo_area {
  width: calc(100% - 78px);
  margin-left: 12px;
  color: #ffffff;
}

p.userName_company {
  font-size: 14px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #ffffff;
}

p.userName_area {
  font-size: 14px;
  font-weight: 200;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.Rectangle-btn.tasks {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.Rectangle-btn {
  min-width: 150px;
  height: 45px;
  flex-grow: 0;
  /* padding: 12px 41px; */
  padding: 12px 14px;
  border-radius: 8px;
  /* background-color: rgba(227, 242, 255, 0.08); */
  background-color: #182A4E;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bfdcff;
  list-style: none;

}

#root.dark .Rectangle-btn {
  background-color: rgb(16 16 16 / 99%);
}

#root.dark .dashboardBtnArea {
  background-color: rgba(227, 242, 255, 0.08);
}

.leftBtn_area {
  /* display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  padding-bottom: 10px;
  grid-gap:12px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 17px;
  padding-right: 17px;
  grid-gap: 12px;
  background-color: #06193F;
  margin-top: 17px;
  margin-bottom: 10px;
  flex-wrap: wrap;
  flex-direction: row;
  flex-basis: 100% !important;
  flex: 1;

}

li.Rectangle-btn.tasks {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.Rectangle-btn.tasks .taskIconForLeft {
  background-image: url('./media/images/png/taskIcon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
}

.Rectangle-btn.tasks .taskIconForRight {
  background-image: url('./media/images/pinIcon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  order: 2;
  margin-left: auto;
}

.Rectangle-btn.calendar {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.Rectangle-btn.calendar .taskIconForRight {
  background-image: url('./media/images/pinIcon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  order: 2;
  margin-left: auto;
}

.Rectangle-btn.profile {
  display: flex;
  justify-content: center;
  grid-gap: 12px;
  gap: 12px;
}

.Rectangle-btn.profile .taskIconForRight {
  background-image: url('./media/images/pinIcon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  order: 2;
  margin-left: auto;
}

/* .Rectangle-btn.tasks {
  background-image: url('./media/images/png/taskIcon.webp');
  background-position: 36px 49%;
  background-repeat: no-repeat;
  background-size: 20px; 
}*/
.Rectangle-btn.filehub {
  display: flex;
  justify-content: center;
  gap: 12px;
}

.Rectangle-btn.filehub .fileHubIconForLeft {
  /* background-image: url('./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue_files.svg'); */
  background-image: url('./media/images/png/filehub.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
}

.Rectangle-btn.filehub .taskIconForRight {
  background-image: url('./media/images/pinIcon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  order: 2;
  margin-left: auto;
}

.leftSearchRight {
  background-image: url('./media/images/filterIcon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
  order: 2;
  margin-left: auto;
  margin-right: 20px;
  cursor: pointer;
}

.leftSearchRight.filtered::after {
  content: '';
  display: inline-block;
  width: 8px;
  height: 8px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #2196f3;
  position: absolute;
  right: 7px;
}

.calendarIconForLeft {
  background-image: url('./media/images/calendarIcon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 21px;
  height: 20px;
}

.dashboardIconForLeft {
  background-image: url('./media/images/dashboardIcom.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 22px;
  background-size: 16px;
}

.profileIcomForLeft {
  background-image: url('./media/images/userProfileIcon.webp');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
}

span.project_icon {
  background-image: url('./media/images/png/filehub.webp');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 16px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.leftSearchLeft {
  width: 272px;
  position: relative;
}

.Rectangle-btn .textLabel {
  color: #fff;
}


.extra_container {
  padding-left: 34px;
  padding-right: 17px;
  padding-top: 30px;
  padding-bottom: 26px;
}

li.conv_list.strict.notifications {
  height: 39px;
  flex-grow: 0;
  margin: 0 0 6px;
  border-radius: 40px;
  background-color: #383377;
  padding-left: 42px;
  color: #fff;
}

.threadMsgBar.threadedMessages {
  height: 39px;
  flex-grow: 0;
  margin: 0 0 0px;
  /* border-radius: 40px; */
  /* background-color: rgb(115 43 226 / 27%); */
  /* background-color: rgb(115 43 226 / 0%); */
  /* background-color: #2c2a6e; */
  background-color: transparent;
  padding-left: 42px;
  color: #fff;
  position: relative;
  justify-content: right !important;
  padding-right: 19px;
  /* width: 394px; */
  width: 100%;
  /* width: calc(100% - 6px); */
  /* margin-left: 3px; */
  bottom: 4px;
  z-index: 99;
}

.threadMsgBar.threadedMessages:hover {
  background-color: #0C2854;
}

/* .threadMsgBar.threadedMessages {
  height: 39px;
  flex-grow: 0;
  margin: 0 0 0px;
  border-radius: 40px;
  background-color: transparent !important;
  padding-left: 0;
  color: #fff;
  justify-content: right !important;
  width: 100%;
  margin-left: 3px;
 
  z-index: 99;
  position: relative;
  display: inline-flex;
  padding-right: 6px;
} */
.thread_button {
  height: 39px;
  /* background-color: #282477; */
  background-size: cover;
  width: 100%;
  /* opacity: 0.27; */
  border-radius: 40px;
}

.threadMsgBar.threadedMessages span.unreadCounter {
  position: absolute;
  /* right: 20px; */
  right: 18px;
}

li.conv_list.strict.threadedMessages {
  padding-right: 20px;
}

li.conv_list.strict.notifications:before {
  content: "";
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_Notifications.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  width: 14px;
  height: 14px;
  position: absolute;
  left: 12px;
  top: 12px;
}

.filterThreadedMessages {
  content: "";
  background-image: url('./media/images/threadIcom.webp');
  background-repeat: no-repeat;
  background-position: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  /* left: 42px; */
  left: 20px;
  top: 12px;
  background-size: 20px;
}

.filterFlaggedMessages {
  content: "";
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_flag.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  /* left: 42px; */
  left: 20px;
  top: 12px;
  background-size: 20px;
}

.threadMsgBar.threadedMessages:before {
  content: "";
  background-image: url('./media/images/threadIcom.webp');
  background-repeat: no-repeat;
  background-position: 50%;
  width: 20px;
  height: 20px;
  position: absolute;
  /* left: 42px; */
  left: 20px;
  top: 12px;
  background-size: 20px;
}

li.conv_list.strict.threadedMessages {
  padding-right: 20px;
  background-image: url('./media/images/icons/other/LightBlue/SVG/WorkFreeli_Icons_LightBlue-01.svg');
  /* background-position: 45px 50%; */
  background-position: 45px 64%;
  background-repeat: no-repeat;
  background-size: 18px;
}

/* left bar top area*/
.avatar-group-area {
  display: flex;
  align-items: center;
  padding-left: 0;
}

.avatar-group-area.rtl {
  direction: rtl;
}

.avatar-group-area .avatar {
  width: 32.1px;
  height: 32.1px;
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #0b1f47;
}

.avatar-group-area .avatar img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}


/* .avatar-group-area.rtl .avatar:hover:not(:last-of-type) {
  transform: translate(10px);
} */
.avatar-group-area .avatar {
  margin-left: 15px;
  transition: transform 0.3s ease;
  cursor: pointer;
}

/* .avatar-group-area .avatar:hover:not(:last-of-type) {
  transform: translate(-10px);
} */
.avatar-group-area .avatar:not(:first-child) {
  margin-left: -10px;
  -webkit-mask: radial-gradient(circle 55px at 100px 50%, transparent 99%, #fff 100%);
  mask: radial-gradient(circle 55px at 100px 50%, transparent 99%, #fff 100%);
}

.avatar-group-area .hidden-avatars {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
  margin-right: 23px;
  background-color: #2c303a;
  color: #fff;
}

.ovel_circle {
  width: 10px;
  height: 10px;
  flex-grow: 0;
  background-color: #7b40d5;
  border-radius: 50%;
  margin-right: 16px;
}

.companySwitch {
  margin-left: 5px;
  cursor: pointer;
}

.companySwitch::after {
  content: "";
  background-image: url('./media/images/switchIcon.webp');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  transition: all 0.3s ease;
}

p.commingSoon {
  font-weight: bold;
  font-size: 32px;
}

span.threadMsgText {
  position: absolute;
  left: 54px;
  top: 11px;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  /* color: #fff; */
}

.list_container.create_conv_list {
  /* border-top: 1px solid;
  border-color: rgba(227, 242, 255, 0.26); */
  padding-bottom: 10px;
  border-top: 0.067px solid #475674;
  position: relative;
  /* mask: linear-gradient(90deg, #475674 0%, #42424200);
  -webkit-mask-image: linear-gradient(90deg, #475674 0%, #42424200); */
}

.list_container.create_conv_list.drawShadow::after {
  content: "";
  position: absolute;
  top: 0;
  left: 230px;
  height: 100%;
  width: 116px;
  /* background: -webkit-linear-gradient(to right, #475674d1, #4756742e 0%, #0c1e47e0 0%); */
  background: linear-gradient(to right, #475674d1, #4756742e 0%, #0c1e47e0 0%);
}

.list_container.pinned_list {
  padding-bottom: 5px;
  /* border-top: 0.067px solid #475674; */
  padding-top: 0px;
  margin-top: 10px;
}

.list_container.archivedRooms_list {
  border-top: 1px solid;
  border-color: rgba(227, 242, 255, 0.26);
  padding-bottom: 10px;
}

.track {
  background-color: #cabae4 !important;
  z-index: 99;
}

.custom-scrollbars div {
  overflow: auto !important;
  right: 0px !important;
}

.archiveRoomScroll div {
  overflow: auto !important;
  right: 0px !important;
  height: calc(100% - 148px);
}

.bactToAll.thread_back {
  background-color: #2c2a6e00;
  width: 100%;
  margin-left: 0px;
  padding-left: 52px !important;
  background-position: 307px 45%;
  margin-top: 5px;
  margin-bottom: -7px;
}

.bactToAll.thread_back:hover {
  background-color: #0C2854;
}

.bactToAllProject {
  height: 35px;
  padding-right: 0;
  width: 100%;
  margin-left: 3px;
  display: flex;
  align-items: center;
  padding: 7px 50px;
  color: #fff;
  font-weight: 300;
  font-size: 14px;
  background-image: url('./media/images/backIcom.svg');
  background-repeat: no-repeat;
  background-size: 16px;
  /* background-position: 21px 42%; */
  background-position: 300px 45%;
  cursor: pointer;
  position: relative;
  line-height: 2px;
}

.bactToAllProject.thread_back {
  /* border-radius: 40px; */
  /* background-color: rgb(115 43 226 / 27%); */
  /* background-color: #2c2a6e; */
  background-color: #2c2a6e00;
  width: 100%;
  margin-left: 0px;
  /* background-position: 24px 50% !important; */
  background-position: 307px 45%;
  margin-bottom: -7px;
}

.bactToAllProject.thread_back:hover {
  background-color: #0C2854;
}

.projectListTask li:last-child {
  margin-bottom: 10px;
}

/* #conversejs{
  display: none;
} */
.sweet-alert .form-control {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  padding: 8px;
  box-sizing: border-box;
}

.googleIconSet {
  display: flex;
  justify-content: center;
  height: 100%;
  margin: 0;
  align-items: center;
}

.googleIconSet div {
  display: flex;
  justify-content: flex-start;
  padding: 0px 10px 5px 5px;

}

.google_icon {
  width: 26px;
  height: 45px;
  background-image: url(./media/images/google_icon.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 6px;
  margin-right: 3px;
  position: relative;
  bottom: -2px;
  cursor: pointer;
}

.google_icon_calender {
  width: 46px;
  height: 45px;
  background-image: url(./media/images/google_calendar.svg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 100%;
  margin-left: 6px;
  position: relative;
  bottom: -2px;
  cursor: pointer;
  margin-right: 3px;
}

.fileRefDiv {
  display: flex;
  gap: 10px
}

.tagFullArea {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

span.user_Management_title.createTeam span.tooltiptext5 {
  width: 170px;
  left: 100%;
  margin-left: -210px;
}

.userActionSection[data-active="false"] {
  pointer-events: none;
}

.userActionSection[data-active="true"] {
  cursor: pointer;
}

span.user_Management_title.createTeam span.tooltiptext5::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 13px 8px 0;
  border-color: transparent #0c1e47 transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: -16px;
  left: 120px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.opt_icons.conv_more.addUser {
  transform: rotate(90deg);
  position: absolute;
  right: 7px;
}

.cu_inputGroup.newCreateUser {
  margin-bottom: 5px;
  margin-top: 5px;
}

.passInputGroup.newCreateUser {
  padding-top: 2px;
  padding-bottom: 2px;
}

.passInputGroup.newCreateUser .passwordLock {
  top: 31px;
  left: 1px;
}

.passInputGroup.newCreateUser .passwordView {
  top: 31px;
  right: 1px;
}

input#password2:not(:valid)+.passwordPlaceholder,
input#password:not(:valid)+.errorLabel+.rgInputMsg+.passwordPlaceholder {
  position: absolute;
  left: 48px;
  top: 17px;
  line-height: 40px;
  font-size: 113px;
  font-family: auto;
  letter-spacing: -6px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #adb5bdd1;
  pointer-events: none;
}

.createUserModalBody.newUser {
  padding: 5px 30px;
}

#password2 {
  border: 1px solid #d6d6d6;
  background: white;
  padding: 8px 50px;
  line-height: 40px;
  font-size: xxx-large;
  -moz-font-size: 2.3em;
  font-family: auto;
  letter-spacing: 8px;
  margin-top: 0;
}

.createUserModal.newUser {
  /* width: 425px; */
  width: 545px;
}

.tooltiptext5::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 13px 8px 0;
  border-color: transparent #0c1e47 transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: -16px;
  left: 225px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

.tooltiptext6::before {
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 6px 13px 8px 0;
  border-color: transparent #0c1e47 transparent transparent;
  display: inline-block;
  vertical-align: middle;
  margin-right: 28px;
  position: absolute;
  top: -16px;
  left: 225px;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
}

span.tooltiptext5.newUserTool::before {
  left: 182px;
}

span.tooltiptext5.newUserTool {
  width: 204px;
  left: 100%;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  margin-left: -221px;
  top: 110px;
  font-size: 14px;
  font-weight: normal;
  border: 2px solid #94b2ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

span.tooltiptext6.newUserTool::before {
  left: 150px;
}

span.tooltiptext6.newUserTool {
  width: 204px;
  left: 100%;
  background-color: #0c1e47;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  margin-left: -250px;
  top: 110px;
  font-size: 14px;
  font-weight: normal;
  border: 2px solid #94b2ff;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.passInputGroup.newCreateUser label {
  width: 100% !important;
  color: #002e98 !important;
  margin-bottom: 6px !important;
  display: flex !important;
  align-items: center !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  padding-bottom: 6px !important;
}

.profilenavMainSection .linkAccount span.tagIcon {
  position: relative;
  top: 4px;
}

.userLogo.navIconCircle {
  height: 30px;
  width: 30px;
}

.userLogo.navIconCircle img {
  height: 22px;
  width: 22px;
}

span.linkAccount .companySwitch::after {
  content: "";
  /* background-image: url('./media/images/companySwitchBlack.png'); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  transition: all .3s ease;
}

span.linkAccount:hover .companySwitch::after {
  content: "";
  /* background-image: url('./media/images/companySwitchWhite.png'); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  transition: all .3s ease;
}

#root.dark span.linkAccount .companySwitch::after {
  content: "";
  /* background-image: url('./media/images/companySwitchWhite.png'); */
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 20px;
  width: 20px;
  height: 20px;
  display: flex;
  transition: all .3s ease;
}

.select-ecosystem.isDisable.css-14jk2my-container .css-107lb6w-singleValue {
  color: #5b6477b5 !important;
}

.user_msg.rootReply {
  /* position: fixed; */
  position: sticky;
  width: 100%;
  /* width: calc(100% - 284px); */
  background-color: #fff;
  height: auto;
  z-index: 9;
  transition: height 0.3s ease;
  top: 0;
  height: auto;
}

#root.dark .user_msg.rootReply {
  /* position: fixed; */
  position: sticky;
  width: 100%;
  /* width: calc(100% - 284px); */
  background-color: #181818;
  height: auto;
  z-index: 9;
  transition: height 0.3s ease;
  top: 0;
  height: auto;
}

#root.dark .leftBtn_area {
  background-color: #222222a3;
}

p.profilenavMidleArea_label {
  font-family: Poppins;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b6477;
  padding-left: 18px;
  padding-top: 16px;
}

#root.dark p.profilenavMidleArea_label {
  color: #ffffff;
}

span.linkAccount.signout {
  padding-top: 22px;
  padding-bottom: 22px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.conv_list.own.online::before {
  content: "";
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_private room.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  width: 13px;
  height: 13px;
  position: absolute;
  left: 24px;
  top: 11px;
  background-color: transparent;
  border-radius: unset;
}

/* .list_container.create_conv_list::after {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: .9;
    background-image: linear-gradient(to right, rgb(11 31 71 / 0%) 0%, #0b1f47 70%);
   height: auto;
} */
/* #root.dark .list_container.create_conv_list::after{
  background-image: linear-gradient(to right, rgb(11 31 71 / 0%) 0%, #222 70%);
} */
span.linkAccount.signout:hover span.signout_menu span.themeMode.border_bottom {
  height: 46px;
  padding: 13px 32px;
  border-radius: unset;
}

.notificationIconButton .buttonText {
  font-size: 14px !important;
}

.onlyReplyContainer.hasAttachment {
  top: 0px;
}

.supportBillingDivs .userTableHead ul li {
  font-size: 12px;
}

.task_User_sec .userListData img {
  width: 28px;
  height: 28px;
  align-items: center;
  padding: 0px;
  margin-top: 0px;
  margin-right: 0px;
  border-radius: 25px;
}

.SweetAlertSupport div {
  z-index: 99999999 !important;
}

.TagpopupSweetAlert div {
  z-index: 99999 !important;
}


@media screen and (max-width: 1079px) {
  .left_container {
    width: 250px !important;
  }

  .profilenavMainSection {
    left: 218px;
    top: 90px;

  }

  .right_container {
    width: calc(100% - 250px);
  }

  .userInfoModal {
    width: calc(100% - 250px) !important;
    left: 250px !important;
  }

  .previewModal {
    width: calc(100% - 250px) !important;
    left: 250px !important;
  }

  .createGroupConv {
    width: calc(100% - 250px);
    left: 250px;
  }

  .updateGroupConv {
    width: 100%;
    left: 0;
  }

  .left_top_bar {
    padding-left: 0px;
    padding-right: 0px;
  }

  .left_top_bar .logoSection {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .left_top_bar .freeli_logo {
    width: 56%;
    height: 44px;
    background-size: contain;
    background-position: center;
    position: relative;
    top: 0px;
    left: 0px;
  }

  .avatar-group-area {
    width: calc(46% - 30px);
  }

  .companySwitch {
    margin-left: 6px;
  }

  .avatar-group-area .avatar {
    width: 25px;
    height: 25px;
    font-size: 16px;
  }

  .leftBtn_area {
    flex-direction: column;
    margin: 0;
    padding-bottom: 3px;
  }

  .Rectangle-btn.tasks {
    background-position: 21px 49%;
  }

  .leftBtn_area a {
    width: 100%;
    margin-top: 8px;
  }

  .Rectangle-btn {
    width: 100%;
  }

  .pinBar,
  .conv_list.cal_panel,
  .conv_list.PrivatePanel,
  .conv_list.flagPanel {
    background-position: 30px 64%;
  }

  .create_conv {
    background-position: 32px 64%;
  }

  .list_container_title,
  .conv_list {
    padding-left: 54px;
  }

  .archiveBar {
    background-position: 24px 60%;
  }

  .threadMsgBar.threadedMessages:before {
    left: 21px;
  }

  .conv_list.offline::before,
  .conv_list.private:before,
  .conv_list.online::before {
    left: 24px;
  }

  .companySwitch::after {
    background-size: 16px;
  }

  .group_container {
    /* height: calc(100% - 341px); */
    height: calc(100% - 585px);
  }

  .replyThreadContainer,
  .checklistEditContainer {
    /* width: calc(100% - 254px); */
    height: 100vh;
    float: left;
    position: absolute;
    top: -1px;
    /* left: 254px; */
    background-color: #fafafa;
    z-index: 2;
  }

  .left_top_bar .filtrSection {
    margin-left: 15px;
  }

  .leftBtn_area .nodecoration {
    text-decoration: none;
    width: 100%;
  }

  .leftSearchRight {
    background-image: url(./media/images/filterIcon.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
    width: 24px;
    height: 24px;
    order: 2;
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
  }

  .leftSearchLeft {
    width: 75%;
    position: relative;
    padding-top: 16px;
  }

}

.task_read,
.task_unread {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  display: inline-block;
  margin-left: 8px;
}

.task_unread {
  background-color: #732be2;
}

/* Matches screen between 1079px AND 768px */
@media only screen and (min-width: 768px) and (max-width: 1079px) {
  .top_bar_left_options {
    position: absolute;
    right: 0;
    top: 5px;
  }

  .part_top_bar.top_bar_right_options {
    position: absolute;
    right: 55px;
    top: 20px;
  }

  .allSearchSection {
    position: absolute;
    top: -3px;
    width: 80%;
    right: 0;
  }

  .user_msg.myArea {
    float: right;
    clear: both;
    padding-left: 20px;
    padding-right: 20px !important;

  }

  .userArea {
    padding-left: 40px !important;
  }

  .top_bar {
    padding: 0 20px;
  }

  .taskHeadSection.topHead {
    height: auto !important;
    flex-direction: column;
    padding-right: 0px !important;
    align-items: flex-start !important;
    padding-bottom: 10px !important;

  }

  .filteredPopup_div .filterActionPopupKanban {
    left: 0px !important;

  }

  .items-center.forTagFilter {
    top: 8px;
    right: 62px;
  }

  .filteredLists {
    background-color: #fff;
    width: 100%;
    left: 0;
  }

}

/* Matches screen between 1200px AND 1080px */
@media only screen and (min-width: 1080px) and (max-width: 1200px) {
  /* .task.right_container {
    left: 300px !important;
  } */

  .ul.task_menu_Lists li.activeTaskMenu:after {
    top: -8px
  }

  .taskHeadSection.topHead {
    height: auto;
    flex-direction: column;
    /* padding-left: 0px !important; */
    padding-right: 0px !important;
    align-items: flex-start !important;
    /* margin-left: -24px; */
  }

  .createConv_body {
    padding: 20px 80px;
  }

  .roomsGrid {
    width: calc(50% - 16px);
  }

  .roomsGrid:nth-child(2n) {
    margin-right: 0;
  }

  .gGridView .gFiles {
    margin-right: 30px;
  }

  /* @media screen and (max-width: 1200px) { */
  .left_container {
    width: 300px;
    height: 100%;
    overflow: auto;
  }

  .right_container {
    width: calc(100% - 300px) !important;
  }

  .userInfoModal {
    width: calc(100% - 300px) !important;
    left: 300px !important;
  }

  .createGroupConv {
    width: calc(100% - 300px) !important;
    left: 300px;
  }

  .updateGroupConv {
    width: 100%;
    left: 0;
  }

  .left_top_bar .logoSection {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .leftSearchLeft {
    width: 234px;
    position: relative;
  }

  .left_top_bar .freeli_logo {
    width: 56%;
    height: 44px;
    background-size: contain;
    background-position: center;
    position: relative;
    top: 0px;
    left: 0px;
  }

  .avatar-group-area {
    width: calc(40% - 30px);
  }

  .companySwitch {
    margin-left: 10px;
  }

  .avatar-group-area .avatar {
    width: 30px;
    height: 30px;
  }

  .leftBtn_area {
    flex-direction: column;
    margin: 0;
    padding-bottom: 3px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 17px;
  }

  .Rectangle-btn.tasks {
    background-position: 21px 49%;
  }

  .leftBtn_area a {
    width: 100%;
    margin-top: 0px;
    min-width: 100%;
  }

  .Rectangle-btn {
    width: 100%;
    min-width: 100%;
  }

  .Rectangle-btn.filehub {
    display: flex;
    justify-content: flex-start !important;
    gap: 12px;
  }

  .pinBar,
  .conv_list.cal_panel,
  .conv_list.PrivatePanel,
  .conv_list.flagPanel {
    background-position: 30px 64%;
  }

  li.conv_list.strict.threadedMessages {
    background-position: 30px 64%;
  }

  span.threadMsgText {
    left: 60px;

  }

  .create_conv {
    background-position: 32px 64%;
  }

  .list_container_title,
  .conv_list {
    padding-left: 54px;
  }

  .threadMsgBar.threadedMessages:before {
    left: 31px;
  }

  .conv_list.offline::before,
  .conv_list.private:before,
  .conv_list.online::before {
    left: 24px;
  }

  .starredFiles span {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-left: 7px;
    position: relative;
    top: -2px;

  }

  .filesText span {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-left: 7px;
    position: relative;
    top: -2px;
  }

  .filesRefType span {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    margin-left: 7px;
    position: relative;
    top: -2px;
  }

  .group_container {
    /* height: calc(100% - 564px); */
    /* height: calc(100% - 620px); */
    height: calc(100vh - 575px) !important;
  }

  .leftBtn_area .nodecoration {
    text-decoration: none;
    width: 100%;
  }

  /* .group_container.adminLists ul.list_container.settingsList {
    position: sticky;
    overflow-y: auto;
    height: calc(100vh - 637px) !important;
  } */

  .css-16xfy0z-control {
    background-color: #D8D8D8 !important;
  }

  .right_container_fileHub .Advance_search_top {
    flex-wrap: nowrap !important;


  }

  ul.tagFileLists .tagFileLists_tab.activeTab:after {
    content: "";
    position: absolute;
    width: 70px !important;
    height: 8px;
    left: -12px !important;

  }

  .right_container_fileHub {
    width: calc(100% - 300px);
  }

  .userSearchInput {
    width: 220px !important;
  }

  .group_container_admin {
    height: calc(100vh - 600px);
  }

  .bactToAll {
    background-position: 260px 45%;

  }

  .popUpRightContainer {
    width: calc(100% - 300px) !important;
  }

  span.exportIcon {
    top: -5px !important;
  }

  .usersTabSecDevide.rightSide {
    width: 40%;
  }

  .usersTabSecDevide.lefttSide {
    width: 60%;
  }

  span.mfromId {
    font-size: 18px !important;
  }



}

@media only screen and (min-width: 400px) and (max-width: 1079px) {
  .right_container_fileHub {
    width: calc(100% - 250px) !important;
  }

  .right_container {
    width: calc(100% - 250px) !important;
  }

  .ul.task_menu_Lists li.activeTaskMenu:after {
    top: -8px
  }

  /* .multi_value.single_file {
    max-width: 100% !important;
  } */

  /* .task.right_container {
    left: 250px !important;
  } */

  .taskHeadSection.topHead {
    flex-direction: column;
    /* padding-left: 0px !important; */
    padding-right: 0px !important;
    align-items: flex-start !important;
    /* margin-left: -24px; */
  }

}

.tagTitle[exist-title="yes"]::before {
  content: "Tag already exist.";
  position: absolute;
  top: 15px;
  font-size: 10px;
  color: red;

}

.no_internet {
  position: fixed;
  text-align: center;
  width: 100%;
  background: red;
  bottom: 0;
  color: #FFF;
}

.conv_list.before_none:before {
  content: unset;
}

.conv_list>.conv_list_mute {
  position: absolute;
  left: 20px;
  top: 8px;
}

.fileBody_area.taskListBody_area.prime_search .notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 435px);
}

.conv_list.display_flase {
  display: none;
}

.user_msg.rootReply .demo-editor.rdw-editor-main {
  overflow-y: hidden;
}

.loaderMain p {
  position: absolute;
  /* top: 30px; */
  font-size: 14px;
  /* left: 26px; */
  margin-top: 68px;
}

/*====== Start For Dark theme ==========*/
#root.dark .tagTable {
  background: unset !important;
}

#root.dark .favTag {
  color: #ffffff
}

#root.dark .favTagViewMain {
  background-color: #000;
  border: 1px solid #fff;
  line-height: 14px;
}

#root.dark .favTagView span {
  color: #ffffff;
}

#root.dark input._inputSearchBar {
  background-color: #1d1f26;
  color: #ffffff;
}

#root.dark .srcBtnSection {
  width: 35px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 3px;
  background-color: #1d1f26;
  border-radius: 0 20px 20px 0;
  /* border-left: 1px solid #d8d8d8; */
  cursor: pointer;
  background-image: url(./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_search.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 50% 50%;
  border-left: 1px solid #fff;
}

#root.dark .tagTable3 {
  background: unset !important;
  background-color: #000 !important;
}

#root.dark .most_recent {
  padding: 13px 5px 15px 0px;
  margin-left: 22px;
  color: #fff;

}

#root.dark .tagHomeTable .tagTableCol3 {
  background-color: black;
  border-top: 1px solid #dfdfdf;
}

#root.dark .personal_tag span {
  color: #fff;
}

#root.dark .personal_tag>div {
  background-color: #1d1f26;
}

#root.dark .personal_tag input {
  background-color: #1d1f26 !important;
  color: #fff;
}

#root.dark .tagTableCol2 .sharedTag {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #000;
  /* margin: 0px 7px; */
  border-radius: 15px;
  border: 1px solid #dfdfdf;
}

#root.dark .bigFont {
  color: #fff;
}

#root.dark .tagText {
  color: #fff;
}

#root.dark .viewTag>div {
  color: #fff;
}

#root.dark .loaderMain p {
  color: #fff;
}

#root.dark .profilenavMainSection::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent #5b6477 transparent transparent;
  left: -20px;
  top: 20px;
}

#root.dark .tagTableRow31 {
  background-color: #1d1f26;
}

#root.dark .tagTableRow3 {
  background-color: #1d1f26;
}

#root.dark .custom_dropdown {

  background-color: #1d1f26;
  border: 1px solid #fff;
}

#root.dark .custom_dropdown-header {
  color: #ffffff;

}

#root.dark .custom_dropdown-body.open {
  background-color: #1d1f26;

}

#root.dark input.start_Date {
  background-color: #1d1f26;
}

#root.dark .tagRelatedPopup {
  background-color: #1d1f26;
}

#root.dark .tagToggle_sidebar_head {
  background-color: #1d1f26;
  border-bottom: 1px solid #444444;

}

#root.dark .tagToggle_sidebar_head .tagsText {
  color: #ffffff;
}

#root.dark .selectedTagLabel {

  color: #a6d068;

}

#root.dark .checkbox.style-c .checkbox__body {
  color: #fff;
}

#root.dark .tagToggle_sidebar .instructionPara {
  color: #fff;
}

#root.dark .inputGroup input {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark input.team-name.short_id_input {
  background-color: #0376da !important;
  color: #ffffff;
}

#root.dark .searchParticipants input {
  color: #fff;
  background-color: #1d1f26;

}

#root.dark .tagAddSearch input {
  color: #fff;
  background-color: #1d1f26;

}

#root.dark .tagareaList {
  background-color: #1d1f26;

}

#root.dark h2.popupTitle.tagfileTitle {
  color: #fff !important;
}

#root.dark span.closeModal.forTag {
  background-image: url('./media/images/close_button_w.svg') !important;
}

#root.dark .react-calendar__navigation {
  background-color: #1d1f26;
}

#root.dark .react-calendar {
  background-color: #1d1f26;

}

#root.dark .react-calendar button {
  color: #fff;
}

#root.dark .react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #2196f3;
}

#root.dark ul.tasks_ul {
  background-color: #1d1f26 !important;
}

#root.dark li.status_heading {
  color: #fff;
}

#root.dark .taskTeamDetails {
  background-color: #1d1f26;
  border: 1px solid #424242;
}

#root.dark span.selectOnew {
  color: #fff;
}

#root.dark .dropdown-header i.fa.fa-chevron-right.icon.false {
  color: #fff !important;

}

#root.dark .totalTasks {
  background-color: #1d1f26 !important;
  color: #fff;
}

#root.dark .tesk-status-item {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .task_status_result {
  color: #fff;
}

#root.dark .viewTasksIcon {
  background-image: url('./media/images/forward_icon_white.svg');
  background-size: 16px;
  width: 16px;
  height: 16px;
  background-repeat: no-repeat;
  cursor: pointer;
  position: relative;
  top: 4px;
}

#root.dark .insightupdate_text {
  color: #ffffff !important;
  background-color: #9b9b9b !important;
}

#root.dark .task_update_item {
  background-color: #1d1f26;

}

#root.dark .tsks_hours_detail div {
  color: #fff;
}

.taskStatus_area {
  overflow-wrap: break-word;
}

#root.dark .right-inner-addon input {
  background-color: #1d1f26;
  border: 1px solid #424242;
  color: #ffffff;
}

#root.dark input.start_Date_task {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark .custom_dropdown-body_task.open {
  background-color: #1d1f26;
  color: #fff !important;
}

#root.dark .taskreviewContainer {
  background-color: #1d1f26;
  color: #fff !important;
}

#root.dark .reviewsTitle {
  color: #fff;

}

#root.dark .reviewsActionLabel {
  color: #fff;

}

#root.dark .efficiencyTitle {
  color: #fff;
}

#root.dark .efficiencyTitle svg {
  color: #fff !important;
}

#root.dark .efficiencyBtn svg {
  color: #fff !important;
}

.efficiencyTitle {
  margin: 0px 20px 15px 0 !important;

}

.efficiencyScore {
  padding-right: 15px !important;
}

#root.dark .insights_work_right_top {
  background-color: #1d1f26;
}

#root.dark .statusSection {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .statusLabel {
  color: #fff;
}

#root.dark .pendingTitleSection {
  color: #fff;
}

#root.dark .pendingTask {
  background-color: #1d1f26;
  color: #fff;
  border: 1px solid #424242;
}

#root.dark .pendingTaskTitle {
  color: #fff;
}

#root.dark .pendingBodyText {
  color: #fff;
}

#root.dark .starRating {
  background-color: #1d1f26;
}

#root.dark .starRating p {
  color: #fff;
}

#root.dark .cu_inputGroup input {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .css-yk16xz-control {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .css-tj5bde-Svg {
  color: #ffffff;
}

#root.dark .crTeam_inputGroup input {
  background-color: #1d1f26;
  color: #fff;
}

/* .group_container.adminLists ul.list_container.settingsList {
  position: sticky;
  overflow-y: auto;
  height: calc(100vh - 521px);
} */

#root.dark .passInput {
  background-color: #1d1f26 !important;
  color: #fff;
}

#root.dark .supportAndBillingPlan {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark span.sbUserNumber {
  color: #fff;
}

#root.dark span.sbUserNumber {
  color: #fff !important;
}

span.sbUserNumber span {
  font-size: 12px !important;
}

#root.dark span.sbUserNumberSt {
  color: #fff;
}

#root.dark .supportAndBillingStorage p {
  color: #fff;
}

#root.dark .supportAndBillingStorage p {
  color: #fff;
}

#root.dark .subsctiptionPayment {

  color: #ffffff;
  background-color: #0b1f47;

}

#root.dark .reviewAreaBilling {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .subsctiptionPaymentBotton {
  background-color: #0b1f47;
  color: #fff;
}

#root.dark span.subscriptionHistory {
  color: #ffffff;
}

#root.dark h2._userList {
  color: #ffffff;
}

#root.dark .secoundLeftArea {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark ul.essentialUL li {
  color: #ffffff;
}

#root.dark .essentialPlanTotal {
  background-color: #0b1f47;
  color: #ffffff;
}

#root.dark .essentialPlanTotal div {

  color: #ffffff;
}

#root.dark .essentialPlanTotal div h3 {

  color: #ffffff;
}

#root.dark .billingAddressFormInput input {
  background-color: #1d1f26;
  color: #ffffff;
}

#root.dark .filterActionPopup {
  background-color: #181818;
  border: 1px solid #d6d6d635;
}

#root.dark ul.filterActionList li {
  color: #ffffff;
}

#root.dark ul.filterActionList li:hover {
  background-color: #404040;
}

#root.dark .filterActionPopup::before {
  content: "";
  position: absolute;
  width: 0px;
  height: 0px;
  border: 10px solid;
  border-color: transparent #5b6477 transparent transparent;
  left: -20px;
  top: 20px;
}

#root.dark ul.filterActionList li.active::after {
  content: "";
  width: 8px;
  height: 15px;
  border-right: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  position: absolute;
  right: 25px;
  top: 4px;
  transform: rotate(35deg);
}

#root.dark .tagTitle[contenteditable="false"] {
  background-color: #181818;
  border: unset;
}

#root.dark ul.userActionPopup.showAll {
  background-color: #181818;
  border: 1px solid #d6d6d635;
}

#root.dark span.linkAccount.signout:hover span.signout_menu {
  background-color: #181818;
  border: 1px solid #d6d6d635;
}

#root.dark .msg_text_bg {
  background-color: #232425 !important;
}

#root.dark .optionsIconDark,
#root.dark .timeDark {
  color: white !important;
}

#root.dark .optionsIconDark:hover {
  background-color: #3B3B3C !important;
}

#root.dark .userActionPopup li {
  color: #ffffff;
}

#root.dark .guestEmptyMsg {
  color: #ffffff;
}

#root.dark .backGroundCololor {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .shareTeamTagText {
  color: #fff;
}

#root.dark .select-ecosystem.isDisable .css-1fhf3k1-control {
  background-color: #1d1f26f2 !important;
}

#root.dark .css-1uccc91-singleValue {
  color: #fff;
}

#root.dark .select-ecosystem .css-1pahdxg-control {
  background-color: #1d1f26 !important;
  color: #fff !important;

}

#root.dark .customTitleInput {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .fileRefType select.refTitleInput {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .privateMsgContainer {
  background-color: #1d1f26;
  color: #ffffff;
  border: 1px solid #d6d6d635;
  background-image: url('./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_private room.svg');
}

#root.dark label.img-div.teamImage.empty {
  background-color: #1d1f26;
  border: 1px solid #d6d6d635;
}

#root.dark .ReportSlider {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .ReportHeader {
  background-color: #1d1f26;
  color: #fff;
  background-image: unset;
}

#root.dark .firstChild {
  background-color: #1d1f26;
  color: #ffffff;
  border: 1px solid #d6d6d635;
}

/* #root.dark .reportcheck_area [type="radio"]:checked + label, .reportcheck_area [type="radio"]:not(:checked) + label {
    color: #ffffff !important;
} */
#root.dark .monthDateText {
  color: #ffffff !important;
}

#root.dark .ReportFooter {
  background-color: #1d1f26;

}

#root.dark .tasksOverduePara {
  color: #fff;

}

#root.dark .taskLoad_lists {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .monthNames_text {
  color: #fff;
}

#root.dark .taskWorkLoad_back {
  background-image: url(./media/images/tagBackWhite.svg);
  background-size: 25px;
  width: 25px;
  height: 42px;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

#root.dark .workload_hours {
  color: #fff !important;
}

#root.dark .capacityText {
  color: #fff;
}

#root.dark .max-hr-workload {
  color: #c1ddf4;
}

#root.dark .overcapacityText {
  color: #c1ddf4 !important;
}

#root.dark .card_area_cl {
  background-color: #1d1f26;
  border: 1px solid #d6d6d635;

}

#root.dark .status_header_text {
  color: #fff;
}

#root.dark .status_counter {
  color: #fff;
}

#root.dark .status_bottom_text {
  color: #ffffff;
}

#root.dark .task_update_item_status {
  background-color: #1d1f26;
  border: 1px solid #d6d6d635;

}

#root.dark .tsks_hours_status div {
  color: #fff;
}

#root.dark .quick_Task_body .inputboxArea input.addNewTask {
  border-bottom: 1px solid #318fff;
  background-color: #1d1f26;
  color: #fff;
  padding-left: 10px;
}

#root.dark .Create_Quick_Task {
  border: unset !important;
  background-color: #1d1f26 !important;
}

#root.dark .heading_create_task .heading_text {
  color: #fff;
}

#root.dark .heading_create_task .para_text {
  color: #fff;

}

#root.dark .task_name_title {
  color: #fff;

}

#root.dark .task_User_sec .searchAndFilterKeyword {
  background-color: #1d1f26;

}

#root.dark .task_User_sec .selectedKeywordCont_task {
  background-color: #1d1f26;

}

#root.dark .task_User_sec .searchAndFilterKeyword p._tag_rooms {
  color: #fff;
}

#root.dark .task_User_sec .AddKeywordsPopup {
  background-color: #1d1f26;
}

#root.dark .goIconBlack {
  background-image: url('./media/images/forward_icon_white.svg');
  content: "";
  width: 12px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: 100%;
}

#root.dark .NotFoundTagnew {
  color: #fff !important;
}

#root.dark .all_tag_head {
  background-color: #1d1f26;
}

/*
#root.dark .bottom_bar_task.bottom_bar.bottomBox_reply.taskChatArea.bottomBox_task {
background-color: #fff;
}
 #root.dark .discussionArea div#bottomBox {
    background-color: #fff !important;
    color: #000 !important;
}
#root.dark .discussionArea .demo-editor {
    color: #1d1f26;

}
#root.dark .adminRightHead.taskHeader span.closeAdminPanel {
    background-image: url(./media/images/task_img/backIconNew.svg);
}
#root.dark .task_body_left.quickShowTask .notes_label {
    color: #0b1f47;
}
#root.dark .Track_footer_upper input.start_Date_task {
    color: #0d2150 !important;
    background-color: #fff !important;
}
#root.dark .Track_fields input.start_Date_task {
    color: #0d2150 !important;
    background-color: #fff !important;
} */
#root.dark .adminRightHead.taskHeader span.closeAdminPanel {
  background-image: url('./media/images/task_img/backIconNew.svg');
}

#root.dark .task_right_container.forQuickViewTask {
  width: 550px;
  background-color: #1d1f26;
  float: right;
  position: fixed;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 999;
  box-shadow: 0 4px 114px 0 rgba(0, 0, 0, 0.75);
  border-left: 1px solid #424242;
}

.task_right_container .deletedPopup {
  top: 0
}

.discussionArea .clearAllSrcTestMain {
  right: 120px;
}

#root.dark input.taskTitle.TitleForUpdated {
  color: #fff !important;
  background-color: #1d1f26;
  /* border: 1px solid #d6d6d6; */
}

#root.dark .cretorAndAssignee_area {
  border-bottom: solid 1px #424242;
  background-color: #1d1f26;
  color: #fff;
}

#root.dark input#addkeyword {
  color: #fff !important;
  background-color: #1d1f26;
  border: 1px solid #424242;
}

#root.dark p.creatorName {
  color: #ffffff !important;
}

#root.dark .cretorAndAssignee_Div .creator_label {
  color: #fff !important;
}

#root.dark span.infoIcon svg {
  color: #fff;
}

#root.dark span.infoIcon svg {
  color: #fff !important;
}

#root.dark .secound_section.quickTask .left_part {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark .secound_section.quickTask .right_part {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark .right_part.fileQuickTask .file_label {
  color: #fff !important;
}

#root.dark .task_body_left.quickShowTask .checklist_section.inTaskList {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark .task_body_left.quickShowTask .checkList_head_text {
  color: #fff !important;
}

#root.dark .task_body_left.quickShowTask .task_describe_section {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark .taskChatBox {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark .taskHeadlabel {
  color: #fff !important;
}

#root.dark .discussionArea .msg_sender_name {
  color: #fff !important;
}

#root.dark .discussionArea .mesg_text {
  color: #fff !important;
}

#root.dark .discussionArea {
  color: #ffffff;
}

#root.dark .task_body_left.quickShowTask .first_section {
  box-shadow: unset;
  border-right: unset !important;
  border-left: unset;
  border-top: solid 1px #424242 !important;
}

#root.dark .taskDescribe[contentEditable=true]:empty:before {
  color: #ffffff;
}

#root.dark .checkLists_area {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark input.addCheckbox.checklist {
  color: #fff !important;
  background-color: #1d1f26;
  top: 6px;
}

#root.dark input.addCheckbox.checklist::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #ffffff !important;
}

#root.dark input.addCheckbox.checklist:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #ffffff !important;
  opacity: 1 !important;
}

#root.dark input.addCheckbox.checklist::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #ffffff !important;
  opacity: 1 !important;
}

#root.dark input.addCheckbox.checklist:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ffffff !important;
}

#root.dark .inputvalAtewa {
  background: #1d1f26;
  border-top: 1px solid #424242;

}

#root.dark span.checkList_plusIcon i.fa.fa-plus.custom_icon {
  font-size: 18px;
  color: #ffffff;
}

#root.dark p.form_label {
  color: #ffffff;
}

#root.dark .task_body_left.quickShowTask .yourNotes #youtnotes {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark .onserversAndReview_Div .creator_area .creator_label {
  font-size: 14px;
  width: auto !important;
  color: #fff;
}

#root.dark .TrackHoursPopUp {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .Track_col {
  color: #ffffff;
}

#root.dark input.track_inputBox {
  color: #fff !important;
  background-color: #1d1f26 !important;
  border: solid 1px #424242;
}

#root.dark .icon_attachment {
  color: #fff !important;
  background-color: #1d1f26;
  /* border: 1px solid #d6d6d6; */
}

#root.dark span.attach_a_file {
  color: #fff !important;
}

#root.dark .add_entryText {
  color: #ffffff;
}

#root.dark span.backtoProperties {
  color: #fff !important;
}

#root.dark .form_hundread_percent input {
  color: #fff !important;
  background-color: #1d1f26;
}

#root.dark .tasknoteInHours {
  color: #fff !important;
  background-color: #1d1f26;
  border: solid 1px #424242;
}

#root.dark .Track_footer_bottom.Track_f_last {
  border-top: 1px solid #424242;
}

#root.dark .Track_body_headding.foot_middle.Track_hours {
  border-top: 1px solid #424242;
}

#root.dark .AssigneeField.roomTaskproperties {
  background-color: #5b6477;
}

input.hours_count {
  padding-right: 3px !important;
  padding-left: 5px !important;
}

#root.dark input.hours_count.trackTime {
  color: #fff;
  background-color: #1d1f26;
}

span.clearInput.allnote::before {
  top: -28px;
  left: 120px;
  cursor: pointer;
}

#root.dark ul.fileLists.msg p.title_file {
  color: #fff;
}

#root.dark p.msg_sender_time {
  color: #fff;

}

#root.dark .left_top_bar .filtrSection {
  background-color: #1d1f26;
}

#root.dark .file_opt {
  background-color: #1d1f26;
}

#root.dark .statusBtn {
  color: #fff;

}

#root.dark .themeMode {
  border-bottom: unset !important;
}

#root.dark label.reportcheck_label {
  color: #fff !important;
}

#root.dark ul.taskMissing_list li.listName {
  color: #fff !important;
}

#root.dark li.listEdit span {
  color: #ffffff;

}

#root.dark .memberNameEllipsis {
  color: #000;
}

#root.dark .taskUnassigned_body>div p {
  color: #ffffff;
}

.taskTeamInsight_info.MemberSelector .MemberSelector {
  background-color: #1d1f26;
  border-top: 1px solid #d6d6d6;
}

#root.dark .isDisable {
  /* opacity: 0.5; */
  background-color: #5b6477 !important;
  pointer-events: none;
  border: 1px solid #5b6477 !important;
  pointer-events: none;
  color: #a999a1 !important;
}

/* .task_chat_section.expandArea .discussion_main_area {
    height: calc(100vh - 102px);
} */
#root.dark p.title_file {
  color: #fff !important;
}

#root.dark .notiActions_heading {
  color: #fff !important;

}

#root.dark .collumns_lists_body {
  background-color: #1d1f26;
}

#root.dark ul.collumns_lists.extra_row {
  background-color: #1d1f26;
}

li.collumns_list_to {
  padding-right: 6px;
}

#root.dark .updateTaskMissing .trackHours {
  background-color: #1d1f26;
}

#root.dark ul.collumns_lists.header {
  background-color: #1d1f26;
}

#root.dark .updateTaskMissing .heading_hours .title {
  color: #ffffff;
}

#root.dark .updateTaskMissing li.collumns_list_hours input.hours_count {
  color: #ffffff;
  background-color: #1d1f26;
}

#root.dark .NoteHours.NoteCollapse {
  color: #ffffff;
  background-color: #1d1f26;
}

.task_chat_section.expandArea .notiActions_area {
  height: calc(100vh - 0px);
  padding-top: 50px;

}

.task_chat_section.expandArea .taskChatBox {
  height: 100vh;
  overflow-y: auto;
}

.task_chat_section.expandArea .bottomBox_task {
  /* position: fixed; */
  position: fixed;
  /* bottom: 0;
    position: absolute; */
  bottom: 0px;
  right: 0px;
  width: 550px;
}

.discussionArea {
  padding-top: 12px;
  /* max-height: calc(100vh - 188px); */
  height: auto;
}

.task_chat_section.expandArea .taskChatBoxHeader {
  position: fixed;
  width: 100%;
  background-color: #fff;
  top: 0;
  /* border-bottom: unset; */
  z-index: 99;
}

.task_chat_section.expandArea .discussion_main_area {
  /* max-height: 371px; */
  /* max-height: calc(100vh - 72px); */
  max-height: calc(100vh - 5px);
  overflow: auto;
  background-color: #fff;
  z-index: 999;
  padding-top: 0px;
}

#root.dark .task_chat_section.expandArea .taskChatBoxHeader {
  background-color: #1d1f26;
}

#root.dark .task_chat_section.expandArea .discussion_main_area {
  background-color: #1d1f26;
}

#root.dark .notiActions_time {
  color: #ffffff;
}

#root.dark .task_body_left.quickShowTask .task_chat_section {
  background-color: #1d1f26;
  color: #ffffff;
  border: solid 1px #424242;
}

.fileBody_area.taskListBody_area.extraClass.prime_search {
  height: calc(100vh - 450px);
}

#root.dark .viewReviewHistory {
  color: #1d1f26;
}

.dateForHour .react-datepicker-popper {
  left: 20px !important;
}

.date_area_div .react-datepicker-wrapper {
  width: 96%;
}

.date_area_div_width_quick .react-datepicker-wrapper {
  width: 100% !important;
}

.date_area_div_width .react-datepicker-wrapper {
  width: 100% !important;
}

.new_area p._tag_rooms {
  color: #0c1e47;
}

.new_area .keyword_list_task_settings p:hover {
  color: #fff !important;
}

#root.dark .taskUnassigned_body .mcPlan {
  color: #fff !important;
}

#root.dark .TrackHours_head_text {
  color: #fff !important;
}

#root.dark .statusParcent {
  color: #fff !important;
}

#root.dark span.closeModal.review {
  background-image: url('./media/images/close_button.svg') !important;
}

#root.dark input#dueTime,
#root.dark input#endDate,
#root.dark input#startDate {
  font-size: 14px;
  color: #ffffff;
  cursor: pointer;
  border: 1px solid #424242;
  border-radius: 5px;
}

#root.dark input._inputBar.searchRight {
  border: solid 1px #424242 !important;
}

#root.dark input._inputBar {
  border: solid 1px #424242 !important;
}

#root.dark input.start_Date_task {
  border: solid 1px #424242 !important;
}

#root.dark .custom_dropdown_task {
  border: solid 1px #424242 !important;
}

#root.dark input._inputSearchBar {
  border: solid 1px #424242 !important;
}

#root.dark .task_basicSearch_area {
  border-top: unset !important;
  border-bottom: solid 1px #424242 !important;
}

#root.dark .srcBtnSection {
  border-left: solid 1px #424242 !important;
}

#root.dark .taskHeadSection.topHead {
  border-bottom: solid 1px #424242 !important;
  border-left: solid 1px #424242 !important;
}

#root.dark .taskTopHead {
  border: solid 1px #424242 !important;
}

#root.dark .task_fulsearch_left {
  border: solid 1px #424242 !important;
  border-bottom: unset !important;
}

#root.dark .task_fulsearch_area {
  border-bottom: solid 1px #424242 !important;
}

#root.dark .TaskHeading,
#root.dark .TaskBody {
  border-top: solid 1px #424242 !important;
  border-bottom: unset !important;
}

#root.dark .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #5b6477 !important;
}

#root.dark .tag_room_list {
  color: #ffffff;
  background-color: #1d1f26;
}

#root.dark .tag_room_list p {
  border-bottom: solid 1px #424242 !important;
  color: #ffffff !important;
}

#root.dark ::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}

#root.dark .react-datepicker {
  background-color: #1d1f26 !important;
  color: #ffffff !important;
  border: 1px solid #424242 !important;
}

#root.dark .react-datepicker__header {
  background-color: #1d1f26 !important;
  border-bottom: 1px solid #424242 !important;
  border-top-left-radius: 0.3rem;
}

#root.dark .react-datepicker__day-name,
#root.dark .react-datepicker__day,
#root.dark .react-datepicker__time-name {
  color: #fff !important;
}

#root.dark .react-datepicker__header select {
  background-color: #1d1f26 !important;
  color: #fff !important;
}

#root.dark .react-datepicker__header button {
  background-color: #1d1f26 !important;
  color: #fff !important;
}

#root.dark .CalendarDiv {
  background-color: #1d1f26 !important;

}

#root.dark .react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #5b6477 !important;
}

#root.dark .msgssenge_chatbox {
  border-bottom: solid 1px #424242;
}

.bottomBox_task:hover .removeFile {
  display: block;
  right: 113px;
  top: 18px;
}

.tagFlieList_body .dnd-droppable-zone {
  border: none
}

.tagFlieList_body .dnd-item-container {
  border: none;
  padding: 0px;
  margin: 0px
}

.task_name_col {
  width: 20% !important;
}

.room_name_col {
  width: 15% !important;
}

.assignedTo_col,
.observer_col {
  width: 11% !important;
}

.due_date_col,
.start_date_col {
  width: 15% !important;
}

.progress_col,
.keywords_col,
.priority_col,
.status_col,
.cost_col,
.hours_col,
.createdBy_col,
.reviewPending_col {
  width: 10% !important;
}

#moreItem {
  display: none;
}

/*====== End For Dark theme ==========*/

.dnd-layout {
  background-image: linear-gradient(0deg, hsl(215, 14%, 16%) 0%, hsl(215, 19%, 29%) 100%);
  border-radius: 3px;
  box-shadow: 0 12px 16px rgba(0, 0, 0, .25);
  margin: 3rem auto;
  max-width: 370px;
}

.dnd-card {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
}

.dnd-items-container {
  text-align: center;
}

.dnd-item-container {
  padding: 5px;
  border: 1px solid rgb(229, 238, 244);
  margin: 5px;
  width: fit-content;

}

.dnd-item-container.column {
  display: inline-block;

}

.dnd-item-container.dragging {
  /* border: 1px solid red; */
  background-color: #318fff;
  width: fit-content !important;
}

.dnd-table {
  border: 1px solid rgb(229, 238, 244);
  width: 100%;
  text-align: center;
}

.dnd-table th,
.dnd-table td {
  border: 1px solid rgb(229, 238, 244);
  font-size: 14px;
  font-family: 'Poppins';
  /* border-top: unset; */
  word-break: break-all;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #032e84;
  position: relative;
  /* top: -4px; */
  text-overflow: ellipsis;
  /* width: 100%; */
  overflow: hidden;
  line-height: 20px;
  /* width: 100%; */
  /* display: flex; */
  /* justify-content: flex-start; */
  padding: 10px 20px;
  /* border: 1px solid rgb(229, 238, 244); */
  position: relative;
  grid-gap: 10px;


}

.dnd-center-align {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.dnd-droppable-zone {
  border: 2px solid rgb(229, 238, 244);
  margin: 10px;
  /* max-height: 100px; */
  min-height: 50px;
  /* overflow: scroll; */
}

.assigneeMem_inner2 {
  /* width: 85px; */
  display: flex;
  margin: 0 auto;
  text-align: left;
  /* flex-direction: column; */
}

.tooltipHolder2 {

  height: 100%;
  /* width: 100%; */
  display: flex;
  flex: 1 1;
}

.tooltipHolder2 span {
  height: 100%;
  width: auto;
  display: flex;
  justify-content: center;
}

._tag_rooms.redColor {
  color: #fff !important;
  background-color: #023d67 !important;
}

.flex.gap-4.kanban-view-col>div:first-child {
  height: calc(100% - 180px) !important;
  max-height: calc(100% - 180px) !important;

}

.group_container {
  height: calc(100vh - 412px);
  overflow-y: auto;
}

.group_container_withThread {
  height: calc(100vh - 456px);
  overflow-y: auto;
}

.group_container_admin {
  height: calc(100vh - 470px);
  overflow-y: auto;
}

/* 
.replyMsgContainer .user_msg {
  pointer-events: none;
} */

.voiceContinue {
  height: 42px;
  width: auto;
  border-radius: 5px;
  background: #9a9a9a;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .2px;
  line-height: 16px;
  text-align: center;
  margin-top: 16px;
  float: right;
  border: none;
  cursor: pointer;
  margin-left: 10px;
  padding: 0 20px;
}

.voiceContinue.inactive {
  pointer-events: none;
}

.voiceContinue.active {
  background: #0b1f47 !important;
}

.memberItemImage img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.tag_main_area {
  width: 100%;
  height: calc(100% - 80px);
}

.tagHomeTable {
  display: flow-root;
  height: calc(100% - 79px);
  overflow-y: auto;
  padding: 10px 20px;
  background-color: #eef3f9;
}

.tagTableRow {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
}

div#bottomBox:hover .removeFile {
  display: flex;
  right: 225px;
  top: 41px;
  align-items: center;
}

.deleteMsgOptDirect:before {
  content: "";
  /* background-image: url("/media/images/icons/other/Red/SVG/WorkFreeli_Icons_Red_trash.svg"); */
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: 18px;
  float: left;
  margin-top: 6px;
  margin-left: 6px;
}

.notificationsCircleTask {
  width: 10px;
  height: 10px;
  background-color: #7b40d5;
  top: 5px;
}

.notificationsCircleTask,
span.notificationsCircle {
  position: relative;
  border-radius: 50%;
  display: inline-block;
  margin-left: 5px;
}

.deleteMsgOptDirect:before {
  content: "";
  background-image: url("/src/media/images/trash-red.svg");
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position-y: 50%;
  background-size: 18px;
  float: left;
  margin-top: 6px;
  margin-left: 6px;
}

.usersTabSecDevide {
  width: 50%;
  display: flex;
  align-items: center;
  height: 40px;
}

.teamManagementTitle {
  font-size: 16px;
  color: #0c1e47;
}

.usersTabList {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: .24px;
  color: #0c1e47;
  margin-right: 13px;
  cursor: pointer;
  position: relative;
  transition: all 2ms cubic-bezier(0, 0, .2, 1);
  padding-right: 32px;
}

.usersTabList.active:before {
  content: "";
  position: absolute;
  width: calc(100% - 30px);
  height: 3px;
  background-color: #e91e63;
  left: 0;
  bottom: -4px;
}

.usersTabList:hover {
  color: var(--NewRed);
}

.usersTabList:hover:before {
  background-color: var(--NewRed);
}

.usersTabListActive {
  color: #e91e63;
}

.freeliModalFoot .btnActionConfirm {
  height: 42px;
  min-width: 130px;
  width: auto;
  border-radius: 5px;
  background-color: #0b1f47;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin: 0px 0px 0px 15px;
  float: right;
  padding: 0 20px;
  box-sizing: border-box;
}

.senderNameForQuickView {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}


.room_bottom_status {
  width: calc(100% - 346px);
  position: fixed;
  z-index: 6;
  bottom: 0px;
  height: 42px;
  right: 0;
}

.status_container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e91e63;
}

.status_icon {
  color: #fff;
  margin-right: 9px;
}

.status_name {
  color: #fff;
  font-size: 15px;
}

.status_btn {
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  padding: 4px 10px;
  cursor: pointer;
  margin-left: 15px;
}

.group_container::-webkit-scrollbar {
  border: none !important;
}

.loaderMain.mini_loader {
  background-color: transparent;
  height: 35px;
  background-size: 54px;
}

.tag_edit_input {
  border: 1px solid #d8d8d8c7;
  padding: 0 22px;
  width: calc(100% - 100px);
}

.editable_tag {
  background-color: #0c1e47;
}

input#popupserachFilter::placeholder {
  color: #318FFF !important;
}

.tagRelatedPopup.forDateRange {
  width: 100%;
  height: 55px;
  top: 120px;
  box-shadow: -20px 4px 54px 0 rgba(0, 0, 0, .18);
  z-index: 99999 !important;
}

.fileUploadBottomCon {
  /* box-shadow: 10px 3px 8px rgb(0 0 0 / 30%); */
  width: 100%;
  background-color: #fff;
  /* padding: 20px; */
  position: absolute;
  display: flow-root;
  bottom: 105px;
  z-index: 10;
  /* transition: all 0.5s ease; */
}

/*Test Msg Design Css*/


/* .chat {
  display: flex;
  flex-direction: column;
}

.date-separator {
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  color: #888;
}

.message-group {
  margin-bottom: 10px;
}

.sender-info {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.sender-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.sender-name {
  font-weight: bold;
}

.message {
  position: relative;
  padding: 5px 10px;
  background-color: #f1f1f1;
  border-radius: 5px;
  margin-bottom: 3px;
}

.msg-body {
  margin-bottom: 5px;
}

.timestamp {
  display: block;
  font-size: 0.8em;
  color: #888;
  text-align: right;
  margin-top: 2px;
} */


/* EOD CSS */
.eodTabCon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 10px;
  border-bottom: 1px solid #d9d9d9;
  padding-left: 40px;
  padding-right: 40px;
  background-color: white;
}

.eocTabHeader {
  display: flex;
  gap: 20px;
  align-items: center;
}

.eodTabText {
  font-size: 14px;
  color: #858fa3;
  padding-bottom: 5px;
  padding-top: 5px;
  border-bottom: 1px solid white;
  cursor: pointer;
}

.eodTabTextActive {
  font-size: 14px;
  color: #0b1f47;
  border-bottom: 2px solid #0b1f47;
}

.salesReportTabCon {
  height: calc(100% - 116px);
  overflow: auto;
}

.salesReportTabMainCon {
  height: calc(100% - 150px);
  overflow: auto;
}

.salesReportMainCon {
  padding: 10px 25px 25px 25px;
  background-color: #F1F6FA;
}

.salesProgressBarCon {
  padding: 0px 0px 0px 0px;
  background-color: #F1F6FA;
}

.salesReportDateText {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #0b1f47;
}

.salesReportGraphHead {
  display: flex;
  gap: 20px;
  align-items: center;
  /* justify-content: space-between; */
  margin-top: 10px;
}

.salesReportGraphBox {
  background-color: #fff;
  padding: 15px 20px;
  border-radius: 10px;
  width: 20%;
}

.salesReportBox {
  display: flex;
  grid-gap: 0;
  gap: 0;
  align-items: center;
  margin-left: -8px;
}

.salesReportIcon {
  font-size: 24px;
  color: #64a6f4;
}

.salesReportNumber {
  font-size: 24px;
  color: #0b1f47;
  width: 85%;
}

.salesReportText {
  font-size: 14px;
  color: #0b1f47;
  font-weight: 600;
  margin-left: -5px;
}

.inputGroup .addMemberBtn:hover {
  background-color: #318fff;
}

#root.dark .inputGroup .addMemberBtn:hover {
  color: #fff !important;
}

.right_container_task {
  width: calc(100% - 346px);
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  transition: width 400ms;
  z-index: 10;
}

@media only screen and (min-width: 1250px) and (max-width: 1370px) {
  .salesReportNumber {
    font-size: 22px;
    color: #0b1f47;
    width: 85%;
  }

  .salesReportIcon {
    font-size: 22px;
    color: #64a6f4;
  }

  .salesReportText {
    font-size: 12px;
    color: #0b1f47;
    font-weight: 600;
    margin-left: -5px;
  }
}

@media only screen and (max-width: 1250px) {
  .salesReportNumber {
    font-size: 20px;
    color: #0b1f47;
    width: 85%;
  }

  .salesReportIcon {
    font-size: 20px;
    color: #64a6f4;
  }

  .salesReportText {
    font-size: 10px;
    color: #0b1f47;
    font-weight: 600;
    margin-left: -5px;
  }
}


.salesProgressBarHead {
  display: flex;
  gap: 30px;
  align-items: center;
  background-color: #fff;
  padding: 30px 25px 31px 25px;
}

.salesProgressBarText {
  /* font-size: 18px; */
  color: #0b1f47;
}

.fromDate_Sales {
  width: 100%;
  position: relative;
}

.fromDate_Sales input {
  height: 28px !important;
  margin-top: 0px !important;
  color: #0b1f47;
  font-size: 14px !important;
}

.fromDate_Sales .react-datepicker-popper[data-placement^=bottom] {
  inset: -1px auto auto 0px !important;
}

.salesProgressBarDate {
  width: 185px;
}

.salesProgressBarDateText {
  font-size: 14px;
  color: #0b1f47;
  margin-bottom: 2px;
}

.datePickerCon {
  position: relative;
}

.calenderIconDate {
  position: absolute;
  right: 8px;
  top: 3px;
  z-index: 99;
  color: #0b1f47;
}

.calenderIconDateClose {
  position: absolute;
  right: 8px;
  top: 4px;
  z-index: 99;
  color: red;
  font-size: 20px;
}

.selectVenueRight {
  position: relative;
  width: 52%;
}

.salesReportRightCon {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.salesReportRightText {
  text-align: right;
  background-color: #e91e62;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 10px;
  gap: 10px;
  border-radius: 6px;
  justify-content: flex-end;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.salesReportRightText.show {
  opacity: 1;
}

.salesReportRightTextIcon {
  cursor: pointer;
  font-size: 22px;
}

.salesReportLeft {
  display: flex;
  align-items: center;
  grid-gap: 20px;
  gap: 20px;
  padding-top: 10px;
  padding-bottom: 25px;
  width: 60%;
}

.eodReportDateAreaHeader,
.task_message {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rightArrowSelectIcon {
  position: absolute;
  top: 10px;
  right: 8px;
}

.clearAllIconSelect {
  font-size: 18px;
  color: #858fa3;
}

.clearAllContainer {
  position: absolute;
  top: 9px;
  right: 22px;
}

.totalCostEODMain {
  display: flex;
  justify-content: center;
  align-items: center;
}

.totalCostEODCon {
  font-weight: 500;
  text-align: center;
  color: #0c1e47;
  font-size: 22px;
  /* margin-top: 3px; */
}

.totalCostEOD {
  font-weight: 500;
  text-align: center;
  color: #0c1e47;
  font-size: 22px;
}

.totalCostEODShort {
  font-weight: 600;
  font-size: 18px;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-toolbar {
  display: none !important;
}

/* EOD CSS */
input.searchBoxInput:focus {
  outline: none !important;
  border-color: #2196f3;
  box-shadow: 0 0 3px #2196f3;
}

/* EOD CSS */

.custom-timezone-select .css-13cymwt-control {
  height: 50px !important;
}

.custom-timezone-select .css-t3ipsp-control {
  height: 50px !important;
}

input.mainTime.updateUserTime {
  width: 110px;
  height: 36px;
  padding-left: 10px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 35px;
  color: #0d2150 !important;
  border: 1px solid #d9e2e8;
}

.leftSearchLeft.fullWidth {
  width: 90%;
}

.top_bar_left_options a:focus {
  color: unset !important;
}

.error_page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

h1.oops {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #023d67;
}

.error_reload {
  background-color: #023d67;
  color: #fff;
  border: none;
  width: 120px;
  border-radius: 2px;
  margin-top: 15px;
}

button.createConv_create.transparent {
  opacity: .5;
  pointer-events: none;
}

.compnayTitleArea input {
  padding-right: 0 !important;
}

.compnayTitleArea.titleHover:hover .removeFile {
  display: flex;
}



.tooltip {
  /* triangle dimension */
  --a: 90deg;
  /* angle */
  --h: 1em;
  /* height */

  --p: 50%;
  /* triangle position (0%:left 100%:right) */
  --r: 1.2em;
  /* the radius */
  --b: 1px;
  /* border width  */
  --c1: #e3f2ff;
  --c2: #ffffff;

  border-radius: min(var(--r), var(--p) - var(--h)*tan(var(--a)/2)) min(var(--r), 100% - var(--p) - var(--h)*tan(var(--a)/2)) var(--r) var(--r)/var(--r);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0,
      min(100%, var(--p) + var(--h)*tan(var(--a)/2)) 0,
      var(--p) calc(-1*var(--h)),
      max(0%, var(--p) - var(--h)*tan(var(--a)/2)) 0);
  background: var(--c1);
  border-image: conic-gradient(var(--c1) 0 0) fill 0/ 0 max(0%, 100% - var(--p) - var(--h)*tan(var(--a)/2)) var(--r) max(0%, var(--p) - var(--h)*tan(var(--a)/2))/var(--h) 0 0 0;
  position: absolute;
  color: #fff;
  text-align: left;
  /* box-shadow: 0 1px 60px 0 rgba(0, 0, 0, 0.1); */
}

.tooltip:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: var(--b);
  border-radius: inherit;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0,
      min(100% - var(--b), var(--p) + var(--h)*tan(var(--a)/2) - var(--b)*tan(45deg - var(--a)/4)) var(--b),
      var(--p) calc(var(--b)/sin(var(--a)/2) - var(--h)),
      max(var(--b), var(--p) - var(--h)*tan(var(--a)/2) + var(--b)*tan(45deg - var(--a)/4)) var(--b));
  background: var(--c2) content-box;
  border-image: conic-gradient(var(--c2) 0 0) fill 0/ 0 max(var(--b), 100% - var(--p) - var(--h)*tan(var(--a)/2)) var(--r) max(var(--b), var(--p) - var(--h)*tan(var(--a)/2))/var(--h) 0 0 0;
}


.tooltipMore {
  /* triangle dimension */
  --a: 90deg;
  /* angle */
  --h: 1em;
  /* height */

  --p: 80%;
  /* triangle position (0%:left 100%:right) */
  --r: 1.2em;
  /* the radius */
  --b: 1px;
  /* border width  */
  --c1: #e3f2ff;
  --c2: #ffffff;

  border-radius: min(var(--r), var(--p) - var(--h)*tan(var(--a)/2)) min(var(--r), 100% - var(--p) - var(--h)*tan(var(--a)/2)) var(--r) var(--r)/var(--r);
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0,
      min(100%, var(--p) + var(--h)*tan(var(--a)/2)) 0,
      var(--p) calc(-1*var(--h)),
      max(0%, var(--p) - var(--h)*tan(var(--a)/2)) 0);
  background: var(--c1);
  border-image: conic-gradient(var(--c1) 0 0) fill 0/ 0 max(0%, 100% - var(--p) - var(--h)*tan(var(--a)/2)) var(--r) max(0%, var(--p) - var(--h)*tan(var(--a)/2))/var(--h) 0 0 0;
  position: absolute;
  color: #fff;
  text-align: left;
  /* box-shadow: 0 1px 60px 0 rgba(0, 0, 0, 0.1); */
}

.tooltipMore:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: var(--b);
  border-radius: inherit;
  clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 0,
      min(100% - var(--b), var(--p) + var(--h)*tan(var(--a)/2) - var(--b)*tan(45deg - var(--a)/4)) var(--b),
      var(--p) calc(var(--b)/sin(var(--a)/2) - var(--h)),
      max(var(--b), var(--p) - var(--h)*tan(var(--a)/2) + var(--b)*tan(45deg - var(--a)/4)) var(--b));
  background: var(--c2) content-box;
  border-image: conic-gradient(var(--c2) 0 0) fill 0/ 0 max(var(--b), 100% - var(--p) - var(--h)*tan(var(--a)/2)) var(--r) max(var(--b), var(--p) - var(--h)*tan(var(--a)/2))/var(--h) 0 0 0;
}

.read-more {
  color: #3D81F1;
  cursor: pointer;
}

.read-more:hover {
  text-decoration: underline;
}

.tooltip.user_Management_title {
  position: relative !important;
  clip-path: none !important;
  background: none;
  border-image: none
}

.left_top_bar a:focus {
  color: #ffffff !important;
}

.freeliModalFoot:before {
  background-color: transparent;
  opacity: 0 !important
}

.usersTableContainer.scroll-container::-webkit-scrollbar {
  width: 8px;
  /* Width of the scrollbar */
  transition: opacity 0.3s ease-in-out;
  /* Smooth transition for opacity */
  opacity: 0;
  /* Hidden by default */
}

.usersTableContainer.scroll-container:hover::-webkit-scrollbar {
  opacity: 1;
  /* Show on hover */
}

.filter-no-user {
  text-align: center;
  height: calc(100vh - 200px);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #0d2150;
}

.searchareaDiv {
  position: relative;
}

.filehub .searchareaDiv {
  /* padding: 0 10px; */

}

.leftBtn_area a {
  flex: 1 1 calc(50% - 6px);
  max-width: calc(50% - 6px);
  box-sizing: border-box;
}

/*======= Expanses button css =========*/
.leftBtn_area a:last-child:nth-child(odd) {
  flex: 1 1 100%;
  max-width: 100%;
  text-align: center;
}

.borderTop {
  border-top: .067px solid #475674;
}

.venueTitleEditIcon {
  margin-left: 5px;
  cursor: pointer;
  color: grey;
  font-size: 18px;
}

.venueTitleEditIcon:hover {
  color: #002e98;
}

button.subScripttionTab[disabled] {
  opacity: 1 !important;
}

/* .expensesIcon {
  background-image: url("./media/images/expanses.svg");
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: 23px;
  width: 24px;
  height: 20px;
} */

/*=======End Expanses button css =========*/

.tagsFilterOptions .text-sm.active {
  background-color: #eceef4;
  color: #0c1e47;
}

.viewFilesButton.inactive {
  opacity: 0.5;
  pointer-events: none;
}

.footerLabelticket {
  clear: both;
  display: flex;
  align-items: center;
  margin-left: auto;
  width: 100%;
  justify-content: right;
  bottom: 8px;
  /* padding: 0 30px; */
}

.reportLabel.ticket {
  font-size: 14px;
  position: relative;
  top: 10px;
  z-index: 99999;
}

span.switchMore {
  position: relative;
  cursor: pointer;
  left: 2px;
}

span.switchMore:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background-color: #0c1e47;
  bottom: 0;
}

.cu_inputGroup_select div>div>div>input {
  height: 40px !important;
}

.cu_inputGroup_select .user_manage {
  border: 1px solid #d6d6d6 !important;
  border-radius: 4px;
  cursor: pointer !important;
}

.cu_inputGroup_select_room div {
  border: none !important;
  cursor: pointer !important;
}

.cu_inputGroup_select {
  background-color: #f3f4f5 !important;
  border-radius: 4px;
  cursor: pointer !important;
}

.fileTitleArea {
  max-width: calc(100% - 50px);
}

.fileTitleArea p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

/*#forwardFiles {
  display: none;
}*/

.taskListAdvance .react-datepicker-wrapper {
  width: 100% !important;
}

.upload_file_action {
  visibility: hidden;
}

.upload_completed .upload_file_action {
  visibility: visible;
}

.inputClassRoomPhone .form-control {
  padding-left: 50px !important;
}

.select-ecosystem.inVenueSelectTeam {
  border: 1px solid #d6d6d6 !important;
}

.cu_inputGroup_select.VenueSelectTeam {
  background-color: #ffffff !important;
}

/* .editorFileUploadComment.rdw-editor-main {
  position: relative;
} */
.editorFileUploadComment.rdw-editor-main span.rdw-suggestion-dropdown {
  bottom: 37px !important;
  position: absolute;
  background-color: #fff;
  width: 46%;
  left: 52% !important;
}

.file_opt.tagOpecity {
  opacity: 0.5;
  pointer-events: none;
}

#root.dark .css-s70ol2-control,
#root.dark .cu_inputGroup input {
  background-color: #1d1f26;
  color: #fff;
}

#root.dark .css-1dimb5e-singleValue {
  color: #fff;
}

#root.dark .css-1iknmh2-control {
  background-color: #1d1f26;
}

.qns-response.mindsdb {
  padding: 15px 45px;
}

#root.dark .msg_screen_loader {
  background-color: transparent !important;
}

div#taskm {
  margin-left: 8px;
}

#taskm input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

#taskm label {
  position: relative;
  /* cursor: pointer; */
}

#taskm label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #c1ddf4;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  /* cursor: pointer; */
  margin-right: 5px;
}

#taskm input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 4px;
  left: 8px;
  width: 9px;
  height: 20px;
  border: solid #0079bf;
  border-width: 0px 3px 3px 0;
  transform: rotate(45deg);
}

.taskViewBabble.userArea {
  background-color: #F5F9FC;
  /* background-color: #e3f2fedb; */
  padding-top: 7px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

#root.dark .taskViewBabble.userArea {
  background-color: #1D1D1D;
}

#root.dark .onlyAssigneeLable {
  color: white;
}

#root.dark .task_message_label {
  color: white;
}

.userProfileDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.taskCreatedLabel {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 15px;
}

.userProfileDiv.changeNameLabel .sender_name {
  font-size: 14px;
}

.userProfileDiv.changeNameLabel .labelTesk {
  color: #318fff;
  font-size: 14px;
  font-weight: 500;
}

.userProfileDiv.changeNameLabel .onlyAssigneeLable {
  font-size: 12px;
  padding-left: 20px;
  color: #424242de;
  display: flex;
  align-items: center;
  gap: 5px;
}

#root.dark .stickyBottomRowContainer .divTableRow.totalRow {
  background-color: #424242;
}

#root.dark .divTableRow:hover {
  background-color: #424242cf;
  cursor: pointer;
}

#root.dark .lastUpdated_text p.user {
  color: #ffffff !important;
}

#root.dark div#record svg.fiBarChart2 {
  color: #ffffff !important;
}

#root.dark .send_msg_opt svg {
  color: #ffffff !important;
}

#root.dark .divTableRow.divTableFirstRow:hover {
  background-color: #ffffffe0 !important;
}

#root.dark .barChartSalesText {
  color: #fff;
}

#root.dark .eodBackground .barChartSales {
  background-color: #7ed6d0 !important;
}

#root.dark .eodBackground .barChartCost {

  background-color: #032e84 !important;

}

.genarated_img_wraper {
  max-width: 400px;
  height: auto;
  min-height: 256px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  overflow: hidden;
  border-radius: 20px;
  position: relative;
  background-color: #00000063;
}

.download_ai_img {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #00000063;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  background-image: url("./media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_download.svg");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.genarated_img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.disabled-link {
  pointer-events: none;
  text-decoration: none;
}

.custom-scrollbars.threadAndFllaged>div {
  margin-bottom: 0 !important;
}

#root.dark .cu_inputGroup_select {
  background-color: #181818 !important;
}

#root.dark .css-13cymwt-control {
  background-color: #181818 !important;
}

#root.dark .group_container_admin a {
  color: #ffffff !important;
}
._userList:last-child .userActionPopup{
  margin-top: -158px;
}

/* .tableContainer table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
} */
.user_msg.userArea .msg_body .msg_txt span{
  background-color: transparent !important;
}