span.angleRight svg {
    margin: 8px 4px 4px 4px;
}
.recurringbillingArea {
    overflow: hidden !important;
}
.supportAndBillingContainer.upgradePlan input.chose_plan_users {
    color: #0c1e47;
}
.billingAddressFormInput.cityInfo {
    gap: 20px;
}
.provinceLabel label.container_checkbox {
    top: 0 !important;
    right:0 !important;
}
.provinceLabel {
    display: flex;
    align-items: center;
}
.provinceLabel label.container_checkbox span {
    font-weight: 400;
}
