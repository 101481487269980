.ai_body {
  height: calc(100vh - 80px);
  width: 100%;
  position: relative;
}

.qns-response {
  width: 100%;
  height: calc(100% - 75px);
}

.response_section {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.ai_main_question {
  display: flow-root;
}

.ai_response {
  display: flex;
  justify-content: space-between;
  padding: 8px 40px;
  position: relative;
}

.ai_response:hover {
  background-color: #f3f3f3;
}

.ai_msg_hover {
  min-width: auto;
  border-radius: 4px;
  border: 1px solid #979797;
  height: 35px;
  /* display: none; */
  align-items: center;
  justify-content: center;
  float: right;
  position: absolute;
  bottom: 0;
  right: 50px;
  background-color: #fff;
  z-index: 9;
}

.ai_msg_delete {
  width: 40px;
  height: 100%;
  cursor: pointer;
  color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai_msg_send {
  padding: 15px 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  max-height: 330px;
  min-height: 75px;
  z-index: 3;
  background-color: #fff;
}

.ai_msg_attach_btn {
  border-radius: 7px;
  background-color: initial;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 13px;
  bottom: 11px;
  width: 26px;
  height: 26px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ai_msg_send input,
.ai_msg_send textarea {
  width: 100%;
  border-radius: 45px;
  border: none;
}

.ai_msg_send textarea {
  background-color: #f4f4f4;
  padding: 10px 50px;
  min-height: 45px;
  max-height: 300px;
  resize: none;
  overflow-y: auto;
  font-size: 16px;
  outline: none;
}

.ai_msg_send_btn {
  border-radius: 32px;
  background-color: #000;
  border: none;
  position: absolute;
  right: 10px;
  bottom: 7px;
  width: 32px;
  height: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

.gpt_icon {
  background-image: url(../../media/images/gpt.svg);
  width: 40px;
  height: 40px;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: 50%;
  border-radius: 100%;
  outline: 1px solid #ececec;
}


/* AI CSS */
.ai_header {
  width: 100%;
  height: 80px;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
  padding: 0 45px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ai_body {
  height: calc(100vh - 80px);
  width: 100%;
  position: relative;
}

.ai_body .file_count {
  font-size: 16px;
}

.ai_header_part {
  display: flex;
  align-items: center;
}

.ai_close {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  color: #06193f;
}

.ai_back {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  cursor: pointer;
  color: #06193f;
}

.qns-response {
  width: 100%;
  height: calc(100% - 75px);
}

.response_section {
  overflow-y: auto;
  width: 100%;
  height: 100%;
  max-height: 100%;
}

.ai_main_question {
  display: flow-root;
}

.ai_question {
  background-color: rgb(244 244 244/1);
  display: inline-block;
  padding: 5px 15px;
  border-radius: 20px;
  cursor: pointer;
  max-width: calc(100% - 47px);
  font-size: 14px;
}

.gpt_icon {
  /* background-image: url(/media/images/gpt.svg); */
  background-image: url(../../media/images/gpt.svg);
  width: 32px;
  height: 32px;
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 100%;
  outline: 1px solid #ececec;
}

.ai_main_question .gpt_icon {
  margin-right: 15px;
}

.ai_response {
  display: flex;
  justify-content: space-between;
  padding: 8px 45px;
  position: relative;
}

.ai_response:hover {
  background-color: #f3f3f3;
}

.ai_msg_hover {
  min-width: auto;
  border-radius: 4px;
  border: 1px solid #979797;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  float: right;
  position: absolute;
  bottom: 0px;
  right: 50px;
  background-color: #ffffff;
  z-index: 9;
}

.ai_response:hover .ai_msg_hover {
  display: flex;
}

.ai_msg_delete {
  width: 40px;
  height: 100%;
  cursor: pointer;
  color: #ff0000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ai_response .gpt_icon {
  width: 41px;
  height: 41px;
  background-color: #fff;
}

.ai_response_ans {
  width: calc(100% - 41px);
  font-size: 14px;
  color: #000000;
  letter-spacing: 0;
  line-height: 18px;
}

.ai_response_ans ul {
  list-style: initial;
}

.ai_response_ans ul,
.ai_response_ans ol {
  padding-left: 30px;
  margin-bottom: 10px;
}

.genarated_img_wraper {
  max-width: 400px;
  height: auto;
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  overflow: hidden;
  border-radius: 20px;
}

.genarated_img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
}

.ai_response_ans p {
  margin-bottom: 10px;
}

.ai_response_ans h1 {
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 600;
}

.ai_response_ans h2 {
  font-size: 16px;
  margin-bottom: 10px;
  font-weight: 600;
}

.ai_response_ans h3 {
  font-size: 15px;
  margin-bottom: 10px;
  font-weight: 600;
}

.code-block {
  background-color: #f5f5f5;
  padding: 10px;
  border-radius: 5px;
  font-family: monospace;
  overflow-x: auto;
}

.ai_response_ans pre {
  border: 1px solid rgb(0 0 0 / 15%);
  background-color: #f9f9f9;
  display: flow-root;
  padding: 15px;
  border-radius: 10px;
  overflow: auto;
  margin: 10px 0;
}

.ai_msg_send {
  padding: 15px 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  max-height: 330px;
  min-height: 75px;
  z-index: 3;
  background-color: #fff;
}

.ai_msg_send textarea {
  width: 100%;
  background-color: #f4f4f4;
  border-radius: 45px;
  border: none;
  padding: 10px 50px 10px;
  min-height: 45px;
  max-height: 300px;
  resize: none;
  overflow-y: auto;
  font-size: 16px;
  outline: none;
}

.ai_msg_send input {
  width: 100%;
  background-color: #ececec;
  border-radius: 45px;
  height: 45px;
  border: none;
  padding: 5px 25px;
}

.ai_msg_attach_btn {
  border-radius: 7px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 13px;
  bottom: 11px;
  width: 26px;
  height: 26px;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
}

.ai_msg_attach_btn:hover {
  background-color: #0000001a;
}

.ai_msg_send_btn {
  border-radius: 32px;
  background-color: #000;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  bottom: 7px;
  width: 32px;
  height: 32px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
}

.ai_send_area {
  width: 100%;
  display: grid;
  position: relative;
}

.send_doc_holder {
  width: 100%;
  height: auto;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
}

.ai_send_doc {
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: 10px;
  outline: 1px solid #ececec;
}

.ai_doc_close {
  position: absolute;
  right: -8px;
  top: -8px;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 10px;
  color: #000;
  background-color: #fff;
  outline: 1px solid #ececec;
  visibility: hidden;
  cursor: pointer;
}

.ai_send_doc:hover .ai_doc_close {
  visibility: visible;
}

.ai_send_doc img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: contain;
}

.scroll_button {
  border-radius: 32px;
  color: #5d5d5d;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, .1);
  position: absolute;
  bottom: 98px;
  right: calc(50% - 16px);
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 8px;
}

.response-container img {
  max-width: 100%;
  height: auto;
}

.response-container pre {
  background: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  overflow: auto;
}

.ai_loader {
  height: 40px;
  width: auto;
  display: inline-flex;
  align-items: center;
  padding-left: 5px;
}

.ai_loader svg {
  height: 100%;
  width: auto;
}

/* SVG Animation */
.imgloader circle {
  fill: none;
  stroke-width: 4;
  stroke-linecap: round;
  animation: spin 1s linear infinite;
  stroke: #3498db;
}

/* Keyframe animation */
@keyframes spin {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }

  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -125;
  }
}