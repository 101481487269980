@import url("https://use.fontawesome.com/releases/v5.2.0/css/all.css");

.taskTopHead {
    padding-left: 30px;
    padding-right: 30px;
    height: 60px;
    border: solid 1px #e3f2fe;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.custom_breadcrumb {
    padding-left: 10px;
    list-style: none;
}

.custom_breadcrumb .breadcrumb-item {
    display: inline;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3;

}

.task_right_container {
    height: 100%;
}

.custom_breadcrumb li+li:before {
    content: "\f105";
    font-family: "FontAwesome";
    color: #53627c;
    font-size: 14px;
    margin-top: 0px;
    margin-right: 4px;
    font-weight: 600;
    margin-left: 2px;
}

.singleKeyword svg.removeKeyword {
    color: #0b1f47;
}

.custom_breadcrumb li:last-child {
    color: #318fff;
}

.search_top_area {
    flex-basis: 100%;
}

.fa_search i.fas.fa-search {
    font-family: 'FontAwesome';
    font-weight: 600;
    color: #318fff;
    font-size: 20px;
    cursor: pointer;
}

input#search_input_box {
    width: 100%;
    box-sizing: border-box;
    height: 33px;
    text-indent: 0px;
    background-repeat: no-repeat;
    background-position: 99%;
    background-size: 15px;
    margin-left: 0;
    margin-right: 0;
    font-size: 14px !important;
    line-height: 35px;
    padding: 6px 14px 7px 10px;
    border-radius: 16.3px;
    border: solid 1px #d9e2e8;
    background-color: #fff;
}

.topSerachIcon {
    float: right;
    margin: 0;
    width: 20px;
    height: 22px;
    background-size: 20px;
}

.search_top_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0px;
    gap: 15px;
}

.search_top_head {
    width: 100%;
}

.closeConnectSearch.closeSearch {
    position: relative;
    width: 20px;
    height: 20px;
    background-image: url('../../media/images/close_button.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 0;
}

ul.task_menu_Lists {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 28px;
}

ul.task_menu_Lists li.activeTaskMenu {
    /* color: #2196f3; */
    position: relative;
}

ul.task_menu_Lists li {
    font-size: 20px;
    color: #0b1f47;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;

}

ul.task_menu_Lists li.activeTaskMenu:after {
    content: "";
    position: absolute;
    width: 103%;
    height: 8px;
    left: 0;
    bottom: 35px;
    border-radius: 4px;
    background-color: #318fff;
}

.createTaskButton span.taskPlusIcon {
    position: relative;
    top: 2px;
    left: 10px;
}

.task_column.button_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.runReportButton {
    width: 108px;
    height: 33px;
    flex-grow: 0;
    padding: 4px 16px 3px 15px;
    border-radius: 16px;
    background-color: #ffaf4c;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.insights_first_area {
    height: 120px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 30px;
    justify-content: space-between;
}

.taskDashboardHeading {
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding-left: 10px;
}

.avatar-number {
    width: 40px;
    height: 40px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background: #a6d068;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.taskTeam_info {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 8px 23px 8px 8px;
    border-radius: 60px;
    background-color: #dfe5f2;
}

.teamTaskMemberName {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.teamTask_TeamName {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}

.task_dashboard {
    display: flex;
    align-items: flex-start;
    gap: 35px;
    padding-left: 40px;
    padding-right: 30px;
}

.calendar_container {
    flex-basis: 40%;
}

.taskStatus_title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.taskStatus_container {
    background-image: url('../../media/images/task_group.svg');
    height: 337px;
    flex-basis: 60%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: rgb(227 242 255 / 40%);
    border-radius: 25px;
    padding: 26px 26px 26px 19px;
}

.taskStatus_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 23px;
}

.totalTasks {
    height: 28px;
    flex-grow: 0;
    padding: 3px 12px 1px 12px;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 70%);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
}

.tesk-status-container {
    /* background: #aaa; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 17px 22px;
    padding-top: 20px;
}

.tesk-status-container>.tesk-status-item {
    flex: 1 1 30%;
    /*grow | shrink | basis */
    height: 111px;
}

.tesk-status-item {
    border-radius: 20px;
    box-shadow: 0 0 34px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    /* padding: 11px 18px 5px 15px; */
}

.task_status_result {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.task_status_circle {
    width: 10px;
    height: 10px;
    border-radius: 40px;
}

.tesk-status-item .firstPara {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 11px 18px 0px 15px;
}

.secoundPara .task_counter {
    font-size: 36px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.secoundPara {
    border-bottom: 1px solid #e3f2ff;
    padding: 0px 18px 4px 15px;
}

.lastPara {
    padding: 2px 18px 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.viewTasksIcon {
    background-image: url('../../media/images/forward_icon.svg');
    background-size: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    top: 4px;
}

.insights_work_load_area {
    display: flex;
    padding-left: 40px;
    padding-right: 20px;
    padding-top: 38px;
    width: 100%;
    gap: 34px;
}

.keyword_search_area {
    width: 40%;
    height: 310px;
    /* overflow-y: auto; */
}

.keyword_search_top {
    background-image: linear-gradient(to right, #e82b8717 16%, #3e009e26 88%);
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 13px 10px 12px 17px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}

.right-inner-addon {
    position: relative;
}

.right-inner-addon input {
    padding-right: 0 !important;
    width: 100%;
    border-radius: 90px;
    box-shadow: inset 0 0 2px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    height: 27px;
    border: unset;
    padding-left: 11px;
}


.right-inner-addon i {
    position: absolute;
    right: 0px;
    padding: 6px 6px;
    pointer-events: none;
    color: #858fa3;
}

.keyword_search {
    flex-basis: 60%;
}

li.task_item_li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #e3f2ff;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
}

.ellipse_shape {
    width: 14px;
    height: 14px;
    border-radius: 40px;
    border: solid 1.5px #318fff;
    background-color: rgba(100, 166, 244, 0.4);
}

li.task_item_li .task_Name {
    padding-left: 13px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

li.task_item_li .task_total {
    margin-left: auto;
    order: 2;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #0b1f47;
}

div#task_item_area {
    height: 225px;
    overflow: auto;
}

.insights_work_detail {
    width: 60%;
    background-image: url('../../media/images/workLoad_bg.svg');
    height: 310px;
    padding: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 20px;
    box-shadow: inset 0 0 0 2000px #0d215003;

}

.insights_work_middle {
    display: flex;
    gap: 22px;
    padding-top: 23px;
}

.insights_work_middle .WorkloadItem {
    width: 50%;
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
}

.manageWorkload_hours span {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.manageWorkload_Title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}

.manageWorkload_Manage {
    padding-top: 25px;
    display: flex;
}

.manageWorkload_forward {
    background-image: url('../../media/images/workload_forward.svg');
    background-size: 16px;
    height: 10px;
    background-repeat: no-repeat;
    margin-left: auto;
    width: 20px;
    cursor: pointer;
    position: relative;
    top: 4px;
}

.tribecaBranding_tile {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.tribecaBranding_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tribecaBranding_para {
    width: 145px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}

.tribecaBranding_bottom {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.tribecaBranding_hours {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #0b1f47;
}

.your_capacity.inTaskInsight {
    height: 44px;
    margin: unset !important;
    padding: unset !important;
    border-radius: 5px;
    background-color: unset !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.insights_work_bottom {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
    border-radius: 23px;
    background-color: #fff;
    padding: 0 28px;
    gap: 32px;
}

.totalTask_graph {
    flex-basis: 88%;
}

/*Task progress Bar*/
.task_progress {
    margin: 20px auto;
    padding: 0;
    width: 90%;
    height: 29px;
    overflow: hidden;
    background: #eff3fb;
    border-radius: 29px;
}

.progressbar {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: #318fff;
    border-radius: 29px;
}

.progressbar .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-family: tahoma, arial, helvetica;
    font-size: 12px;
    color: white;
}

.totalTask_number {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    flex-basis: 105px;
}

.task_search_area {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 36px;
}

.insights_full_area {
    height: calc(100vh - 120px);
    overflow-y: auto;
}

/* .tasksbyRoom_top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-image: linear-gradient(95deg, rgb(255 175 76 / 12%) 26%, rgb(62 0 158 / 9%) 82%);
    padding: 13px 13px 12px 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
} */
.tasksbyRoom_top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-image: linear-gradient(95deg, rgb(255 175 76 / 12%) 26%, rgb(62 0 158 / 9%) 82%);
    padding: 0px 13px 0px 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    height: 52px;
}

.tasksbyRoom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    height: 100%;
    width: 43%;
    border-right: 1px solid #fff;
}

.tasksbyRoomTitle {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
}

.tasksbyRoomDate {
    padding: 2px 11px 2px;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 72%);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.tasksbySearch {
    width: 45%;
}

ul.tasksbyRoom_heading {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: solid 0.3px rgb(160 210 253 / 40%);
    padding-left: 20px;
    padding-right: 20px;
}

.tasksbyRoom_datas {
    height: 220px;
    overflow-y: auto;
}

ul.task_room_list {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-bottom: solid 0.3px rgb(160 210 253 / 40%);
}

li.tasksbyRoom_name {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3;
}

li.tasksbyRoom_priority {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3;
    display: flex;
    justify-content: flex-start;
}

li.tasksbyRoom_total {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3;
    display: flex;
    justify-content: flex-start;
}

li.task_room_Name {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

li.task_prioritizedTasks {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    display: flex;
    justify-content: flex-start;
}

li.task_totalTasks {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    display: flex;
    justify-content: flex-start;
}

.calendar_container .calendar_top {
    padding: 14px 115px 14px 18px;
    background-image: linear-gradient(124deg, #318fff3b 12%, #a6d06842 98%);
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}

.calendar_container .calendar_top_head {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;

}

.calendar_container .react-datepicker {
    border: unset;
    width: 100%;
    padding: 14px 18px 0px 18px;
}

.calendar_container .datepicker_calendar_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar_container .react-datepicker__month-container {
    float: left;
    width: 100%;
}

.calendar_container .react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
}

.calendar_container .react-datepicker__day-name {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7890a5;
}

.calendar_container .react-datepicker__day {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
    padding-bottom: 7px;
    padding-top: 7px;
}

.calendar_container .react-datepicker__day--selected {
    color: #fff;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    padding: 4px 0px;
    background-color: #0b1f47;
}

.calendar_container .react-datepicker__header {
    background-color: transparent;
}

/* .calendar_container .react-datepicker__header select {
    border: none !important;
    border-radius: 49.5px;
    background-color: rgb(226 229 232 / 40%);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding: 4px 12px;
} */
.calendar_container .react-datepicker__header select {
    border: none !important;
    border-radius: 49.5px !important;
    background-color: rgb(226 229 232 / 40%);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding: 4px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../media/images/dropdown_arrow.svg');
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    appearance: none;
    outline-width: 0;
    background-repeat: no-repeat;
    background-position: 93% 50%;
}

.calendar_container select.monthView {
    width: 128px;
}

.calendar_container select.yearView {
    width: 85px;
}

.calendar_container .react-datepicker__day--keyboard-selected {
    color: #ffffff !important;
    border-radius: 50px;
    padding-top: 4px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    width: 30px;
    height: 30px;
}

/* .taskLoad_lists {
    width: 100%;
    padding: 36px 40px 0 30px;
} */
.taskLoad_lists {
    width: 100%;
    padding: 36px 0px 22px 0px;
    background-color: #eff3fb;
    height: calc(100vh - 115px);
    overflow-y: auto;
}

.task_Workloadslists_area {
    display: flex;
    flex-wrap: wrap;
    gap: 33px;
}

.taskWorkloadCard {
    width: 48%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: inset 0 4px 14px 0 rgb(0 0 0 / 2%);
    background-image: linear-gradient(115deg, rgb(0 112 245 / 2%) 3%, rgb(139 87 219 / 4%) 97%);
    padding: 17px 22px 17px 26px;
}

.taskWorkloadCard .progress {
    padding-left: 13px;
    padding-right: 17px;
    background-color: #fff;
    padding-top: 15px;
    padding-bottom: 14px;
    border-radius: 50px;
}

.taskWorkloadCard .week_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}

.week_head .monthDate {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b1f47;
}

.week_head .work_hours {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b1f47;
}

.workload_status_count span {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    text-align: left;

    color: #0b1f47;
}

.workload_status {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.workload_status_item {
    width: 28%;
    border-bottom: 1px solid #d9e2e8;
    flex: 0 0 28%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.workload_status_item:nth-child(4) {
    border-bottom: unset;
}

.workload_status_item:nth-child(5) {
    border-bottom: unset;
}

.workload_status_item:nth-child(6) {
    border-bottom: unset;
}

.workload_status_circle {
    width: 10px;
    height: 10px;
    border-radius: 50px;
}

.monthNames_cal_icon {
    background-image: url('../../media/images/calendar_icon.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px;
}

.monthNames_text {
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.64;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.task_Workloadslists_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 34px;
}

.monthNames {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    width: 260px;
    height: 50px;
    border-radius: 10px;
    border: solid 1px rgb(160 210 253 / 30%);
    padding: 11.7px 9px 12px 21px;
}

.monthNames_up_icon {
    background-image: url('../../media/images/up_Arrow.svg');
    width: 11px;
    height: 6px;
    background-repeat: no-repeat;
    background-size: 11px;
    cursor: pointer;
}

.monthNames_down_icon {
    background-image: url('../../media/images/down_Arrow.svg');
    width: 11px;
    height: 6px;
    background-repeat: no-repeat;
    background-size: 11px;
    cursor: pointer;
}

.monthNames_change_icons {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    margin-left: 20px;
}

.Workload_setting_icon {
    background-image: url(../../media/images/gearTask.svg);
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: 15px;
}

.Workload_setting {
    display: flex;
    align-items: center;
    gap: 11px;
    width: 125px;
    height: 50px;
    padding: 16px 21px 16px 20px;
    border-radius: 60px;
    border: solid 1px rgb(160 210 253 / 30%);
    cursor: pointer;
}

.Workload_setting_area {
    display: flex;
    align-items: center;
    gap: 12px;
}

.AssigneeField.workLoadAssin {
    border-radius: 60px;
    border: solid 1px rgb(160 210 253 / 30%);
    width: 232px;
}

.AssigneeField.workLoadAssin .ellipsis span {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.serachbyRoomOrName {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
    padding-bottom: 5px;
}

span.keyword_Color.workloadUsersName {
    border-radius: 4px;
    background-color: #732be2;
}

.taskWorkLoad_back {
    background-image: url(../../media/images/tagBack.svg);
    background-size: 25px;
    width: 25px;
    height: 42px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

/* .Workload_month_area {
    display: flex;
    align-items: center;
    gap: 15px;
} */
.Workload_month_area {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    padding-left: 30px;
    border-bottom: 1px solid rgb(160 210 253 / 30%);
    padding-bottom: 27px;
}

._tag_rooms.workLoadRooms {
    height: 39px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0px !important;
    border-bottom: 1px solid #e3f2ff;
    background-color: #ffffff !important;
}

._tag_rooms.workLoadRooms .PlusIcon {
    top: 0 !important;
    font-weight: 400 !important;
}

._tag_rooms.workLoadRooms .inviteEmail {
    top: 0 !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

._tag_rooms.workLoadRooms:last-child {
    border-bottom: unset !important;
}

.keyword_list_task_settings.workloadUsers .tagNotFound {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
}

.task_circle {
    width: 9px;
    height: 9px;
    background-color: #732be25c;
    border-radius: 50%;
}

.task_circle.active-color {
    background-color: #732be2;
}

i.fa.fa-plus.custom_icon.false {
    padding: 5px 9px 6px;
    border-radius: 16px;
    background-color: #e3f2ff;
    color: #318fff;
}

i.fa.fa-plus.custom_icon.open {
    padding: 5px 9px 6px;
    border-radius: 16px;
    background-color: #318fff;
    color: #fff;
    transform: rotate(0deg);
}

.custom_dropdown_more.moreColums {
    width: 155px;
}

.custom_dropdown_more.moreColums div {
    padding: 10px 14px;
    justify-content: space-between;
    align-items: center;
}

.custom_dropdown_more.moreColums div label {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f49;
}

span.removeIcon::before {

    width: 24px;
    height: 24px;
    content: "\f068";
    font-family: "FontAwesome";
    font-weight: 600;
    color: #318FFF;
    font-size: 15px;
    /* padding: 3px 10px 0px 0px; */
}

span.addIcons::before {
    width: 24px;
    height: 24px;
    content: "\f067";
    font-family: "FontAwesome";
    font-weight: 600;
    color: #318FFF;
    font-size: 15px;
    /* padding: 3px 10px 0px 0px; */
}

.taskBodyArea {
    height: calc(100vh - 0px);
}

.left_container.taskLeftBar {
    width: 300px;
}

.main_container.taskManager .right_container {
    width: calc(100% - 300px);
}

.left_container.taskLeftBar span.adminCloseIcon {
    top: -28px;
    right: 15px;
}

.breadcrumb_area {
    width: 100%;
}

.task_basicSearch_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding-top: 23px; */
    /* padding-bottom: 23px; */
    padding-left: 40px;
    padding-right: 30px;
    width: 100%;
    border: solid 1px #e3f2fe;
}

.task_search_go {
    width: 57.1px;
    height: 33.1px;
    flex-grow: 0;
    padding: 6px 18.1px 6.1px 19px;
    border-radius: 16px;
    background-color: #318fff;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    cursor: pointer;
}

.task_input_area {
    width: 86%;
}

.task_advance_button {
    width: 15%;
}

.task_advance_button {
    width: 149px;
    height: 33px;
    flex-grow: 0;
    margin: 21px 12px 23px;
    padding: 6px 13.7px 6px 16px;
    border-radius: 30px;
    border: solid 0.5px #318fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
}

input.basicSearch {
    width: 100%;
    height: 33px;
    padding: 8px 13px 9px 16px;
    border-radius: 90px;
    background-color: #f1f6fa;
    border: 1px solid #d6d6d6;
}

.task_advance_text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff;
}

.task_filterIcon {
    background-image: url('../../media/images/taskAdvaceShape.svg');
    background-size: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
}

.task_downArrowIcon {
    background-image: url('../../media/images/down_Arrow_Blue.svg');
    background-size: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    top: 4px;
    right: -5px;
}

input#_inputBar_tagdetails::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    text-align: left;
    color: #318fff !important;
}

.first_row {
    padding-left: 0;
    padding-right: 0px;
    padding-top: 20px;
    position: relative;
}

.filter_for_a_tag::placeholder {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3 !important;
}

.first_row .col1 {
    width: 22%;
}

.refresh_button {
    position: absolute;
    right: 30px;
    width: 82px;
    height: 21px;
    flex-grow: 0;
    padding: 4px 9.9px 3px 10px;
    border-radius: 16px;
    background-color: #e3f2ff;
    align-items: center;
    display: flex;
    top: 5px;
    gap: 7px;
    cursor: pointer;
}

span.refreshButtonText {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #318fff;
}

span.refresh_button_icon i.fa.fa-undo {
    font-size: 12px;
    transform: rotate(0deg);
    color: #318fff;
}

.first_row .clearAllSrc::before {
    content: "\f057";
    font-family: var(--FA);
    font-weight: 600;
    color: var(--NewRed);
    font-size: 20px;
}

.first_row .clearAllSrc {
    cursor: pointer;
}

span.clearAllSrcTag::before {
    content: "\f057";
    font-family: var(--FA);
    font-weight: 600;
    color: var(--NewRed);
    font-size: 20px;
}

span.clearAllSrcTag {
    cursor: pointer;
}

.secound_row .tags_Color {
    font-weight: 400;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 0 #318fff;
    background-color: #318fff;
    color: #fff;
}

.third_row .tags_Color {
    font-weight: 400;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 0 #318fff;
    background-color: #318fff;
    color: #fff;
}

.AssigneeField.insight {
    padding: 0;
    height: 50px;
    display: flex;
    justify-content: space-around;
    border-radius: 5px;
    margin-top: -5px;
    width: 100%;
    align-items: center;
    border: unset;
}

ul.tasks_ul {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 6px 17px 5px 8px;
    border-radius: 40px;
    background-color: rgb(255 255 255 / 50%);
}

li.status_heading {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    cursor: pointer;
}

li.status_heading.active {
    padding: 4px 10px;
    border-radius: 50px;
    background-color: #8b57db;
    color: #ffffff;
}

ul.task_item_list {
    height: 100%;
}

.keyword_title {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.keyword_search .right-inner-addon input {
    padding-right: 0 !important;
    width: 100%;
    box-shadow: inset 0 0 2px 0 rgb(0 0 0 / 5%);
    height: 27px;
    border: unset;
    padding-left: 11px;
    border-radius: 90px;
    background-color: rgba(227, 242, 255, 0.5);
    margin-top: 15px;
}

.keyword_search .right-inner-addon i {
    position: absolute;
    right: 0px;
    padding: 6px 6px;
    pointer-events: none;
    color: #858fa3;
    top: 15px;
}

.keyword_search {
    padding-left: 15px;
}

li.room_heading {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding: 4px 8px 4px 9px;
    cursor: pointer;
}

ul.tasks_roms_ul {
    display: flex;
    align-items: center;
    grid-gap: 9px;
    gap: 9px;
    padding: 6px 14px 5px 8px;
    border-radius: 40px;
    background-color: rgb(91 100 119 / 10%);
}

li.room_heading.active {
    padding: 4px 8px 4px 9px;
    border-radius: 50px;
    background-color: #fff;
}

.view_all_tasks {
    height: 100%;
    display: flex;
    align-items: center;
    width: 160px;
    justify-content: flex-end;
    border-left: 1px solid #fff;
}

.tasksbySearch .right-inner-addon.input-container {
    padding: 0 14px;
}

.tasksbySearch .right-inner-addon i {
    position: absolute;
    right: 16px;
    padding: 6px 6px;
    pointer-events: none;
    color: #858fa3;
}

.insights_work_charts {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 46px;
}

.insights_work_right_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 10px;
}

.insights_work_charts .insights_work_item {
    width: 50%;
}

.insights_work_right_bottom {
    padding-top: 20px;
}

.insights_work_title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding-bottom: 10px;
}

.insightupdate_text {
    padding: 12px 16px 11px 14px;
    background-color: rgba(227, 70, 70, 0.19);
    border-radius: 10px;
    width: auto;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.insightupdatePara {
    font-size: 11px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #ba0922;
}

.insights_work_Left.insights_work_item {
    width: 60%;
}

.task_update_item {
    width: 31%;
    height: 142px;
    padding: 20px 15px 13px 14px;
    border-radius: 10px;
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tsks_hours_detail div {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.task_update_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 8px 15px 8px 14px;
    border-radius: 50px;
    border: solid 1px #df1e39;
    background-color: rgba(223, 30, 57, 0.1);
    width: 100%;
    height: 30px;
}

span.task_update_head {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ba0922;
}

svg.updateRightArrow {
    padding-top: 3px;
}

.piechartForInsight {
    width: 68%;
    position: relative;
}

li.tasksbyRoom_total {
    margin-left: auto;
}

li.task_totalTasks {
    margin-left: auto;
    padding-left: 10px;
}

li.task_prioritizedTasks {
    padding-left: 10px;
}

.workloadReaportBtn {
    width: auto;
    height: 49px;
    flex-grow: 0;
    padding: 12px 8px 11px 8px;
    border-radius: 49.5px;
    border: solid 1px #ffc328;
    background-color: #ffc328;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
    cursor: pointer;
}

.Workload_month_right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.Workload_warning_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 40px 30px 30px;
    width: 100%;
    gap: 24px;
    border-bottom: 1px solid rgb(160 210 253 / 30%);
}

.update_the_following_tasks {
    width: 243px;
    height: 84px;
    flex-grow: 0;
    background-color: rgba(227, 70, 70, 0.19);
    padding: 22px 17px 22px 20px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #ba0922;

}

.task_update_item_status {
    display: flex;
    align-items: center;
    width: 243px;
    justify-content: space-between;
    height: 84px;
    flex-grow: 0;
    padding: 22px 17px 22px 20px;
    border-radius: 20px;
    background-color: #fff;
}

.tsks_hours_status div {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

/* .workload_card.taskLoadCard {
    border-radius: 20px;
    box-shadow: inset 0 4px 44px 0 rgb(0 0 0 / 10%);
    background-image: linear-gradient(110deg, rgb(0 112 245 / 10%) 3%, rgb(139 87 219 / 18%) 50%);
    margin-top: 26px;
    padding-top: 31px;
    padding-bottom: 35px;
} */
.workload_card.taskLoadCard {
    border-radius: unset;
    box-shadow: unset;
    background-image: unset;
    margin-top: unset;
    padding-top: unset;
    padding-bottom: unset;
    background-color: transparent;
    border-bottom: 2px solid #eff3fb;
}

.workload_card_full_area.taskLoadCard {
    border-radius: 20px;
    box-shadow: inset 0 4px 44px 0 rgb(0 0 0 / 10%);
    background-image: linear-gradient(110deg, rgb(0 112 245 / 10%) 3%, rgb(139 87 219 / 18%) 50%);
    margin-top: 26px;
    padding-top: 31px;
    padding-bottom: 35px;
}

.workload_card_full_area.taskLoadCard.overcapacityTake {
    box-shadow: inset 0 4px 44px 0 rgb(0 0 0 / 10%);
    background-image: linear-gradient(110deg, rgb(255 0 36 / 10%) 35%, rgb(255 140 0 / 12%) 61%);
}

.full_workload_items_area {
    padding: 0 30px 0 30px;
}

.workload_date.taskLoad {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
    padding: 2px 12px 0;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 70%);
    height: 23px;
    width: 85px;
}

.your_capacity.workLaoad {
    border-radius: 9px;
    background-color: rgba(255, 255, 255, 0.55);
    padding: 17px 0px 18px 12px;
}

.workLimit.limit_ar {
    border-radius: 60px;
}

.workLimit.limitBar {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
}

.workLimit.workloadLimit {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
}

.your_capacity_area.taskLad {
    margin: 0;
    padding-right: 5px;
}

.workload_bottom_area {
    padding: 29px 20px 0px 27px;
}

.taskStatusThisWeek {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
    width: auto;
    height: 29px;
    flex-grow: 0;
    padding: 4px 18px 3px 18px;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 70%);
}

.status_top_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.taskStatusTotalTask {
    width: auto;
    height: 29px;
    flex-grow: 0;
    padding: 4px 17px 3px 17px;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 70%);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
}

.status_cards {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.status_cards_area {
    padding-top: 24px;
}

.card_area_cl {
    height: 111px;
    padding: 10px 13px 9px 14px;
    border-radius: 20px;
    background-color: #fff;
    margin-right: 18px;
    width: 25%;
    display: flex;
    flex-flow: column nowrap;
}

.status_header_text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.status_counter {
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.status_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
}

.status_bottom_text {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.status_right_cards {
    height: 111px;
    padding: 10px 13px 9px 14px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
}

.status_right_head {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    width: 229px;
}

.status_right_para {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
    padding-top: 3px;
}

.status_right_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    order: 2;
    margin-top: auto;
    cursor: pointer;
}

.status_right_bottom_text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.status_header_circle {
    width: 10px;
    height: 10px;
    border-radius: 40px;
}

.status_header {
    display: flex;
    align-items: center;
    margin-bottom: auto;
    justify-content: space-between;
}

.reviews_icon {
    background-image: url('../../media/images/msgBabul.png');
    width: 33px;
    height: 35px;
    background-size: 35px;
    background-repeat: no-repeat;
    position: relative;
    top: -6px;
}

.reviewTaskIcon {
    background-image: url('../../media/images/msgVo.png');
    width: 29px;
    height: 29px;
    background-size: 28px;
    background-repeat: no-repeat;
    position: relative;
    top: 2px;
}

span.heartIcon {
    position: absolute;
    top: 2px;
    right: 4px;
    transform: rotate(45deg);
    background: #a6d9f1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

span.chatIconIcon {
    position: absolute;
    bottom: 22px;
    right: 6px;
    transform: rotate(-45deg);
    background: #a6d9f1;
    width: 35px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.piechartForInsight.item .recharts-wrapper svg {
    width: 100%;
    height: 150px;
}

.newCalenderIcon {
    position: absolute;
    margin: 10px 0px 0px -30px;
    cursor: pointer;
    background-image: url(../../media/images/task_img/calendar.webp);
    background-size: 16px;
    width: 16px;
    height: 16px;
}

.Track_col.formdate:hover span.newCalenderIcon {
    background-image: unset;
}

.Track_col.todate:hover span.newCalenderIcon {
    background-image: unset;
}

.slideLeft.kanbanRight {
    left: 5px;
    bottom: 40%;
}

.slideLeft {
    position: absolute !important;
    box-sizing: border-box;
    height: 65px;
    width: 65px;
    border: 1.5px solid #0c1e47;
    opacity: 0.8;
    background-color: #ffffff;
    border-radius: 50%;
    left: 350px;
    bottom: 47%;
    cursor: pointer;
    z-index: 11;
    background-image: url(../../media/images/sliderLeft_blue.svg);
    background-repeat: no-repeat;
    background-size: 73%;
    -webkit-user-select: none;
    user-select: none;
    /* top:220px; */
    /* background-position: 7px; */
    background-position: 50% 50%;
}

.slideLeft:hover {
    background-color: #0C1E47;
    ;
    border: 1.5px solid #0C1E47;
    background-image: url(../../media/images/sliderLeft_blue.svg);
    box-shadow: 0px 0px 12px #0C1E47;
}

.TaskListSlideRight {
    position: absolute !important;
    box-sizing: border-box;
    height: 65px;
    width: 65px;
    border: 1.5px solid #0c1e47;
    opacity: 0.8;
    background-color: #ffffff;
    border-radius: 50%;
    right: 30px;
    bottom: 30%;
    cursor: pointer;
    z-index: 11;
    background-image: url(../../media/images/sliderRight_blue.svg);
    background-repeat: no-repeat;
    background-size: 73%;
    background-position: 70% 50%;
    -webkit-user-select: none;
    user-select: none;
    display: none;
}

.taskMainListTableCon:hover .TaskListSlideRight {
    display: block;
    opacity: 1;
    transition: opacity 0.3s ease, visibility 0s linear 3s;
}

.TaskListSlideLeft {
    position: absolute !important;
    box-sizing: border-box;
    height: 65px;
    width: 65px;
    border: 1.5px solid #0c1e47;
    opacity: 0.8;
    background-color: #ffffff;
    border-radius: 50%;
    left: 10px;
    bottom: 30%;
    cursor: pointer;
    z-index: 11;
    background-image: url(../../media/images/sliderLeft_blue.svg);
    background-repeat: no-repeat;
    background-size: 73%;
    background-position: 40% 50%;
    -webkit-user-select: none;
    user-select: none;
    display: none;
}

.taskMainListTableCon:hover .TaskListSlideLeft {
    display: block;
    opacity: 1;
    transition: opacity 0.3s ease, visibility 0s linear 3s;
}

.slideRight {
    position: absolute !important;
    box-sizing: border-box;
    height: 65px;
    width: 65px;
    border: 1.5px solid #0c1e47;
    opacity: 0.8;
    background-color: #ffffff;
    border-radius: 50%;
    right: 40px;
    bottom: 47%;
    cursor: pointer;
    z-index: 11;
    background-image: url(../../media/images/sliderRight_blue.svg);
    background-repeat: no-repeat;
    background-size: 73%;
    background-position: 70% 50%;
    -webkit-user-select: none;
    user-select: none;
}

.slideLeft.kanbanLeft {
    left: 5px;
    bottom: 40%;
    display: none;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 3s;
}

.slideRight.kanbanRight {
    right: 5px;
    bottom: 40%;
    display: none;
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0s linear 3s;
}

.kanban_container:hover .slideRight.kanbanRight {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0s linear 3s;
}

.kanban_container:hover .slideLeft.kanbanLeft {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s ease, visibility 0s linear 3s;

}

.back-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background-color: #0c1e47;
    color: #ffffff;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.addOwner .newAddText {
    padding: 0 3px 0 6px;
}

.addOwner.topProperties {
    position: absolute;
    right: 45px;
    top: 0;
}

span.chatforFocus svg {
    position: fixed;
    right: 17px;
    top: 10%;
    z-index: 20;
    cursor: pointer;
}

.addOwner {
    margin: -2px 0 0 3px;
}

.addselectedOwner {
    width: auto;
    border-radius: 4px;
    background-color: #f1f6fa;
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
    padding: 2px 5px 2px 2px;
}


span.createConv_back.forkanban {
    background-image: url('../../media/images/back_new.svg');
    background-position: 10%;
    background-repeat: no-repeat;
    background-size: 22px;
    top: 0;
    left: 0;
    cursor: pointer;
    position: relative;
    width: 24px;
    height: 23px;
}

.kanban_main {
    width: 100%;
    height: calc(100vh - 140px);
    overflow: auto;
    position: relative;
}

.kanban_section {
    width: 100%;
    padding: 20px 20px 10px;
    height: calc(100vh - 117px);
    box-sizing: border-box;
    display: flow-root;
}

.kanban_container {
    width: 100%;
    height: 100%;
    overflow: auto;
    position: relative;
}

.kanban-tasks {
    padding: 10px;
    min-height: calc(100% - 58px);
    max-height: calc(100% - 58px);
    overflow-y: auto;
    margin-top: 10px;
}

.kanban-task {
    background: white;
    margin-bottom: 10px;
    cursor: grab;
}

.kanban-tasks.empty {
    min-height: 50px;
}


.kanbanTaskColCon {
    border-radius: 5px;
    /* border: 1px solid rgba(0, 0, 0, 0.05); */
}

.taskCanbanTitle {
    position: relative;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.taskPiece.task.task_k_title_area .fill_flagIcom_active {
    background-size: 12px;
    position: absolute;
    right: 0;
    top: 0;
}

.fill_flagIcom_active {
    background-image: url('../../media/images/fill_flagIcon_active.png');
    background-size: 18px;
    background-repeat: no-repeat;
    padding-left: 30px;
    background-position: 2px 0;
    cursor: pointer;
    position: relative;
    top: 3px;
    width: 17.1px;
    height: 20px;
}
.TaskListSlideRight:hover {
    background-color: #0c1e47;
    border: 1.5px solid #0c1e47;
    box-shadow: 0 0 12px #0c1e47;
    color: #ffffff;
    background-image: url(../../media/images/sliderRight_white.svg);
}
.TaskListSlideLeft:hover {
    background-color: #0c1e47;
    border: 1.5px solid #0c1e47;
    box-shadow: 0 0 12px #0c1e47;
    color: #ffffff;
    background-image: url(../../media/images/sliderLeft_white.svg);
}
.buttonsDesign:hover{
    background-color: #0c1e47;
}
.Create_Quick_Task span.backToChat.closeBtn:hover {
    background-color: #318fff;
    border: 2px solid #e91e63;

}

