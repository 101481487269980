/* KanbanBoard.css */
.kanban-board {
    display: flex;
    overflow-x: auto;
    padding: 20px;
}

.kanban-column {
    background: #f0f0f0;
    border: 1px solid #ddd;
    border-radius: 8px;
    width: 300px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
    /* For sticky header to work properly */
}

.kanban-column-header {
    background: white;
    position: sticky;
    top: 0;
    z-index: 1;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.kanban-column-header button {
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
}

/* .kanban-tasks {
    padding: 10px;
    min-height: 200px;
    max-height: 500px;
    overflow-y: auto;
    margin-top: 10px;
}

.kanban-task {
    background: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: grab;
}

.kanban-tasks.empty {
    min-height: 50px;
} */

.kanban-column.collapsed .kanban-tasks {
    display: none;
}

.kanban-column.collapsed {
    min-width: 100px;
    /* Minimize width when collapsed */
}