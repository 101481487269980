@import url("https://use.fontawesome.com/releases/v5.2.0/css/all.css");

.taskTopHead {
    padding-left: 20px;
    padding-right: 20px;
    height: 60px;
    border: solid 1px #e3f2fe;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#root.dark .taskTopHead {
    background-color: #ffffff0a;
    border-top: unset;
    border-right: unset;
    border-left: unset;
}

.csvExport {
    display: flex;
    justify-content: left;
    margin-left: 20px;
    /* margin-bottom: 5px; */
}

.csvFile {
    display: none;
}

.csvExport span {
    background: var(--PrimaryC);
    width: 120px;
    height: 33px;
    padding: 5px 6px 6px 5px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.custom_breadcrumb {
    padding-left: 10px;
    list-style: none;
}

.custom_breadcrumb .breadcrumb-item {
    display: inline;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #002e98;

}

.task_right_container {
    height: 100%;
}

.custom_breadcrumb li+li:before {
    content: "\f105";
    font-family: "FontAwesome";
    color: #53627c;
    font-size: 14px;
    margin-top: 0px;
    margin-right: 4px;
    font-weight: 600;
    margin-left: 2px;
}

.singleKeyword svg.removeKeyword {
    color: #0b1f47;
}

.custom_breadcrumb li:last-child {
    color: #318fff;
}

.search_top_area {
    flex-basis: 100%;
}

.fa_search i.fas.fa-search {
    font-family: 'FontAwesome';
    font-weight: 600;
    color: #318fff;
    font-size: 20px;
    cursor: pointer;
}

input#search_input_box {
    width: 100%;
    box-sizing: border-box;
    height: 33px;
    text-indent: 0px;
    background-repeat: no-repeat;
    background-position: 99%;
    background-size: 15px;
    margin-left: 0;
    margin-right: 0;
    font-size: 14px !important;
    line-height: 35px;
    padding: 6px 14px 7px 10px;
    border-radius: 16.3px;
    border: solid 1px #d9e2e8;
    background-color: #fff;
}

.topSerachIcon {
    float: right;
    margin: 0;
    width: 20px;
    height: 22px;
    background-size: 20px;
}

.search_top_container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    padding: 0px;
    gap: 15px;
}

.search_top_head {
    width: 100%;
}

.closeConnectSearch.closeSearch {
    position: relative;
    width: 20px;
    height: 20px;
    background-image: url('../../media/images/close_button.svg');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 20px;
    top: 0;
    right: 0;
    cursor: pointer;
    margin: 0;
}

.taskHeadSection.topHead {
    display: flex;
    height: 57px;
    border-bottom: 1px solid #d9e2e8;
    padding-top: 0px;
    padding-left: 0;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

ul.task_menu_Lists {
    display: flex;
    align-items: center;
    height: 100%;
    gap: 35px;
}

ul.task_menu_Lists li.activeTaskMenu {
    color: #2196f3;
    position: relative;
}

ul.task_menu_Lists li:hover {
    color: #2196f3;
    position: relative;
}

ul.task_menu_Lists li {
    font-size: 14px;
    color: #0b1f47;
    cursor: pointer;
    box-sizing: border-box;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}

#root.dark ul.task_menu_Lists li {
    color: #fff;
}

#root.dark ul.task_menu_Lists li:hover {
    color: #2196f3;
    position: relative;
}

ul.task_menu_Lists li.activeTaskMenu:after {
    content: "";
    position: absolute;
    width: 103%;
    height: 8px;
    left: 0;
    bottom: 35px;
    border-radius: 4px;
    background-color: #318fff;
}

.createTaskButton {
    width: 144px;
    height: 33px;
    /* padding: 4px 6px 6px 5px; */
    border-radius: 50px;
    background-color: #0c1e47;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.createTaskButton span.taskPlusIcon {
    position: relative;
    top: 4px;
    left: 5px;
}

.task_column.button_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.runReportButton {
    width: 108px;
    height: 33px;
    flex-grow: 0;
    padding: 6px 5px 5px 5px;
    border-radius: 50px;
    background-color: #ffaf4c;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.insights_first_area {
    height: 120px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    padding-right: 24px;
    justify-content: space-between;
}

/* .taskDashboardHeading {
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;

} */

#root.dark .taskDashboardHeading {
    color: #ffffff;
}

#root.dark .calendar_container .calendar_top_head {
    color: #ffffff
}

.numberCircle {
    display: inline-block;
    line-height: 0px;
    border-radius: 50%;
    background-color: #732be2;
    font-size: 12px;
    margin-left: 5px;
}

.numberCircle span {
    display: inline-block;
    padding-top: 50%;
    padding-bottom: 50%;
    margin-left: 8px;
    margin-right: 8px;
    color: #ffffff;
}

.avatar-number {
    width: 40px;
    height: 40px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background: #a6d068;
    color: #ffffff;
    font-weight: 600;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.taskTeam_info {
    display: flex;
    align-items: center;
    gap: 7px;
    padding: 8px 23px 8px 8px;
    border-radius: 60px;
    background-color: #dfe5f2;
}

.taskTeamDetails.forTaskproperties {
    border-radius: 8px !important;
    height: 30px;
    padding-top: 0;
    padding-left: 0;
    cursor: pointer;
    width: 100% !important;
    border: unset !important;
}

.AssigneeField.roomTaskproperties {
    height: 30px;
    padding-top: 0;
    margin-top: 0;
    padding-left: 0;
    border: 1px solid #d9e2e8;
    padding-top: 8px;
}

.AddKeywordsPopup_task_settings.insightTaskBoard .selectedRoomCont span {
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 4px;
    background-color: #732be2;
    color: #fff;
    padding: 1px 3px;
    font-size: 12px;
    height: 23px;
    display: flex;
    align-items: center;
}

.AssigneeField {
    padding: 7px;
    height: 50px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #c1ddf4;
    border-radius: 5px;
    margin-top: 8px;
    width: 70%;
    align-items: center;
}

.teamTaskMemberName {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.teamTask_TeamName {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}

.task_dashboard {
    display: flex;
    align-items: flex-start;
    gap: 35px;
    padding-left: 40px;
    padding-right: 24px;
}

.calendar_container {
    flex-basis: 40%;
}

.taskStatus_title {
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.08;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.taskStatus_container {
    /* background-image: url('../../media/images/task_group.svg'); */
    min-height: 370px;
    flex-basis: 60%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    background-color: rgb(194 222 244 / 15%);
    border-radius: 25px;
    padding: 26px 26px 26px 26px;
    box-shadow: inset 0 4px 44px 0 rgb(0 0 0 / 8%);
}

.taskTeamInsight_notify {
    height: 50px;
    flex-grow: 0;
    padding: 15px 20px 15px 19px;
    background-color: #732be2;
    display: flex;
    color: #ffffff;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-left: auto;
    margin-right: 23px;
}

.insight_notify_text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
}

.insight_notify_close {
    cursor: pointer;
}

.taskStatus_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.totalTasks {
    height: 28px;
    flex-grow: 0;
    padding: 5px 12px 1px 12px;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 70%);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
}

#root.dark .totalTasks {
    background-color: rgb(255 255 255 / 90%);
}

.tesk-status-container {
    /* background: #aaa; */
    display: flex;
    margin-top: 8px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 17px 22px;
    padding-top: 20px;
}

.tesk-status-container>.tesk-status-item {
    flex: 1 1 24%;
    /*grow | shrink | basis */
    height: 111px;
}

.tesk-status-item {
    border-radius: 20px;
    box-shadow: 0 0 34px 0 rgb(0 0 0 / 10%);
    background-color: #fff;
    /* padding: 11px 18px 5px 15px; */
}

.task_status_result {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.task_status_circle {
    width: 10px;
    height: 10px;
    border-radius: 40px;
}

.tesk-status-item .firstPara {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding: 11px 18px 0px 15px;
}

.secoundPara .task_counter {
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.secoundPara {
    border-bottom: 1px solid #e3f2ff;
    padding: 5px 18px 4px 15px;
}

.lastPara {
    padding: 2px 18px 0px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.viewTasksIcon {
    background-image: url('../../media/images/forward_icon.svg');
    background-size: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    top: 4px;
}

.insights_work_load_area {
    display: flex;
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 32px;
    width: 100%;
    gap: 37px;
}

.keyword_search_area {
    width: 39%;
    height: 310px;
    /* overflow-y: auto; */
}

.keyword_search_top {
    background-image: linear-gradient(to right, #e82b870a 16%, #3e009e17 88%);
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 18px 10px 18px 17px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}

#root.dark .keyword_search_top {
    background-color: rgb(194 222 244 / 15%);
    background-image: unset;
}

.right-inner-addon {
    position: relative;
    padding-right: 20px;
    /* padding-bottom:10px; */
}

.right-inner-addon input {
    padding-right: 20px !important;
    width: 100%;
    border-radius: 90px;
    box-shadow: inset 0 0 2px 0 rgb(0 0 0 / 5%);
    background-color: #fff;
    height: 27px;
    border: unset;
    padding-left: 11px;
}


/* .right-inner-addon i {
    position: absolute;
    right: 0px;
    padding: 6px 6px;
    pointer-events: none;
    color: #858fa3;
} */
svg.keyword_search_icon {
    position: absolute;
    right: 26px;
    pointer-events: none;
    color: #318fff;
    top: 17px;
}

.keyword_search {
    flex-basis: 60%;
}

li.task_item_li {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #e3f2ff;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    border-radius: 5px;
}

li.task_item_li:hover {
    background-color: #eff3fb;
}

.ellipse_shape {
    width: 14px;
    height: 14px;
    border-radius: 40px;
    border: solid 1.5px #318fff;
    background-color: rgba(100, 166, 244, 0.4);
}

li.task_item_li .task_Name {
    padding-left: 13px;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

#root.dark li.task_item_li .task_Name {
    color: #ffffff;
}

#root.dark li.task_item_li:hover .task_Name {
    color: #0b1f47;
    ;
}

li.task_item_li .task_total {
    margin-left: auto;
    order: 2;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #0b1f47;
}

#root.dark li.task_item_li .task_total {
    color: #ffffff;
}

#root.dark li.task_item_li:hover .task_total {
    color: #0b1f47;
}

div#task_item_area {
    height: 213px;
    overflow: auto;
}

#root.dark div#task_item_area {
    color: #ffffff;
    background-color: rgb(194 222 244 / 15%);
    position: relative;
    top: 4px;
}

.insights_work_detail {
    width: 58%;
    background-image: url('../../media/images/workLoad_bg.svg');
    height: 310px;
    padding: 25px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    border-radius: 25px;
    box-shadow: inset 0 0 0 2000px #0d215003;

}

.insights_work_middle {
    display: flex;
    gap: 22px;
    padding-top: 23px;
}

.insights_work_middle .WorkloadItem {
    width: 50%;
    border-radius: 20px;
    background-color: #fff;
    padding: 20px;
}

.manageWorkload_hours span {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.manageWorkload_Title {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}

.manageWorkload_Manage {
    padding-top: 25px;
    display: flex;
}

.manageWorkload_forward {
    background-image: url('../../media/images/workload_forward.svg');
    background-size: 16px;
    height: 10px;
    background-repeat: no-repeat;
    margin-left: auto;
    width: 20px;
    cursor: pointer;
    position: relative;
    top: 4px;
}

.tribecaBranding_tile {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.tribecaBranding_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tribecaBranding_para {
    width: 145px;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}

.tribecaBranding_bottom {
    padding-top: 10px;
    display: flex;
    align-items: center;
}

.tribecaBranding_hours {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #0b1f47;
}

.your_capacity.inTaskInsight {
    height: 44px;
    margin: unset !important;
    padding: unset !important;
    border-radius: 5px;
    background-color: unset !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.insights_work_bottom {
    display: flex;
    width: 100%;
    align-items: center;
    margin-top: 20px;
    border-radius: 23px;
    background-color: #fff;
    padding: 0 28px;
    gap: 32px;
}

.totalTask_graph {
    flex-basis: 88%;
}

/*Task progress Bar*/
.task_progress {
    margin: 20px auto;
    padding: 0;
    width: 90%;
    height: 29px;
    overflow: hidden;
    background: #eff3fb;
    border-radius: 29px;
}

.progressbar {
    position: relative;
    float: left;
    min-width: 1%;
    height: 100%;
    background: #318fff;
    border-radius: 29px;
}

.progressbar .percent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    font-family: tahoma, arial, helvetica;
    font-size: 12px;
    color: white;
}

.totalTask_number {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    flex-basis: 105px;
}

.task_search_area {
    padding-left: 40px;
    padding-right: 30px;
    padding-top: 36px;
    height: 312px;
    padding-bottom: 50px;
    /* overflow: auto; */
    width: 99%;
}

.insights_full_area {
    height: calc(100vh - 120px);
    overflow-y: auto;
}

/* .tasksbyRoom_top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-image: linear-gradient(95deg, rgb(255 175 76 / 12%) 26%, rgb(62 0 158 / 9%) 82%);
    padding: 13px 13px 12px 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
} */
.tasksbyRoom_top {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    background-image: linear-gradient(to right, #94567417 16%, #3e009e12 88%);
    padding: 0px 13px 0px 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    height: 52px;
}

.tasksbyRoom {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    height: 100%;
    width: 43%;
    border-right: 1px solid #fff;
}

.tasksbyRoomTitle {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
}

.tasksbyRoomDate {
    padding: 2px 11px 2px;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 72%);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.tasksbySearch {
    width: 45%;
}

ul.tasksbyRoom_heading {
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: solid 0.3px rgb(160 210 253 / 40%);
    padding-left: 20px;
    padding-right: 20px;
}

.tasksbyRoom_datas {
    height: 220px;
    overflow-y: auto;
}

ul.task_room_list {
    display: flex;
    align-items: center;
    padding: 15px 20px;
    border-bottom: solid 0.3px rgb(160 210 253 / 40%);
}

ul.task_room_list:hover {
    background-color: #e3f2fe;
}

#root.dark ul.task_room_list:hover li.task_room_Name {

    color: #0b1f47;
}

li.tasksbyRoom_name {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3;
}

li.tasksbyRoom_priority {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3;
    display: flex;
    justify-content: flex-start;
}

li.tasksbyRoom_total {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3;
    display: flex;
    justify-content: flex-start;
}

li.task_room_Name {
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    cursor: pointer;
}

#root.dark li.task_room_Name {
    color: #ffffff;
}

/* li.task_room_Name:hover{
    color: #e91e63;
} */
li.task_prioritizedTasks {
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    display: flex;
    justify-content: flex-start;
}

#root.dark li.task_prioritizedTasks {
    color: #ffffff;
}

#root.dark ul.task_room_list:hover li.task_prioritizedTasks {
    color: #0b1f47;
}

li.task_totalTasks {
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    display: flex;
    justify-content: flex-start;
}

#root.dark li.task_totalTasks {
    color: #ffffff;
}

#root.dark ul.task_room_list:hover li.task_totalTasks {
    color: #0b1f47;
}

.calendar_container .calendar_top {
    padding: 14px 115px 14px 18px;
    background-image: linear-gradient(to right, rgb(148 86 116 / 12%) 16%, rgb(62 0 158 / 7%) 88%);
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
}

#root.dark .calendar_container .calendar_top {
    color: #ffffff;
    background-color: rgb(194 222 244 / 15%);
    background-image: unset;
}

.calendar_container .calendar_top_head {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;

}

.calendar_container .react-datepicker {
    border: unset;
    width: 100%;
    padding: 14px 18px 0px 18px;
    background-blend-mode: overlay;
    /* background-color: aliceblue; */
    border-radius: 0px 0px 25px 25px;
}

.calendar_container .datepicker_calendar_top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.calendar_container .react-datepicker__month-container {
    float: left;
    width: 100%;
}

.calendar_container .react-datepicker__day-names,
.react-datepicker__week {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
}

.calendar_container .react-datepicker__day-name {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #7890a5;
}

.calendar_container .react-datepicker__day {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
    padding-bottom: 7px;
    padding-top: 7px;
}

.calendar_container .react-datepicker__day--selected {
    color: #fff;
    border-radius: 25px;
    width: 30px;
    height: 30px;
    padding: 4px 0px;
    background-color: #0b1f47;
}

.calendar_container .react-datepicker__header {
    background-color: transparent;
}

/* .calendar_container .react-datepicker__header select {
    border: none !important;
    border-radius: 49.5px;
    background-color: rgb(226 229 232 / 40%);
    font-size: 18px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding: 4px 12px;
} */


.calendar_container .react-datepicker__header select {
    border: none !important;
    border-radius: 49.5px !important;
    background-color: rgb(226 229 232 / 40%);
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding: 4px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('../../media/images/dropdown_arrow.svg');
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    appearance: none;
    outline-width: 0;
    background-repeat: no-repeat;
    background-position: 93% 50%;
}

.fromDate_One_task.col1_row2 .react-datepicker__header select {
    font-size: 13px;
}

.Track_col .react-datepicker__header select {
    font-size: 13px;
}

.react-datepicker__header select {
    font-size: 13px;
}

.calendar_container select.monthView {
    width: 128px;
}

.calendar_container select.yearView {
    width: 85px;
}

.calendar_container .react-datepicker__day--keyboard-selected {
    color: #ffffff !important;
    border-radius: 50px;
    padding-top: 4px !important;
    padding-bottom: 5px !important;
    padding-left: 0px !important;
    width: 30px;
    height: 30px;
}

/* .taskLoad_lists {
    width: 100%;
    padding: 36px 40px 0 30px;
} */
.taskLoad_lists {
    width: 100%;
    padding: 36px 0px 22px 0px;
    background-color: #eff3fb;
    height: calc(100vh - 115px);
    overflow-y: auto;
}

.task_Workloadslists_area {
    display: flex;
    flex-wrap: wrap;
    gap: 33px;
}

.taskWorkloadCard {
    width: 48%;
    box-sizing: border-box;
    border-radius: 20px;
    box-shadow: inset 0 4px 14px 0 rgb(0 0 0 / 2%);
    background-image: linear-gradient(115deg, rgb(0 112 245 / 2%) 3%, rgb(139 87 219 / 4%) 97%);
    padding: 17px 22px 17px 26px;
}

.taskWorkloadCard .progress {
    padding-left: 13px;
    padding-right: 17px;
    background-color: #fff;
    padding-top: 15px;
    padding-bottom: 14px;
    border-radius: 50px;
}

.taskWorkloadCard .week_head {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 12px;
}

.week_head .monthDate {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b1f47;
}

.week_head .work_hours {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0b1f47;
}

.workload_status_count span {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.86;
    letter-spacing: normal;
    text-align: left;

    color: #0b1f47;
}

.workload_status {
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
}

.workload_status_item {
    width: 28%;
    border-bottom: 1px solid #d9e2e8;
    flex: 0 0 28%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.workload_status_item:nth-child(4) {
    border-bottom: unset;
}

.workload_status_item:nth-child(5) {
    border-bottom: unset;
}

.workload_status_item:nth-child(6) {
    border-bottom: unset;
}

.workload_status_circle {
    width: 10px;
    height: 10px;
    border-radius: 50px;
}

.monthNames_cal_icon {
    background-image: url('../../media/images/calendar_icon.svg');
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 20px;
}

.monthNames_text {
    font-size: 28px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.64;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.task_Workloadslists_head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 34px;
}

.monthNames {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 15px;
    width: 260px;
    height: 50px;
    border-radius: 10px;
    border: solid 1px rgb(160 210 253 / 30%);
    padding: 11.7px 9px 12px 21px;
}

.monthNames_up_icon {
    background-image: url('../../media/images/up_Arrow.svg');
    width: 11px;
    height: 6px;
    background-repeat: no-repeat;
    background-size: 11px;
    cursor: pointer;
}

.monthNames_down_icon {
    background-image: url('../../media/images/down_Arrow.svg');
    width: 11px;
    height: 6px;
    background-repeat: no-repeat;
    background-size: 11px;
    cursor: pointer;
}

.monthNames_change_icons {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    margin-left: 20px;
}

.Workload_setting_icon {
    background-image: url(../../media/images/gearTask.svg);
    width: 15px;
    height: 15px;
    background-repeat: no-repeat;
    background-size: 15px;
}

.Workload_setting {
    display: flex;
    align-items: center;
    gap: 11px;
    width: 125px;
    height: 50px;
    padding: 16px 21px 16px 20px;
    border-radius: 60px;
    border: solid 1px rgb(160 210 253 / 30%);
    cursor: pointer;
}

.Workload_setting_area {
    display: flex;
    align-items: center;
    gap: 12px;
}

.AssigneeField.workLoadAssin {
    border-radius: 60px;
    border: solid 1px rgb(160 210 253 / 30%);
    width: 232px;
}

.AssigneeField.workLoadAssin .ellipsis span {
    padding-left: 10px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.serachbyRoomOrName {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
    padding-bottom: 5px;
    padding-left: 10px;
    background-color: #ffffff;
}

span.keyword_Color.workloadUsersName {
    border-radius: 4px;
    background-color: #732be2;
    color: #fff;
    padding: 2px;
    font-size: 12px;
    height: 20px;
}

.taskWorkLoad_back {
    background-image: url('../../media/images/tagBack.svg');
    background-size: 25px;
    width: 25px;
    height: 42px;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
}

/* .Workload_month_area {
    display: flex;
    align-items: center;
    gap: 15px;
} */
.Workload_month_area {
    display: flex;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
    padding-left: 30px;
    border-bottom: 1px solid rgb(160 210 253 / 30%);
    padding-bottom: 27px;
}

._tag_rooms.workLoadRooms {
    height: 39px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0px !important;
    border-bottom: 1px solid #e3f2ff;
    background-color: #ffffff !important;
}

._tag_rooms.workLoadRooms .PlusIcon {
    top: 0 !important;
    font-weight: 400 !important;
}

._tag_rooms.workLoadRooms .inviteEmail {
    top: 0 !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

._tag_rooms.workLoadRooms:last-child {
    border-bottom: unset !important;
}

.AddKeywordsPopup_task_settings.workloadTaskBoard {
    width: 270px !important;
}

.keyword_list_task_settings.workloadUsers .tagNotFound {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
}

.tagNotFound {
    background-color: white;
}

.task_circle {
    width: 9px;
    height: 9px;
    background-color: #732be25c;
    border-radius: 50%;
}

.task_circle.active-color {
    background-color: #732be2;
}

i.fa.fa-plus.custom_icon.false {
    padding: 5px 9px 6px;
    border-radius: 16px;
    background-color: #e3f2ff;
    color: #318fff;
}

i.fa.fa-plus.custom_icon.open {
    padding: 5px 9px 6px;
    border-radius: 16px;
    background-color: #318fff;
    color: #fff;
    transform: rotate(0deg);
}

.custom_dropdown_more.moreColums {
    width: 155px;
}

.custom_dropdown_more.moreColums div {
    padding: 10px 14px;
    justify-content: space-between;
    align-items: center;
}

.custom_dropdown_more.moreColums div label {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f49;
}

span.removeIcon::before {

    width: 24px;
    height: 24px;
    content: "\f068";
    font-family: "FontAwesome";
    font-weight: 600;
    color: #318FFF;
    font-size: 15px;
    /* padding: 3px 10px 0px 0px; */
}

span.addIcons::before {
    width: 24px;
    height: 24px;
    content: "\f067";
    font-family: "FontAwesome";
    font-weight: 600;
    color: #318FFF;
    font-size: 15px;
    /* padding: 3px 10px 0px 0px; */
}

.taskBodyArea {
    /*height: calc(100vh - 0px);*/
    height: auto;
}

.left_container.taskLeftBar {
    width: 300px;
}

/* .main_container.taskManager .right_container {
    width: calc(100% - 300px);
} */
.left_container.taskLeftBar span.adminCloseIcon {
    top: -28px;
    right: 15px;
}

.breadcrumb_area {
    width: 100%;
}

.task_basicSearch_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* padding-top: 23px; */
    /* padding-bottom: 10px; */
    padding-left: 40px;
    padding-right: 30px;
    width: 100%;
    border-top: solid 1px #e3f2fe;
    border-bottom: solid 1px #e3f2fe;
    position: relative;
}

.task_basicSearch_area.Top_area {
    padding-bottom: 10px;
    position: relative;
}

.refresh_button.basic {
    top: 60px;
}

.task_search_go {
    width: 57.1px;
    height: 33.1px;
    flex-grow: 0;
    padding: 6px 18.1px 6.1px 19px;
    border-radius: 16px;
    background-color: #318fff;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    cursor: pointer;
}

.task_input_area {
    width: 86%;
}

.task_advance_button {
    width: 15%;
}

.task_advance_button {
    width: 149px;
    height: 33px;
    flex-grow: 0;
    margin: 10px 12px 15px;
    padding: 6px 13.7px 6px 16px;
    border-radius: 30px;
    border: solid 0.5px #318fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;
    margin-left: 0;
}

input.basicSearch {
    width: 100%;
    height: 33px;
    padding: 8px 13px 9px 16px;
    border-radius: 90px;
    background-color: #f1f6fa;
    border: 1px solid #d6d6d6;
}

.task_advance_text {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff;
}

.task_filterIcon {
    background-image: url('../../media/images/taskAdvaceShape.svg');
    background-size: 16px;
    width: 16px;
    height: 16px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
}

.task_downArrowIcon {
    background-image: url('../../media/images/down_Arrow_Blue.svg');
    background-size: 12px;
    width: 16px;
    height: 14px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    top: 4px;
    right: -5px;
}

.task_downArrowIcon.up {
    background-image: url('../../media/images/up_Arrow.svg');
    background-size: 12px;
    width: 16px;
    height: 14px;
    background-repeat: no-repeat;
    cursor: pointer;
    position: relative;
    top: 2px;
    right: -3px;
}

input#_inputBar_tagdetails::placeholder {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    text-align: left;
    color: #318fff !important;
}

.first_row {
    padding-left: 0;
    padding-right: 0px;
    padding-top: 5px;
    position: relative;
}

.filter_for_a_tag::placeholder {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #858fa3 !important;
}

.first_row .col1 {
    width: 22%;
}

.refresh_button {
    position: absolute;
    right: 30px;
    width: 82px;
    height: 21px;
    flex-grow: 0;
    padding: 4px 9.9px 3px 10px;
    border-radius: 16px;
    background-color: #e3f2ff;
    align-items: center;
    display: flex;
    top: 3px;
    gap: 7px;
    cursor: pointer;
}

span.refreshButtonText {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #318fff;
}

span.refresh_button_icon i.fa.fa-undo {
    font-size: 10px;
    transform: rotate(0deg);
    color: #318fff;
    position: relative;
    top: -3px;
}

.secound_row {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding-left: 40px;
    padding-right: 30px;
    gap: 18px;
    /* min-height: 45px; */
    max-height: 200px;
    padding-top: 0px;
}

.search_item .searchAndFilter>div {
    width: 100%;
}

.secound_row .search_item {
    width: 100%;
}

.secound_row .search_item .selectedRoomCont {
    padding: 0px 0px;
}

.secound_row .search_item .tag_room_list {
    width: calc(100% - 0px);
    left: 0;
}

.secound_row .search_item .searchAndFilter {
    height: 40px;
}

.first_row .clearAllSrc::before {
    content: "\f057";
    font-family: var(--FA);
    font-weight: 600;
    color: var(--NewRed);
    font-size: 20px;
}

.first_row .clearAllSrc {
    cursor: pointer;
}

span.clearAllSrcTag::before {
    content: "\f057";
    font-family: var(--FA);
    font-weight: 600;
    color: var(--NewRed);
    font-size: 20px;
}

span.clearAllSrcTag {
    cursor: pointer;
}

.secound_row .tags_Color {
    font-weight: 400;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 0 #318fff;
    background-color: #318fff;
    color: #fff;
}

.third_row .tags_Color {
    font-weight: 400;
    padding: 2px 6px;
    border-radius: 4px;
    font-size: 14px;
    border-radius: 4px;
    border: solid 0 #318fff;
    background-color: #318fff;
    color: #fff;
}

.AssigneeField.insight {
    padding: 0 20px 0 20px;
    height: 50px;
    display: flex;
    /* justify-content: space-around; */
    border-radius: 5px;
    margin-top: -5px;
    width: 100%;
    align-items: center;
    border: unset;
}

.AddKeywordsPopup_task_settings {
    display: block;
    z-index: 99999;
    height: auto;
    position: absolute;
    border-radius: 5px;
    background-color: white;
    box-shadow: 7px 7px 7px 7px rgb(0 0 0 / 10%);
    top: 32px;
}




ul.tasks_ul {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 6px 17px 5px 8px;
    border-radius: 40px;
    background-color: rgb(255 255 255 / 50%);
}

#root.dark ul.tasks_ul {
    background-color: rgb(255 255 255 / 90%);
}

li.status_heading {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    cursor: pointer;
    padding: 4px 10px;
}

li.status_heading.active {
    padding: 4px 10px;
    border-radius: 50px;
    background-color: #0b1f47;
    color: #ffffff;
}

ul.task_item_list {
    height: 100%;
}

.keyword_title {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

#root.dark .keyword_title {
    color: #ffffff
}

.keyword_search .right-inner-addon input {
    padding-right: 30px !important;
    width: 100%;
    box-shadow: inset 0 0 2px 0 rgb(0 0 0 / 5%);
    height: 27px;
    border: unset;
    padding-left: 11px;
    border-radius: 90px;
    background-color: #858fa31f;
    margin-top: 10px;
    margin-bottom: 5px;
}

.keyword_search .right-inner-addon i {
    position: absolute;
    right: 0px;
    padding: 6px 6px;
    pointer-events: none;
    color: #858fa3;
    top: 15px;
}

.keyword_search .right-inner-addon input::placeholder {
    color: #318FFF !important;
    font-size: 14px !important;
    font-weight: normal;
}

.keyword_search {
    padding-left: 15px;
}

li.room_heading {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding: 4px 8px 4px 9px;
    cursor: pointer;
}

#root.dark li.room_heading {
    color: #ffffff;
}

li.room_heading:hover {
    background-color: #2196f3;
    border-radius: 50px;
}

ul.tasks_roms_ul {
    display: flex;
    align-items: center;
    grid-gap: 9px;
    gap: 9px;
    padding: 6px 14px 5px 8px;
    border-radius: 40px;
    background-color: #5b647729;
}

li.room_heading.active {
    padding: 4px 8px 4px 9px;
    border-radius: 50px;
    background-color: #0b1f47;
    color: #ffffff;
}

.view_all_tasks {
    height: 100%;
    display: flex;
    align-items: center;
    width: 160px;
    justify-content: flex-end;
    border-left: 1px solid #fff;
}

.tasksbySearch .right-inner-addon.input-container {
    padding: 0 14px;
}

.tasksbySearch .right-inner-addon i {
    position: absolute;
    right: 16px;
    padding: 6px 6px;
    pointer-events: none;
    color: #858fa3;
}

.view_all_tasks .createTaskButton {
    margin-left: 20px;
    background-color: #0b1f47;
    color: #ffffff;
    /* width: 130px; */
}

.insights_work_charts {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 16px;
}

.insights_work_right_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    border-radius: 20px;
    padding: 10px;


}

/* .Piechart_area{
      background-image: url(../../media/images/group_chart.svg);
    background-size: 70%;
    width: 100%;
    height: 180px;
    background-repeat: no-repeat;
    background-position: center center;
} */
.Piechart_area {
    background-size: contain, cover;
    width: 100%;
    /* background-image: url('../../media/images/group_chart.svg'); */
    height: 180px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.insights_work_charts .insights_work_item {
    width: 50%;
}

.insights_work_right_bottom {
    padding-top: 20px;
}

.insights_work_title {
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding-bottom: 10px;
}

#root.dark .insights_work_title {
    color: #ffffff;
}

#root.dark .insights_work_detail {
    background-color: rgb(194 222 244 / 15%);
    background-image: unset;
}

.insightupdate_text {
    padding: 12px 16px 11px 14px;
    background-color: rgba(227, 70, 70, 0.19);
    border-radius: 10px;
    width: auto;
    margin-bottom: 22px;
    display: flex;
    align-items: center;
    gap: 8px;
}

#root.dark .insightupdate_text {
    color: #ffffff;
    background-color: #fafafa;
}

.insightupdatePara {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.11;
    letter-spacing: normal;
    text-align: left;
    color: #ba0922;
    /* cursor:pointer; */
}

/* .insightupdate_text:hover {
    background-color: #ffaf4c;
} */
.insights_work_Left.insights_work_item {
    width: 60%;
}

.task_update_item {
    width: 31%;
    height: 142px;
    padding: 20px 15px 13px 14px;
    border-radius: 10px;
    background-color: #fff;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.tsks_hours_detail div {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.tsks_hours_detail:hover div {
    color: #ba0922 !important;
    cursor: pointer;
}

.task_update_text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    padding: 5px;
    font-size: 12px;
    margin-left: -3px;
    border-radius: 50px;
    border: solid 1px #df1e39;
    background-color: rgba(223, 30, 57, 0.1);
    /* width: 100%; */
    height: 25px;
    min-width: 62px;
}

.task_update_text:hover {
    background-color: #ffc328;
    color: white;
}

span.task_update_head {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ba0922;
}

svg.updateRightArrow {
    padding-top: 3px;
}

.piechartForInsight {
    width: 68%;
    position: relative;
}

li.tasksbyRoom_total {
    margin-left: auto;
}

li.task_totalTasks {
    margin-left: auto;
    padding-left: 10px;
}

li.task_prioritizedTasks {
    padding-left: 10px;
}

.workloadReaportBtn {
    width: auto;
    height: 46px;
    flex-grow: 0;
    padding: 13px 8px 10px 8px;
    border-radius: 49.5px;
    border: solid 1px #ffc328;
    background-color: #ffc328;
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.3;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
    cursor: pointer;
}

.workloadReaportBtn:hover {
    background-color: #318fff;
    color: white;
}

.view_all_tasks>div:hover {
    background-color: #318FFF;
}

.Workload_month_right {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.Workload_warning_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 30px 40px 30px 30px;
    width: 100%;
    gap: 24px;
    border-bottom: 1px solid rgb(160 210 253 / 30%);
}

.update_the_following_tasks {
    width: 243px;
    height: auto;
    flex-grow: 0;
    background-color: rgba(227, 70, 70, 0.19);
    padding: 22px 17px 22px 20px;
    border-top-left-radius: 20px;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.17;
    letter-spacing: normal;
    text-align: center;
    color: #ba0922;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

}

.task_update_item_status {
    display: flex;
    align-items: center;
    width: 243px;
    justify-content: space-between;
    height: 84px;
    flex-grow: 0;
    padding: 22px 17px 22px 20px;
    border-radius: 20px;
    background-color: #fff;
    gap: 10px;
}

.tsks_hours_status div {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

/* .workload_card.taskLoadCard {
    border-radius: 20px;
    box-shadow: inset 0 4px 44px 0 rgb(0 0 0 / 10%);
    background-image: linear-gradient(110deg, rgb(0 112 245 / 10%) 3%, rgb(139 87 219 / 18%) 50%);
    margin-top: 26px;
    padding-top: 31px;
    padding-bottom: 35px;
} */
.workload_card.taskLoadCard {
    border-radius: unset;
    box-shadow: unset;
    background-image: unset;
    margin-top: unset;
    padding-top: unset;
    padding-bottom: unset;
    background-color: transparent;
    border-bottom: 2px solid #eff3fb;
}

.workload_card_full_area.taskLoadCard {
    border-radius: 20px;
    box-shadow: inset 0 4px 44px 0 rgb(0 0 0 / 10%);
    background-image: linear-gradient(110deg, rgb(0 112 245 / 10%) 3%, rgb(139 87 219 / 18%) 50%);
    margin-top: 26px;
    padding-top: 31px;
    padding-bottom: 35px;
}

.workload_card_full_area.taskLoadCard.overcapacityTake {
    box-shadow: inset 0 4px 44px 0 rgb(0 0 0 / 10%);
    background-image: linear-gradient(110deg, rgb(255 0 36 / 10%) 35%, rgb(255 140 0 / 12%) 61%);
}

.full_workload_items_area {
    padding: 0 30px 0 30px;
}

.workload_date.taskLoad {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
    padding: 2px 12px 0;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 70%);
    height: 23px;
    /* width: 85px; */
}

.your_capacity.workLaoad {
    border-radius: 9px;
    background-color: rgba(255, 255, 255, 0.55);
    padding: 17px 0px 18px 12px;
}

.workLimit.limit_ar {
    border-radius: 60px;
}

.workLimit.limitBar {
    border-top-left-radius: 60px;
    border-bottom-left-radius: 60px;
}

.workLimit.workloadLimit {
    border-top-right-radius: 60px;
    border-bottom-right-radius: 60px;
}

.your_capacity_area.taskLad {
    margin: 0;
    padding-right: 5px;
}

.workload_bottom_area {
    padding: 29px 20px 0px 27px;
}

.taskStatusThisWeek {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
    width: auto;
    height: 29px;
    flex-grow: 0;
    padding: 4px 18px 3px 18px;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 70%);
}

.status_top_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.taskStatusTotalTask {
    width: auto;
    height: 29px;
    flex-grow: 0;
    padding: 4px 17px 3px 17px;
    border-radius: 49.5px;
    background-color: rgb(255 255 255 / 70%);
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #0b1f47;
}

.status_cards {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.status_cards_area {
    padding-top: 24px;
}

.card_area_cl {
    height: 111px;
    padding: 10px 13px 9px 14px;
    border-radius: 20px;
    background-color: #fff;
    margin-right: 18px;
    width: 25%;
    display: flex;
    flex-flow: column nowrap;
}

.status_header_text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.status_counter {
    font-size: 30px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.status_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    cursor: pointer;
}

.status_bottom_text:hover {
    color: #e91e63
}

.status_bottom_text {
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.status_right_cards {
    height: 111px;
    padding: 10px 13px 9px 14px;
    border-radius: 20px;
    background-color: #fff;
    display: flex;
    flex-flow: column nowrap;
}

.status_right_head {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    width: 229px;
}

.status_right_para {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
    padding-top: 3px;
}

.status_right_bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    order: 2;
    margin-top: auto;
    cursor: pointer;
}

.status_right_bottom_text {
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.status_header_circle {
    width: 10px;
    height: 10px;
    border-radius: 40px;
}

.status_header {
    display: flex;
    align-items: center;
    margin-bottom: auto;
    justify-content: space-between;
}

.reviews_icon {
    background-image: url('../../media/images/msgBabul.png');
    width: 33px;
    height: 35px;
    background-size: 35px;
    background-repeat: no-repeat;
    position: relative;
    top: -6px;
}

.reviewTaskIcon {
    background-image: url('../../media/images/msgVo.png');
    width: 29px;
    height: 29px;
    background-size: 28px;
    background-repeat: no-repeat;
    position: relative;
    top: 2px;
}

span.heartIcon {
    position: absolute;
    top: -10px;
    right: 28px;
    transform: rotate(45deg);
    background: #a6d9f1;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

span.chatIconIcon {
    position: absolute;
    bottom: -9px;
    right: 20px;
    transform: rotate(-45deg);
    background: #a6d9f1;
    width: 35px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
}

.piechartForInsight.item .recharts-wrapper svg {
    width: 100%;
    height: 150px;
}

.ObsImageTask {
    width: 32px;
    height: 32px;
    float: left;
    /* border-radius: 12px; */
    box-sizing: border-box;
    overflow: hidden;
    color: white;
    font-weight: bold;
    pointer-events: none;
    display: flex;
    justify-content: center;
    border-radius: 20px;
    margin-top: 10px;
    margin-right: 5px;
    background-color: rgb(255, 153, 136);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ffffff;
    font-size: 12px;
}

.ObsImageTask img {
    width: 32px;
    height: 32px;
    border-radius: 12px;
}


li.ObsImageTask:nth-child(n+2) {
    margin-left: -25px;
}

.selectedKeywordCont_task {
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
    background-color: #ffffff;
    max-height: 160px;
    overflow-y: auto;
    z-index: 99999 !important;
    /* padding: 10px; */
    /* margin: 10px 10px 0px 10px; */
}

.OldReview {
    width: 650px;
    height: 580px;
    background-color: #ffffff;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: absolute;
    overflow-y: auto;
}

.OverallRating {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 110px;
    background-color: #0a256a;
    color: white;
    border-radius: 5px 5px 0px 0px;
}

.HistoryFor {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: row;
    width: 150px;
    color: white;
    position: absolute;
    background-color: #0b1f47;
    margin-top: -20px;
    border-radius: 2px 2px 0px 0px;
    /* border-bottom: 2px solid rgb(254, 191, 58);; */
}


.Refreshing {
    background-color: #0a256a;
    color: white;
    padding: 4px;
    border-radius: 5px;
    font-size: 18px;
    width: 100px;
    text-align: center;
    cursor: pointer
}

.TableHead {
    display: grid;
    /* justify-content: space-evenly; */
    grid-template-columns: 150px 110px 110px auto;
    /* height: 50px; */
    background-color: #0b1f47;
    padding: 10px;
    color: white;
    border-radius: 0px 0px 5px 5px;


}

.TableBody {
    height: 350px;
    overflow-y: auto;
}

.TableBody div {
    display: grid;
    grid-template-columns: 150px 110px 110px auto;
    border-bottom: 1px solid #ddd;


}

.TableBody div span {
    display: flex;
    align-items: center;
    text-align: left;
    padding-left: 10px;
}

.TableBody div:hover {
    background-color: #f5f5f5;
}


ul.usersGroup {
    list-style-type: none;
    margin: 0;
    padding: 0;
    width: auto;
    margin-right: 10px;
}

ul.usersGroup li {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    padding-top: 2px;
    padding-left: 0px;
    font-weight: 600;
    color: #fff;
    font-size: 18px;
    text-align: center;
}

/* ul.usersGroup li:nth-child(n+2) { margin-left: -50px; } */
ul.usersGroup li:nth-child(n+2) {
    margin-left: -18px;
    margin-right: 0px;
}

.userDropDownArea {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
}

span.selectOnew {
    float: left;
    position: relative;
    /* left: -67px; */
    width: 150px;
}

input.Focus.searchPeople::placeholder {
    color: chartreuse;
}

input.Focus.searchPeople::-webkit-input-placeholder {
    color: chartreuse;
}

input.Focus.searchPeople:-moz-placeholder {
    color: chartreuse;
}

/*Task create design*/
.taskCreate_full_area {
    width: 95%;
    /* height: calc(100vh - 20px); */
    height: 860px;
    border-radius: 15px;
    box-shadow: 0 0 44px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    position: relative;
}

.taskCreate_header_top {
    margin: 1px 0 0;
    padding: 40px 0px 36px 33px;
    /* opacity: 0.8; */
    background-color: rgba(47, 43, 226, 0.06);
}

.taskCreate_text {
    font-size: 34px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.53;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.flag_Icon_task {
    background-image: url('../../media/images/fill_flagIcon.png');
    background-size: 18px;
    background-repeat: no-repeat;
    padding-left: 30px;
    background-position: 2px 0;
    cursor: pointer;
    position: relative;
    top: 3px;
}

.flag_Icon_task_active {
    background-image: url('../../media/images/fill_flagIcon_active.png');
    background-size: 18px;
    background-repeat: no-repeat;
    padding-left: 30px;
    background-position: 2px 0;
    cursor: pointer;
    position: relative;
    top: 3px;
}

.taskCreate_mainarea {
    padding: 34px 32px 0 33px;
}

.taskCreate_body {
    /* padding: 25px 21px 0px 13px; */
    border-radius: 13px;
    border: solid 1px #e3f2ff;
}

.taskCreate_Title {
    padding: 25px 21px 0px 13px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 14px;
}

.task_flag_icon {
    width: 40px;
    height: 40px;
    flex-grow: 0;
    padding: 9px 14px 13px 11px;
    border-radius: 90px;
    background-color: rgba(227, 242, 255, 0.5);
}

.taskCreate_input input {
    height: 40px;
    flex-grow: 0;
    padding: 7px 0px 7px 17px;
    border-radius: 90px;
    background-color: rgba(227, 242, 255, 0.5);
    width: 100%;
    border: unset;
}

.taskCreate_input {
    width: 100%;
}

.watch_area span {
    position: absolute;
    top: 22px;
    right: 6px;
}

.watch_area input {
    height: 40px;
    width: 75px;
    position: relative;
    top: 5px;
    border-radius: 5px;
    border: solid 1px #c1ddf4;
    padding-left: 5px;
    /* margin-top: 13px; */
}

/* Chrome, Safari, Edge, Opera */
.watch_area input::-webkit-outer-spin-button,
.watch_area input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.watch_area input[type=number] {
    -moz-appearance: textfield;
}

.watch_area {
    position: relative;
    top: 13px;
}

.taskCreate_secound_area {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0px 21px 0px 22px;
    height: 118px;
}

input.taskCreate_searchRoom {
    width: 100%;
    height: 35px;
    flex-grow: 0;
    padding: 5px 8px 4px 9px;
    border-radius: 5px;
    background-color: rgba(227, 242, 255, 0.5);
    /* border: unset; */
    border: solid 1px #c1ddf4;
}

.taskCreate_RoomName {
    flex-basis: 50%;
    border-right: 1px solid #e3f2ff;
    padding: 16px 0 20px 0px;
    position: relative;
    height: 100%;
}

.taskCreate_RoomName.taskCreate_search {
    padding-right: 15px;
}

.taskCreate_RoomName.taskCreate_assignto {
    padding-left: 15px;
}

.taskCreate_RoomName.taskCreate_search span.taskCreate_searchIcon {
    position: absolute;
    right: 20px;
    top: 25px;
}

.taskCreate_RoomName.taskCreate_assignto {
    border-right: unset;
}

.taskCreate_RoomName.taskCreate_assignto span.taskCreate_searchIcon {
    position: absolute;
    right: 6px;
    top: 25px;
}

.taskCreate_third_area {
    border-top: 1px solid #e3f2ff;
}

.taskCreate_third_area {
    border-top: 1px solid #e3f2ff;
    display: flex;
    align-items: center;
    padding: 0px 22px 0 22px;
    height: 118px;
}

.firstPart {
    display: flex;
    align-items: center;
}

.firstPart_status_label {
    width: 81px;
    height: 35px;
    flex-grow: 0;
    margin: 0 12px 0 0;
    padding: 8px 16px 5px 17px;
    border-radius: 4px;
    background-color: #f1f6fa;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}



.firstPart_status_droupDown {
    width: 100%;
}

input.cm-toggle.reviewToggle:after {
    content: '';
    width: 77px;
    height: 26px;
    display: inline-block;
    background: #5b6477 !important;
    border-radius: 18px;
    clear: both;
}

input.cm-toggle.reviewToggle:checked:after {
    background: #7db52a !important;
}

.cm-toggle {
    -webkit-appearance: none !important;
}

.taskcreate_fourth_area {
    border-top: 1px solid #e3f2ff;
    padding-top: 20px;
}

.inputWithIcon input[type="text"] {
    width: 100%;
    height: 35px;
    flex-grow: 0;
    padding: 4px 0px 4px 30px;
    border-radius: 4px;
    background-color: #f1f6fa;
    border: unset;
}

.inputWithIcon {
    position: relative;
    padding: 0 22px 15px 22px;
}

.inputWithIcon .addPlusIcon {
    position: absolute;
    left: 24px;
    top: 5px;
    /* padding: 9px 8px; */
    color: #5b6477;
    transition: 0.3s;
}

.Taskkeywords_area {
    display: flex;
    padding: 0 22px 0 23px;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: flex-start;
    height: 75px;
    overflow: auto;
}

.Taskkeywords_area .removeKeyword {
    width: 14px !important;
    top: 0px !important;
}

.Taskkeywords_area .singleKeyword {
    font-weight: 600;
}

span.clearInput.task_Keyword {
    top: -16px;
    right: -14px;
}

.taskcreate_bottom_text {
    height: 81px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

span.Task_will_be_assigned {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #858fa3;
}

.taskcreate_footer_area {
    display: flex;
    align-items: center;
    height: 71px;
    justify-content: space-between;
    padding: 0px 32px 0 33px;
    border-top: 1px solid #e3f2ff;
    width: 100%;
    position: absolute;
    bottom: 0;
}

.cancel_fullarea {
    width: 67px;
    height: 27px;
    flex-grow: 0;
    padding: 6px 13px 8px 11px;
    border-radius: 34px;
    background-color: #0a256a;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.saveNewTask {
    width: 90px;
    height: 27px;
    flex-grow: 0;
    padding: 6px 24px 7px;
    border-radius: 34px;
    background-color: #318fff;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
}

.taskCreate_mainarea input::-webkit-input-placeholder {
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff !important;
}

.taskCreate_mainarea input::-moz-placeholder {
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff !important;
}

.taskCreate_mainarea input::-ms-placeholder {
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff !important;
}

.taskCreate_mainarea input::placeholder {
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff !important;
}

input.AddTaskName::placeholder {
    font-size: 16px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63 !important;
    letter-spacing: normal;
    color: #858fa3 !important;
}

input.keywordInput::placeholder {
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.63 !important;
    letter-spacing: normal;
    color: #858fa3 !important;
}

.AddKeywordsPopup.forCreateTask {
    left: 0;
    background-color: #fff;
}

span.keyword_Color.observerLists {
    height: 30px;
    flex-grow: 0;
    padding: 5px 8px 4px;
    border-radius: 4px;
    background-color: #318fff;
    color: #fff;
    margin-top: 10px;
}

/*==================Task Notify css===========================*/
.taskNotify_full_area {
    /* width: 100%;
    height: 100%; */
    /* width: 100%; */
    height: calc(100vh - 125px);
    overflow-y: auto;
}

h1.commingSoon {
    text-align: center;
    position: absolute;
    left: 40%;
    top: 40%;
}

.discussionArea {
    height: calc(100vh - 350px);
    overflow: auto;
    margin-bottom: 55px;
}

.discussionArea .bottomBox_task .editMsgContainer .demo-wrapper,
.bottomBox_task .demo-wrapper {
    width: 92% !important;
}

.discussionArea .rdw-suggestion-dropdown {

    bottom: 50px !important;

}

.discussionArea .voiceIcon1MsgBox {
    left: 0;
    bottom: 15px
}

.discussionArea .voiceIconMsgBox {

    left: 0;
    bottom: 15px
}


.main_container.fromMessage .task_right_container {
    position: absolute;
    background-color: #ffffff;
    right: 0;
    width: calc(100% - 346px);
}

.main_container.fromMessage .taskContainer_setting {
    position: absolute;
    width: calc(100% - 346px);
    right: 0;
    background-color: #fff;
    height: 100%;
}

/* .select-ecosystem.createTaskSelect {
   min-height: 31px !important;
    max-height: 31px !important;
} */
/* .select-ecosystem.createTaskSelect .css-yk16xz-control {
   min-height: 31px !important;
    max-height: 31px !important;
} */
/* .select-ecosystem.createTaskSelect .css-g1d714-ValueContainer {
    min-height: 31px !important;
    max-height: 31px !important;
    padding: 5px 7px !important;
    display: flex !important;
    justify-content: flex-start;
    align-items: center !important;
    margin-top: -6px;
} */
/* .select-ecosystem.createTaskSelect .css-1pahdxg-control{
    min-height: 31px !important;
    max-height: 31px !important;
} */
/* .select-ecosystem.createTaskSelect .css-1hb7zxy-IndicatorsContainer{
 min-height: 31px !important;
    max-height: 31px !important;
} */
.assigneeMem_inner li.ObsImageTask {
    margin-right: -5px;
}

.tooltipHolder:nth-child(n+2) {
    margin-left: -25px;
}

.ellipsisText1 {
    width: 100%;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    white-space: nowrap;
}

.assigneeMem_inner {
    width: 85px;
    display: inline-flex;
    margin: 0 auto;
    margin-left: -16px !important;

}

.assigneeMem_inner1 {
    /* width: 85px; */
    display: flex;
    margin: 0 auto;
    flex-direction: column;
}

.AddKeywordsPopup span.keyword_Color {
    /* background-color: #318fff;
    color: #fff;
    padding: 5px;
    border-radius: 4px; */
    border-radius: 4px;
    background-color: #732be2;
    color: #fff;
    padding: 2px;
    font-size: 12px;
    height: 20px;
}

.creator_area .selectedKeywordCont_task {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 4px;
    gap: 4px;
    background-color: #ffffff;
    /* padding: 5px; */
    margin: 10px 10px 0px 10px;
    max-height: 120px;
}

.creator_area .searchPeople {
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 4px;
    background-color: #eef4f9;
}

.status_area select {
    width: 151px;
    height: 30px;
    flex-grow: 0;
    margin: 5px 0 0;
    padding: 2px 12px 5px 11px;
    border-radius: 5px;
    background-color: #5b6477;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
}

.new_area {
    display: flex;
    justify-content: space-between;
    /* flex-wrap: wrap; */
}

input#addkeyword {
    padding-left: 20px;
    border-radius: 4px;
    background-color: #e3f2ff;
    outline: none;
    border: 1px solid #d6d6d6;
    padding-right: 30px;
    height: 30px;
}

.status_area {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}

.status_area p.projectTitleRoom {
    margin-top: 6px;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5b6477;
    border-radius: 4px;
    background-color: #f1f6fa;
    padding: 6px 8px 3px 9px;
    height: 30px;
}

.tasksbySearch.priorityStatus .keyword_search_icon {
    top: 7px;
    right: 20px;
}

ul.colorGroup.keywordText {
    display: flex;
    gap: 5px;
    align-items: center;
    flex-wrap: wrap;
}

.keywordTitle {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    padding: 4px;
    border-radius: 4px;
    background-color: #e3f2ff;
    margin: 0 11px 0 0;
    width: auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    align-items: center;
}


.addKeyword_section.myTask .tag_room_list {
    position: absolute;
    left: 0;
    width: calc(100% - 0px);
    background-color: #ffffff;
    top: 38px;
    z-index: 99;
    box-sizing: border-box;
    padding: 5px 5px;
    max-height: 336px;
    overflow-y: auto;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}

div#youtnotes {
    border: solid 1px #c1ddf4;
    margin-top: 5px;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    word-wrap: break-word;
    padding-right: 40px;
    cursor: pointer;
}

.NoteHours {
    border: solid 1px #c1ddf4;
    /* margin-top: 5px; */
    border-radius: 5px;
    padding: 8px;
    width: 100%;
    word-wrap: break-word;
    background-color: white;
}

/* .createMyNote_box[contentEditable=true]:empty:before { */
/* .createMyNote_box[contentEditable=true]:empty:not(:focus)::before{ */
.createMyNote_box[contentEditable]:empty:before {
    content: attr(data-placeholder);
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}


.NoteHours.NoteExpand {
    height: 120px;
    max-height: 150px;
    font-weight: 400;
    overflow-y: auto;
    color: #0b1f47;
    padding: 8px;
    margin-bottom: 8px
}

.NoteHours.NoteCollapse {
    height: 36px;
    font-weight: 400;
    overflow-y: auto;
    color: #0b1f47;
    padding: 8px;
    margin-bottom: 8px
}

.NoteHours.NoteCollapse,
.NoteHours.NoteExpand {
    transition: height 0.3s ease-in-out;
}

input.forecastedCost::-webkit-outer-spin-button,
input.forecastedCost::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input.forecastedCost[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

input.actualCost::-webkit-outer-spin-button,
input.actualCost::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input.actualCost[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

input.totalCost::-webkit-outer-spin-button,
input.totalCost::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input.totalCost[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

p.notFoundData {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-top: 100px;
}

#root.dark p.notFoundData {
    color: #ffffff
}

p.notes_btn.saveBtn {
    background-color: #318fff;
    color: #fff;
    /* padding-top: 1px; */
}

/* .fileBody_area{
    height: 100%;
} */
/* .fileBody_area.taskListBody_area.prime_search {
    height: calc(100vh - 260px);
} */
/* .fileBody_area.taskListBody_area.prime_search .infinite-scroll-component {
    height: calc(100vh - 260px) !important;
    height: 100%;
} */
.fileBody_area.taskListBody_area.prime_search {
    /* height: unset; */
    /* height: calc(100vh - 433px); */
    height: calc(100vh - 365px);

}

input.start_Date_task {
    color: #0d2150;
    width: 100% !important;

}

.react-datepicker__day--today,
.react-datepicker__month-text--today,
.react-datepicker__quarter-text--today,
.react-datepicker__year-text--today {
    font-weight: bold;
    background-color: #0d2150 !important;
    border-radius: 50% !important;
    color: #fff !important;
}

.react-datepicker__day.react-datepicker__day--today {
    color: #fff !important;
}

span.saveSettings.updateDidabled {
    opacity: 0.6;
    pointer-events: none;
}

.select-ecosystem.createTaskSelect.isDisable .css-yk16xz-control {
    background-color: #d8d8d8 !important;
    pointer-events: none;
    border: 1px px solid #d6d6d6 !important;
    pointer-events: none;
    color: #a999a1 !important;
    opacity: 0.5;
}

/* .select-ecosystem.createTaskSelect .css-yk16xz-control .css-1uccc91-singleValue {
    top: 36%;
} */
.select-ecosystem.taskSelectdata .css-1e70mot-control .css-1uccc91-singleValue {
    margin-left: 0px;
}

.user_msg p.task_Title {
    padding-bottom: 5px;
    padding-top: 5px;
    font-style: italic;
    font-size: 20px;
}

.task_advacedSearch_area input._inputSearchBar {
    padding-right: 32px;
}

.task_advacedSearch_area input._inputBar.searchRight {
    padding-right: 32px;
}

.TaskTable .notFound {
    text-align: center;
    position: relative;
    top: 40%;
    font-size: 20px;
    font-weight: 600;
}

.file_counter span {
    padding: 4px 9px 4px 7px;
    border-radius: 40px;
    background-color: #0b1f47;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

svg.file_AttachIcon {
    transform: rotate(45deg);
    font-size: 20px;
}

.dueTime {
    position: relative;
    width: 22%;
}

span.timeIcon {
    position: absolute;
    right: 4px;
    top: 30px;
    cursor: pointer;
}

input.redClor {
    color: #df1e39;
}

.pieChart_area {
    position: relative;
}

.custom-tooltip .label {
    color: #318fff !important;
    font-size: 14px;
}

.tooltipHolder {

    height: 100%;
    width: 100%;
    display: flex;
}

.tooltipHolder span {
    height: 100%;
    width: auto;
    display: flex;
    justify-content: center;
}

.tooltipSpan {
    max-width: 150px !important;
    position: relative;

}

.statusType {
    width: -moz-fit-content;
    width: fit-content;
    height: 20px;
    margin: 0 2px 8px 40px;
    padding: 0px 5px 0px 5px;
    border-radius: 5px;
    background-color: #318FFF;
    cursor: pointer;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    grid-gap: 2px;
    grid-gap: 5px;
    gap: 5px;
    margin: 10px auto 0px auto;
}

.searchAndFilterKeyword {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 3px 0;
    background-color: #fff;
}

.keyword_list_task_settings {
    position: relative;
    background-color: #fff;
    z-index: 99;
    box-sizing: border-box;
    padding: 5px;
    max-height: 300px;
    overflow-y: auto;
    border-radius: 5px;
}

.searchPeople {
    width: 100%;
    border: none;
    padding: 10px;
    border-radius: 4px;
    background-color: #eef4f9;
}

.remove_all {
    background-color: #318fff;
    padding: 2px 6px;
    border-radius: 5px;
    margin-right: 6px;
    font-size: 12px;
    color: #fff;
    cursor: pointer;
    margin-left: 5px;
    width: 70px;
    float: right;
    position: absolute;
    right: -13px;
    margin-top: -20px;
}

input#startDate {
    font-size: 14px;
    color: #0b1f47;
    cursor: pointer;
}

input#endDate {
    font-size: 14px;
    color: #0b1f47;
    cursor: pointer;
}

input#dueTime {
    font-size: 14px !important;
    color: #0b1f47;
    cursor: pointer;
}

.date_area_div>div>div>input {
    font-size: 14px !important;
    color: #0b1f47;
    cursor: pointer;
    padding: 7px 6px !important;
}

.date_area_div .react-datepicker-popper {
    left: 35px !important;
    inset: 0 !important;
}

.task_body_left.quickShowTask .notes_label_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 42px;
    cursor: pointer;
}

.notes_label_area {
    display: flex;
    flex-direction: row;
    grid-gap: 6px;
    gap: 6px;
}

.task_body_left.quickShowTask .notes_label_area p {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
}

.task_body_left.quickShowTask .notes_label_area p:hover {
    color: #0079d1;
}

.notes_label_area .noteFirstColumn {
    cursor: pointer;
    display: flex;
    align-items: center;
}

.lastPara:hover {
    color: #e91e63;
}

.file_counter_last_area {
    order: 2;
    margin-left: auto;
    display: flex;
    align-items: center;
    gap: 10px;
}

.file_counter_new {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #318fff;
}

.markCheckList {
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
}

/* Hide the browser's default checkbox */

.checklistMarker {
    width: 18px;
    height: 18px;
    border-radius: 4px;
}

/* .checklistMarker {
    background-color: #a6d068 !important;
  } */
.checklistMarker:after {
    content: " ";
    background-color: #a6d068;
    display: inline-block;
    visibility: visible;
}

.checklistMarker:checked:after {
    width: 18px;
    height: 18px;
    margin: 0 !important;
    content: "\2714";
    box-shadow: 0px 2px 4px rgba(155, 155, 155, 0.15);
    border-radius: 2px;
    display: block;
    text-align: center;
    font-size: 12px;
    color: white;
}

.checklistInput {
    height: 100%;
    width: calc(100% - 80px);
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checklistInputField {
    width: 100%;
    height: 100%;
    border: 1px solid #d9e2e8;
    margin: 0 !important;
}

.checklistAddBtn {
    height: 33px;
    width: 50px;
    background: #318fff;
    border: none;
    color: #fff;
    border-radius: 4px;
}

.checklistArea {
    width: 100%;
    display: flex;
    align-items: center;
    height: 45px;
    position: relative;
}

.checkList_first_row .asce .sortIcons:before,
.checkList_first_row .desc .sortIcons:before {
    font-family: "FontAwesome";
    margin-left: 0;
    font-size: 16px;
    position: absolute;
    left: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    color: #0c1e47;
}

.checkList_first_row .desc .sortIcons:before {
    content: "\f160";
}

.checklist_label {
    height: 100%;
    width: calc(100% - 50px);
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    position: relative;
    font-size: 14px;
    /* top: -2px; */
}

.checklistAction {
    align-items: center;
    justify-content: space-between;
    right: 10px;
    display: none;
    width: auto;
    height: 34px;
    border: 1px solid #0c1e47;
    align-items: center;
    border-radius: 4px;
    box-sizing: border-box;
    position: absolute;
    background-color: #f3f3f3;
    z-index: 1;
}

.checklistArea:hover {
    background-color: #ebebeb;
    cursor: pointer;
}

.checklistArea:hover>.checklistAction {
    display: flex;
}

.dropdown-header.taskpro {
    height: 36px;
}

.dropdown.progressbar {
    width: 151px;
    height: 24px;
    margin-top: 10px;
}

.dropdown-header.progress_head {
    height: 24px;
    padding: 2px 9px;
}

.dropdown-header.progress_head i.fa {
    font-size: 11px;
}

.dropdown-header.forProgress {
    height: 36px;
}

.searchAndFilter .MuiInputBase-fullWidth {
    width: 100%;
    border: solid 1px #d9e2e8;
    border-radius: 25px;
    padding-left: 10px;

}

.searchAndFilter .MuiInputBase-fullWidth:focus {
    color: #0c1e47;
    outline: none !important;
    border-color: #2196f3;
    box-shadow: 0 0 3px #2196f3;
    border: solid 1px #318fff;
    background-color: #fff;
    border-radius: 25px;
}

.MuiInput-underline:before {

    border-bottom: unset !important;

}

.MuiInput-underline:after {
    border-bottom: unset !important;

}

.searchAndFilter .MuiIconButton-root {
    padding: 5px;
    font-size: 16px;

}

.focused-class .MuiOutlinedInput-notchedOutline {
    border-color: red;
    /* change the border color to red when focused */
}

/* input.MuiInputBase-input.MuiInput-input:focus {
    outline: none !important;
    border-color: #2196f3;
    box-shadow: 0 0 3px #2196f3;
    border: solid 1px #318fff;
    background-color: #fff;
    border-radius: 25px;
    padding-left: 10px;
} */
.third_row {
    padding-top: 10px;
    padding-bottom: 10px;
}

.fromDate_One_task.col1_row2 span.clearInputTaskMain {
    display: none;
}

.fromDate_One_task.col1_row2:hover span.clearInputTaskMain {
    display: block;
}


.taskMissig_startDate span.clearInputTaskMain {
    display: none;
}

.taskMissig_startDate:hover span.clearInputTaskMain {
    display: block;
}

.reportcheck_startDate span.clearInputTaskMain {
    display: none;
}

.reportcheck_startDate:hover span.clearInputTaskMain {
    display: block;
}

.task_search_go.active {
    pointer-events: none;
    opacity: 0.5;
}

.task_search_go.inactive {
    pointer-events: all;
}

.fileBody_area.taskListBody_area {
    /* height: 100vh; */
    /* height: calc(100vh - 445px); */
}

.fileBody_area.taskListBody_area.advanced_search_area {
    /* height: unset; */
    /* height: calc(100vh - 368px); */
    height: calc(100vh - 335px);
}

input.start_Date_task::placeholder {
    font-size: 14px !important;
    font-family: "Poppins" !important;
}

.taskTitle_div span.clearInput.taskTitle_clear {
    display: none;
}

.taskTitle_div:hover span.clearInput.taskTitle_clear {
    display: block;
}

.date_area_div span.clearInput.endDate {
    display: none;
}

.date_area_div:hover span.clearInput.endDate {
    display: block;
}

.keyword_div span.clearInput.getKeyword {
    display: none;
}

.keyword_div:hover span.clearInput.getKeyword {
    display: block;
}

select.notDefined {
    background-color: #3262c2;
}

select.stage1 {
    background-color: #3262c2;
}

select.stage2 {
    background-color: #3262c2;
}

select.stage3 {
    background-color: #3262c2;
}

select.finalstage {
    background-color: #3262c2;
}

p.TaskTitle_text {
    font-size: 22px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

#root.dark p.TaskTitle_text {
    color: #ffffff;
}

#root.dark .TaskBody {
    color: #fff;
}

#root.dark .TaskBody:hover {
    color: #0b1f47;
}

#root.dark .TaskBody:hover td {
    color: #0b1f47 !important;
}

p.conversation_name_text {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0079d1;
}

.select-ecosystem.selectForUpdated .css-yk16xz-control {
    border: 1px solid #d6d6d600 !important;
}

.select-ecosystem.selectForUpdated .css-1uccc91-singleValue {
    color: #0079d1 !important;
    font-size: 16px;
    text-align: left;
    margin-left: -7px;
    cursor: pointer;
}

p.projectTitleRoom.conversation_name_Label {
    display: none;
}

p.projectTitleRoom.TitleLabelUpdated {
    display: none;
}

input.taskTitle.TitleForUpdated {
    border: 1px solid #d6d6d600;
    padding-left: 0;
    margin-top: -10px;
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.18;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.taskTitle_area.taskTitle_area_Updated span.clearInput.taskTitle_clear {
    top: -22px;
    right: -34px;
}

.select-ecosystem.selectForUpdated .css-1pahdxg-control .css-1uccc91-singleValue {
    color: #0b1f47 !important;
    margin-left: 0px !important;
}

input.taskTitle.TitleForUpdated:focus {
    color: #0b1f47 !important;
    border: 1px solid #d6d6d6;
    border-radius: 4px;
    font-family: "Poppins";
    font-family: var(--F1);
    padding: 12px 8px;
    font-size: 16px;
    line-height: 31px;
    padding-right: 32px;
    font-weight: 500;
}

input.taskTitle.isDisable.TitleForUpdated {
    color: #0b1f4787 !important;
    font-size: 24px;
    font-weight: 600;
    padding-left: 10px;
}

span.clearInput.notes {
    right: 0;
    top: 30%;
}

.yourNotes span.clearInput.notes {
    display: none;
}

.yourNotes:hover span.clearInput.notes {
    display: block;
}

span.clearInput.describe {
    right: -10px;
    top: 40%;
}

.task_describe_section span.clearInput.describe {
    display: none;
}

.task_describe_section:hover span.clearInput.describe {
    display: block;
}

select.error {
    border: 1px solid red;
}

.TaskHeading .asce .sortIcons:before {
    content: "\f160";
    font-family: "FontAwesome";
    margin-left: 4px;
    font-size: 16px;
    position: absolute;
    font-weight: 600;
    left: 0px;
    color: #0c1e47;
}

.TaskHeading .desc .sortIcons:before {
    content: "\f161";
    font-family: "FontAwesome";
    margin-left: 4px;
    font-size: 16px;
    position: absolute;
    left: 0px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    color: #0c1e47;
}

#root.dark .TaskHeading .desc .sortIcons:before {
    color: #ffffff;
}

.TaskHeading .sort_active .sortIcons::after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    left: 3px;
    top: -4px;
}

.TaskHeading span.sortIcons {
    width: 25px !important;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

._task_title {
    display: flex;
    margin-left: -10px;
    cursor: pointer;
}

._startdate {
    display: flex;
    cursor: pointer;
}

._conversationName {
    display: flex;
    cursor: pointer;
}

.yourNotes.notesDisabled span.clearInput.notes {
    display: none;
}

.TaskBody.unread {
    background-color: #e3f2ffd1;
    border-bottom: 1px solid #e0e0e0;
}

p.form_label>span {
    cursor: pointer;
}

p.form_label.head {
    display: flex;
    gap: 5px;
}

p.form_label.head span {
    padding: 0px 7px;
    border-radius: 50px;
    color: #006ab7;
    background-color: #f1f6fa;
    font-size: 14px;
    height: 20px;
    margin-top: 2px;
}

input {
    cursor: pointer;
}

.runReportButton:hover {
    background-color: #ab6d21;
}

.createTaskButton:hover {
    background-color: #318fff;
}

span.saveSettings:hover {
    /* background-color: #0c1e47; */
    background-color: #318fffd1;
}

span.taskSettingsTextClose:hover {
    background-color: #318fff;
    color: #fff;
}

.refresh_button:hover {
    background-color: #318fff;
    color: #fff !important;
}

.refresh_button:hover span.refreshButtonText {
    color: #fff !important;
}

.refresh_button:hover span.refresh_button_icon i.fa.fa-undo {
    color: #fff !important;
}

li.status_heading.active:hover {
    background-color: #2196f3;
}

li.room_heading.active:hover {
    background-color: #2196f3;
}

.task_search_go.inactive:hover {
    background-color: #0c1e47;
}

.titleText {
    display: flex;
    cursor: pointer;
    align-items: center;
}

.titleText {
    display: flex;
    align-items: center;
    gap: 3px;
}

p.form_label.head span:hover {
    background-color: #318fff;
    color: #fff;
    padding: 0px 7px;
    border-radius: 50px;
}

li.status_heading:hover {
    background-color: #2196f3;
    padding: 4px 10px;
    border-radius: 50px;
    color: #fff;
}

.track_area_inner {
    display: flex;
    width: 100%;
    align-items: center;
    height: 100%
}

.TrackHoursPopUp {
    border: solid 1px #a0d2fd;
    width: 100%;
    height: 100vh;
    margin: 0 0 19px;
    padding: 0px 4px 0 0;
    box-shadow: 0 4px 14px 0 rgba(0, 0, 0, 0.05);
    background-color: #fff;
    position: absolute;
    z-index: 999;
    right: 0;
}

.TrackHours_head {
    padding: 16px 20px 20px 20px;
    border-bottom: 1px solid #e3f2ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 58px;
}

.TrackHours_head_action {
    display: flex;
    align-items: center;
    gap: 10px;
}

.TrackHours_head_text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.Track_cancel {
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #006ab7;
    cursor: pointer;
    font-size: 12px;
    width: 71px;
    height: 25px;
    flex-grow: 0;
    padding: 3px 15px 3px;
    border-radius: 45px;
    background-color: #e3f2ff;
}

.Track_cancel:hover {
    background-color: #318fff;
    color: #fff;
}

.Track_save {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    cursor: pointer;
    width: 57px;
    height: 25px;
    flex-grow: 0;
    padding: 4px 13px 3px 14px;
    border-radius: 45px;
    background-color: #318fff;
}

.Track_save:hover {
    background-color: #002e98;
}

.Track_body_headding {
    display: flex;
    padding: 10px 16px 0px 16px;
    align-items: center;
}

.track_form_div .Track_body_headding {
    grid-gap: 10px;
    gap: 10px;
}

span.downloadIcon svg,
span.verTicleborderLine {
    color: #318fff;
}

.Track_col {
    position: relative;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0079d1;
}

input.track_inputBox {
    width: 100%;
    padding: 5px 7px 6px 10px;
    border-radius: 5px;
    border: solid 1px #c1ddf4;
    background-color: #e3f2ff;
    height: 30px;
    color: #5b6477;
}

input.track_inputBox::placeholder {
    font-family: Poppins;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477 !important;
}
#root.dark input.track_inputBox::placeholder {
 
    color: #858fa3 !important;
}
textarea.track_inputBox {
    width: 100%;
    padding: 5px 7px 6px 10px;
    border-radius: 5px;
    border: solid 1px #c1ddf4;
    background-color: #e3f2ff;
    color: #5b6477;
}

textarea.track_inputBox::placeholder {
    font-family: Poppins;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477 !important;
}

.Track_input_fields {
    display: flex;
    padding: 8px 34px 8px 20px;
    position: relative;
}

.Track_body_heading {
    display: flex;
    padding: 10px 16px 0;
    align-items: center;
}

.Track_input_fields .removeFile.trackCosts {
    display: block;
    top: 14px !important;
    right: 12px;
}

.fileOpts.delete_opts.trackCosts {
    position: absolute;
    right: -3px;
    top: 12px;
}

input.track_inputBox.cost_title {
    background-color: #e3f2ff;
}

.Track_fields>div:not(:last-child) {
    border-bottom: 1px solid #cde5f9;
}

.costsPopUp .TrackHoursPopUp .Track_fields {
    height: calc(100% - 0px);
    overflow-y: auto;
}

.TrackHoursPopUp.hoursTra .Track_body {
    max-height: calc(100% - 335px);
    overflow-y: auto;
    min-height: calc(100% - 335px);
    box-shadow: 0 0px 14px 0 rgb(0 0 0 / 14%);
}

.Track_body_headding.foot {
    /* border-top: solid 1px #e3f2ff; */
    padding: 10px;
}

.Track_body_headding.foot_middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 28px 0px 20px;
    /* border-bottom: 1px solid #e3f2ff; */
}

/* .Track_footer {
    position: absolute;
    width: 100%;
    bottom: 12px;
} */
.Track_footer {
    padding-top: 5px;
}

.Track_variance_text {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff;
}

.Track_variance_amount {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #318fff;
    /* color: #df1e39; */
}

.Track_variance_amount.err_variance {
    color: #df1e39;
}

.trackCostPopUpCon .closeAdminPanel {
    background-image: url('../../media/images/task_img/backBlue.webp');
    width: 14px;
    height: 12px;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 0px 9px;
}
.closeAdminPanelRoom {
    background-image: url('../../media/images/task_img/backBlue.webp');
    width: 20px;
    height: 25px;
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 0px 0px;
}

.middle_area {
    text-align: center;
    overflow: auto;
}

.overlay-container {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

.overlay-container:hover .edit-icon {
    display: block;
}

.edit-icon,
.overlay-container.editing .edit-icon {
    display: none;
}

.edit-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #94b2ff;
    font-size: 16px;
}

textarea.youtnotesTextArea.forNoteAndDes {
    width: 100% !important;
    border: unset !important;
    padding: 7px 24px 7px 7px;
    height: calc(100vh - 100px);
}

.task_read {
    background-color: #7B40D5;
}

.task_read,
.task_unread {
    width: 9px;
    height: 9px;
    border-radius: 50%;
    display: inline-block;
    margin-left: 8px;
}

.yourNoteIndex.overlay-container:hover .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .12549019607843137);
    transition: background-color .3s ease;
}

.icon_attachment {
    width: 55px;
    height: 25px;
    flex-grow: 0;
    padding: 2px 11px 3px 0;
    border-radius: 45px;
    cursor: pointer;
    display: flex;
    align-items: center;
    grid-gap: 5px;
    gap: 10px;
}

.TrackHoursPopUp .adminRightHead.taskHeader {
    height: 48px;
}

.TrackHoursPopUp span.closeAdminPanel {
    padding-left: 24px;
}

span.backtoProperties {
    font-family: 'Poppins';
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0079d1;
}

.file_attachment_lebel {
    display: flex;
    padding: 10px 0px 12px 20px;
    justify-content: space-between;
    padding-right: 10px;
    color: #318fff;
}

svg.file_Attachment {
    transform: rotate(45deg);
    position: relative;
    left: 4px;
}

span.attach_a_file {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #006ab7;
}

.calenderAdvance.fdate input {
    height: 30px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

input.hours_count.trackTime {
    height: 30px !important;
    margin-top: 0 !important;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
    background-color: #e3f2ff;
}

.Track_input_fields.hoursTrackInner {
    display: block;
}

.Track_input_fields.hoursTrackInner .top_area {
    display: flex;
    align-items: center;
    gap: 11px;
}

.Track_body_headding.hoursTrackHead {
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-bottom: 1px solid #cde5f9;
    padding-bottom: 10px;
}

.Track_footer_upper .Track_col.checked {
    top: 24px;
}

.tasknoteInHours {
    min-height: 30px;
    border-radius: 5px;
    border: solid 1px #c1ddf4;
    color: #000;
    padding: 3px 10px;
    cursor: pointer;
    background-color: #e3f2ff;
}

span.hoursSpan.trackH {
    margin-top: 6px;
}

.Track_footer_upper {
    /* display: flex; */
    display: block;
    align-items: center;
    padding: 0px 20px 0px 20px;
    /* border-top: 1px solid #e3f2ff; */
    border-bottom: 1px solid #e3f2fe;
    gap: 10px;
}

.footer_up {
    display: flex;
    align-items: center;
    gap: 14px;
}

.Track_col.editContant.footer_down {
    width: 100%;
    margin-left: auto;
    /* padding-left: 32px; */
    padding-top: 8px;
    padding-bottom: 15px;
}

.Track_totalHoures_label {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff;
    width: 47%;
}

.Track_totalHoures {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: right;
    color: #318fff;
}

.Track_footer_bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
}

.costsPopUp .Track_body {
    height: calc(100% - 215px);
    box-shadow: 0 0px 14px 0 rgb(0 0 0 / 10%);

}

.costsPopUp .Track_body_hour {
    height: calc(100% - 265px) !important;
    box-shadow: 0 0px 14px 0 rgb(0 0 0 / 10%);

}

.Track_body.mainBody .notFoundRow,
.Track_body .notFoundRow {
    text-align: center;
    position: absolute;
    left: 40%;
    top: 55%;
    font-size: 18px;
}

.file_attachment_lebel .addBtn {
    width: 52px;
    height: 25px;
    flex-grow: 0;
    padding: 4px 13px 3px;
    border-radius: 45px;
    background-color: #318fff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    cursor: pointer;
    margin-left: auto;
}

.Track_input_fields span.task_unread {
    position: absolute;
    left: -1px;
    top: 20px;
    background-color: #032e84;
}

.vsCalenderIcon.htrack {
    position: absolute;
    margin: 7px 0px 0px -23px;
    color: black;
    cursor: pointer;
}

.MuiFormControl-root.MuiTextField-root.calenderAdvance.startDate.fdate input {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    background-color: #e3f2ff;
}

.MuiFormControl-root.MuiTextField-root.calenderAdvance.endDate.fdate input {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    background-color: #e3f2ff;
}

span.clearInput.allnote::before {
    content: "\f057";
    font-family: var(--FA);
    font-weight: 600;
    color: var(--NewRed);
    font-size: 20px;
    position: relative;
    top: -26px;
    left: -5px;
    cursor: pointer;
}

span.clearInput.noteTrackIcon {
    right: 0;
    top: 0;
}

span.clearInput.noteTrackIcon::before {
    content: "\f057";
    font-family: var(--FA);
    font-weight: 600;
    color: var(--NewRed);
    font-size: 20px;
    position: relative;
    top: 3px;
    left: -5px;
    cursor: pointer;
}

span.clearInput.allnote::before {
    content: "\f057";
    font-family: var(--FA);
    font-weight: 600;
    color: var(--NewRed);
    font-size: 20px;
    position: relative;
    top: -27px;
    left: 120px;
    cursor: pointer;
}

span.clearInput.allnote {
    right: 126px;
}

span.clearInput.endDate.fdate {
    display: none;
}

.Track_col.todate:hover span.clearInput.endDate.fdate {
    display: block;
}

span.clearInput.allnote {
    display: none;
}

.Track_col.notesField:hover span.clearInput.allnote {
    display: block;
}

span.clearInput.endDate.fd_bottom {
    display: none;
}

.Track_col.fd_bottom:hover span.clearInput.endDate.fd_bottom {
    display: block;
}

span.clearInput.noteTrackIcon {
    display: none;
}

.Track_col.editContant:hover span.clearInput.noteTrackIcon {
    display: block;
}

.Track_col.checked {
    cursor: pointer;
    position: relative;
    top: 20px;
}

.track_label_first {
    width: 60%;
    width: 60%;
    height: 100%;
    display: flex;
    align-items: center;
}

.Track_body_headding.foot_middle.Track_hours {
    border-top: 1px solid #e3f2ff;
    border-bottom: unset;
}

.tasknoteInHours[data-placeholder]:empty:before {
    content: attr(data-placeholder) !important;
    font-family: 'Poppins';
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477 !important;
}

.Track_col.notesField {
    width: 100%;
    margin-left: 0px;
    padding-top: 8px;
    /* padding-left: 54px; */
}

.Track_footer.fdate_bottom .Track_totalHoures_label {
    font-family: Poppins;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #318fff;
}

.Track_footer.fdate_bottom .addBtn {
    width: 57px;
    height: 25px;
    padding: 4px 13px 3px;
    border-radius: 45px;
    background-color: #318fff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 20px;
}

.hrsAddBtn {
    width: 57px;
    height: 25px;
    padding: 4px 15px 3px;
    border-radius: 45px;
    background-color: #318fff;
    font-family: Poppins;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #fff;
    cursor: pointer;
    right: 20px;
    margin-bottom: 5px;
}

.verTiclePipeLine {
    width: 1px;
    background-color: #0d2150;
    height: 16px;
    position: unset;
    right: unset;
    top: unset;
}

.taskHeader_right_area span.closeModal {
    background-color: #0c1e47;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    background-image: url(../../media/images/close_button_w.svg);
    background-size: 10px;
    background-position: 50%;
    right: unset;
    top: 0 !important;
    position: relative;
    border: 2px solid #fff;
}

.taskCreateCloseIcon {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    background-image: url(../../media/images/task_closeIcon.webp);
    background-size: 20px;
    background-position: 50%;
    background-repeat: no-repeat;
    right: unset;
    top: 0 !important;
    position: relative;
    border: 1px solid #fff;
}

.task_body_area.disabled {
    pointer-events: none;
    opacity: .1;
}

.collapse_icon_chat {
    background-image: url(../../media/images/task_img/collapIcon.webp);
    left: 247px;
}

.taskChatExpand {
    position: fixed;
    width: 550px;
    top: 0px;
    background: white;
    height: calc(100vh - 0px);
    z-index: 0;
}

.add_entryText {
    font-family: Poppins;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0079d1;
    padding-left: 0px;
    padding-bottom: 16px;
}

.infoHourCircle span.info_tooltip_new.hoursInfo {
    margin-left: 0px;
    margin-top: 0;
    width: 16px;
    background-size: 16px;
    cursor: pointer;
    position: relative;
    top: 0;
}

.Track_input_fields.hoursTrackInner span.info_tooltip_new.hoursInfo {
    top: 20px;
}

.TrackHoursPopUp .Track_footer .Track_input_fields {
    padding-top: 0;
}

.TrackHoursPopUp .Track_footer .add_entryText {
    padding-left: 20px;
}

.Track_footer_upper .add_entryText {
    padding-left: 0px !important;
}

.costsPopUp .TrackHoursPopUp {
    
}

.Track_body.mainBody {
    order: 2;
    transition: transform ease-in 0.3s;
    transition-delay: 1s;
}

.footerBottom.mainFooterBottom {
    order: 3;
    transition: transform ease-in 0.3s;
    transition-delay: 1s;
}

.TrackHoursPopUp.hoursTra {
    display: flex;
    flex-direction: column;
}

.TrackHoursPopUp.hoursTra .Track_body.bodyArea {
    transition: transform ease-in 0.3s;
    transition-delay: 1s;
    order: 2
}

.Track_footer.fdate_bottom.Track_f_bottom {
    transition: transform ease-in 0.3s;
    transition-delay: 1s;
    order: 1;
}

.Track_footer_bottom.Track_f_last {
    transition: transform ease-in 0.3s;
    transition-delay: 1s;
    order: 3;
}

#root.dark .searchAndFilterBar.LeftSide {
    border-left: unset;
}

#root.dark .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: #d8d8d8;
}

#root.dark .status_area p.projectTitleRoom {
    color: #0b1f47;
}

#root.dark .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
    color: #fff
}

#root.dark .notes_label {
    color: #fff
}

#root.dark .creator_area span.nameLetters {
    background-color: #0c1e47 !important;
    color: #ffffff !important;
}

#root.dark .creator_area .AddKeywordsPopup {
    background-color: #fff;
}

#root.dark div#youtnotes {
    background-color: #d8d8d8 !important;
}

#root.dark .TaskTable .notFound {
    color: #ffffff !important;
}

#root.dark .user_msg p.task_Title {
    color: #ffffff !important;
}

#root.dark .TaskBody {
    color: #fff;
    border-right: unset !important;
    border-left: unset !important;
}

#root.dark .TaskHeading {
    border-right: unset !important;
    border-left: unset !important;
}

#root.dark .checklistAction .opts.edit_opts {
    background-image: url('../../media/images/icons/whiteTheme/SVG/edit_pen.svg') !important;

}

#root.dark .checklistAction .opts_show:hover {
    background-color: transparent !important;
}

#root.dark .select-ecosystem.createTaskSelect .css-1uccc91-singleValue {
    color: #fff;
}

.select-ecosystem.createTaskSelect.isDisable.selectForUpdated .css-1uccc91-singleValue {
    font-size: 16px;
}

.fileBody_area.taskListBody_area::-webkit-scrollbar {
    width: 10px;
}

.fileBody_area.taskListBody_area::-webkit-scrollbar-thumb {
    background-color: #9b9b9b;
    /* Change the color to your desired color */
}

.fileBody_area.taskListBody_area::-webkit-scrollbar-track {
    background-color: #f1f1f1;
    /* Change the track color to your desired color */
}

.select-ecosystem.createTaskSelect.isDisable.selectForUpdated .css-1e70mot-control {

    padding-left: 8px;
}

.TrackHoursPopUp.hoursTrack {
    height: 425px;
    width: 800px;
}

.Track_footer.fdate_bottom {
    border-top: 1px solid #e3f2ff;
}

.Track_col.td_bottom:hover span.clearInput.endDate.fd_bottom {
    display: block;
}

.Track_col.formdate span.clearInput.endDate {
    display: none;
}

.Track_col.formdate:hover span.clearInput.endDate {
    display: block;
}

.select-ecosystem.createTaskSelect.selectForUpdated .css-1szd4mj-control .css-1uccc91-singleValue {
    font-size: 16px;
    padding-left: 10px;
    line-height: 20px;
    color: #0c1e47 !important;
}

.select-ecosystem.createTaskSelect.selectForUpdated .css-1szd4mj-control {
    /* max-height: 25px !important; */
    box-shadow: none !important;
}

.select-ecosystem.createTaskSelect.selectForUpdated .css-1szd4mj-control .css-1hnd8od-ValueContainer {
    padding-left: 0px;
}

.select-ecosystem.createTaskSelect.selectForUpdated>div {
    border-color: #d6d6d600;
}

.select-ecosystem.createTaskSelect.selectForUpdated .css-1hb7zxy-IndicatorsContainer {
    display: none;
}

.select-ecosystem.createTaskSelect.selectForUpdated .css-1szd4mj-control .css-1hb7zxy-IndicatorsContainer {
    display: block;
}

.sender_img.nameL {
    display: flex;
    margin-right: 5px;
}

.sender_details_pro {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
}

.userAssign_div:nth-child(2) {
    margin-left: auto;
    text-align: right;
}

.toggle-button-cover {
    text-align: left;
    position: relative;
    top: 3px;
    left: 38px;
}

.userAssign_div .assigneeMem div {
    text-align: left;
}

.assingeetrim {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.assingeetrim>div {
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: space-between; */
    width: 100%;
}

.right-inner-addon.input-container span.clearAllSrcTag {
    display: none;
}

.right-inner-addon.input-container:hover span.clearAllSrcTag {
    display: block;
}

.right-inner-addon.input-container span.clearAllSrcTag:before {
    font-size: 15px;
}

.TaskTable.taskTotalArea {
    transition: width 0.5s ease;
}

span.checkList_plusIcon {
    position: absolute;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
}

span.checkList_plusIcon:hover i.fa.fa-plus.custom_icon {
    color: #318fff;
}

span.checkList_plusIcon i.fa.fa-plus.custom_icon {
    font-size: 18px;
    color: #023d67;
}

/* .yourNotes.notesDisabled {
    pointer-events: none;
} */
.taskDescribe.isDisable {
    pointer-events: none;
}

p.notFound {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 90vh;
    color: #0c1e47;
    font-size: 22px;
    font-weight: 600;
}

#root.dark p.notFound {
    color: #ffffff;
}

.taskmambers .assingeetrim>div {
    margin-left: 18px;
}

.toggle-button-cover.will_disable {
    pointer-events: none;
}

.toggle-button-cover.will_disable .cm-toggle:checked:after {
    background: #d8d8d8;
}

span.adminUserFilter.forChecklist {
    pointer-events: auto;
    left: 5px;
    top: 10px;
}

span.ChecklistHead {
    margin-left: 20px;
}


.userActionPopup,
.userActionPopup li {
    list-style: none;
    box-sizing: border-box;
}

ul.userActionPopup.showAll.checklistPopup {
    position: absolute;
    left: 5px;
    z-index: 99;
    margin-top: 0px;
}

.userActionPopup,
.userActionPopup li {
    list-style: none;
    box-sizing: border-box;
}

.userActionPopup {
    height: auto;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 0 1px 0 rgba(10, 31, 68, .08), 0 8px 10px 0 rgba(10, 31, 68, .1);
    border: none;
}

.userActionPopup li {
    display: block;
    overflow: hidden;
    width: 100%;
    color: #53627c;
    color: #0c1e47;
    font-family: var(--F1);
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    padding: 8px 16px;
    font-weight: 400 !important;
    text-align: center;
}

ul.userActionPopup.showAll.checklistPopup li.active {
    background-color: #ECEEF4;
    color: #0c1e47;
}

.userActionPopup li:hover {
    background-color: #ECEEF4;
    color: #0c1e47;
}

.forKanbanView.filtered {
    position: relative;
}

#root.dark .filterKanbanIcon,
.filterKanbanIcon {
    width: 30px;
    height: 26px;
    border: 2px solid transparent;
    margin: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 50%;
    display: flex;
    right: 0;
    top: 0;
    pointer-events: auto;
}

.filterKanbanIcon {
    background-image: url(../../media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_filter2.svg);
    background-size: 20px;
}

#root.dark .filterKanbanIcon {
    background-image: url(../../media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_filter2.svg);
    background-size: 20px;
}

.filterKanbanIconTask {
    width: 30px;
    height: 26px;
    border: 2px solid transparent;
    margin: 0;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: 50%;
    display: flex;
    right: 0;
    top: 0;
    pointer-events: auto;
    background-image: url(../../media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_filter2.svg);
    background-size: 15px;
}

#root.dark .filterKanbanIconTask {
    background-image: url(../../media/images/icons/blackTheme/SVG/WorkFreeli_Icons_White_filter2.svg);
    background-size: 15px;
}
#root.dark .msgHoverArea .fullHoverDiv{
background-color: #181818 !important;

}
#root.dark .msgHoverArea .fullHoverDiv{
    background-color: #181818  !important;
    border: 1px solid #f3f3f305 !important;
}
#root.dark .msgHoverArea {
    background-color: #181818 !important;
    box-shadow: #1d1f2600 0px 10px 36px 0px, #424242 0px 0px 0px 1px;
}
#root.dark .msgHoverArea .hidden{
    background-color: #181818 !important;
}
.filteredPopup_div .filterActionPopupKanban {
    left: -75px;
    top: 30px;
    padding-bottom: 5px;
    width: 220px;
}

.filterActionPopupKanban {
    width: 310px;
    height: auto;
    position: absolute;
    box-sizing: border-box;
    padding: 0;
    border-radius: 12px;
    left: 360px;
    top: 370px;
    background-color: #ffffff;
    box-shadow: 0 1px 60px 0 rgba(0, 0, 0, 0.1);
    cursor: context-menu;
    border: solid 1px #e3f2ff;
    background-color: #fff;
    z-index: 999;
}

.filteredPopup_div ul.filterActionList li:hover {
    background-color: #ECEEF4;
}

.pinnedIcon {
    background-image: url('../../media/images/icons/blackTheme/SVG/WorkFreeli_Icons_Blue_unpinned.svg');
    /* background-image: url(/media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_unpinned.svg); */
    /* background-position-y: 50%;
    background-size: 16px;
    background-position-x: 8px; */
}

span.info_tooltip_new.hoursInfo {
    margin-left: 0px;
    margin-top: 0;
    width: 16px;
    background-size: 16px;
    cursor: pointer;
    position: relative;
    top: 20px;
}

/*task fulsearch area*/
.task_fulsearch_area {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e0e0e0;
    /* align-items: center; */
}

.task_fulsearch_left {
    width: 90%;
    border-right: 1px solid #e0e0e0;
}

/*Modify task properties component*/
.task_body_left.quickShowTask {
    width: 100%;
}

.statuAndProgress_area {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-top: 1px solid #e3f2fe; */
    padding-top: 10px;
    width: 100%;
    gap: 20px;
    padding: 0 22px;
}

.statuAndProgress_area .status_area {
    width: 50%;
}

.statuAndProgress_area .date_area {
    width: 50%;
    margin-top: 8px;
}

.statuAndProgress_area .dropdown .dropdown-header.forProgress {
    height: 26px;
}

/* .statuAndProgress_area .dropdown .dropdown-header.forProgress .icon {
    transform: rotate(180deg);
} */

.statuAndProgress_area .status_area select {
    width: 100%;
}

.first_task_area .date_area {
    width: 38%;
}

.cretorAndAssignee_Div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.cretorAndAssignee_Div .creator_area {
    width: 50%;
    gap: 10px;
}

.onserversAndReview_Div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.onserversAndReview_Div p.creatorName {
    font-size: 14px !important;
}

.onserversAndReview_Div p.created_by {
    font-size: 12px !important;
}

.onserversAndReview_Div .creator_area {
    width: 50%;
    gap: 10px;
}

.creator_area.rightArea {
    border-left: solid 1px #e3f2fe;
}

.secound_section.quickTask {
    height: auto;
    display: block;
    width: 100%;
    margin-top: 0;
}

.secound_section.quickTask .left_part {
    width: 100%;
    border: unset !important;
}

.secound_section.quickTask .right_part {
    width: 100%;
}

.trackCostPopUpCon {
    width: 550px;
    background-color: #ffffff;
    float: right;
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 999;
    /* box-shadow: 0 4px 114px 0 rgba(0, 0, 0, 0.75); */
}

.task_right_container.forQuickViewTask {
    width: 550px;
    background-color: #ffffff;
    float: right;
    position: fixed;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 999;
    box-shadow: 0 4px 114px 0 rgba(0, 0, 0, 0.75);
}

.addselectKeywords {
    width: 58px;
    height: 24px;
    border-radius: 4px;
    background-color: #f1f6fa;
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
}

.addselectKeywordsdiv {
    height: 24px;
    border-radius: 4px;
    background-color: #f1f6fa;
    display: flex;
    align-items: center;
    grid-gap: 2px;
    gap: 2px;
}

.cretorAndAssignee_Div .creator_area .creator_label {
    font-size: 14px;
    width: auto !important;
}

.onserversAndReview_Div .creator_area .creator_label {
    font-size: 14px;
    width: auto !important;
}

.cretorAndAssignee_Div .sender_img.nameL span.nameLetters {
    width: 27px !important;
    height: 27px !important;
    font-size: 12px;
}

.task_body_left.quickShowTask .notes_label_area p {
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    font-weight: 400 !important;
    cursor: pointer;
}

textarea.youtnotesTextArea {
    width: 100% !important;
    border: 1px solid #d9e2e8;
    padding: 7px 24px 7px 7px;
}

.yourNotes .voiceIcon1MsgBox {
    left: 498px;
    top: 43px;
    margin: 0;
    bottom: 0;
    height: 26px;
    z-index: 9 !important;
}

.task_body_left.quickShowTask .notes_label_area {
    display: flex;
    align-items: center;
    height: 42px;
    cursor: pointer;
    gap: 32px;
}

span.downArrowSpan {
    cursor: pointer;
}

.secound_section.quickTask .notes_label_area {
    padding-left: 20px;
    padding-right: 20px;
}

.track_hoursArea {
    border-bottom: 1px solid #e3f2ff;
}

.secound_section.quickTask .file_counter_last_area {
    margin-left: 0;
}

.secound_section.quickTask .file_counter {
    display: flex;
    align-items: center;
    gap: 2px;
}

.right_part.fileQuickTask .track_area {
    gap: 5px;
}

.right_part.fileQuickTask .file_label {
    display: flex;
    gap: 10px;
    align-items: center;
}

.right_part.fileQuickTask span.downArrowSpan {
    margin-left: auto;
}

.right_part.fileQuickTask .track_area {
    grid-gap: 5px;
    gap: 5px;
    border: unset;
    cursor: pointer;
}

.task_right_container.forQuickViewTask div#task_body_container {
    width: 100%;
    display: flex;
    grid-gap: 25px;
    gap: 25px;
    padding-top: 5px;
    padding-right: 0px;
    padding-left: 0;
}

.task_body_left.quickShowTask span.ChecklistHead {
    margin-left: 35px;
    cursor: pointer;
    font-weight: 400;
}

.task_body_left.quickShowTask span.adminUserFilter.forChecklist {
    pointer-events: auto;
    left: 18px;
    top: 10px;
}

.task_body_left.quickShowTask .checkList_fr_left.taskCount {
    margin-left: 0;
    order: unset;
}

.task_body_left.quickShowTask .checkList_head_text {
    width: 25% !important;
}

.task_body_left.quickShowTask .checkList_area.taskCheckListDiv {
    height: auto;
}

.checkList_body {
    height: 334px;
}

.task_body_left.quickShowTask .checkList_first_row {
    border-bottom: unset;
    height: 42px;
}

.task_body_left.quickShowTask .task_describe_section {
    border-radius: 5px;
    box-shadow: unset;
    border: unset;
    background-color: #fff;
    padding: 20px;
    margin-top: 0;
    position: relative;
    padding-top: 0px;
    padding-bottom: 10px;
}

.secound_section.quickTask .right_part {
    width: 100%;
    border-top: unset;
    border-right: unset;
    border-left: unset;
}

.task_body_left.quickShowTask .checklist_section.inTaskList {
    margin-top: 0;
}

.secound_section.quickTask .form_full {
    padding-top: 0;
}

.task_body_left.quickShowTask .task_chat_section {
    margin-top: 0;
    /* min-height: 150px; */
    /* min-height: 250px; */
    min-height: calc(100vh - 595px) !important;
}

.task_body_left.quickShowTask .yourNotes {
    padding-bottom: 0px;
    padding-top: 0px;
}

.task_body_left.quickShowTask .yourNotes #youtnotes {
    border: solid 1px #c1ddf4;
    margin-top: 0;
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    word-wrap: break-word;
    padding-right: 40px;
    cursor: pointer;
    margin-bottom: 10px;
    /* min-height: 60px; */
}

.task_body_left.quickShowTask .first_section {
    box-shadow: unset;
    border-right: unset !important;
}

/* .taskMemberBottom {
    margin-top: 15px;
} */
span.showAllOption {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    height: 35px;
    cursor: pointer;
}

input.taskTitle.TitleForUpdated:focus {
    outline: 0;
    /* border-color:unset!important; */
    box-shadow: none !important;
}

.userActionPopup li:hover a {
    color: #fff;
}

.task_right_container.forQuickViewTask span.closeAdminPanel {
    background-image: url('../../media/images/task_img/backIconNew.svg');
    width: 14px;
    height: 12px;
    background-size: 14px;
    background-position: 0px 9px;
}

span.taskSettingsTextClose.taskSettings::before {
    content: " ";
    background-image: url(../../media/images/settings_blue.svg);
    background-size: 18px;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 17px;
    height: 17px;
    position: absolute;
    left: 428px;
    top: 22px;
}

span.taskSettingsTextClose.taskSettings {
    border: unset;
    background: none;
    font-size: 14px;
}

span.taskSettingsTextClose.taskSettings:hover {
    border: unset;
    background-color: unset;
    color: #318fff;
}

.task_name_title_area {
    position: relative;
    display: flex;
    gap: 20px;
}

.task_name_title.editable.active {
    border: 1px solid #858fa3;
    border-radius: 4px;
    padding: 2px 7px;
}

.fullwithProgress {
    width: 100%;
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.Track_body {
    height: auto;
}

.task_fulsearch_right {
    padding-right: 15px;
}

.creator_area.rightArea .AddKeywordsPopup span.keyword_Color {
    height: 23px;
}

.TaskSerachListSelected {
    color: rgb(255, 255, 255);
    cursor: pointer;
    position: relative;
    top: 0px;
    margin-left: 5px;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 50%;
    padding: 2px;
}

.task-room-name {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.conversation_name_label {
    position: relative;
}

.taskRoomTitleLabel {
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0079d1;
    padding-bottom: 5px;
    width: auto;
}

.task-room-name {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}

.taskTitle_div {
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
    width: 100%;
    position: relative;
}

p.titleHeading {
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #0b1f47;
}

.custom_dropdown-body-task-open {
    position: relative;
    top: 0;
    display: block;
    width: 100%;
    background-color: #fff;
    z-index: 99;
}

.creator_label.inlineText {
    display: flex;
    grid-gap: 3px;
    gap: 3px;
    font-style: normal;
    font-size: 12px;
    color: #1d1f26 !important;
    margin-left: 0;
}

.creator_label,
p.creatorName {
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}

span.exportIcon {
    width: 20px;
    height: 20px;
    cursor: pointer;
    /* background-image: url(../../media/images/export.webp); */
    background-repeat: no-repeat;
    background-size: 18px;
    background-position: 50% 50%;
    display: block;
    position: absolute;
    right: 10px;
    top: 10px;
}

.right_container1 {
    /* width: calc(100% - 346px); */
    height: 100vh;
    float: left;
    background-color: #fff;
    position: relative;
    overflow: hidden;
    transition: width .4s;
}

span.removeFile.closeBtn {
    display: block;
    top: 5px;
    order: 2;
    margin-left: auto;
    right: 2px;
}

.removeFile {
    background-image: url("../../media/images/Remove.svg");
    width: 18px;
    height: 18px;
    box-sizing: border-box;
    background-repeat: no-repeat;
    border: 2px solid red;
    border-radius: 100%;
    background-position-x: 50%;
    background-position-y: 50%;
    background-size: 10px;
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
    display: none;
}

.selected_close_row {
    padding: 3px;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    border-bottom: 1px solid #e3f2ff;
    padding-top: 5px;
}

.taskObserverArea .selected_label {
    color: #318fff;
    padding-left: 15px;
    font-size: 14px;
}

.selectedObserversList {
    padding: 0px 0px;
}

.searchAndFilterBar.inObserver_inner li.observerIndivisual {
    list-style: none;
    /* background-color: #0b1f47; */
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    color: #0b1f47;
    padding: 3px;
    justify-content: space-between;
    /* height: 35px; */
    font-size: 14px;
    border-bottom: 1px solid #e3f2ff;
    padding: 0px 8px;
}

.searchAndFilterBar.inObserver_inner li.observerIndivisual .observer_user_name {
    padding: 0px 11px;
}

/*Media query*/
@media screen and (max-width: 1199) and (min-width: 768) {
    .taskHeadSection.topHead {
        display: flex;
        height: auto !important;

    }

}

@media screen and (max-width: 1440px) and (min-width: 1200px) {
    .update_the_following_tasks {
        font-size: 12px;
    }

    .tsks_hours_status div {
        font-size: 12px;
        font-weight: 600;
        line-height: 1;
    }

    .task_update_item_status {
        gap: 10px;
    }

    .workloadReaportBtn {
        font-size: 14px;
    }

    .task_update_text {
        padding: 8px 10px 8px 8px;
    }

    span.task_update_head {
        font-size: 10px;
    }

    .insights_work_right.insights_work_item {
        width: 37% !important;
    }

    .workload_hours {
        font-size: 20px !important;

    }

    .task_body_right {
        width: 38% !important;
    }

    .yourProgress.taskProgress {
        width: 24% !important;
        padding-left: 10px !important;
    }

    .progress_bar.taskBar {
        width: 36%;
    }

    .fileBody_area.taskListBody_area.prime_search {
        /* height: unset; */
        height: calc(100vh - 370px);
        /* height: calc(100vh - 460px); */

    }

}

@media screen and (min-width: 500px) and (max-width: 1199px) {
    .dueTime {
        width: 100% !important;
    }

    .task_dashboard {
        display: block !important;
    }

    .insights_work_load_area {
        display: block !important;
    }

    .keyword_search_area {
        width: 100% !important;
    }

    .insights_work_detail {
        width: 100% !important;
        margin-top: 20px !important;
    }

    .new_area {
        display: block !important;
    }

    .flagIcom_area {
        display: block;
    }

    .keywords_area {
        margin-top: 10px;
    }

}

p.checklist_status_area {
    height: 51px;
    padding: 0 50px;
    background-color: #f6fbfe;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #2c56ac;
}
p.checklist_status_area span.unreadCounter.colorChange {
    margin-left: 5px;
    background-color: #002e98;
    color: #fff;
    font-weight: 700;
    font-family: "Rubik"!important;
    font-weight: 400;
}
span.unreadCounter {
    height: 24px;
    width: 24px;
    background-color: #7b40d5;
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
}
.checklist_status_area {
    padding-left: 20px;
    border-bottom: 1px solid #e5eef4;
    font-size: 16px;
}
.checkLists_area.taskCheckList p.notFoundData {
    margin: 10px!important;
}
p.notFoundData {
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin-top: 100px;
}
p.checklist_status_area span.unreadCounter.colorChange {
    margin-left: 5px;
    background-color: #002e98;
    color: #fff;
    font-weight: 700;
    font-family: "Rubik"!important;
    font-weight: 400;
}

.taskListAdvance .react-datepicker-popper[data-placement^=bottom] {
    inset: 0px auto auto 0px !important;
}

.react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover{
        border-radius: 25px;
        color: #ffffff;
    
}
.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range{
    border-radius: 25px;
    color: #ffffff;
}
.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range, .react-datepicker__month-text--in-selecting-range, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__year-text--in-selecting-range){
    background-color: #1d5d90 !important;
    color: #ffffff !important;
}
.react-datepicker__day--selected {
    background-color: #318fff !important;
    border-radius: 25px;
    color: #ffffff;
}
.react-datepicker__day--selected:hover {
    background-color: #318fff !important;
    border-radius: 25px;
    color: #ffffff;
}
#root.dark .right_container_task {
    background-color: #181818 !important;
}
#root.dark .taskTopHead button svg{
    color: #ffffff;
}
#root.dark ul.userActionPopup.showAll.checklistPopup li.active {
    background-color: #eceef436 !important;
    color: #ffffff;
}
#root.dark .taskLoadBfrore{
    background-color: #181818e3 !important;
}
#root.dark .userActionPopup li:hover{
    background-color: #eceef436 !important;
    color: #ffffff;
}
#root.dark .task_body_left.quickShowTask .taskTitle_div p {
    color: #ffffff !important;
}
#root.dark .taskTop, #root.dark .creator_label.inlineText {
    color: #ffffff !important;
}
#root.dark div#chatHere svg{
    color: #ffffff !important;
}
svg.taskCloseIcon{
    color: #0b1f47;
}
#root.dark svg.taskCloseIcon{
    color: #ffffff !important;
}
#root.dark .trackCostPopUpCon {

    background-color: #181818;
}

#root.dark textarea.youtnotesTextArea.forNoteAndDes {
    background-color: #181818;
    border: 1px solid #424242 !important;
    color: #fff;
}
#root.dark .lastReviewed_History{
   
    color: #fff;
}
#root.dark .yourNoteIndex svg{
right: 5px !important;
}
#root.dark .Track_body.bodyArea .mediaContainer .fileContent {
    background-color: #181818 !important;
}
.assignedToLabel:hover {
    border: 1px solid #0d2150;
}