.CalendarNavMain {
    /* position: relative;   */
    height: auto;
    background-color: #0b1f47;
    color: white;
    display: block;
    margin-left: auto;
    border-left: 0.1px solid #a0d2fd33;
    font-size: 14px;
}

.cal_nav_top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 0.1px solid #a0d2fd33;
    padding: 15px 25px;
}

.calendar_input_main {
    padding: 5px 15px;
    border-radius: 90px;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 250px;
}

.cal_nav_input {
    background-color: transparent;
    border: none;
    outline: none;
    font-family: Poppins;
    font-size: 12px;
    color: whitesmoke;
}

.cal_nav_bottom {
    /* position: relative; */
    display: flex;
    justify-content: center;
}

.cal_nav_bottom>div {
    padding: 6px 10px;
}

.cal_nav_bottom>div:nth-child(2) {
    border-left: 0.1px solid #a0d2fd33;
}

.cal_nav_bottom_child {
    background-color: rgba(227, 242, 255, 0.08);
    padding: 5px;
    border-radius: 90px;
    max-width: 400px;
    /* margin: 0 auto; */
}

.cal_nav_bottom_child_left>div {
    background-color: #318fff;
    border-radius: 90px;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 3px;
    padding: 6px 12px;
    cursor: pointer;
}

.cal_nav_bottom_child_right {
    color: #0b1f47;
    display: flex;
    gap: 15px;
    justify-content: space-between;
}

.cal_nav_bottom_child_right>div {
    border-radius: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 6px 12px;
    cursor: pointer;
}

.cal_nav_bottom_child_right>div:nth-child(1) {
    background-color: #e3f2ff;
}

.cal_nav_bottom_child_right>div:nth-child(2) {
    background-color: #8b57db
}

.cal_nav_bottom_child_right>div:nth-child(3) {
    background-color: #fce053
}

.calendar_bottom_main {
    /* position: relative; */
    height: calc(100% - 115px);
    /* height: fit-content; */
    padding: 20px 15px;
    display: flex;
    /* align-items: center; */
    gap: 20px;
    overflow-y: auto;
}

.calendar_left {
    width: 35%;
}

.calendar_right {
    width: 65%;
    /* overflow: auto; */
    /* height: 100%; */
    /* background-color: #64a6f4; */
}

.calendar_myChat {
    border-radius: 5px;
    background-color: #8b57db26;
    height: 400px;
    overflow: hidden;
}

.calendar_myChat>div:nth-child(2) {
    padding: 20px 0;
    height: calc(100% - 43px);
    overflow-y: scroll;
}

.calendar_right .label {
    border-radius: 5px 5px 0 0;
    padding: 5px 10px;
    background-color: #8b57db;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.label_left {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
}

.label_left>div:nth-child(2) {
    padding: 5px 15px;
    border-radius: 100px;
    background-color: #0b1f4766;
}

.label_left>div:nth-child(3) {
    padding: 5px 15px;
    border-radius: 100px;
    border: 1px solid #732be266;
}

.calendar_myChat>div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.singleChat_main {
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.singleChat_left {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
}

.singleChat_left>div:nth-child(1) {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ffaf4c;
    color: white;
    font-size: 32px;
    font-weight: bold;
}

.singleChat_left>div:nth-child(2)>.bottom_line {
    font-size: 12px;
    color: #5b6477;
}

.singleChat_left>div:nth-child(2)>p:nth-child(1) {
    font-size: 14px;
    color: #0b1f47;
}

.attachment_main {
    padding: 10px 0;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.attachment_single {
    background-color: #fff;
    width: fit-content;
    display: flex;
    gap: 6px;
    padding: 5px 15px 5px 5px;
    /* border: 1px solid #a0d2fd66; */
    border-radius: 10px;
    box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 2px 1px 0px rgba(0, 0, 0, 0.2);
}

.attachment_single>div>p:nth-child(1) {
    font-size: 14px;
    color: #0b1f47;
}

.attachment_single>div>p:nth-child(2) {
    font-size: 12px;
    color: #5b6477;
}

/* TaskOverview */
.taskOverview_Main {
    margin-top: 35px;
    overflow: hidden;
    border: 1px solid #a0d2fd66;
    border-left: 3px solid #318fff66;
}

.taskOverview_Nav {
    background-color: #e3f2ff;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.taskOverview_Nav_left {
    color: #0a256a;
    display: flex;
    gap: 5px;
    align-items: center;
}

.taskOverview_Nav_left>p {
    font-size: 16px;
}

.taskOverview_Nav_right {
    display: flex;
    gap: 5px;
}

.taskOverview_Nav_right>div {
    font-size: 14px;
    padding: 5px 10px;
    width: fit-content;
    border-radius: 20px;
}


.taskOverview_Nav_right>div:nth-child(1) {
    border: solid 1px #032e84;
    color: #032e84;
}

.taskOverview_Nav_right>div:nth-child(2) {
    border: solid 1px #a6d068;
    background-color: #a6d068;
    color: white;
    display: flex;
    align-items: center;
    gap: 4px;
}

.create_task_input {
    margin: 15px 5px 5px 5px;
    padding: 5px 20px;
    border-radius: 45px;
    border: solid 1px #a0d2fd;
    background-color: #f1f6fa;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #64a6f4;
}

.create_task_input>input {
    width: 100%;
    border: none;
    padding: 6px 0;
    font-size: 14px;
    background-color: #f1f6fa;
}

.taskOverview_filter {
    padding: 6px 10px;
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #318fff;
    margin-bottom: 5px;
}

.taskOverview_filter>div {
    padding: 4px 10px;
    color: #318fff;
    cursor: pointer;
}

.taskOverview_filter .activeTab {
    color: white;
    background-color: #318fff;
    border-radius: 25px;
}

.TaskCardsContainer {
    padding: 0 10px 20px 10px;
    height: 300px;
    overflow-y: scroll;
}

.taskCardMain {
    padding: 10px 20px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    margin-top: 10px;
    background-color: #F2F4F4;
}

.taskCard_left {
    display: flex;
    align-items: center;
    gap: 25px;
}

.taskCard_text>p:nth-child(1) {
    color: #0b1f47
}

.taskCard_text>p:nth-child(2) {
    color: #5b6477;
    font-weight: 300;
}

.taskCard_right {
    display: flex;
    align-items: center;
    gap: 15px;
}

.taskCard_right>div {
    cursor: pointer;
}

.taskCard_right>div:nth-child(1) {
    color: #732be2;
}

/* Files */
.files_Nav_center {
    padding: 0 6px;
    display: flex;
    gap: 8px;
    font-size: 12px;
    font-weight: 300;
}

.files_Nav_center>div {
    display: flex;
    align-items: center;
    gap: 8px;
    border-right: 1px solid #a0d2fd66;
    border-left: 1px solid #a0d2fd66;
    padding: 0 4px;
}

.file_container {
    padding: 6px;
    background-color: #f1f6fa;
}

/* Full Calendar */
.fullcal_layout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 20;
}

.calendarUI {
    width: 70%;
}

.cal_events_container {
    width: 30%;
    background-color: #a0d2fd66;
    border-radius: 10px;
    padding: 6px 0px 6px 5px;
    height: 100%;
    overflow-y: scroll;
}

.full_cal_nav>div {
    font-size: 14px;
    padding: 2px 6px;
    width: fit-content;
    cursor: pointer;
    border-radius: 25px;
    gap: 5px;
    border: 0.5px solid #8b57db;
    /* background-color: #732be266; */
    font-weight: 500;
}

.cal_close_btn {
    background-color: #732be2 !important;
    padding: 4px 12px !important;
    color: #fff;
}

.cal_nav_logo_bg {
    padding: 2px 3px;
    background-color: #fff;
    border-radius: 50%;
}

.fc-toolbar-title {
    font-size: 20px !important;
    color: #0a256a;
}

.fc-button {
    min-width: 32px;
    height: 32px;
    padding: 1px !important;
}

.fc-header-toolbar {
    margin-bottom: 15px !important;
}

.fc-col-header-cell-cushion {
    font-size: 14px;
}

.full_cal_nav {
    background-color: #732be266;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    padding: 6px 15px;
}

.fc-timegrid-slot-label-cushion {
    font-size: 14px;
}

.fc-timegrid-axis-cushion {
    font-size: 14px;
}

.cal_events_container>div:nth-child(1) {
    color: #0b1f47;
    text-align: center;
    padding: 6px 0;
    font-weight: 600;
    font-size: 18px;
    position: sticky;
}

.cal_events_container>div:nth-child(2) {
    overflow-y: scroll;
    height: 550px;
}

/* DARK MODE CSS */
#root.dark .calendar_bottom_main {
    color: white;
}

#root.dark .singleChat_left>div:nth-child(2)>p:nth-child(1) {
    color: #fff;
}

#root.dark .create_task_input {
    background-color: transparent;
    color: white;
}

#root.dark .create_task_input>input {
    background-color: transparent;
    color: white;
}

#root.dark .taskCard_text>p:nth-child(1) {
    color: #fff
}

#root.dark .taskCardMain {
    background-color: #e3f2ff0D;
}

#root.dark .fc-col-header-cell-cushion {
    color: #fce053;
}

#root.dark .fc-theme-standard .fc-scrollgrid {
    border: 1px solid;
}

#root.dark .fullcal_layout {
    background-color: #181818;
}

#root.dark .fc-toolbar-title {
    color: #fafafa;
}

#root.dark .cal_events_container>div:nth-child(1) {
    color: white;
}

#root.dark .cal_events_container {
    background-color: #131313;
}