@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*call popup css*/

.videoCallMenuPopup {
  overflow: hidden;
  z-index: 9;
  box-sizing: border-box;
  height: auto;
  width: 306px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(10 31 68 / 8%), 0 8px 10px 0 rgb(10 31 68 / 10%);
  position: absolute;
  padding: 8px;
  top: 80px;
  right: 40px;
}

.call-advance-option-div {
  display: block;
  min-height: 98px;
  border-radius: 4px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgb(10 31 68 / 8%), 0 3px 4px 0 rgb(10 31 68 / 10%);
  box-sizing: border-box;
}

.call-advance-option-div .call-users-count {
  /* sujon */
  color: #8a94a6;
  font-family: var(--F1);
  font-size: 11px;
  font-weight: normal;
  line-height: 16px;
  margin: 5px 0px 0px 5px;
  /* text-transform: uppercase; */
}

.call-create-btn {
  height: 37px;
  width: 130px;
  border-radius: 4px;
  /* background-color: var(--PrimaryC); */
  background-color: #0b1f47 !important;
  color: #ffffff;
  font-family: var(--F1);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 21px;
  border: none;
  cursor: pointer;
  margin-left: 5px;
}

.call-create-btn:hover {
  background-color: #0d2150;
}

.call-cancel-btn {
  box-sizing: border-box;
  height: 38px;
  width: 100px;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  color: #9b9b9b;
  font-family: var(--F1);
  font-size: 16px;
  font-weight: normal;
  letter-spacing: 0.2px;
  line-height: 21px;
  cursor: pointer;
  background-color: transparent;
  transition: all 0.5s ease;
}

.todo-btn-group {
  float: right;
  margin-top: 16px;
}

.channel-member-search>input {
  height: 32px;
  border-radius: 3px;
  background-color: #f1f2f4;
  border: none;
  padding: 6px 24px 6px 8px;
  background-image: url(../public/media/images/Search.svg);
  background-repeat: no-repeat;
  background-size: 22px;
  background-position-x: 97%;
  background-position-y: 50%;
  box-sizing: border-box;
  width: 100%;
}

.channel-member-search {
  display: block;
  overflow: hidden;
  box-sizing: border-box;
  /* margin-bottom: 12px; */
  margin: 0px 14px;
}

.call-advance-option-div>p {
  color: #8a94a6;
  font-family: var(--F1);
  font-size: 11px;
  font-weight: normal;
  line-height: 16px;
  margin: 0 16px 14px;
  text-transform: uppercase;
}

.added-channel-members li {
  list-style: none;
  padding: 10px 30px 10px 43px;
  box-sizing: border-box;
  position: relative;
  cursor: context-menu;
  display: block;
}

.added-channel-members li>label {
  color: #53627c;
  font-family: var(--F1);
  font-size: 14px;
  /* font-weight: 600; */
  font-weight: normal;
  line-height: 20px;
  padding-left: 0px;
  cursor: pointer;
}

.added-channel-members li>label>input {
  position: absolute;
  opacity: 0;
  cursor: context-menu;
}

.added-channel-members li>label>.checkmark {
  position: absolute;
  top: 12px;
  left: 16px;
  height: 16px;
  width: 17px;
  background-color: #fff;
  box-shadow: 0px 0px 0.5px 1px #c9ced6;
  border-radius: 2px;
  box-sizing: border-box;
  cursor: pointer;
}

.added-channel-members li>label>.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.added-channel-members li>label>input:checked~.checkmark {
  /* background-color: var(--BtnC); */
  background-color: var(--PrimaryC);
}

.added-channel-members li>label>input:checked~.checkmark:after {
  display: block;
}

.added-channel-members li>label>.checkmark:after {
  left: 6px;
  top: 1px;
  width: 3px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* #memberHolderCall li .user-name-text {
  position: relative;
  left: 20px;
  top: -3px;
} */

#memberHolderCall li .online {
  /* background-image: url(../public/media/images/basicAssets/zmdidot-circle.svg); */
  background-repeat: no-repeat;
  /* background-size: 16px; */
  position: absolute;
  width: 24px;
  left: 6px;
  top: 2px;
  height: 24px;
  background-image: url(../public/media/images/zmdidot-circle.svg);
  background-size: 15px;
  background-position: 50% center;
}

#memberHolderCall li .offline {
  /* background-image: url(../public/media/images/basicAssets/zmdidot-circle.svg); */
  background-repeat: no-repeat;
  /* background-size: 16px; */
  position: absolute;
  width: 24px;
  left: 6px;
  top: 2px;
  height: 24px;
  background-image: url(../public/media/images/zmdidot-offline.svg);
  background-size: 15px;
  background-position: 50% center;
}

#memberHolderCall li .offline,
#memberHolderCall li .online {
  left: 35px !important;
  position: absolute;
  top: 7px;
}

.call-advance-option-div .call-users-count {
  color: #8a94a6;
  font-family: var(--F1);
  font-size: 11px;
  font-weight: normal;
  line-height: 16px;
  margin: 5px 0px 0px 5px;
}

.calling_busymsg {
  width: 18px;
  position: relative;
  height: 18px;
  left: 10px;
  top: 6px;
}

/* 
#memberHolderCall li .user-name-text {
  position: relative;
  left: 20px;
  top: -3px;
} */

#memberHolderAll li .user-name-text {
  color: #8a94a6
}