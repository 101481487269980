.wrapper {
    position: fixed;
    z-index: 9;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    background-color: #01296533;
    display: flex;
    align-items: center;
    justify-content: center;
}

.popup_container {
    width: 85%;
    height: 85%;
    background-color: #fff;
    border-radius: 3px;
    padding: 25px;
    display: flow-root;
}

.header_container {
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
}

.form_container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
}

.input_group {
    width: 30%;
    height: auto;
}

.input_field {
    width: 100%;
    height: 42px;
    padding: 5px 15px;
    border-radius: 2px;
    border: 1px solid #333;
}

.save_button {
    width: auto;
    height: 42px;
    padding: 5px 25px;
    border-radius: 2px;
    border: 1px solid #333;
    background-color: #333;
    color: #fff;
    cursor: pointer;
}

.data_container {
    width: 100%;
    height: calc(100% - 122px);
    display: flex;
    border: 1px solid #333;
    margin-top: 20px;
}

.data_column {
    width: 33.33%;
    border-right: 1px solid #333;
    height: 100%;
    display: flow-root;
}

.data_column:last-child {
    border-right: none;
}

/* CustomTable.module.css */

.table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

.table th,
.table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.table tbody tr {
    height: 40px;
}

.table tr:nth-child(even) {
    background-color: #f2f2f2;
}

.table tr:hover {
    background-color: #ddd;
}

.table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}