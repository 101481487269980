.microsite-down {
    text-align: center;
    font-family: Arial, sans-serif;
    padding: 50px 20px;
  }
  
  .microsite-heading {
    font-size: 20px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .microsite-logo-container {
    margin: 20px 0;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .microsite-logo{
    max-width: 250px;
    height: auto;
  }
  .microsite-message {
    font-size: 16px;
    color: #0d2150;
    line-height: 1.5;
    margin: 20px 0;
  }
  
  @media (max-width: 768px) {
    .microsite-heading {
      font-size: 18px;
    }
  
    .microsite-message {
      font-size: 16px;
    }
  }
  