.reportingDateCon .react-datepicker-wrapper{
    width: 100% !important;
}
.maintenanceDate{
    width: 100%;
    background-color: #E3F2FF;
    border: 1px solid #d9d9d9;
}
.maintenanceDate:focus {
    background-color: white;
}
.reportingDateCon .react-datepicker-popper{
    width: 47%;
}
.reportingDateCon .react-datepicker{
    width: 100%;
}
.reportingDateCon .react-datepicker__month-container{
    width: 100%;
}
.mainHeaderContainer {
    width: 100%;
    border-radius: 5px;
    height: 38px;
    background-color: #E3F2FF;
    border: 1px solid #d9d9d9;
    /* color: gray; */
    padding: 0px;
    /* margin-top: -6px; */
}
.mainHeader {
    padding: 6px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Poppins;
    font-size: 14px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477 !important;
}
span.mfromId {
    font-size: 24px;
    color: #0b1f47;
    padding-left: 5px;
}

.input-group-header {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    height: 40px;
    width: 100%;
    margin-bottom: 20px;
    justify-content: center;

}
.input-group-header {
    background-color: #032e84;
    width: 25%;
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-size: 14px;
}
.activeOpecity {
    opacity: .6;
}

.maitenance-form-container .input-group {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 8px;
    flex-wrap: nowrap;
    position: relative;
}
.maitenance-form-container .flexColm {
    width: 23.5%;
    height: 38px !important;
}
.mainInputFiled {
    height: 38px!important;
    margin-top: 0!important;
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #5b6477;
    background-color: #e3f2ff;
    width: 100%;
}

.reportingDateCon .react-datepicker-popper[data-placement^=bottom] {
    inset: 0px auto auto 0px !important;
}
.attachFileMaintenance {
    /* min-width: calc(8% - 10px)!important; */
    min-height: 68px;
    border: 1px solid #cfd8dc;
    border-radius: 4px;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    margin: 5px 5px 5px 0;
}
.attachFile.single_file {
    min-width: 60px!important;
}
.attachFile.single_file, .moreAttach {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
}
.single_file {
    /* max-width: calc(33% - 10px); */
    width: 100%;
    min-height: 68px;
    float: left;
    border: 1px solid #cfd8dc;
    border-radius: 5px;
    background-color: #fff;
    position: relative;
    box-sizing: border-box;
    margin: 5px 5px 5px 0;
}

.totalFooter {
    display: flex;
    align-items: center;
    width: calc(100% - 124px);
    border-top: 1px solid #d9e2e8;
    padding-top: 5px;
    margin-left: 20px;
    margin-top: 10px;
}
.totalFooter div {
    width: 25%;
    font-size: 14px;
    color: #0b1f47;
    padding-bottom: 5px;
    font-weight: 700;
}
.totalFooter div:not(:first-child) {
    text-align: right;
}
textarea.maintenanceTextArea {
    border: 1px solid #d9e2e8;
    padding: 7px 24px 7px 7px;
}
.discussion_main_area.activityArea {
    overflow-y: auto;
    height: calc(100vh - 440px)!important;
    overflow-x: hidden;
    border-top: 1px solid #d9e2e8;
    border-bottom: 1px solid #d9e2e8;
}