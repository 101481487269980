.closeReportProperties {
    width: 21px;
    height: 25px;
    background-image: url(../media/images/close_button.svg);
    background-size: 22px;
    background-position: 50%;
    right: 0;
    top: 0!important;
    position: relative;
    background-repeat: no-repeat;
    cursor: pointer;
}
#root.dark .closeReportProperties {
    width: 21px;
    height: 25px;
    background-image: url(../media/images/close_button_w.svg);
    background-size: 22px;
    background-position: 50%;
    right: 0;
    top: 0!important;
    position: relative;
    background-repeat: no-repeat;
    cursor: pointer;
}

.closeAdminPanelReport {
    padding-left: 30px;
    font-size: 20px;
    color: #0c1e47;
    background-image: url(../media/images/backIconNew.svg);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 0 0;
    cursor: pointer;
    width: 14px;
    height: 12px;
    font-weight: 700;
}
.createReportButton {
    width: 130px;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
    border-radius: 50px;
    background-color: #0b1f47;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: white;
    cursor: pointer;
}
.createReportButton:hover {
    background-color: #318fff;
    color: #fff;
}
.srcSectionPopup {
    width: 24px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    background-image: url(../media/images/task_img/task-search-icon.webp);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 50% 50%;
    position: absolute;
    top: 5px;
    right: 21px;
}
.task_body_area.inReportArea {
    height: calc(100% - 62px);
}
.divTableBody {
    display: flex;
        flex-direction: column;
        height: calc(100vh - 115px);
        overflow-y: auto;
        min-width: 100%;
}

.divTableRow {
    display: flex;
    transition: all .25s ease-in-out;
    border-bottom: 1px solid #e5eef4;
    padding: 0 19px;
    align-items: center;
}
.divTableFirstRow {
    background-color: #fff;
    font-size: 14px;
    font-weight: 700;
    color: #fff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    padding: 0 20px 0 30px;
    z-index: 9;
    width: 100%;
}
#root.dark .divTableFirstRow {
    background-color: #424242 !important;
  
}

.divTableRow:hover {
    background-color: #ebebeb;
    cursor: pointer;
}
.divTableFirstRow .divTableCell {
    padding: 10px;
    font-size: 14px;
    color: #0b1f47;
    font-weight: 400;
    display: flex;
    align-items: center;
}
.divTableCell {
    color: #0b1f47;
}
.divTable.eodTable .divTableRow .divTableCell {
    flex-grow: 0;
    flex-basis: 9%;
}
.divTableCell {
    flex: 1 1;
    /* padding: 10px; */
    padding: 10px 10px 10px 0;
    font-size: 14px;
}
.divTableCell p.columnNum {
    color: #0b1f47;
    font-size: 12px;
}
span.columnNum {
    font-weight: 400;
    color: #858fa3;
    font-size: 12px;
    width: auto;
}
span.sortIcons {
    width: 30px!important;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.divTableBody .sortIcons:before {
    content: "\f160";
    font-family: "FontAwesome";
    margin-left: 0;
    font-size: 16px;
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    color: #0c1e47;
    left: -20px;
    top: -4px;
}
.divTableBody .asce .sortIcons:before {
    content: "\f161";
    font-family: "FontAwesome";
    margin-left: 0;
    font-size: 16px;
    position: absolute;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 600;
    color: #0c1e47;
    left: -20px;
    top: -4px;
}

.divTableBody .desc .sortIcons:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    left: -20px;
    top: 19px;
}
.divTableBody .asce .sortIcons:after {
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: red;
    left: -19px;
    top: -5px;
}
.divTableCell .dateDivReport {
    width: 70px;
}

._inputBar::placeholder {
    color: #318fff !important;
}

.divTableRow .divTableCell {
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477;
}
p.comps_text, p.net_sales_text, p.total_text {
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}
p.net_sales_text, p.total_text {
    color: #188744;
}
p.comps_text {
    color: #d31c36;
}
p.completeColor, p.draftColor {
    width: 80px;
    height: 22px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2px 20px;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}
p.completeColor {
    border-radius: 125px;
    border: 1px solid #188744;
    background-color: #188744;
    color: #fff;
    font-family: "Poppins";
}
p.draftColor {
    border-radius: 125px;
    border: 1px solid #ffaf4c;
    background-color: #ffaf4c;
    font-family: Poppins;
    color: #0b1f47;
}
.task_right_container.forQuickViewTask.reportContainer .opt_icons.conv_filter {
    background-size: 20px;
}
.conv_filter {
    background-image: url('../media/images/icons/whiteTheme/SVG/WorkFreeli_Icons_Blue_filter2.svg');
    background-size: 26px;
}
.conv_filter_chatTop{
    background-size: 24px !important;
}
.ForTaskListInner {
    position: absolute;
    left: 5px;
    top: 34px;
}
ul.userActionPopup.showAll.checklistPopup.ForTaskList {
    left: 0;
    width: 200px !important;
    text-align: left;
}

.divTableCell.lastupdatedCol {
    padding-right: 0;
}
.divTableCell:last-child {
    border-right: none;
}
.divTableCell.lastupdatedCol .columnNum, .divTableCell.lastupdatedCol .lastUpdated_text {
    width: 145px;
}
.lastUpdated_text p.user, .lastUpdated_text p.userUpdateDate {
    font-family: Poppins;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
}
.lastUpdated_text p.user {
    font-size: 13px;
    font-weight: 600;
    color: #0b1f47;
}
.lastUpdated_text p.userUpdateDate {
    font-size: 9px;
    font-weight: 400;
    color: #5b6477;
}
.srcSectionPopup {
    width: 24px;
    height: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0 20px 20px 0;
    cursor: pointer;
    background-image: url(../media/images/task_img/task-search-icon.webp);
    background-repeat: no-repeat;
    background-size: 15px;
    background-position: 50% 50%;
    position: absolute;
}
.maintenanceDiv {
    padding: 5px 19px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.maintenanceDiv, span.mfromId {
    font-size: 24px;
    color: #0b1f47;
}
.maintenanceDiv, span.mfromId {
    font-size: 24px;
    color: #0b1f47;
}
.maintenanceDiv {
    font-size: 14px;
}
























.reportingDate {
    position: relative;
    width: 50%;
}

.reportingHead {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.reportingNotes {
    width: 50%;
}

p.reportLabel {
    font-size: 14px;
    color: #0b1f47;
    padding-bottom: 5px;
}

span.requiredLabel {
    color: red !important;
    font-size: 14px;
}

.reportUpper_area {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding-top: 20px;
    border-top: 1px solid #d9e2e8;
    padding: 10px 19px 42px 19px;
}

.reportFlexDiv {
    display: flex;
    align-items: center;
    position: relative;
}

.reportFlexDiv:hover .removeFile{
    display: block !important;
} 

.realTimeData {
    width: 55%;
    position: relative;
}

.realTimeData:hover .removeFile {
    display: block !important;
}

.task_body_area.inReportArea .custom_dropdown-header {
    padding: 6px 10px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Poppins;
    font-size: 12px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #5b6477 !important;
}

.reportMiddleArea {
    /* border-top: 1px solid #d9e2e8; */
    padding: 0px 19px 0 0px;
}

.reportMiddleHeaderInputs_area {
    border-bottom: 1px solid #d9e2e8;
    padding: 0px 0px 10px 0px;
}

.reportMiddleHeader {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 0px 0 19px;

}

.reportMiddleHeader .headCloms {
    width: 25%;
    display: flex;
    align-items: center;
}

/* .headCloms.withPercent {
    width: 30%;
} */

.headCloms span {
    color: #318fff;
}

.headCloms .reportTotal {
    color: black;
    margin-left: 7px;
    font-size: 14px;
}

.reportUpper_area span {
    color: #318fff;
}

.footer_area {
    padding: 15px 19px 0 19px;
}

.task_body_area.inReportArea .tagCoupleBtn {
    /* position: relative !important;
    bottom: 27px;
    display: flex;
    right: 20px;
    align-items: flex-end;
    gap: 9px;
    float: right;
    top: 7px; */
    position: relative !important;
    bottom: 27px;
    display: flex;
    right: 20px;
    align-items: flex-end;
    gap: 9px;
    float: right;
    top: 7px;
    flex-wrap: wrap;
    justify-content: right;
}
.tagCoupleBtn .skipButton, .tagCoupleBtn .viewFilesButton {
    height: 34px;
    flex-grow: 0;
    border-radius: 46px;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
    cursor: pointer;
}
.tagCoupleBtn .skipButton {
    width: 70px;
    padding: 7px 19px 7px 22px;
    background-color: #0b1f47;
}
.skipButton.createRep {
    width: 73px!important;
    height: 30px!important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 125px!important;
    border: 1px solid #0b1f47;
    background-color: initial!important;
    font-size: 12px!important;
    color: #0b1f47!important;
}
.tagCoupleBtn .viewFilesButton {
    width: 108px;
    padding: 7px 21px 7px 20px;
    background-color: #318fff;
}

.innerLabel {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}

.reportingDate input.start_Date_task {
    width: 245px !important;
}

.custom_dropdown-body_task.inReport.open {
    border: 1px solid #42424229 !important;
}

input.hours_count.trackTime:focus {
    background-color: #ffffff;
}

.realTimeData input.track_inputBox::placeholder {
    font-size: 12px !important;
}

.task_body_area.inReportArea input.hours_count.trackTime::placeholder {
    color: #a5a0a0 !important;
    font-size: 14px !important;
}
span.selectVenueLabel{
    color: #a5a0a0 !important;
}

.footer_area textarea.youtnotesTextArea::placeholder {
    color: #a5a0a0 !important;
    font-size: 14px !important;
}

.footer_area textarea.youtnotesTextArea {
    padding-top: 5px;
    border-radius: 5px;
}

h4.popupTitle.Warning {
    display: flex;
    gap: 5px;
    color: red;
}

.viewFilesButton.buttonDisabled {
    opacity: 0.6;
    pointer-events: none;
}

.task_right_container.forQuickViewTask.reportContainer {
    /* width: 100%; */
    width: calc(100% - 346px);
    left: 346px;

}

.reportingDate input.reportingDate {
    border: 1px solid #d9d9d9;
    height: 30px;
    padding-left: 10px;
    border-radius: 5px;
    color: #5b6477;
    font-size: 12px;
    line-height: 35px;
    width: 100%;
}

.reportingNotes .custom_dropdown_task {
    height: 40px;
}
.reportingDate input.reportingDate::placeholder {
    color: #5b6477 !important;
    font-size: 14px !important;
}

.reportingDate .react-datepicker-wrapper {
    width: 100%;
}

.reportingDate .react-datepicker-popper[data-placement^=bottom] {
    width: 100%;
}

.reportingDate .react-datepicker {

    width: 100%;
}

.reportingDate .react-datepicker__month-container {
    width: 100%;
    float: left;
}

.reportMiddleHeader.topHeadRow {
    padding-top: 0;
    height: 40px;
    /* border-bottom: 1px solid #d9e2e8; */
    /* background-color: #032e84; */
}

.reportMiddleHeader.topHeadRow .headCloms {
    border-left: 10px solid #fff;
    height: 100%;
    background-color: #032e84;
}

.reportMiddleHeader.topHeadRow .headCloms:first-child {
    border-left: unset !important;

}

.headCloms.withPercent {
    padding-left: 10px;
}

.reportMiddleHeader.topHeadRow .headCloms p.reportLabel {
    color: #fff;
}
.inReportArea .innerLabel, .readonlycursor {
    color: grey!important;
}
.innerLabel {
    font-size: 14px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}
.stickyBottomRowContainer {
    max-width: 100%;
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    z-index: 10;
    background-color: #6d5757;
}
.divTableRow.totalRow {
    background-color: #fff;
    max-width: 100%;
    overflow: hidden;
}
.divTableRow.totalRow .divTableCell {
    font-weight: 700;
}
.divTable.eodTable .divTableRow.even .divTableCell {
    flex-grow: 0;
    flex-basis: 9%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.divTable.eodTable .divTableRow .divTableCell:nth-of-type(1) {
    flex-basis: 10%;
}

.divTable.eodTable .divTableRow .divTableCell:nth-of-type(2) {
    flex-basis: 12%;
}

.divTable.eodTable .divTableRow .divTableCell:nth-of-type(3) {
    flex-basis: 9%;
}

.divTable.eodTable .divTableRow .divTableCell:nth-of-type(8) {
    flex-basis: 11%;
}
.divTable.eodTable .divTableRow .divTableCell:nth-of-type(9) {
    flex-basis: 11%;
}
.divTable.eodTable .divTableRow .divTableCell:nth-of-type(10) {
    flex-basis: 15%;
}

.divTable.eodTable .divTableRow .divTableCell span.color-black {
    display: none;
}
.divTableRow.totalRow:hover {
    background-color: #ebebeb;
    cursor: pointer;
}
.task_body_area.inReportArea input{
    height: 40px ;
    font-size: 14px;
}
.task_body_area.inReportArea input:focus{
    background-color: #ffffff;
}
.task_body_area.inReportArea input.hours_count.trackTime{
    height: 40px !important;
    text-align: right;
    font-size: 14px !important;
}

.reportingNotes .custom_dropdown_task .custom_dropdown-header{
    padding: 10px 10px;
    font-size: 14px !important;
}
.reportMiddleHeader .headCloms p.reportLabel{
    height: 40px;
}
.no-results:hover {
    background-color: unset;
    color: #0d2150;
    text-align: center;
}
.no-results {
    background-color: unset;
    color: #0d2150;
    text-align: center;
}
.task_body_area.inReportArea input::placeholder{
    font-size: 14px !important;
}
.red_border {
    border: 1px solid red !important;
    
}
.Camps, .Netsales, .TotalData {
    width: 17%;
}
.footer_area .operational_note.allNotes:hover .removeFile{
    display: block;
}
.footer_area .allNotes {
    position: relative;
    padding-bottom: 20px;
}
.footer_area .briefing_note.allNotes:hover .removeFile{
    display: block;
}
.footer_area .service_note.allNotes:hover .removeFile{
    display: block;
}
.footer_area .kitchen_note.allNotes:hover .removeFile{
    display: block;
}
.reportFlexDiv input.hours_count.trackTime {
    text-align: left !important;
}
.charecteLeft.redColor {
    color: red;
    visibility: visible;
}
.addNewLabel {
    display: flex;
    align-items: center;
    grid-gap: 4px;
    gap: 4px;
    cursor: pointer;
    width: -webkit-max-content;
    width: max-content;
    padding-top: 10px;
}
.addNewLabel, .addNewLabel span {
    color: #0b1f47;
}
.participantsModalBody {
    padding-left: 0px;
    padding-bottom: 20px;
}
.task_body_area.inReportArea .reportingHead{
    padding: 10px 19px 42px 19px;
}
.deleteBtnEod{
    padding: 6px 21px 7px 20px;
    border-radius: 32px;
    background-color: #FF5955;
    width: 95px;
    height: 30px;
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    
}
.deleteBtnEod:hover{
    background-color: #D82D28;
}

.salesProgressBarHead.forChartHead {
    padding: 20px 20px 0px 25px;
}
div#totalLabourCosts input {
    border-radius: 4px;
    background-color: #f1f6fa;
}
div#totalLabourCosts input:focus{
    background-color: #ffffff;
}
div#totalLabourCosts input{
    color: #5b6477;
}
div#totalLabourCosts input.laboutCategory{
    color: #0b1f47;
    pointer-events: none;
    background-color: transparent;
}
.task_body_area.inReportArea .reportingDate.inSalesReport input {
    height: 30px;
    font-family: Poppins;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #5b6477;
  background-color: #f1f6fa;
}
.task_body_area.inReportArea .reportingDate.inSalesReport input:focus{
    background-color: #ffffff;
}
.reportingNotes .custom_dropdown_task.inSalesReportVenue {
    height: 30px;
}
.reportingNotes .custom_dropdown_task.inSalesReportVenue .custom_dropdown-header {
    padding: 0px 10px;
    font-size: 16px !important;
}
.reportingNotes .custom_dropdown_task.inSalesReportVenue .custom_dropdown-body_task.inReport.open {
    border: 1px solid #42424229 !important;
    top: 4px;
}
.reportingDate.inSalesReport p.reportLabel {
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}
.reportingNotes.salesReportVenueArea p.reportLabel{
    padding-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}
.reportingNotes.salesReportVenueArea i.fa.fa-chevron-right.custom_icon {
    top: 2px;
    position: relative;
}
.task_body_area.inReportArea .VenueInputArea input.venueTitle {
    border-radius: 4px;
    background-color: #f1f6fa;
    width: 100%;
    border: 1px solid #d9d9d9;
    height: 30px !important;
    padding-left: 10px;
    color: #5b6477;
}
.task_body_area.inReportArea .VenueInputArea {
position: relative;
}
.task_body_area.inReportArea .VenueInputArea:hover .removeFile{
display: flex;
}
.task_body_area.inReportArea .VenueInputArea .custom_dropdown-body_task.inReport {
    position: absolute;
    top: 32px;
    height: 380px;
    overflow: auto;
}
.notelables {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    width: max-content;
}
.note_top_area {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    padding-bottom: 20px;
}
.charecteLeft.forNote {
    font-family: Poppins;
    font-size: 16px !important;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #0b1f47;
}
span.charecteLeft.forNote.redColor{
    color: red !important;
}
#root.dark span.charecteLeft.forNote.redColor{
    color: red !important;
}
.salesReport.adminRightHead.taskHeader {
    border: unset;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.salesReportDashboard.adminRightHead.taskHeader {
    border-bottom: solid 1px #d9d9d9;
    height: 56px;
    display: flex; 
    align-items: center;
    justify-content: space-between;
}
.shiftTitle:hover .removeFile {
    display: flex;
}
span.notApplicable {
    font-size: 10px;
    text-align: right;
    padding-left: 20px;
}
.total_Info {
    pointer-events: none;
    cursor: none;
}
#root.dark .eodTabTextActive {
    color: #ffffff !important;
    border-bottom: 2px solid #ffffff !important;
}
#root.dark .action_list, #root.dark .barChartDayName, #root.dark .calenderIconDate, #root.dark .chartRightSideText p, #root.dark .divTableCell, #root.dark .formID_text, #root.dark .fromDate_SalesHover, #root.dark .salesProgressBarDateText, #root.dark .salesProgressBarText, #root.dark .salesReportDateText, #root.dark .salesReportIcon, #root.dark .salesReportNumber, #root.dark .salesReportText, #root.dark .totalCostEOD, #root.dark .totalCostEODCon {
    color: #fff !important;
}

#root.dark .salesProgressBarCon, #root.dark .salesReportMainCon, #root.dark .searchAndFilter, #root.dark .shareLinkModal, #root.dark .tagModal, #root.dark .taskCreatorPopup, #root.dark .themeMenu, #root.dark .top_bar, #root.dark .userInfoModal, #root.dark .userMiniModal, #root.dark .eodBackground {
    background-color: #181818;
}
#root.dark .salesProgressBarHead, #root.dark .totalsalesArea{
    background-color: #181818;
}
#root.dark .salesReportGraphBox {
    background-color: #424242;
}
#root.dark .totalsalesArea h2, #root.dark .totalLabel, #root.dark .barChartCostHead p{
    color: #fff;
}
#root.dark .eodBackground div, #root.dark .eodBackground h2  {
    background-color: #181818 !important;
    color: #fff !important;
}
.loaderMain.mini_loader.salesLoader {
    height: 80%;
}
#root.dark .loaderMain.mini_loader.salesLoader {
    height: 80%;
    background-color: #000;
}
#root.dark .css-w89k0v-control{
    background-color: #181818 !important;
    color: #fff !important;
}
#root.dark .css-nbv5hw-control{
    background-color: #181818 !important;
    color: #fff !important;
}
#root.dark .css-1p3m7a8-multiValue svg{
    color: #181818 !important;
}
#root.dark .divTableFirstRow .divTableCell p.columnNum{
    color: #fff !important; 
}
#root.dark .divTableRow.divTableFirstRow  .sortIcons:before {
    color: #fff;

}
#root.dark .task_body_area.inReportArea{
    background-color: #181818 !important;
    color: #fff !important;
}
#root.dark .custom_dropdown-body_task.inReport .searchareaDiv {
    background-color: #181818 !important;
}
#root.dark .custom_dropdown-body_task.inReport .custom_dropdown-item{
    color: #fff !important;
}
#root.dark  .popUpRightContainer{
    background-color: #181818 !important;
}
#root.dark  .popUpRightContainer div{
    color: #fff !important;
   
}
#root.dark .popUpRightContainer p{
    color: #fff !important;
   
}
#root.dark .footer_area textarea.youtnotesTextArea{
    background-color: #181818 !important;

    color: #fff !important;
   
}
#root.dark .charecteLeft.forNote {

    color: #fff !important;
}
#root.dark .note_top_area span {
    color: #fff;
}
#root.dark .laboutCategory {
    color: #fff !important;
}